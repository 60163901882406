import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "g-container px-3" }
const _hoisted_2 = { class: "g-container pt-10 px-3" }
const _hoisted_3 = {
  class: "pb-10",
  style: {"display":"flex","justify-content":"center","align-content":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_Title = _resolveComponent("Title")!
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_ProductItemList = _resolveComponent("ProductItemList")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Breadcrumb)
    ]),
    _createVNode(_component_Title, {
      title: `搜尋“` + _ctx.searchName + `”`,
      class: "g-title-type2"
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SearchInput, {
          style: {"width":"770px"},
          placeholder: '請輸入關鍵字',
          getInput: _ctx.getInput,
          "onUpdate:getInput": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.getInput) = $event))
        }, null, 8, ["getInput"])
      ]),
      _createVNode(_component_ProductItemList, { class: "hidden pt-5 sm:block" }),
      _createVNode(_component_ProductItemList, {
        class: "sm:hidden",
        row: 2,
        isMobile: true
      })
    ])
  ], 64))
}