import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "g-container hidden sm:block" }
const _hoisted_2 = {
  class: "sm:hidden breadcrumb fixed z-[3] bg-white w-full",
  ref: "breadcrumb"
}
const _hoisted_3 = { class: "flex flex-col sm:p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_SubHeader = _resolveComponent("SubHeader")!
  const _component_SquareItemList = _resolveComponent("SquareItemList")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Breadcrumb, { class: "hidden sm:flex my-2.5" }),
      _createVNode(_component_SubHeader, {
        onClickCmdyClassList: _ctx.clickCmdyClassList,
        onClickSubCmdyClassList: _ctx.clickSubCmdyClassList,
        class: "sm:mb-5"
      }, null, 8, ["onClickCmdyClassList", "onClickSubCmdyClassList"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SubHeader, {
        onClickCmdyClassList: _ctx.clickCmdyClassList,
        onClickSubCmdyClassList: _ctx.clickSubCmdyClassList,
        class: "sm:mb-5"
      }, null, 8, ["onClickCmdyClassList", "onClickSubCmdyClassList"])
    ], 512),
    _createElementVNode("div", {
      class: _normalizeClass(["g-container z-0 bg-blue sm:mt-0", [
      {
        'mt-[40px]': _ctx.productStore.state.selectedCmdyClass.Level === 1,
        'mt-[105px]': _ctx.productStore.state.selectedCmdyClass.Level !== 1,
      },
    ]])
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SquareItemList, {
          productList: _ctx.productList,
          "onUpdate:productList": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.productList) = $event)),
          selectProduct: _ctx.selectProduct,
          "onUpdate:selectProduct": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectProduct) = $event))
        }, null, 8, ["productList", "selectProduct"])
      ])
    ], 2)
  ], 64))
}