import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-952f0c74"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "square-photo-content px-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "square-item",
    style: _normalizeStyle({ width: _ctx.width, height: '100%' })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["product-img bg-image-style hidden sm:block rounded-full", {
          'border-[2px]':
            _ctx.imageSelected === '' &&
            _ctx.productStore.state.selectedCmdyClass.CmdyClassId !== undefined &&
            _ctx.productStore.state.selectedCmdyClass.CmdyClassId === _ctx.BgIconId,
          'border-primary-color':
            _ctx.productStore.state.selectedCmdyClass.CmdyClassId !== undefined &&
            _ctx.productStore.state.selectedCmdyClass.CmdyClassId === _ctx.BgIconId,
        }]),
        style: _normalizeStyle({
          backgroundImage: 'url(' + _ctx.showImage + ')',
          width: `86px`,
        })
      }, null, 6),
      _createElementVNode("div", {
        class: _normalizeClass(["product-img bg-image-style sm:hidden rounded-full", {
          'border-[2px]':
            _ctx.imageSelected === '' &&
            _ctx.productStore.state.selectedCmdyClass.CmdyClassId !== undefined &&
            _ctx.productStore.state.selectedCmdyClass.CmdyClassId === _ctx.BgIconId,
          'border-primary-color':
            _ctx.productStore.state.selectedCmdyClass.CmdyClassId !== undefined &&
            _ctx.productStore.state.selectedCmdyClass.CmdyClassId === _ctx.BgIconId,
        }]),
        style: _normalizeStyle({
          backgroundImage: 'url(' + _ctx.showImage + ')',
          width: `60px`,
        })
      }, null, 6)
    ]),
    (_ctx.title !== '')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["description-content whitespace-normal text-gary-text-color mt-[4px] sm:mt-[13px] text-[13px] sm:text-sm", {
        'text-primary-color':
          _ctx.productStore.state.selectedCmdyClass.CmdyClassId !== undefined &&
          _ctx.productStore.state.selectedCmdyClass.CmdyClassId === _ctx.BgIconId,
      }]),
          style: _normalizeStyle({
        width: _ctx.width,
      })
        }, _toDisplayString(_ctx.title), 7))
      : _createCommentVNode("", true)
  ], 4))
}