import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock(_Transition, {
    class: "cursor-pointer",
    name: _ctx.isLike ? 'zoom' : '',
    mode: "out-in"
  }, {
    default: _withCtx(() => [
      (_ctx.isLike)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: "like",
            iconName: _ctx.likeIconName,
            width: _ctx.width
          }, null, 8, ["iconName", "width"]))
        : (_openBlock(), _createBlock(_component_Icon, {
            key: "unlike",
            iconName: _ctx.unlikeIconName,
            width: _ctx.width
          }, null, 8, ["iconName", "width"]))
    ]),
    _: 1
  }, 8, ["name"]))
}