
import { computed, defineComponent, ref, toRef, watch } from 'vue'
import Input from '@/components/common/Input.vue'
export default defineComponent({
  emits: ['getCaptchaImage', 'update:captchaCode'],
  props: {
    image: {
      type: String
    },
    captchaCode: {
      type: String
    }
  },
  components: {
    Input
  },
  setup (props, context) {
    const { emit } = context
    const previewLogo = ref(new Image())
    const imageRef = toRef(props, 'image')
    const captchaCodeRef = computed({
      get () {
        return props.captchaCode
      },
      set (val) {
        emit('update:captchaCode', val)
      }
    })
    watch(imageRef, (newVal) => {
      previewLogo.value.src = `data:image/png;base64,${newVal}`
    })

    return { previewLogo, captchaCodeRef }
  }
})
