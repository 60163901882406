import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "product-item-list" }
const _hoisted_2 = { class: "flex flex-wrap justify-between space-b gap-[10px] sm:gap-[20px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RectangleItemFullImage = _resolveComponent("RectangleItemFullImage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ipSubListRef, (ipSubItem, index) => {
        return (_openBlock(), _createBlock(_component_RectangleItemFullImage, {
          key: index,
          style: _normalizeStyle({ 'flex-basis': `calc(100%/${_ctx.row} - 20px)` }),
          class: "hidden sm:block sm:pb-4",
          title: ipSubItem.Name,
          image: ipSubItem.CoverImage,
          onClick: ($event: any) => (
          _ctx.goIPProductList(ipSubItem.ShopHallCode, '', ipSubItem.CmdyThemeId)
        )
        }, null, 8, ["style", "title", "image", "onClick"]))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ipSubListRef, (ipSubItem, index) => {
        return (_openBlock(), _createBlock(_component_RectangleItemFullImage, {
          key: index,
          style: _normalizeStyle({ 'flex-basis': `calc(100%/${_ctx.row} - 5px)` }),
          class: "sm:hidden",
          title: ipSubItem.Name,
          image: ipSubItem.CoverImage,
          onClick: ($event: any) => (
          _ctx.goIPProductList(ipSubItem.ShopHallCode, '', ipSubItem.CmdyThemeId)
        )
        }, null, 8, ["style", "title", "image", "onClick"]))
      }), 128))
    ])
  ]))
}