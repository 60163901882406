
import { defineComponent, ref } from 'vue'
import { numberInput, positiveIntegerInput } from '@/utils/utils'
import { useField } from 'vee-validate'
export default defineComponent({
  name: 'g-input',
  props: {
    width: {
      type: String,
      require: false,
      default: '100px'
    },
    height: {
      type: String,
      require: false,
      default: '40px'
    },
    modelValue: {
      type: [String, Number],
      require: false,
      default: ''
    },
    label: {
      type: String,
      require: false,
      default: ''
    },
    placeholder: {
      type: String,
      require: false,
      default: ''
    },
    required: {
      type: Boolean,
      require: false,
      default: false
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false
    },
    iconName: {
      type: String,
      require: false,
      default: ''
    },
    iconWidth: {
      type: String,
      require: false,
      default: ''
    },
    inputType: {
      type: String,
      require: false,
      default: 'text'
    }
  },
  setup (props, context) {
    const { emit } = context
    const inputRef = ref(null)
    const change = (event: Event) => {
      if (props.inputType === 'number') {
        (event.target as HTMLInputElement).value = numberInput(event)
        emit('update:modelValue', numberInput(event))
      } else if (props.inputType === 'positiveInteger') {
        (event.target as HTMLInputElement).value = positiveIntegerInput(event)
        emit('update:modelValue', positiveIntegerInput(event))
      } else {
        emit('update:modelValue', (event.target as HTMLInputElement).value)
      }
      // console.log(props.modelValue)
      // console.log((event.target as HTMLInputElement).value)
      // console.log(typeof (event.target as HTMLInputElement).value)
    }

    return {
      change,
      inputRef,
      numberInput
    }
  }
})
