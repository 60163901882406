import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "edit-input flex w-full h-4.5 p-0 rounded border-solid border-black-300-color items-center" }
const _hoisted_2 = {
  key: 0,
  class: "font-normal text-[0.8125rem] text-gary-text-color tracking-[.12em]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isEdit)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.modelValue), 1))
      : _createCommentVNode("", true),
    (_ctx.modelValue === '' && !_ctx.isEdit)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: "font-normal cursor-text text-[0.8125rem] text-black-300-color tracking-[.12em]",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.editName && _ctx.editName(...args)))
        }, " 輸入作品名稱 "))
      : _createCommentVNode("", true),
    (_ctx.isEdit)
      ? _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 2,
          ref: "nameInputRef",
          type: "text",
          class: "font-normal text-[0.8125rem] text-gary-text-color tracking-[.12em]",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.name) = $event)),
          onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.editDone && _ctx.editDone(...args)), ["enter"]))
        }, null, 544)), [
          [_vModelText, _ctx.name]
        ])
      : _createCommentVNode("", true),
    (!_ctx.isEdit)
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 3,
          class: "cursor-pointer ml-2.5",
          onClick: _ctx.editName,
          width: '16px',
          iconName: "edit"
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true),
    (_ctx.isEdit)
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 4,
          class: "cursor-pointer ml-2.5",
          onClick: _ctx.editDone,
          width: '16px',
          iconName: "selected_white"
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ]))
}