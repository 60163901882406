
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'g-callapse',
  props: {
    height: {
      type: String,
      default: '197px'
    }
  },
  setup () {
    const listeners = {
      // 元素由隐藏变为可见
      onEnter (el: HTMLElement) {
        el.style.height = 'auto'
        const endHeight = window.getComputedStyle(el).height
        el.style.height = '0px'
        el.offsetHeight as any
        el.style.height = endHeight
      },
      onAfterEnter (el: HTMLElement) {
        el.style.height = null as any
      },
      // 元素由可见变为隐藏
      onLeave (el: HTMLElement) {
        el.style.height = window.getComputedStyle(el).height
        el.offsetHeight as any
        el.style.height = '0px'
      },
      onAfterLeave (el: HTMLElement) {
        el.style.height = null as any
      }
    }

    return {
      listeners
    }
  }
})
