

/**
 * inline 預設
 *
 * example
 * <CheckBoxGroup v-model="check">
      <CheckBox text="測試1" :value="1"></CheckBox>
      <CheckBox text="測試2" :value="2"></CheckBox>
      <CheckBox text="測試3" :value="3"></CheckBox>
      <CheckBox text="測試4" :value="4"></CheckBox>
    </CheckBoxGroup>
 */

import { defineComponent, toRefs, nextTick, computed, provide } from 'vue'
export default defineComponent({
  name: 'g-check-box-group',
  props: {
    modelValue: {
      type: [Array],
      default: () => undefined
    },
    inline: {
      type: Boolean,
      default: true
    }
  },
  emits: ['change', 'update:modelValue'],
  setup (props, ctx) {
    const changeEvent = (value: any) => {
      ctx.emit('update:modelValue', value)
      nextTick(() => {
        ctx.emit('change', value)
      })
    }

    const _modelValue = computed({
      get () {
        return props.modelValue
      },
      set (val) {
        changeEvent(val)
      }
    })

    const customClass = computed(() => {
      let customClass = ''
      if (!props.inline) {
        customClass += ' ' + 'flex-col'
      }

      return customClass
    })

    provide('CheckboxGroup', {
      name: 'CheckboxGroup',
      _modelValue,
      ...toRefs(props),
      changeEvent
    })

    return {
      customClass
    }
  }
})
