
import { defineComponent, ref, reactive, onMounted, toRef } from 'vue'
import Collapse from '@/components/common/Collapse.vue'
import { useRoute, useRouter } from 'vue-router'
export default defineComponent({
  name: 'g-faq-menu',
  emits: ['update:modelValue', 'menuClick', 'itemsClick'],
  components: {
    Collapse
  },
  props: {
    modelValue: {
      type: Object,
      required: false,
      default: null
    },
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    multiple: {
      type: Boolean,
      required: false,
      default: true
    },
    showList: {
      type: Array,
      default: () => []
    },
    selectItem: {
      default: null
    }
  },
  setup (props, context) {
    const { emit } = context
    const route = useRoute()
    const router = useRouter()
    const showListRef = toRef(props, 'showList')
    const selectItemRef = toRef(props, 'selectItem')
    const itemsClickRef = (item: any) => {
      emit('itemsClick', item)
    }
    const menuClickRef = (item: any) => {
      emit('menuClick', item)
    }
    return {
      showListRef,
      selectItemRef,
      menuClickRef,
      itemsClickRef
    }
  }
})
