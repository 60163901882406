
import { defineComponent, ref, provide, onBeforeUpdate, onUpdated } from 'vue'
import SquareItemFullImage from '@/components/SquareItemFullImage.vue'
import RectangleItem from '@/components/RectangleItem.vue'
import { CmdyClass as CmdyType } from '@/types/cmdyType'
import productStore from '@/composition/productStore'
export default defineComponent({
  props: {},
  components: {
    SquareItemFullImage,
    RectangleItem
  },
  setup () {
    provide('productStore', productStore)
    interface RecObject {
      isMobile: boolean;
      number: number;
      index: number;
      content: HTMLElement;
    }
    const selectedRec = ref({
      isMobile: false,
      number: 0,
      index: 0,
      content: {} as HTMLElement
    } as RecObject)
    const recList = ref([])
    const selectProductIndex = ref(0)
    const getSelectProduct = (
      product: CmdyType,
      index: number,
      isMobile: boolean
    ) => {
      productStore.setSelectedCmdy(product)
      selectProductIndex.value = index
      productStore.setShowRectangleItem(false)
      if (recList.value) {
        recList.value.forEach((rec: RecObject) => {
          const position = selectProductIndex.value - rec.index

          if (isMobile === true && rec.isMobile === true) {
            if (position < 1 && position > -1 && rec.number === 1) {
              selectedRec.value = rec
            }

            if (position < 3 && position > 0 && rec.number === 2) {
              selectedRec.value = rec
            }
            if (position < 5 && position > 2 && rec.number === 3) {
              selectedRec.value = rec
            }
          }
          if (isMobile === false && rec.isMobile === false) {
            if (position < 5 && position > -1 && rec.number === 1) {
              selectedRec.value = rec
            }
            if (position >= 5 && position < 10 && rec.number === 2) {
              selectedRec.value = rec
            }
          }
        })
      }
      productStore.setShowRectangleItem(true)
    }
    onBeforeUpdate(() => {
      recList.value = []
    })
    onUpdated(() => {
      if (productStore.state.showRectangleItem === false) {
        selectedRec.value = {
          isMobile: false,
          number: 0,
          index: 0,
          content: {} as HTMLElement
        }
      } else {
        if (productStore.state.selectedCmdyClass.Level !== 1) {
          if (selectedRec.value.isMobile === true) {
            (document.getElementById('app') as HTMLBodyElement).scrollTo({
              top: selectedRec.value.content.offsetTop - 100,
              left: 0,
              behavior: 'smooth'
            })
          } else {
            (document.getElementById('app') as HTMLBodyElement).scrollTo({
              top: selectedRec.value.content.offsetTop - 50,
              left: 0,
              behavior: 'smooth'
            })
          }
        }
        if (productStore.state.selectedCmdyClass.Level === 1) {
          (document.getElementById('app') as HTMLBodyElement).scrollTo({
            top: selectedRec.value.content.offsetTop - 50,
            left: 0,
            behavior: 'smooth'
          })
        }
      }
    })

    return {
      recList,
      productStore,
      selectProductIndex,
      getSelectProduct
    }
  }
})
