import { State, ShoppingCartInfo, UserInfo } from '@/store/types/stateType'
import * as type from './mutationsType'
// import { CmdyClass } from '@/types/cmdyType'
// import { UserLoginInfo } from '@/types/userLoginType'
// import { DeliveryType, PaymentType, InvoiceType } from '@/types/ResponseType'
// lodash
import cloneDeep from 'lodash/cloneDeep'

export const state: State = {
  showLogo: false,
  showMenu: false,
  isShowLoading: false,
  userInfo: undefined
}

export const mutations = {
  [type.SHOW_LOGO]: (state: State, isShowLogo: boolean) => {
    state.showLogo = isShowLogo
  },
  [type.SHOW_MENU]: (state: State, isShowMenu: boolean) => {
    state.showMenu = isShowMenu
  },
  [type.CHANGE_SHOW_LOADING_STATUS]: (state: State, isShow: boolean) => {
    state.isShowLoading = isShow
  },
  [type.UPDATE_USER_INFO]: (state: State, userInfo: UserInfo) => {
    state.userInfo = cloneDeep(userInfo)
  }

  // [type.SET_CLASS_LIST]: (state: State, cmdyList: CmdyClass[]) => {
  //   state.cmdyClassList = cloneDeep(cmdyList)
  // },

  // [type.SET_USER_LOGIN]: (state: State, loginInfo: UserLoginInfo) => {
  //   state.isLogin = loginInfo.isLogin
  //   state.name = loginInfo.name
  // },
  // [type.SET_SHOPPING_CART_COUNT]: (state: State, count: number) => {
  //   state.shoppingCartCount = count
  // },

  // [type.UPDATE_ORDER_INFO]: (
  //   state: State,
  //   shoppingCartDetailInfo: ShoppingCartInfo
  // ) => {
  //   if (!shoppingCartDetailInfo) return

  //   state.shoppingCartDetailInfo.remark =
  //     shoppingCartDetailInfo.remark || state.shoppingCartDetailInfo.remark
  //   state.shoppingCartDetailInfo.shoppingCartItems =
  //     cloneDeep(shoppingCartDetailInfo.shoppingCartItems) ||
  //     state.shoppingCartDetailInfo.shoppingCartItems

  // if (shoppingCartDetailInfo.deliveryInfo) {
  //   state.shoppingCartDetailInfo.deliveryInfo = cloneDeep(
  //     shoppingCartDetailInfo.deliveryInfo
  //   )
  // }

  // if (shoppingCartDetailInfo.invoiceInfo) {
  //   state.shoppingCartDetailInfo.invoiceInfo = cloneDeep(
  //     shoppingCartDetailInfo.invoiceInfo
  //   )
  // }

  // if (shoppingCartDetailInfo.paymentInfo) {
  //   state.shoppingCartDetailInfo.paymentInfo = cloneDeep(
  //     shoppingCartDetailInfo.paymentInfo
  //   )
  // }

  // if (shoppingCartDetailInfo.receiver) {
  //   state.shoppingCartDetailInfo.receiver = cloneDeep(
  //     shoppingCartDetailInfo.receiver
  //   )
  // }

  // if (shoppingCartDetailInfo.buyer) {
  //   state.shoppingCartDetailInfo.buyer = cloneDeep(
  //     shoppingCartDetailInfo.buyer
  //   )
  // }

  // state.shoppingCartDetailInfo = cloneDeep(state.shoppingCartDetailInfo)
  // },
  // [type.UPDATE_PAYMENTTYPE_LIST]: (
  //   state: State,
  //   paymentTypeList: PaymentType[]
  // ) => {
  //   state.paymentTypeList = cloneDeep(paymentTypeList)
  // },
  // [type.UPDATE_DELEIVERYTYPE_LIST]: (
  //   state: State,
  //   deliveryTypeList: DeliveryType[]
  // ) => {
  //   state.deliveryTypeList = cloneDeep(deliveryTypeList)
  // },
  // [type.UPDATE_INVOICETYPE_LIST]: (
  //   state: State,
  //   invoiceTypeList: InvoiceType[]
  // ) => {
  //   state.invoiceTypeList = cloneDeep(invoiceTypeList)
  // },
  // [type.INIT_SHOPPING_CART_INFO]: (state: State) => {
  //   state.shoppingCartDetailInfo = cloneDeep({
  //     remark: '',
  //     shoppingCartItems: []
  //   })
  // },
  // [type.SET_TYPE_RANK]: (state: State, typeRankNow: number) => {
  //   state.typeRank = typeRankNow
  // },
  // [type.SET_MAIN_TYPE_RANK]: (state: State, typeRankNow: number) => {
  //   state.mainTypeRank = typeRankNow
  // },
  // [type.SET_PAGE_NUMBER]: (state: State, pageNumberNow: number) => {
  //   state.pageNumber = pageNumberNow
  // },

  // [type.RESET_STATE]: (state: State) => {
  //   const _state = {
  //     isShowLoading: false,
  //     isLogin: false,
  //     name: '',
  //     cmdyClassList: [],
  //     shoppingCartCount: 0,
  //     deliveryTypeList: state.deliveryTypeList,
  //     paymentTypeList: state.paymentTypeList,
  //     invoiceTypeList: state.invoiceTypeList,
  //     shoppingCartDetailInfo: {
  //       remark: '',
  //       shoppingCartItems: []
  //     },
  //     typeRank: 1,
  //     pageNumber: 1,
  //     navSettingOpen: false,
  //     mainTypeRank: -1,
  //     cmdyItemType: 'SubClassList',
  //     hallStatus: {},
  //     selectedLevelOneRank: 1,
  //     selectedClassObj: {}, // 記憶分類相關變數
  //     userDetails: {}
  //   }
  //   Object.assign(state, _state)
  // },
  // [type.SET_NAV_SETTIG_BLOCK]: (state: State, value: boolean) => {
  //   state.navSettingOpen = value
  // },
  // [type.TOGGLE_NAV_SETTING_BLOCK]: (state: State, value: boolean) => {
  //   state.navSettingOpen = value
  // },

  // [type.SET_LAST_CMDY_ITEM]: (state: State, value: any) => {
  //   state.lastCmdyItem = cloneDeep(value)
  // },

  // [type.SET_MENU_OPEN]: (state: State, value: any) => {
  //   state.menuOpen = value
  // },

  // [type.SET_CMDY_ITEM_TYPE]: (state: State, value: string) => {
  //   state.cmdyItemType = value
  // },

  // [type.SET_HALL_STATUS]: (state: State, value: any) => {
  //   state.hallStatus = value
  // },

  // [type.SET_SELECTED_LEVEL_ONE_RANK]: (state: State, value: any) => {
  //   state.selectedLevelOneRank = value
  // },

  // [type.SET_SELECTED_CLASS_OBJ]: (state: State, value: any) => {
  //   state.selectedClassObj = value
  // },

  // [type.SET_USER_DETAILS]: (state: State, value: any) => {
  //   state.userDetails = value
  // }
}
