
import { computed, defineComponent, toRef, provide } from 'vue'
import BgIcon from '@/components/common/BgIcon.vue'
import productStore from '@/composition/productStore'
import { Navigation, Autoplay, Mousewheel, FreeMode, Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/pagination'

export default defineComponent({
  emits: ['getCmdyClassList'],
  props: {
    // productClassIconList: [],
    // selectProductClassIconId: null
  },
  components: { Swiper, SwiperSlide, BgIcon },
  setup (props) {
    provide('productStore', productStore)
    // const productClassIconListRef = toRef(props, 'productClassIconList')
    // const selectProductClassIconIdRef = toRef(
    //   props,
    //   'selectProductClassIconId'
    // )
    return {
      productStore,
      modules: [Autoplay, Navigation, Mousewheel, FreeMode, Scrollbar]
      //   productClassIconListRef,
      //   selectProductClassIconIdRef
    }
  }
})
