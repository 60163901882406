import { withKeys as _withKeys, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search" }
const _hoisted_2 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      ref: "inputRef",
      class: "placeholder:text-black-300-color",
      onKeydown: _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.getInput(_ctx.searchInput)), ["enter"])),
      placeholder: [_ctx.placeholder],
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchInput) = $event))
    }, null, 40, _hoisted_2), [
      [_vModelText, _ctx.searchInput]
    ]),
    _createVNode(_component_Icon, {
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.getInput(_ctx.searchInput))),
      iconName: "search",
      width: '20px',
      style: {"cursor":"pointer"}
    })
  ]))
}