
import { defineComponent, onMounted, provide, ref, watch } from 'vue'
import Title from '@/components/common/Title.vue'
import Input from '@/components/common/Input.vue'
import Collapse from '@/components/common/Collapse.vue'
import SearchInput from '@/components/common/SearchInput.vue'
import Tabs from '@/components/common/Tabs.vue'
import SquareItemFullImage from '@/components/SquareItemFullImage.vue'
import DatePicker from 'vue-datepicker-next'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import find from 'lodash/find'
import { useRoute, useRouter } from 'vue-router'
import cartStore from '@/composition/cartStore'
import moment from 'moment'
import store from '@/composition/store'
import { unescapeHTML } from '@/utils/utils'
export default defineComponent({
  name: 'g-order-list-view',
  components: {
    SearchInput,
    DatePicker,
    Tabs,
    SquareItemFullImage
  },
  setup () {
    provide('store', store)
    provide('cartStore', cartStore)
    const route = useRoute()
    const router = useRouter()
    const statusList = ref(cartStore.state.purchaseStatusList)
    const orderList = ref([])
    const timeRange = ref(null)
    const dateStart = ref('')
    const dateEnd = ref('')
    const selectStatus = ref('')
    const searchText = ref('')
    const selectId = ref(0)
    watch(timeRange, (newVal) => {
      if (timeRange.value !== null) {
        dateStart.value = moment(timeRange.value[0]).format('YYYYMMDDHHmmss')
        dateEnd.value = moment(timeRange.value[1])
          .add(1, 'days')
          .format('YYYYMMDDHHmmss')
        fetchOrderList()
      }
    })
    const tabSelect = (tab: any) => {
      const selectTab = find(
        cartStore.state.purchaseStatusList,
        (item: any) => {
          return item.id === tab
        }
      )
      selectStatus.value = selectTab.code
      selectId.value = tab
      router.push({
        query: {
          purchaseStatus: selectStatus.value
        }
      })
      fetchOrderList()
    }
    const mobileTabSelect = (tab: any) => {
      const selectTab = find(
        cartStore.state.purchaseStatusList,
        (item: any) => {
          return item.id === tab
        }
      )
      selectStatus.value = selectTab.code
      selectId.value = tab
      router.push({
        query: {
          purchaseStatus: selectStatus.value
        }
      })
      fetchOrderList()
    }
    const fetchOrderList = (): Promise<{
      OrderList: any;
    }> => {
      return new Promise((resolve, reject) => {
        store.setShowLoading(true)
        const data = {
          Data: {
            PageNum: 1,
            PageSize: 0,
            DateStart: dateStart.value,
            DateEnd: dateEnd.value,
            Status: '',
            PurchaseStatus: selectStatus.value,
            IsIncludeItem: 1,
            SearchText: searchText.value
          }
        }
        request
          .post(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API +
              requestUrl.getOrderList,
            data,
            {
              headers: {
                'api-version': '3.0'
              }
            }
          )
          .then((res) => {
            const resData = res.data.Data
            orderList.value = resData.OrderList
            console.log(resData)
            store.setShowLoading(false)
            resolve({ OrderList: resData.OrderList })
          })
          .catch((error) => {
            reject(error)
            console.error(error)
            store.setShowLoading(false)
          })
      })
    }
    const goOrderDetail = (orderId: any) => {
      router.push({ path: '/orderDetail/' + orderId })
    }
    const disabledAfterToday = (date: any) => {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      return date > today
    }
    const getInput = (val: string) => {
      searchText.value = val
      fetchOrderList()
    }
    const getCurrentStatusList = () => {
      fetchOrderList().then(() => {
        if (
          route.query.purchaseStatus !== undefined &&
          route.query.purchaseStatus !== ''
        ) {
          const selectTab = find(
            cartStore.state.purchaseStatusList,
            (item: any) => {
              return item.Code === route.query.purchaseStatus
            }
          )
          selectId.value = selectTab.id
          tabSelect(selectTab.id)
          mobileTabSelect(selectTab.id)
        } else {
          tabSelect(0)
          mobileTabSelect(0)
        }
      })
    }
    watch(
      () => route.params.dateNow,
      (newVal) => {
        if (newVal !== undefined && newVal !== '') {
          getCurrentStatusList()
        }
      }
    )
    onMounted(() => {
      getCurrentStatusList()
    })
    return {
      cartStore,
      statusList,
      timeRange,
      orderList,
      dateStart,
      dateEnd,
      selectId,
      fetchOrderList,
      tabSelect,
      mobileTabSelect,
      goOrderDetail,
      disabledAfterToday,
      unescapeHTML,
      moment,
      selectStatus,
      getInput
    }
  }
})
