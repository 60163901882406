import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71c1a3c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "swiper-icon-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BgIcon = _resolveComponent("BgIcon")!
  const _component_SwiperSlide = _resolveComponent("SwiperSlide")!
  const _component_Swiper = _resolveComponent("Swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Swiper, {
      class: "swiper",
      modules: _ctx.modules,
      "slides-per-view": 4,
      "space-between": 10,
      breakpoints: {
        '640': {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        '768': {
          slidesPerView: 4,
          spaceBetween: 40,
        },
        '1024': {
          slidesPerView: 7,
          spaceBetween: 50,
        },
      }
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ipIconList, (ipIconItem) => {
          return (_openBlock(), _createBlock(_component_SwiperSlide, {
            class: "slide py-5 sm:py-[51px]",
            key: ipIconItem.ShopHallId
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BgIcon, {
                class: "hidden sm:block",
                title: ipIconItem.Name,
                image: ipIconItem.LinkImage,
                onClick: ($event: any) => (_ctx.goIPProductList(ipIconItem.Code, ''))
              }, null, 8, ["title", "image", "onClick"]),
              _createVNode(_component_BgIcon, {
                class: "sm:hidden",
                title: ipIconItem.Name,
                width: '80px',
                image: ipIconItem.LinkImage,
                onClick: ($event: any) => (_ctx.goIPProductList(ipIconItem.Code, ''))
              }, null, 8, ["title", "image", "onClick"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["modules"])
  ]))
}