import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dade5280"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "h-[82px] sm:h-[143px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BgIcon = _resolveComponent("BgIcon")!
  const _component_SwiperSlide = _resolveComponent("SwiperSlide")!
  const _component_Swiper = _resolveComponent("Swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Swiper, {
      class: "swiper",
      modules: _ctx.modules,
      observer: true,
      onObserverUpdate: _ctx.observerUpdate,
      freeMode: true,
      mousewheel: true,
      onSwiper: _ctx.setControlledSwiper,
      navigation: {
        hiddenClass: 'swiper-button-hidden',
        prevEl: 'g-swiper-nav-prev',
        nextEl: 'g-swiper-nav-next',
      },
      slidesPerView: 'auto'
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productStore.state.cmdyClassList, (productClassIcon) => {
          return (_openBlock(), _createBlock(_component_SwiperSlide, {
            class: "slide",
            key: productClassIcon.CmdyClassId
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BgIcon, {
                class: "hidden sm:block",
                BgIconId: productClassIcon.CmdyClassId,
                image: productClassIcon.IconImage,
                imageSelected: productClassIcon.IconImageSelected,
                title: productClassIcon.Name,
                onClick: ($event: any) => {
            _ctx.productStore.setSelectedCmdyClass(productClassIcon);
            _ctx.$emit('getCmdyClassList');
          }
              }, null, 8, ["BgIconId", "image", "imageSelected", "title", "onClick"]),
              _createVNode(_component_BgIcon, {
                class: "sm:hidden",
                BgIconId: productClassIcon.CmdyClassId,
                image: productClassIcon.IconImage,
                imageSelected: productClassIcon.IconImageSelected,
                title: productClassIcon.Name,
                width: '80px',
                onClick: ($event: any) => {
            _ctx.productStore.setSelectedCmdyClass(productClassIcon);
            _ctx.$emit('getCmdyClassList');
          }
              }, null, 8, ["BgIconId", "image", "imageSelected", "title", "onClick"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["modules", "onObserverUpdate", "onSwiper"])
  ]))
}