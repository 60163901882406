
import { defineComponent, ref } from 'vue'
import ModalBase from '@/components/common/ModalBase.vue'
import Btn from '@/components/common/Btn.vue'
export default defineComponent({
  props: {
    portfolioName: {
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      require: false,
      default: ''
    }
  },
  components: {
    ModalBase,
    Btn
  },
  setup (props, context) {
    const { emit } = context
    const change = (event: Event) => {
      emit('update:modelValue', (event.target as HTMLInputElement).value)

      console.log(props.modelValue)
      console.log((event.target as HTMLInputElement).value)
      console.log(typeof (event.target as HTMLInputElement).value)
    }
    const show = ref(false)
    const title = ref('')
    const content = ref('')
    const promiseResolver = ref(
      (value?: any | PromiseLike<Record<string, never>> | undefined) => {
        return null
      }
    )
    const promiseReject = ref(
      (value?: any | PromiseLike<Record<string, never>> | undefined) => {
        return null
      }
    )
    const hideCancel = ref(true)
    const cancel = () => {
      /**
       * 取消按鈕通知
       * @event cancel
       * @type {none}
       */
      // this.$emit('cancel');
      promiseReject.value()
      show.value = false
    }
    const confirm = () => {
      /**
       * 確認按鈕通知
       * @event confirm
       * @type {none}
       */
      // this.$emit('confirm');
      promiseResolver.value()
      show.value = false
    }
    const createConfirm = (
      _title: string,
      _content: string,
      _hideCancel?: boolean
    ) => {
      title.value = _title
      content.value = _content
      show.value = true
      hideCancel.value = !!_hideCancel
      return new Promise((resolve: any, reject: any) => {
        promiseResolver.value = resolve
        promiseReject.value = reject
      })
    }
    return {
      show,
      title,
      content,
      hideCancel,
      change,
      cancel,
      confirm,
      createConfirm
    }
  }
})
