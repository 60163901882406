import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6fd1e489"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "required-dot bg-dot-red"
}
const _hoisted_2 = ["type", "value", "placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["g-input text-sm text-left inline-block", [{ disabled: _ctx.disabled }]])
  }, [
    (_ctx.label !== '')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["input-label flex items-center relative font-light pb-2 text-breadcrumb-color tracking-widest", [{ required: _ctx.required }]])
        }, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          _renderSlot(_ctx.$slots, "label-icon", {}, () => [
            (_ctx.required)
              ? (_openBlock(), _createElementBlock("span", _hoisted_1))
              : _createCommentVNode("", true)
          ], true)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "input-wrap",
      style: _normalizeStyle({ height: _ctx.height, width: _ctx.width, lineHeight: _ctx.height })
    }, [
      _createElementVNode("input", {
        ref: "inputRef",
        class: "h-full",
        type: _ctx.isPasswordText,
        value: _ctx.modelValue,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args))),
        placeholder: _ctx.placeholder,
        disabled: _ctx.disabled
      }, null, 40, _hoisted_2),
      (_ctx.isPassword == false)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            style: { '--iconHeight': '24px' },
            class: "absolute top-[calc(50%_-_var(--iconHeight)_/_2)] right-2 cursor-pointer",
            iconName: "pwd_show",
            width: '24px',
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isPassword = true))
          }))
        : _createCommentVNode("", true),
      (_ctx.isPassword == true)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 1,
            style: { '--iconHeight': '24px' },
            class: "absolute top-[calc(50%_-_var(--iconHeight)_/_2)] right-2 cursor-pointer",
            iconName: "pwd_hide",
            width: '24px',
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isPassword = false))
          }))
        : _createCommentVNode("", true)
    ], 4)
  ], 2))
}