
import { defineComponent } from 'vue'
export default defineComponent({
  data () {
    return {}
  },
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    image: {
      type: Object
    },
    rectangleItem: {
      type: [],
      required: false,
      default: {
        CmdyClassId: '319dbb68-5a7c-11ed-a1ff-0242ac1b0002',
        Name: 'Rabbit',
        ShopHallCode: 'BT21',
        Rank: 1,
        Image: {
          PcWeb: 'https://fakeimg.pl/594x399/?text=2',
          MobileWeb: 'https://fakeimg.pl/594x399/?text=2'
        },
        Alternation: '',
        MarkImage: '',
        ClassDesc: '',
        IconImage: 'https://fakeimg.pl/594x399/?text=2'
      }
    }
  }
})
