
import { defineComponent, ref, onMounted, provide, watch } from 'vue'
import Icon from '@/components/common/Icon.vue'
import SquareItemList from '@/components/SquareItemList.vue'
import Title from '@/components/common/Title.vue'
import UnderLine from '@/components/common/UnderLine.vue'
import ProductClassItemList from '@/components/ProductClassItemList.vue'
// import ProductClassIconList from '@/components/ProductClassIconList.vue'
import ProductItemList from '@/components/ProductItemList.vue'
import Banner from '@/components/common/Banner.vue'
import Breadcrumb from '@/components/common/Breadcrumb.vue'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import { useRouter, useRoute } from 'vue-router'
import productStore from '@/composition/productStore'
import { useToast, POSITION } from 'vue-toastification'
import findIndex from 'lodash/findIndex'
import isMobile from 'ismobilejs'
import store from '@/composition/store'
import map from 'lodash/map'
import cloneDeep from 'lodash/cloneDeep'
export default defineComponent({
  setup () {
    provide('productStore', productStore)
    provide('store', store)
    const router = useRouter()
    const route = useRoute()
    const toast = useToast()
    const promotionData = ref({} as any)
    const RefProductItemList = ref()
    const RefProductItemListForMobile = ref()
    const cmdyClassItem = ref({})
    const cmdyThemeList = ref([] as any)
    const selectThemeList = ref([] as any)
    const getPromotionCmdyList = () => {
      if (route.query.cmdyTagId !== undefined) {
        getPromotionCmdyListById()
      }

      productStore
        .fetchPromotionCmdyList(route.query.promotionId?.toString() || '')
        .then((res) => {
          promotionData.value = res.resData
          promotionData.value.Content = promotionData.value.Content.map((item: string) => {
            return item.replace(/\n/g, '<br/>')
          })
          promotionData.value.WarningDesc = promotionData.value.WarningDesc.map((item: string) => {
            return item.replace(/\n/g, '<br/>')
          })
          // cmdyClassItem.value = res.cmdyClass
          // debugger
          // productStore.setShowLoading(false)
        })
        .catch(() => {
          toast.warning('查不到此商品分類，已幫你轉導到首頁', {
            position: POSITION.TOP_CENTER
          })
          router.push({ name: 'home' })
        })
      // productStore.setSelectedCmdyClass({})
    }

    const getPromotionCmdyListById = (tagClick = false) => {
      productStore.setShowRectangleItem(false)
      store.setShowLoading(true)
      if (tagClick) {
        route.query.cmdyTagId = productStore.state.selectedCmdyTag.CmdyTagId
      }

      router.replace({
        query: {
          promotionId: route.query.promotionId,
          cmdyTagId: route.query.cmdyTagId?.toString() || '',
          dateNow: Date.now()
        }
      })
      const data = {
        Data: {
          PageNum: 0,
          PageSize: 0,
          PromotionActId: route.query.promotionId?.toString() || '',
          CmdyTagId: route.query.cmdyTagId?.toString() || '',
          OrderType: productStore.state.selectedOrderType
        }
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
          requestUrl.getCmdyListByPromotionId,
          data
        )
        .then((res) => {
          const resData = res.data.Data
          productStore.setCmdyTagList(resData.CmdyTagList)
          if (resData.CmdyList.length !== 0) {
            const cmdyList = map(resData.CmdyList, (item: any) => {
              if (item.Checked === undefined) {
                item.Checked = false
              }
              if (item.IsEndProduct === undefined) {
                item.IsEndProduct = 1
              }
              return item
            })
            productStore.setCmdyList(cmdyList)

            if (route.query.cmdyTagId && !tagClick) {
              productStore.state.cmdyTagList.forEach((item: any) => {
                if (item.CmdyTagId === route.query.cmdyTagId) {
                  productStore.setSelectedCmdyTag(item)
                }
              })
            }
          } else {
            productStore.setCmdyList([])
          }
          store.setShowLoading(false)
        })
        .catch((error) => {
          store.setShowLoading(false)
          console.error(error)
        })
    }
    const closeRecItem = () => {
      if (
        productStore.state.showRectangleItem === true &&
        store.state.showLoading === 0 &&
        store.state.showLoginModal === false
      ) {
        productStore.setShowRectangleItem(false)
      }
    }
    const clickProductClassIconList = () => {
      productStore.setSelectedCmdyTag({})
      selectThemeList.value = []
      route.query.cmdyId = ''
      route.params.cmdyThemeId = ''
      // route.params.cmdyClassId = productStore.state.selectedCmdyClass.CmdyClassId
      // route.query.subCmdyClassId =
      //   productStore.state.selectedCmdyClass.CmdyClassId
      productStore.setShowRectangleItem(false)
      // getPromotionCmdyList()
      router.replace({
        query: {
          // shopHallCode: route.query.shopHallCode,
          cmdyClassId: route.query.cmdyClassId,
          subCmdyClassId: productStore.state.selectedCmdyClass.CmdyClassId,
          cmdyTagId: '',
          cmdyId: ''
          // dateNow: Date.now()
        }
      })
    }
    const clickProductClassItem = () => {
      route.query.cmdyId = ''
      route.params.cmdyThemeId = ''
      route.query.cmdyTagId = productStore.state.selectedCmdyTag.CmdyTagId
      getPromotionCmdyList()
      router.replace({
        query: {
          // shopHallCode: route.query.shopHallCode,
          cmdyClassId: route.query.cmdyClassId,
          subCmdyClassId: productStore.state.selectedCmdyClass.CmdyClassId,
          cmdyTagId: productStore.state.selectedCmdyTag.CmdyTagId,
          cmdyId: '',
          dateNow: Date.now()
        }
      })
    }
    const clickProductItemList = () => {
      route.query.cmdyId = ''
      route.params.cmdyThemeId = ''
      // router.push({ name: 'home' })
      getPromotionCmdyList()
    }
    const clickProductItem = (cmdyId: string) => {
      // route.query.cmdyId = productStore.state.selectedCmdy.CmdyId
      router.replace({
        query: {
          // shopHallCode: route.query.shopHallCode,
          cmdyClassId: route.query.cmdyClassId,
          subCmdyClassId: productStore.state.selectedCmdyClass.CmdyClassId,
          cmdyTagId: productStore.state.selectedCmdyTag.CmdyTagId || '',
          cmdyId: cmdyId || productStore.state.selectedCmdy.CmdyId
          // dateNow: Date.now()
        }
      })
    }
    // watch(
    //   () => route.query.cmdyClassId,
    //   (newVal) => {
    //     if (newVal !== undefined && route.name === 'productList') {
    //       productStore.setSelectedCmdyClass({})
    //       getPromotionCmdyList()
    //     }
    //   }
    // )
    // watch(
    //   () => route.query.subCmdyClassId,
    //   (newVal) => {
    //     if (newVal !== undefined && route.name === 'productList') {
    //       getPromotionCmdyList()
    //     }
    //   }
    // )
    watch(
      () => productStore.state.selectedOrderType,
      () => {
        const cmdyClassId = ref(
          productStore.state.selectedCmdyClass.CmdyClassId
        )
        getPromotionCmdyListById()
      }
    )
    watch(
      () => route.query.promotionId,
      () => {
        if (route.name === 'promotionList') {
          // productStore.setSelectedCmdyClass({})
          getPromotionCmdyList()
        }
      }
    )
    onMounted(() => {
      productStore.resetAllStatus()
      getPromotionCmdyList()
    })
    return {
      RefProductItemList,
      RefProductItemListForMobile,
      cmdyClassItem,
      productStore,
      cmdyThemeList,
      selectThemeList,
      getPromotionCmdyList,
      closeRecItem,
      clickProductClassIconList,
      clickProductItemList,
      clickProductClassItem,
      clickProductItem,
      isMobile,
      promotionData,
      getPromotionCmdyListById
    }
  },
  components: {
    // SquareItemList,
    Title,
    UnderLine,
    ProductClassItemList,
    ProductItemList,
    Banner,
    Breadcrumb,
    Icon
  }
})
