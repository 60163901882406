
import { defineComponent, ref, computed, nextTick, toRef } from 'vue'
export default defineComponent({
  name: 'g-select',
  emits: ['change', 'update:modelValue'],
  props: {
    itemList: {
      type: Array,
      required: false,
      default: () => []
    },
    width: {
      type: String,
      require: false,
      default: '100px'
    },
    height: {
      type: String,
      require: false,
      default: '40px'
    },
    itemValue: {
      type: String,
      required: false,
      default: 'value'
    },
    itemText: {
      type: String,
      required: false,
      default: 'text'
    },
    label: {
      type: String,
      require: false,
      default: ''
    },
    placeholder: {
      type: String,
      require: false,
      default: ''
    },
    required: {
      type: Boolean,
      require: false,
      default: false
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false
    },
    modelValue: {
      type: [String, Number],
      required: false,
      default: ''
    },
    selectValue: {
      type: [String, Number],
      required: false,
      default: ''
    }
  },
  setup (props, context) {
    const { emit } = context
    const modelValueRef = toRef(props, 'modelValue')
    const value = ref('')
    const dropdown = ref(null as null | HTMLSelectElement)
    const tmpDropdown = ref(null as null | HTMLSelectElement)

    const getSelectText = () => {
      if (dropdown.value) {
        nextTick(() => {
          console.log(
            (dropdown.value as HTMLSelectElement).selectedOptions[0].text,
            tmpDropdown.value
          )
          if ((dropdown.value as HTMLSelectElement).selectedOptions[0]) {
            (tmpDropdown.value as HTMLSelectElement).selectedOptions[0].text = (
              dropdown.value as HTMLSelectElement
            ).selectedOptions[0].text
            getDropdownWidth()
          }
        })
        if ((dropdown.value as HTMLSelectElement).selectedOptions[0]) {
          // this.$nextTick(() => {
          //   this.getDropdownWidth();
          //   tmpDropdown.selectedOptions[0].text = dropdown[specIndex].selectedOptions[0].text;
          // });
          return (dropdown.value as HTMLSelectElement).selectedOptions[0].text
        }
      }
    }

    const getDropdownWidth = () => {
      nextTick(() => {
        if (tmpDropdown.value) {
          (dropdown.value as HTMLSelectElement).style.width =
            (tmpDropdown.value as HTMLSelectElement).offsetWidth + 'px'
        }
      })
    }

    const change = (event: Event) => {
      emit('update:modelValue', (event.target as HTMLInputElement).value)
      emit('change', (event.target as HTMLInputElement).value)
    }

    return {
      // selectValueRef,
      modelValueRef,
      value,
      dropdown,
      tmpDropdown,
      change,
      getSelectText
    }
  }
})
