import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center mb-[16px]" }
const _hoisted_2 = { class: "w-[80px]" }
const _hoisted_3 = { class: "activity-container" }
const _hoisted_4 = { class: "activity-title" }
const _hoisted_5 = { class: "activity-content ml-[-5px]" }
const _hoisted_6 = { class: "activity-content" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "activity-image",
        style: _normalizeStyle({ backgroundImage: `url(${_ctx.activityInfo.Image.PcWeb})` })
      }, null, 4)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.activityInfo.Name), 1),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Icon, { "icon-name": "clock" }),
        _createElementVNode("p", null, _toDisplayString(_ctx.activityInfo.EffectiveDateStart) + " - " + _toDisplayString(_ctx.activityInfo.EffectiveDateEnd), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", {
          innerHTML: _ctx.activityInfo.Content.join('\n')
        }, null, 8, _hoisted_7)
      ])
    ])
  ]))
}