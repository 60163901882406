
import {
  defineComponent,
  onMounted,
  ref,
  toRef,
  computed,
  provide,
  watch
} from 'vue'
import Select from '@/components/common/Select.vue'
import Input from '@/components/common/Input.vue'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import { useToast, POSITION } from 'vue-toastification'
import store from '@/composition/store'
export default defineComponent({
  name: 'g-address-input',
  components: {
    Select,
    Input
  },
  props: {
    modelValue: {
      type: String,
      require: false,
      default: ''
    },
    label: {
      type: String,
      require: false,
      default: ''
    },
    placeholder: {
      type: String,
      require: false,
      default: ''
    },
    required: {
      type: Boolean,
      require: false,
      default: false
    },
    deliveryCity: {
      type: String
    },
    deliveryDist: {
      type: String
    },
    deliveryZip: {
      type: String
    },
    deliveryAddr: {
      type: String
    }
  },
  setup (props, context) {
    provide('store', store)
    const { emit } = context
    const toast = useToast()
    const cityArr = ref([] as any)
    const distArr = ref([] as any)
    const deliveryCityRef = computed({
      get: () => props.deliveryCity,
      set: (val) => {
        console.log(val)

        emit('update:deliveryCity', val)
      }
    })
    const deliveryDistRef = computed({
      get: () => props.deliveryDist,
      set: (val) => {
        emit('update:deliveryDist', val)
      }
    })
    const deliveryZipRef = computed({
      get: () => props.deliveryZip,
      set: (val) => {
        emit('update:deliveryZip', val)
      }
    })
    const deliveryAddrRef = computed({
      get: () => props.deliveryAddr,
      set: (val) => {
        emit('update:deliveryAddr', val)
      }
    })
    const getCitys = () => {
      store.setShowLoading(true)
      const data = {
        Data: {
          City: ''
        }
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.getDist,
          data
        )
        .then((req) => {
          const resData = req.data
          cityArr.value = []
          resData.Data.AdminRegionList.forEach((element: any) => {
            cityArr.value.push({
              value: element.Code,
              text: element.Desc
            })

            // cityArr.value.push(element.Desc)
          })
          store.setShowLoading(false)
        })
        .catch((error) => {
          console.error(error)
          toast.error(error.response.data.Message, {
            position: POSITION.TOP_CENTER
          })
          store.setShowLoading(false)
          // alert(error.response.data.Message)
        })
    }
    const getDists = (deliveryCity:any) => {
      if (deliveryCity !== '') {
        store.setShowLoading(true)
        const data = {
          Data: {
            City: deliveryCity
          }
        }
        request
          .post(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.getDist,
            data
          )
          .then((req) => {
            const resData = req.data
            distArr.value = []

            let distChange = true
            resData.Data.AdminRegionList.forEach((element: any) => {
              if (deliveryDistRef.value === element.Desc) {
                distChange = false
              }
              distArr.value.push({
                zip: element.Code,
                distName: element.Desc
              })
            })
            if (distChange) {
              // deliveryDistRef.value = ''
              // deliveryZipRef.value = ''
              deliveryDistRef.value = distArr.value[0].distName
              deliveryZipRef.value = distArr.value[0].zip
            }
            getZip(deliveryDistRef.value)
            store.setShowLoading(false)
          })
          .catch((error) => {
            console.error(error)
            toast.error(error.response.data.Message, {
              position: POSITION.TOP_CENTER
            })
            store.setShowLoading(false)
            // alert(error.response.data.Message)
          })
      }
    }
    const getZip = (deliveryDist:any) => {
      distArr.value.forEach((element: any) => {
        if (element.distName === deliveryDist) {
          deliveryZipRef.value = element.zip
        }
      })
    }
    onMounted(() => {
      getCitys()
      getDists(deliveryCityRef.value)
    })
    return {
      deliveryAddrRef,
      deliveryDistRef,
      deliveryZipRef,
      deliveryCityRef,
      cityArr,
      distArr,
      getCitys,
      getDists,
      getZip
    }
  }
})
