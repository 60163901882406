import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "product-item-list" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-wrap justify-start space-b gap-[10px] sm:gap-[4px] mt-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductItem = _resolveComponent("ProductItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productStore.state.cmdyList, (productItem, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        (index % _ctx.row == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.row, (n) => {
                return (_openBlock(), _createBlock(_component_ProductItem, {
                  key: n,
                  class: _normalizeClass([{
            'border-white':  !_ctx.productStore.state.cmdyList[index + (n - 1)]?.Checked,
            'border-primary-color': _ctx.productStore.state.cmdyList[index + (n - 1)]?.Checked,
          }, "hidden sm:block border-4 p-1 rounded-lg"]),
                  style: _normalizeStyle({ 'flex-basis': `calc(100%/${_ctx.row} - 4px)` }),
                  product: _ctx.productStore.state.cmdyList[index + (n - 1)],
                  "onUpdate:product": ($event: any) => ((_ctx.productStore.state.cmdyList[index + (n - 1)]) = $event),
                  onClick: ($event: any) => (
            _ctx.getSelectProduct(
              _ctx.productStore.state.cmdyList[index + (n - 1)]
            )
          )
                }, null, 8, ["class", "style", "product", "onUpdate:product", "onClick"]))
              }), 128)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.row, (n) => {
                return (_openBlock(), _createBlock(_component_ProductItem, {
                  key: n,
                  class: _normalizeClass([{
            'border-white':  !_ctx.productStore.state.cmdyList[index + (n - 1)]?.Checked,
            'border-primary-color': _ctx.productStore.state.cmdyList[index + (n - 1)]?.Checked,
          }, "sm:hidden border-4 p-1 rounded-lg"]),
                  style: _normalizeStyle({ 'flex-basis': `calc(100%/${_ctx.row} - 5px)` }),
                  product: _ctx.productStore.state.cmdyList[index + (n - 1)],
                  "onUpdate:product": ($event: any) => ((_ctx.productStore.state.cmdyList[index + (n - 1)]) = $event),
                  onClick: ($event: any) => (
            _ctx.getSelectProduct(
              _ctx.productStore.state.cmdyList[index + (n - 1)]
            )
          )
                }, null, 8, ["class", "style", "product", "onUpdate:product", "onClick"]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}