
import {
  defineComponent,
  ref,
  provide,
  onBeforeUpdate,
  onUpdated,
  toRef,
  computed
} from 'vue'
import ProductItem from '@/components/ProductItem.vue'
import FilterDropDown from '@/components/common/FilterDropDown.vue'
import DropDown from '@/components/common/DropDown.vue'
import RectangleItem from '@/components/RectangleItem.vue'
import { CmdyClass as CmdyType } from '@/types/cmdyType'
import productStore from '@/composition/productStore'
import { useRouter } from 'vue-router'
export default defineComponent({
  emits: ['update:selectThemeList', 'clickProductItem'],
  props: {
    row: {
      type: Number,
      required: false,
      default: 5
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    withSelect: {
      type: Boolean,
      default: true
    },
    selectThemeList: {
      type: Array,
      default: [] as any
    },
    getCmdyClassList: {
      type: Function as any
    },
    isGoProductDetail: {
      type: Boolean,
      default: false
    },
    cmdyList: {
      type: Array,
      default: [] as any
    }
  },
  components: {
    ProductItem
  },
  setup (props, context) {
    const { emit } = context
    const router = useRouter()
    provide('productStore', productStore)
    interface RecObject {
      index: number;
      content: HTMLElement;
    }
    const selectedRec = ref({
      index: 0,
      content: {} as HTMLElement
    } as RecObject)
    const selectThemeListRef = computed({
      get: () => props.selectThemeList,
      set: (val: any) => {
        emit('update:selectThemeList', val)
      }
    })
    const recList = ref([] as any)
    const selectProductIndex = ref(0)
    const goProductDetail = (cmdyId: string) => {
      // (document.getElementById('app') as HTMLBodyElement).scrollTo({
      //   top: 0,
      //   left: 0,
      //   behavior: 'smooth'
      // })
      // router.push({
      //   name: 'productDetail',
      //   query: {
      //     cmdyId: cmdyId
      //   }
      // })

      window.open(
        router.resolve({
          name: 'productDetail',
          query: {
            cmdyId: cmdyId
          }
        }).href,
        '_blank'
      )
    }
    const getSelectProduct = (product: any, index: any) => {
      goProductDetail(product.CmdyId)
    }

    function findPosY (element: any) {
      let x = 0
      let y = 0
      while (element) {
        x += element.offsetLeft - element.scrollLeft + element.clientLeft
        y += element.offsetTop - element.scrollLeft + element.clientTop
        element = element.offsetParent
      }

      return { y }
    }
    const changeOption = (cmdyId: string) => {
      emit('clickProductItem', cmdyId)
    }
    const smoothToTop = () => {
      if (productStore.state.showRectangleItem !== false) {
        if (selectedRec.value.content.offsetTop !== undefined) {
          if (props.isMobile === false) {
            (document.getElementById('app') as HTMLBodyElement).scrollTo({
              top: findPosY(selectedRec.value.content).y - 50,
              left: 0,
              behavior: 'smooth'
            })
          } else {
            (document.getElementById('app') as HTMLBodyElement).scrollTo({
              top: findPosY(selectedRec.value.content).y,
              left: 0,
              behavior: 'smooth'
            })
          }
        }
      }
    }
    onBeforeUpdate(() => {
      recList.value = []
    })
    onUpdated(() => {
      smoothToTop()
    })
    return {
      productStore,
      selectThemeListRef,
      selectProductIndex,
      getSelectProduct,
      recList,
      changeOption,
      smoothToTop,
      goProductDetail
    }
  }
})
