
import { defineComponent, ref } from 'vue'
import ModalBase from '@/components/common/ModalBase.vue'
import Btn from '@/components/common/Btn.vue'
export default defineComponent({
  props: {},
  components: {
    ModalBase,
    Btn
  },
  setup () {
    const show = ref(false)
    const title = ref('')
    const content = ref('')
    const promiseResolver = ref(
      (value?: any | PromiseLike<Record<string, never>> | undefined) => {
        return null
      }
    )
    const promiseReject = ref(
      (value?: any | PromiseLike<Record<string, never>> | undefined) => {
        return null
      }
    )
    const hideCancel = ref(true)
    const cancel = () => {
      /**
       * 取消按鈕通知
       * @event cancel
       * @type {none}
       */
      // this.$emit('cancel');
      promiseReject.value()
      show.value = false
    }
    const confirm = () => {
      /**
       * 確認按鈕通知
       * @event confirm
       * @type {none}
       */
      // this.$emit('confirm');
      promiseResolver.value()
      show.value = false
    }
    const createConfirm = (
      title1: string,
      content1: string,
      hideCancel1?: boolean
    ) => {
      title.value = title1
      content.value = content1
      show.value = true
      hideCancel.value = !!hideCancel1
      return new Promise((resolve: any, reject: any) => {
        promiseResolver.value = resolve
        console.log(promiseResolver.value)
        console.log(resolve)
        promiseReject.value = reject
      })
    }
    return { show, title, content, hideCancel, cancel, confirm, createConfirm }
  }
})
