
import { defineComponent, onMounted, provide, ref, watch } from 'vue'
import Tabs from '@/components/common/Tabs.vue'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import find from 'lodash/find'
import { BaseResponse, DeliveryList, UserAddress } from '@/types/ResponseType'
import cartStore from '@/composition/cartStore'
import cloneDeep from 'lodash/cloneDeep'
import map from 'lodash/map'
import AddAddressModal from '@/components/modal/AddAddressModal.vue'
import { POSITION, useToast } from 'vue-toastification'
import ModalConfirm from '@/components/modal/ConfirmModal.vue'
import { ConfirmModal } from '@/types/ConfirmModal'
export default defineComponent({
  emits: ['cancel', 'ok'],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    deliveryType: {
      type: String,
      default: 'Home'
    }
  },
  components: {
    Tabs,
    AddAddressModal,
    ModalConfirm
  },
  setup (props, context) {
    const { emit } = context
    const showAddAddressModal = ref(false)
    provide('cartStore', cartStore)
    const toast = useToast()
    const confirmModal = ref()
    const isUpdate = ref(false)
    const deliveryAddrItemInfo = ref({
      DeliveryAddrId: '',
      ReceiverName: '',
      ReceiverTel: '',
      ReceiverMobile: '',
      DeliveryZip: '',
      DeliveryCountry: '',
      DeliveryProvince: '',
      DeliveryCity: '',
      DeliveryDist: '',
      DeliveryAddr: '',
      IsDefault: true
    })
    const deliveryAddrId = ref('')
    const receiverName = ref('')
    const receiverTel = ref('')
    const receiverMobile = ref('')
    const deliveryZip = ref('')
    const deliveryCountry = ref('')
    const deliveryProvince = ref('')
    const deliveryCity = ref('')
    const deliveryDist = ref('')
    const deliveryAddr = ref('')
    const isDefault = ref(true)
    const cityArr = ref([])
    const distArr = ref([])
    const setEditDeliveryDetail = (deliveryAddrItem: any) => {
      deliveryAddrItemInfo.value = deliveryAddrItem
      showAddAddressModal.value = true
    }
    const editAddrDetail = () => {
      const data = {
        Data: {
          DeliveryAddrId: deliveryAddrId.value,
          Name: receiverName.value,
          Tel: receiverTel.value || '',
          Mobile: receiverMobile.value,
          Zip: deliveryZip.value,
          Country: deliveryCountry.value,
          Province: deliveryProvince.value,
          City: deliveryCity.value,
          Dist: deliveryDist.value,
          Addr: deliveryAddr.value,
          IsDefault: isDefault.value ? 1 : 0
        }
      }
      if (deliveryAddrId.value === '') {
        request
          .post(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API +
              requestUrl.updateDeliveryAddr,
            data
          )
          .then((req) => {
            const resData = req.data
            // alert(resData.Message)
            getAddrDetail()
          })
          .catch((error) => {
            alert(error.response.data.Message)
            console.error(error.response.data.Message)
          })
      } else {
        request
          .put(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API +
              requestUrl.updateDeliveryAddr,
            data
          )
          .then((res) => {
            getAddrDetail()
            // alert(res.data.Message)
          })
          .catch((error) => {
            alert(error.response.data.Message)
            console.error(error.response.data.Message)
          })
      }
    }
    const setSelectDelivery = (deliveryAddrItem: any) => {
      const _shoppingCartDetailInfo = cloneDeep(
        cartStore.state.shoppingCartDetailInfo
      )
      _shoppingCartDetailInfo.Delivery = deliveryAddrItem
      cartStore.setShoppingCartDetailInfo(_shoppingCartDetailInfo)
      resetAll()
      emit('ok')
    }
    const remove = (item: UserAddress) => {
      try {
        const _confirmModal = confirmModal.value as ConfirmModal
        _confirmModal
          .createConfirm('是否要移除此地址', '', false)
          .then(async () => {
            const res = await request.post(
              window._env_.VUE_APP_STOREBOOK_SERVICE_API +
                requestUrl.deleteAddress,
              {
                Data: {
                  DeliveryAddrIds: item.DeliveryAddrId
                }
              }
            )
            getAddrDetail()
          })
      } catch (err) {
        console.log(err)
      }
    }
    const deliveryAddrList = ref([] as any[])
    const deliverySelect = ref({} as UserAddress)
    const getAddrDetail = () => {
      request
        .post<BaseResponse<DeliveryList>>(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
            requestUrl.getDeliveryAddrList,
          {
            Data: {}
          }
        )
        .then((req) => {
          const resData = req.data
          deliveryAddrList.value = resData.Data.DeliveryAddrList
          deliveryAddrList.value = map(deliveryAddrList.value, (item) => {
            item.IsDefault = !!item.IsDefault
            return item
          })
          deliverySelect.value =
            find(deliveryAddrList.value, (item) => {
              return item.IsDefault === 1
            }) || deliveryAddrList.value[0]
          if (cartStore.state.shoppingCartDetailInfo.Delivery) {
            deliverySelect.value =
              find(deliveryAddrList.value, (item) => {
                return (
                  item.DeliveryAddrId ===
                  cartStore.state.shoppingCartDetailInfo.Delivery.DeliveryAddrId
                )
              }) || deliverySelect.value
          }
        })
        .catch((error) => {
          console.error(error)
          toast.error(error.data.Message, {
            position: POSITION.TOP_CENTER
          })
          // alert(error.data.Message)
          // alert(error)
        })
    }
    const resetAll = () => {
      deliveryAddrId.value = ''
      receiverName.value = ''
      receiverTel.value = ''
      receiverMobile.value = ''
      deliveryZip.value = ''
      deliveryCountry.value = ''
      deliveryProvince.value = ''
      deliveryCity.value = ''
      deliveryDist.value = ''
      deliveryAddr.value = ''
      isDefault.value = true
      cityArr.value = []
      distArr.value = []
    }
    const resetInfo = () => {
      deliveryAddrItemInfo.value = {
        DeliveryAddrId: '',
        ReceiverName: '',
        ReceiverTel: '',
        ReceiverMobile: '',
        DeliveryZip: '',
        DeliveryCountry: '',
        DeliveryProvince: '',
        DeliveryCity: '',
        DeliveryDist: '',
        DeliveryAddr: '',
        IsDefault: true
      }
      deliveryAddrId.value = ''
      receiverName.value = ''
      receiverTel.value = ''
      receiverMobile.value = ''
      deliveryZip.value = ''
      deliveryCountry.value = ''
      deliveryProvince.value = ''
      deliveryCity.value = ''
      deliveryDist.value = ''
      deliveryAddr.value = ''
      cityArr.value = []
      distArr.value = []
    }
    watch(isUpdate, () => {
      getAddrDetail()
    })
    onMounted(() => {
      getAddrDetail()
    })
    return {
      confirmModal,
      isUpdate,
      deliveryAddrItemInfo,
      showAddAddressModal,
      deliveryAddrId,
      receiverName,
      receiverTel,
      receiverMobile,
      deliveryZip,
      deliveryCountry,
      deliveryProvince,
      deliveryCity,
      deliveryDist,
      deliveryAddr,
      isDefault,
      cityArr,
      distArr,
      editAddrDetail,
      deliveryAddrList,
      deliverySelect,
      getAddrDetail,
      setSelectDelivery,
      resetAll,
      resetInfo,
      setEditDeliveryDetail,
      remove
    }
  }
})
