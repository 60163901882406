
import { defineComponent } from 'vue'
import Icon from '@/components/common/Icon.vue'
export default defineComponent({
  props: {
    activityInfo: {
      type: Object,
      default: [] as any
    }
  },
  setup () {
    return {

    }
  },
  components: {
    Icon
  }
})
