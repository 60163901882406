
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  onUpdated,
  provide,
  ref,
  watch
} from 'vue'
import UnderLine from '@/components/common/UnderLine.vue'
import SquareItemFullImage from '@/components/SquareItemFullImage.vue'
import Icon from '@/components/common/Icon.vue'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import { useRoute, useRouter } from 'vue-router'
import { useClipboard } from '@vueuse/core'
import { useToast, POSITION } from 'vue-toastification'
import { unescapeHTML } from '@/utils/utils'
import { useGtm } from '@gtm-support/vue-gtm'
export default defineComponent({
  data () {
    return {
      detail: true
    }
  },
  props: {},
  components: {
    SquareItemFullImage,
    UnderLine,
    Icon
  },
  setup () {
    onBeforeMount(() => {
      nextTick(() => {
        document.body.setAttribute('style', 'background:#f5f5f5')
      })
    })
    const route = useRoute()
    const router = useRouter()
    const toast = useToast()
    const copyOrderId = ref()
    const { copy, isSupported } = useClipboard()
    const handleCopy = (copyItem: string) => {
      if (!isSupported) {
        toast.warning('不支援複製功能', {
          position: POSITION.TOP_CENTER
        })
        return
      }
      const copyValue = ref('')
      if (copyItem === 'bankAccount') {
        copyValue.value = route.params.bankAccount.toString() || ''
        copy(route.params.bankAccount.toString() || '')
      }
      if (copyItem === 'orderId') {
        copyValue.value = copyOrderId.value?.innerText
        copy(copyOrderId.value?.innerText)
      }
      toast.success(`複製成功：${copyValue.value}`, {
        position: POSITION.TOP_CENTER
      })
    }
    const orderDetail = ref()
    const gtm = useGtm()
    const orderItemList = ref([])
    const giveawayItemList = ref([])
    const addOnItemList = ref([])

    const openMap = () => {
      window.open('https://www.google.com/maps/place/' + orderDetail.value.DeliveryDestShopAddr, '_blank')
    }
    const fetchOrderDetail = (
      orderId: any
    ): Promise<{
      Order: any
    }> => {
      return new Promise((resolve, reject) => {
        const data = {
          Data: {
            OrderId: orderId
          }
        }
        request
          .post(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.getOrder,
            data,
            {
              headers: {
                'api-version': '2.0'
              }
            }
          )
          .then((res) => {
            const resData = res.data.Data
            orderDetail.value = resData
            console.log(resData)
            orderItemList.value = resData.OrderItemList.filter((item: any) => {
              return item.CmdyType !== 'Giveaway' && item.CmdyType !== 'AddOn'
            })
            giveawayItemList.value = resData.OrderItemList.filter(
              (item: any) => {
                return item.CmdyType === 'Giveaway'
              }
            )
            addOnItemList.value = resData.OrderItemList.filter(
              (item: any) => {
                return item.CmdyType === 'AddOn'
              }
            )

            const _itemsList = ref([] as any)
            const _itemsListForFB = ref([] as any)
            resData.OrderItemList.forEach((orderitem: any) => {
              const _item = {
                item_id: '',
                item_name: '',
                price: 0,
                quantity: 1
              }

              _item.item_id = orderitem.CmdyId
              _item.item_name = orderitem.CmdyName
              _item.price = orderitem.CmdyAmt
              _item.quantity = orderitem.CmdyQty
              _itemsList.value.push(_item)

              const _itemForFB = {
                id: '',
                quantity: 0
              }
              _itemForFB.id = orderitem.CmdyId
              _itemForFB.quantity = orderitem.CmdyQty
              _itemsListForFB.value.push(_itemForFB)
            })
            if (gtm?.enabled()) {
              window.dataLayer?.push({
                event: 'purchase',
                ecommerce: {
                  transaction_id: resData.OrderId,
                  value: resData.TotalAmount,
                  tax: 0,
                  shipping: resData.FreightAmt,
                  currency: 'TWD',
                  items: _itemsList.value
                }
              })
            }
            // window.fbq('track', 'Purchase', {
            //   currency: 'TWD',
            //   value: resData.TotalAmount
            // })
            window.fbq('track', 'Purchase', {
              content_type: 'product',
              contents: _itemsListForFB.value,
              value: resData.TotalAmount,
              currency: 'TWD'
            })
          })
          .catch((error) => {
            reject(error)
            console.error(error)
          })
      })
    }
    onBeforeUnmount(() => {
      document.body.removeAttribute('style')
    })
    onMounted(() => {
      // fetchOrderDetail('202311221001344793')
      if (!route.query.orderid && !route.params.orderId) {
        router.push({ name: 'home' })
      } else {
        fetchOrderDetail(route.query.orderid || route.params.orderId)
      }
    })
    return {
      copyOrderId,
      handleCopy,
      router,
      orderDetail,
      fetchOrderDetail,
      unescapeHTML,
      openMap,
      orderItemList,
      giveawayItemList,
      addOnItemList
    }
  }
})
