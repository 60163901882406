
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'g-footer',
  setup () {
    const goToPage = (type: string) => {
      switch (type) {
        case 'line':
          window.open('https://lin.ee/9wk6zF4', '_blank')
          break
        case 'facebook':
          window.open('https://www.facebook.com/horlay.official', '_blank')
          break
        case 'instagram':
          window.open('https://www.instagram.com/horlay.official/', '_blank')
          break
      }
    }
    return { goToPage }
  }
})
