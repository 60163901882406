
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'g-title',
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    }
  }
})
