
import {
  defineComponent,
  ref,
  onMounted,
  provide,
  watch,
  onUpdated,
  getCurrentInstance,
  toRef,
  computed
} from 'vue'

// import { SquareItem as SquareItemType } from '@/types/SquareItemType'
import {
  BaseResponse,
  CommodityDetailResponse,
  // CmdyList,
  CmdySpecList
} from '@/types/ResponseType'
import SquareItemFullImage from '@/components/SquareItemFullImage.vue'
import SwiperSquareItem from '@/components/SwiperSquareItem.vue'
import ImageRadioButton from '@/components/common/ImageRadioButton.vue'
import TextRadioButton from '@/components/common/TextRadioButton.vue'
import InputWithButton from '@/components/common/InputWithButton.vue'
import Input from '@/components/common/Input.vue'
import AddPortFolioModal from '@/components/modal/AddPortfolioModal.vue'
import AddOnModal from '@/components/modal/AddOnModal.vue'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import {
  mappingCodeParser,
  mappingCodeCombine
} from '@/utils/mappingCodeParser'
import cloneDeep from 'lodash/cloneDeep'
import productStore from '@/composition/productStore'
import store from '@/composition/store'
import cartStore from '@/composition/cartStore'
import FavoriteButton from '@/components/common/FavoriteButton.vue'
import Icon from './common/Icon.vue'
import axios from 'axios'
import { getToken } from '@/utils/auth'
import { useRoute, useRouter } from 'vue-router'
import { useToast, POSITION } from 'vue-toastification'
import { unescapeHTML } from '@/utils/utils'
import ModalConfirm from '@/components/modal/ConfirmModal.vue'
import { ConfirmModal } from '@/types/ConfirmModal'
import moment from 'moment'
import Collapse from '@/components/common/Collapse.vue'
import { useGtm } from '@gtm-support/vue-gtm'
import filter from 'lodash/filter'
import map from 'lodash/map'
import find from 'lodash/find'
import EventBus from '@/event_bus/eventBus'
export default defineComponent({
  emits: ['changeOption', 'smoothToTop'],
  props: {
    isGoProductDetail: {
      type: Boolean,
      default: false
    }
    // selectItem: {
    //   type: Object as () => CmdyList,
    //   required: false
    // }
  },
  components: {
    SquareItemFullImage,
    SwiperSquareItem,
    ImageRadioButton,
    TextRadioButton,
    FavoriteButton,
    AddPortFolioModal,
    AddOnModal,
    Collapse,
    // Select,
    InputWithButton,
    Input,
    Icon
    // ModalConfirm
  },
  setup (props, context) {
    const { emit } = context
    const addPortFolioModal = ref()
    const confirmModal = ref()
    const productDescShow = ref(true)
    const warningShow = ref(false)
    const mailShow = ref(1)
    const addOnShow = ref(true)
    const packageShow = ref(true)
    const showAddOnModal = ref(false)
    const restockMail = ref('')
    const test = ref(1)
    const selectedType = ref('')
    provide('productStore', productStore)
    provide('cartStore', cartStore)
    provide('store', store)
    const toast = useToast()
    const router = useRouter()
    const route = useRoute()
    const isLike = ref(false)
    // const selectItemRef = toRef(props, 'selectItem')
    const selectItemDetail = ref({} as any)
    const selectedAddOn = ref([] as any[])
    const selectedCmdyAddOn = ref([] as any[])
    const selectedCmdyPackage = ref([] as any[])
    const optionModel = ref({})
    const cmdyQty = ref(1 as number)
    const maxCmdyQty = ref(20 as number)
    const isClickGoEditor = ref(false)
    const isGoProductDetailRef = toRef(props, 'isGoProductDetail')
    const selectAddOnCmdy = ref({} as any)
    const clickGoEditor = () => {
      isClickGoEditor.value = true
      goEditor()
    }
    const getSelectedAddOn = computed(() => {
      const _selectedItems = filter(selectedCmdyAddOn.value, (item: any) => {
        return item.Checked === true
      })
      return _selectedItems
    })
    const getSelectedPackage = computed(() => {
      const _selectedItem = filter(selectedCmdyPackage.value, (item: any) => {
        return item.Checked === true
      })
      return _selectedItem
    })
    const gtm = useGtm()
    // const {
    //   proxy: { $forceUpdate }
    // }: any = getCurrentInstance()
    const subtractClick = () => {
      if (cmdyQty.value === 1) {
        return
      }

      cmdyQty.value = cmdyQty.value - 1
    }
    const addClick = () => {
      if (cmdyQty.value >= maxCmdyQty.value) {
        cmdyQty.value = maxCmdyQty.value
        return toast.warning('已達訂購上限', {
          position: POSITION.TOP_CENTER
        })
      }
      cmdyQty.value = cmdyQty.value + 1
    }

    // watch(
    //   () => cmdyQty.value,
    //   (newVal, oldVal) => {
    //     if (newVal === '' || parseInt(newVal) === 0) {
    //       cmdyQty.value = 1
    //     }
    //     cmdyQty.value = newVal
    //   }
    // )
    const specList = ref(Object() as CmdySpecList[])
    const initGetProductDetail = () => {
      productDescShow.value = true
      warningShow.value = false
      // this.quantity = window.localStorage.getItem('quantity') ? Number(window.localStorage.getItem('quantity')) : 1;
      if (productStore.state.selectedCmdy.CmdyId !== undefined) {
        getSelectItemDetail(productStore.state.selectedCmdy.CmdyId)
      }
      // getSelectItemDetail({ Data: { CmdyId: selectItemRef.value?.CmdyId } })
      // getSelectItemDetail({
      //   Data: { CmdyId: 'd4a60b78-5595-11ed-a1ff-0242ac1b0002' }
      // })
    }
    const getSelectItemDetail = (parameter: any, type = 'cmdyid') => {
      if (!parameter) return

      store.setShowLoading(true)
      const reqData = {
        Data: { CmdyId: parameter, MappingCode: parameter, AccessId: '' }
      }
      const accessId = sessionStorage.accessId || ''
      let apiVersion = '5.0'
      reqData.Data.AccessId = accessId
      let getUrl =
        window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.getCmdyById

      // 依照type 不同使用不同
      if (type !== 'cmdyid') {
        getUrl =
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
          requestUrl.getCmdyByMappingCode

        apiVersion = '5.0'
      }
      request
        .post<BaseResponse<CommodityDetailResponse>>(getUrl, reqData, {
          headers: {
            'api-version': apiVersion
          }
        })
        .then((res) => {
          const resData = res.data
          specList.value = resData.Data.CmdySpecList
          selectItemDetail.value = resData.Data
          selectedCmdyAddOn.value = map(resData.Data.AddOnCmdyList, (item) => {
            item.Checked = false
            item.CmdyQty = 1
            return item
          })
          selectedCmdyPackage.value = map(
            resData.Data.PackageCmdyList,
            (item, index) => {
              item.Checked = false
              if (index === 0) {
                item.Checked = true
              }
              item.CmdyQty = 1
              return item
            }
          )
          // selectedCmdyAddOn.value.forEach((item) => {
          //   item.Checked = false
          //   item.CmdyQty = 1
          // })
          maxCmdyQty.value = selectItemDetail.value.MaxCmdyQty
          // productStore.setSelectedCmdy(selectItemDetail.value)

          mappingCodeParser(resData.Data.MappingCode)
            .then((result: any) => {
              optionModel.value = cloneDeep(result)
            })
            .catch((e: any) => {
              console.log(e)
            })
          // $forceUpdate()
          store.setShowLoading(false)
          if (gtm?.enabled()) {
            window.dataLayer?.push({ ecommerce: null }) // Clear the previous ecommerce object.
            window.dataLayer?.push({
              event: 'view_item',
              ecommerce: {
                currency: 'TWD',
                value: selectItemDetail.value.MinUnitFeeReal,
                items: [
                  {
                    item_id: selectItemDetail.value.CmdyId,
                    item_name: selectItemDetail.value.Name,
                    price: selectItemDetail.value.MinUnitFeeReal,
                    quantity: 1
                  }
                ]
              }
            })
          }
          window.fbq('track', 'ViewContent', {
            content_type: 'product',
            content_ids: [selectItemDetail.value.CmdyId],
            content_name: selectItemDetail.value.Name,
            value: selectItemDetail.value.MinUnitFeeReal,
            currency: 'TWD'
          })
          // 解析MappingCode 從mappingcode反推現在所選擇的option

          // console.log(resData);
          // let _infoList = resData.Data.IntroList;
          // this.imageList = [];
          // if (_infoList.length === 0) return;

          // _infoList[0].IntroImgList.forEach((element: any) => {
          //   this.imageList.push({
          //     id: '0_' + element.Rank,
          //     image: element.Image
          //   });
          // });
          // console.log('activities >>>> ', resData.Data);
          // this.$route.params.productId = resData.Data.CmdyId;
          // 確認detail id是否一致
        })
        .catch((error) => {
          console.log(error)
          store.setShowLoading(false)
        })
    }
    const changeOption = () => {
      mappingCodeCombine(optionModel.value)
        .then((result: string) => {
          console.log(result)
          // this.$store.commit(mutationsType.CHANGE_SHOW_LOADING_STATUS, true);
          getSelectItemDetail(result, 'mappingcode')
        })
        .catch((e: any) => {
          console.log(e)
        })
    }
    const addToCollect = (cmdy: any) => {
      window.fbq('track', 'AddToWishlist')
      if (store.state.isLogin) {
        if (!cmdy.IsInCmdyCollect) {
          productStore.addCmdyCollect(cmdy.CmdyId).then((res) => {
            cmdy.IsInCmdyCollect = true
            cmdy.CmdyCollectId = res.collectId
            getSelectItemDetail(cmdy.CmdyId)
            // itemUpdate(item, 'collect')
          })
          // try {
          //   const _confirmModal = confirmModal.value as ConfirmModal
          //   _confirmModal
          //     .createConfirm('確定移至我的最愛，下次再訂購？', '', false)
          //     .then(() => {

          //     })
          // } catch (err) {
          //   console.log(err)
          // }
        } else {
          const collectIdArray: string[] = []
          collectIdArray.push(cmdy.CmdyCollectId)
          productStore.delCmdyCollect(collectIdArray).then(() => {
            cmdy.IsInCmdyCollect = false
            getSelectItemDetail(cmdy.CmdyId)
            // itemUpdate(item, 'collect')
          })
          // try {
          //   const _confirmModal = confirmModal.value as ConfirmModal
          //   _confirmModal
          //     .createConfirm('確定要從我的最愛移除？', '', false)
          //     .then(() => {

          //     })
          // } catch (err) {
          //   console.log(err)
          // }
        }
      } else {
        store.setShowLoginModal(true)
      }
    }
    const addShoppingCart = (cmdy: any) => {
      // cmdy.CmdyQty = parseInt(cmdyQty.value)
      cmdy.CmdyQty = cmdyQty.value
      cmdy.AddOnCmdyList = getSelectedAddOn.value
      cmdy.PackageCmdyList = getSelectedPackage.value
      cartStore.addShoppingCart(cmdy, route.fullPath)
      if (gtm?.enabled()) {
        console.log(cmdy)
        window.dataLayer?.push({ ecommerce: null }) // Clear the previous ecommerce object.
        window.dataLayer?.push({
          event: 'add_to_cart',
          ecommerce: {
            currency: 'TWD',
            value: cmdy.MinUnitFeeReal,
            items: [
              {
                item_id: cmdy.CmdyId,
                item_name: unescapeHTML(cmdy.Name),
                price: cmdy.MinUnitFeeReal,
                quantity: cmdy.CmdyQty
              }
            ]
          }
        })
      }
      window.fbq('track', 'AddToCart', {
        content_ids: [cmdy.CmdyId],
        content_name: cmdy.Name,
        contents: [{ id: cmdy.CmdyId }]
      })
    }
    const portfolioName = ref('')
    const goEditor = () => {
      store
        .fetchUserInfo()
        .then(() => {
          try {
            portfolioName.value = ''
            const _addPortFolioModal = addPortFolioModal.value as any
            _addPortFolioModal.createConfirm('作品名稱', '', false).then(() => {
              store.setShowLoading(true)
              const data = {
                DistributorId: store.state.userInfo.Account,
                TemplateSkuId:
                  selectItemDetail.value.EditorTemplateList[0]
                    .EditorPartnerTemplateId
              }
              axios
                .post(
                  window._env_.VUE_APP_AIPS_URL +
                    '/UltraAIPSTaskManagerNet/WorkSet/GenerateWorkSetID',
                  data
                )
                .then((res) => {
                  const resData = res.data.Data
                  if (res.data.Status === 'Success') {
                    const workSetID = resData.WorkSetID

                    addPortfolio(workSetID)
                      .then(() => {
                        const PartnerBookId = workSetID
                        const orderurl = encodeURIComponent(
                          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
                            '/orders/add-shopping-cart-from-editor?Token=' +
                            getToken() +
                            '&PartnerBookId=' +
                            PartnerBookId +
                            '&CmdyQty=1'
                        )
                        let exiturl = ''
                        let returnurl = ''

                        if (window._env_.ENV === 'develop') {
                          exiturl = encodeURIComponent(
                            'https://localhost:9123' + route.fullPath
                          )
                          returnurl = encodeURIComponent(
                            'https://localhost:9123/addShoppingCart?partnerBookId=' +
                              PartnerBookId
                          )
                        } else {
                          exiturl = encodeURIComponent(
                            window._env_.VUE_APP_GIFT_URL + route.fullPath
                          )
                          returnurl = encodeURIComponent(
                            window._env_.VUE_APP_GIFT_URL +
                              '/addShoppingCart?partnerBookId=' +
                              PartnerBookId
                          )
                        }

                        window.location.href =
                          window._env_.VUE_APP_GIFT_EDITOR_URL +
                          `/auth/${workSetID}/ip?orderurl=${orderurl}&exiturl=${exiturl}&returnurl=${returnurl}&distributorid=${store.state.userInfo.Account}`
                        store.setShowLoading(false)
                      })
                      .catch((e) => {
                        toast.error(e.data.Message, {
                          position: POSITION.TOP_CENTER
                        })
                        // alert(e.data.Message)
                        store.setShowLoading(false)
                      })
                  } else {
                    toast.warning(res.data.Message, {
                      position: POSITION.TOP_CENTER
                    })
                    // alert(res.data.Message)
                    store.setShowLoading(false)
                  }
                })
                .catch((err) => {
                  toast.warning(err.data.Message, {
                    position: POSITION.TOP_CENTER
                  })
                  store.setShowLoading(false)
                })
            })
          } catch (err) {
            console.log(err)
          }
        })
        .catch(() => {
          store.setShowLoginModal(true)
        })

      // request
      //   .post(
      //     'http://192.168.45.231:9988/UltraAIPSTaskManagerNet/WorkSet/GenerateWorkSetID',
      //     data
      //   )
      //   .then((res) => {
      //     const resData = res.data.Data
      //     alert(resData.WorkSetID)
      //     store.setShowLoading(false)
      //   })
      //   .catch((error) => {
      //     store.setShowLoading(false)
      //     console.error(error)
      //   })
      // location.href = 'https://google.com'
      // http://192.168.45.231:9988/UltraAIPSTaskManagerNet/WorkSet/GenerateWorkSetID
      // http://192.168.45.17:9099/Portfolio/${portfolioId}/ip
      //       orderurl=${domain}/orders/add-shopping-cart-from-editor?Token=${Token}&PartnerBookId=${PartnerBookId}$CmdyQty=1
      // exiturl=${exiturl}
      // returnurl=${returnurl}
    }
    const addPortfolio = (
      editorPartnerBookId: string
    ): Promise<{ status: any }> => {
      return new Promise((resolve, reject) => {
        const data = {
          Data: {
            PortfolioList: [
              {
                CmdyId: selectItemDetail.value.CmdyId,
                EditorTempId:
                  selectItemDetail.value.EditorTemplateList[0].EditorTemplateId,
                EditorPartnerId:
                  selectItemDetail.value.EditorTemplateList[0].EditorPartnerId,
                EditorPartnerBookId: editorPartnerBookId,
                Name: portfolioName.value,
                PageQty: '',
                IsInCart: '0',
                RefPortfolioId: '',
                RefReason: ''
              }
            ]
          }
        }
        request
          .post(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API +
              requestUrl.addPortfolio,
            data
          )
          .then((res) => {
            const resData = res.data.Data
            resolve({ status: true })
          })
          .catch((error) => {
            reject(error)
            console.error(error)
          })
      })
    }
    const closeRecItem = () => {
      selectItemDetail.value = {}
      setTimeout(() => {
        productStore.setShowRectangleItem(false)
      }, 500)
    }
    const goProductDetail = (cmdyId: string) => {
      (document.getElementById('app') as HTMLBodyElement).scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      router.push({
        name: 'productDetail',
        query: {
          cmdyId: cmdyId
        }
      })
    }
    const sentRestockNotification = (cmdyId: string) => {
      let isSendMail = false

      if (mailShow.value === 2 && restockMail.value === '') {
        return toast.warning('請輸入Email', {
          position: POSITION.TOP_CENTER
        })
      }
      if (store.state.isLogin) {
        if (store.state.userInfo.Email) {
          isSendMail = true
          restockMail.value = store.state.userInfo.Email
          mailShow.value = 3
        } else {
          mailShow.value = 2
        }
      } else {
        if (mailShow.value === 2) {
          isSendMail = true
        } else {
          mailShow.value = 2
        }
      }
      if (isSendMail) {
        const data = {
          Data: {
            CmdyId: cmdyId,
            Email: restockMail.value
          }
        }
        request
          .post(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API +
              requestUrl.addCmdyRestockNotify,
            data
          )
          .then((res) => {
            restockMail.value = ''
            mailShow.value = 3
            if (res.data.Data.IsExist) {
              toast.warning('重複發送', {
                position: POSITION.TOP_CENTER
              })
            } else {
              toast.success('已發送', {
                position: POSITION.TOP_CENTER
              })
            }
          })
          .catch((error) => {
            console.error(error)
            toast.error(error.data.Message, {
              position: POSITION.TOP_CENTER
            })
          })
      }
    }
    const setSelectAddOnCmdy = (addOnCmdy: any, type: string) => {
      selectedType.value = type
      selectAddOnCmdy.value = addOnCmdy
      showAddOnModal.value = true
    }
    const getSelectPrice = computed(() => {
      const _cmdyOriAmt = selectItemDetail.value.MinUnitFeeOri * cmdyQty.value
      const _cmdyRealAmt = selectItemDetail.value.MinUnitFeeReal * cmdyQty.value
      const _packageOriAmt =
        getSelectedPackage.value[0]?.UnitFeeOri *
        getSelectedPackage.value[0]?.CmdyQty
      const _packageRealAmt =
        getSelectedPackage.value[0]?.UnitFeeReal *
        getSelectedPackage.value[0]?.CmdyQty
      let _addOnOriAmt = 0
      let _addOnRealAmt = 0
      getSelectedAddOn.value.forEach((item: any) => {
        _addOnOriAmt += item.UnitFeeOri * item.CmdyQty
        _addOnRealAmt += item.UnitFeeReal * item.CmdyQty
      })
      return {
        TotalOriAmt: _cmdyOriAmt + _packageOriAmt + _addOnOriAmt,
        TotalRealAmt: _cmdyRealAmt + _packageRealAmt + _addOnRealAmt
      }
    })
    const setPackage = (cmdy: any) => {
      console.log(cmdy)
      selectedCmdyPackage.value = selectedCmdyPackage.value.map(
        (packageCmdy: any) => {
          if (packageCmdy.CmdyId === cmdy.CmdyId) {
            packageCmdy.Checked = true
          } else {
            packageCmdy.Checked = false
          }
          return packageCmdy
        }
      )
    }
    // watch(
    //   () => selectAddOnCmdy.value,
    //   (newVal) => {
    //     console.log(newVal)
    //     if (selectedType.value === 'AddOn') {
    //       selectedCmdyAddOn.value.forEach((addOnCmdy: any) => {
    //         if (addOnCmdy.CmdyId === newVal.CmdyId) {
    //           addOnCmdy = newVal
    //         }
    //       })
    //     }
    //     if (selectedType.value === 'Package' && newVal === true) {
    //       selectedCmdyPackage.value.map((packageCmdy: any) => {
    //         if (packageCmdy.CmdyId === newVal.CmdyId) {
    //           packageCmdy.Checked = true
    //           console.log('true')
    //         } else {
    //           packageCmdy.Checked = false
    //           console.log('false')
    //         }
    //         return packageCmdy
    //       })
    //     }
    //   }
    // )
    // watch(
    //   () => selectedCmdyAddOn.value,
    //   (newVal) => {
    //     console.log(newVal)
    //     // if (selectedType.value === 'AddOn') {
    //     //   selectedCmdyAddOn.value.forEach((addOnCmdy: any) => {
    //     //     if (addOnCmdy.CmdyId === newVal.CmdyId) {
    //     //       addOnCmdy = newVal
    //     //     }
    //     //   })
    //     // }
    //     // if (selectedType.value === 'Package' && newVal === true) {
    //     //   selectedCmdyPackage.value.map((packageCmdy: any) => {
    //     //     if (packageCmdy.CmdyId === newVal.CmdyId) {
    //     //       packageCmdy.Checked = true
    //     //       console.log('true')
    //     //     } else {
    //     //       packageCmdy.Checked = false
    //     //       console.log('false')
    //     //     }
    //     //     return packageCmdy
    //     //   })
    //     // }
    //   }
    // )
    watch(
      () => productStore.state.selectedCmdy,
      () => {
        if (productStore.state.showRectangleItem) {
          initGetProductDetail()
          isClickGoEditor.value = false
        }
      }
    )
    watch(
      () => selectItemDetail.value,
      () => {
        setTimeout(() => {
          emit('smoothToTop')
        }, 500)
        mailShow.value = 1
        emit('changeOption', selectItemDetail.value.CmdyId)
      }
    )
    watch(
      () => store.state.isLogin,
      (newVal) => {
        if (
          newVal === true &&
          selectItemDetail.value.IsEndProduct === 0 &&
          productStore.state.showRectangleItem &&
          isClickGoEditor.value
        ) {
          goEditor()
        }
      }
    )
    onMounted(() => {
      initGetProductDetail()
    })
    return {
      // test1,
      setPackage,
      addPortFolioModal,
      portfolioName,
      productDescShow,
      warningShow,
      mailShow,
      addOnShow,
      packageShow,
      restockMail,
      test,
      isLike,
      productStore,
      cartStore,
      selectedType,
      // selectItemRef,
      specList,
      optionModel,
      selectItemDetail,
      cmdyQty,
      isGoProductDetailRef,
      changeOption,
      addToCollect,
      addShoppingCart,
      goEditor,
      addPortfolio,
      addClick,
      subtractClick,
      closeRecItem,
      unescapeHTML,
      confirmModal,
      moment,
      isClickGoEditor,
      clickGoEditor,
      goProductDetail,
      sentRestockNotification,
      setSelectAddOnCmdy,
      selectAddOnCmdy,
      showAddOnModal,
      selectedAddOn,
      selectedCmdyAddOn,
      selectedCmdyPackage,
      getSelectedAddOn,
      getSelectedPackage,
      getSelectPrice,
      EventBus
    }
  }
})
