import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "g-banner hidden sm:block",
      style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.image.PcWeb + ')' })
    }, null, 4),
    _createElementVNode("div", {
      class: "g-image-component sm:hidden after:pt-[64%]",
      style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.image.MobileWeb + ')' })
    }, null, 4)
  ], 64))
}