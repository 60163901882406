
import {
  defineComponent,
  watch,
  ref
} from 'vue'
import { onClickOutside } from '@vueuse/core'
export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  setup (props, context) {
    const { emit } = context
    const modalElement = ref(null)
    // onMounted(() => {
    //   document.body.classList.add('modal-open')
    // })

    // onUnmounted(() => {
    //   document.body.classList.remove('modal-open')
    // })

    watch(
      () => props.show,
      (newVal, oldVal) => {
        if (newVal) {
          document.body.classList.add('modal-open')
        } else {
          document.body.classList.remove('modal-open')
        }
      }
    )

    onClickOutside(modalElement, (event) => {
      if ((event.target as HTMLElement).offsetParent) {
        if (!(event.target as HTMLElement)?.classList.contains('modal-container')) {
          if (!(event.target as HTMLElement).offsetParent?.classList.contains('modal-container')) {
            debugger
            // console.log('close', event, (event.target as HTMLElement).offsetParent)
            emit('close')
          }
        }
      }
    })
    return {
      modalElement
    }
  }
})
