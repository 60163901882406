import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex gap-3 mt-8 justify-end" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Btn = _resolveComponent("Btn")!
  const _component_ModalBase = _resolveComponent("ModalBase")!

  return (_openBlock(), _createBlock(_component_ModalBase, {
    show: _ctx.show,
    class: "modal-300-width"
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.title), 1)
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", null, _toDisplayString(_ctx.content), 1)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (!_ctx.hideCancel)
            ? (_openBlock(), _createBlock(_component_Btn, {
                key: 0,
                text: '取消',
                height: '36px',
                width: '60px',
                type: 'light',
                required: true,
                onClick: _ctx.cancel
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Btn, {
            text: '確認',
            height: '36px',
            width: '60px',
            onClick: _ctx.confirm
          }, null, 8, ["onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["show"]))
}