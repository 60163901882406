
import { defineComponent, ref, inject, toRefs, provide } from 'vue'
import { stringifyQuery, useRouter } from 'vue-router'
import productStore from '@/composition/productStore'
export default defineComponent({
  emits: ['clickCmdyClassList', 'clickSubCmdyClassList'],
  name: 'g-sub-header',
  props: {
    cmdyClassList: null,
    subCmdyClassList: null,
    selectedCmdyClassId: String,
    selectedSubCmdyClassId: String
  },

  setup () {
    provide('productStore', productStore)
    const router = useRouter()

    return {
      router,
      productStore
    }
  }
})
