import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "text-center text-xl text-gary-text-color my-[20px] px-auto" }
const _hoisted_2 = { class: "w-[80%] mx-auto" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "flex gap-3 mt-[30px] justify-end" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Btn = _resolveComponent("Btn")!
  const _component_ModalBase = _resolveComponent("ModalBase")!

  return (_openBlock(), _createBlock(_component_ModalBase, {
    show: _ctx.show,
    class: "modal-300-width"
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("input", {
          class: "w-full h-[30px] pb-[3px] text-gary-text-color border-[1px] border-light-gary-text-color tracking-[.12em] select-auto",
          value: _ctx.modelValue,
          onInput: _cache[0] || (_cache[0] = 
            (e) => {
              _ctx.change(e);
            }
          ),
          placeholder: "請輸入作品名稱"
        }, null, 40, _hoisted_3)
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (!_ctx.hideCancel)
            ? (_openBlock(), _createBlock(_component_Btn, {
                key: 0,
                text: '取消',
                height: '36px',
                width: '60px',
                type: 'light',
                fontSize: '14px',
                required: true,
                onClick: _ctx.cancel
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_Btn, {
            text: '確認',
            height: '36px',
            width: '60px',
            fontSize: '14px',
            onClick: _ctx.confirm
          }, null, 8, ["onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["show"]))
}