
export const SHOW_LOGO = 'SHOW_LOGO'

export const SHOW_MENU = 'SHOW_MENU'
/**
 * 更新會員資訊
 */
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO'

/**
 * 更換loading view 顯示狀態
 */
export const CHANGE_SHOW_LOADING_STATUS = 'CHANGE_SHOW_LOADING_STATUS'

// /**
//   * 設定class list
//   */
// export const SET_CLASS_LIST = 'SET_CLASS_LIST'

// /**
//   * 會員登入流程
//   */

// export const SET_USER_LOGIN = 'SET_USER_LOGIN'

// /**
//   * 設定購物車數量
//   */

// export const SET_SHOPPING_CART_COUNT = 'SET_SHOPPING_CART_COUNT'

// // 更新結帳前訂單資訊
// export const UPDATE_ORDER_INFO = 'UPDATE_ORDER_INFO'

// // 清除結帳資訊
// export const CLEAR_ORDER_INFO = 'CLEAR_ORDER_INFO'

// // 更新寄送方式清單
// export const UPDATE_DELEIVERYTYPE_LIST = 'UPDATE_DELEIVERY_LIST'

// // 更新付款方式清單
// export const UPDATE_PAYMENTTYPE_LIST = 'UPDATE_PAYMENTTYPE_LIST'

// // 更新電子發票清單
// export const UPDATE_INVOICETYPE_LIST = 'UPDATE_INVOICETYPE_LIST'

// // 初始化購物車資訊
// export const INIT_SHOPPING_CART_INFO = 'INIT_SHOPPING_CART_INFO'

// // 記錄瀏覽商品的type
// export const SET_TYPE_RANK = 'SET_TYPE_RANK'

// // 記錄瀏覽大類商品的type
// export const SET_MAIN_TYPE_RANK = 'SET_MAIN_TYPE_RANK'

// // 記錄目前瀏覽商品的分頁
// export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER'

// // 重置state狀態

// export const RESET_STATE = 'RESET_STATE'

// /**
//   * 設定setting block 開啟 或 關閉
//   */

// export const SET_NAV_SETTIG_BLOCK = 'SET_NAV_SETTIG_BLOCK'

// /**
//   * 開啟或關閉setting block
//   */

// export const TOGGLE_NAV_SETTING_BLOCK = 'TOGGLE_NAV_SETTING_BLOCK'

// export const SET_LAST_CMDY_ITEM = 'SET_LAST_CMDY_ITEM'

// export const SET_MENU_OPEN = 'SET_MENU_OPEN'

// export const SET_HALL_NAME = 'SET_HALL_NAME'

// export const SET_CMDY_ITEM_TYPE = 'SET_CMDY_ITEM_TYPE'

// export const SET_HALL_STATUS = 'SET_HALL_STATUS'

// export const SET_SELECTED_LEVEL_ONE_RANK = 'SET_SELECTED_LEVEL_ONE_RANK'

// export const SET_SELECTED_CLASS_OBJ = 'SET_SELECTED_CLASS_OBJ'

// export const SET_USER_DETAILS = 'SET_USER_DETAILS'
