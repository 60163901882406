import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22bf4234"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "g-checkbox my-1" }
const _hoisted_2 = { class: "flex relative items-center" }
const _hoisted_3 = ["value"]
const _hoisted_4 = {
  key: 0,
  class: "px-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Icon, {
        iconName: _ctx.isChecked ? 'checkbox-checked' : 'checkbox-uncheck',
        width: _ctx.iconWidth
      }, null, 8, ["iconName", "width"]),
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
        value: _ctx.value,
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args)))
      }, null, 40, _hoisted_3), [
        [_vModelCheckbox, _ctx.model]
      ]),
      (_ctx.text!='')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.text), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}