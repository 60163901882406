
import { defineComponent } from 'vue'
export default defineComponent({
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  name: 'g-image-radio-button',
  props: {
    width: {
      type: String,
      required: false,
      default: '40px'
    },
    url: {
      type: String,
      required: false,
      default: ''
    },
    modelValue: {
      type: [String, Number, Object],
      default: ''
    },
    inputValue: {
      type: [String, Number, Object]
    }
  },
  computed: {
    selected () {
      return this.modelValue === this.inputValue
    }
  },
  setup (props, context) {
    const { emit } = context
    const change = (event: Event) => {
      emit('update:modelValue', props.inputValue)
      console.log(props.inputValue)
    }

    return {
      change
    }
  }
})
