
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'g-drivider',
  props: {
    vertical: {
      type: Boolean,
      default: false
    }
  }
})
