
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'g-transition-plus-button',
  props: {
    isLike: {
      type: [Boolean],
      default: false
    },
    iconType: {
      type: String,
      default: 'noCircle'
    },
    width: {
      type: String,
      default: '30px'
    }
  },
  setup (props) {
    const likeIconName = ref('like')
    const unlikeIconName = ref('unlike')

    if (props.iconType !== 'noCircle') {
      likeIconName.value = 'circle_favorite'
      unlikeIconName.value = 'circle_unfavorite'
    }
    return { likeIconName, unlikeIconName }
  }
})
