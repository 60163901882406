export function isPropType (prop: any, type: string): any {
  const types = {
    string: '[object String]',
    number: '[object Number]',
    boolean: '[object Boolean]',
    object: '[object Object]',
    array: '[object Array]'
  } as any
  return (Object.prototype.toString.call(prop) as string) === (types[type])
}
