
import { defineComponent } from 'vue'
import SquareItemList from '@/components/SquareItemList.vue'
import Title from '@/components/common/Title.vue'
import UnderLine from '@/components/common/UnderLine.vue'
import ProductClassItemList from '@/components/ProductClassItemList.vue'
import ProductClassIconList from '@/components/ProductClassIconList.vue'
import ProductItemList from '@/components/ProductItemList.vue'
import Banner from '@/components/common/Banner.vue'
import Breadcrumb from '@/components/common/Breadcrumb.vue'
export default defineComponent({
  data () {
    return {}
  },
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    subTitle: {
      type: String,
      required: true,
      default: ''
    },
    imageList: {
      type: Array,
      required: true,
      default: () => [0, 1, 2, 3, 4, 5, 6, 7, 8]
    },
    row: {
      type: Number,
      required: false,
      default: 5
    }
  },
  components: {
    SquareItemList,
    Title,
    UnderLine,
    ProductClassItemList,
    ProductClassIconList,
    ProductItemList,
    Banner
  }
})
