import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ad3fabd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-xs relative z-1 text-gary-text-color" }
const _hoisted_2 = { class: "text pr-8" }
const _hoisted_3 = {
  key: 0,
  class: "dropdown-content w-[110px] shadow-md rounded-sm z-[2]"
}
const _hoisted_4 = { class: "w-auto" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_clickOutSide = _resolveDirective("clickOutSide")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "dropdown-title cursor-pointer",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openFilter && _ctx.openFilter(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.selected), 1)
    ]),
    (_ctx.showContent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "body", {}, () => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("ul", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderTypeList, (orderType) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: orderType.Code,
                    class: "py-[5px] px-[10px] cursor-pointer hover:bg-light-gary-color active:bg-light-gary-color",
                    onClick: ($event: any) => (_ctx.filterClick(orderType))
                  }, [
                    _createElementVNode("span", null, _toDisplayString(orderType.Desc), 1)
                  ], 8, _hoisted_5))
                }), 128))
              ])
            ])
          ], true)
        ]))
      : _createCommentVNode("", true)
  ])), [
    [_directive_clickOutSide, _ctx.closeFilter]
  ])
}