import { reactive, computed, readonly } from 'vue'
import { getToken } from '@/utils/auth'
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import {
  BaseResponse,
  CommodityDetailResponse,
  UserDetail
} from '@/types/ResponseType'
import store from './store'
import {
  BaseRequest,
  getCmdyClassListV2,
  getCmdyListByClassId
} from '@/types/RequestType'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import { mappingCodeParser } from '@/utils/mappingCodeParser'
import { useToast, POSITION } from 'vue-toastification'
import cartStore from './cartStore'
const toast = useToast()

// 對應原本的 state
const stateRef: any = reactive({
  selectedCmdyClass: {},
  selectedSubCmdyClass: {},
  selectedCmdyTag: {},
  selectedCmdy: {},
  selectedCmdyDetail: {},
  selectedOrderType: 'OnShelfDate',
  cmdyClassList: [],
  subCmdyClassList: [],
  cmdyTagList: [],
  cmdyList: [],
  showRectangleItem: false
})

// 對應原本的 mutations
const setSelectedCmdyClass = (val: any) => {
  stateRef.selectedCmdyClass = val
}
const setSelectedSubCmdyClass = (val: any) => {
  stateRef.selectedSubCmdyClass = val
}
const setSelectedCmdyTag = (val: any) => {
  stateRef.selectedCmdyTag = val
}
const setSelectedCmdy = (val: any) => {
  stateRef.selectedCmdy = val
}
const setSelectedCmdyDetail = (val: any) => {
  stateRef.selectedCmdyDetail = val
}
const setSelectedOrderType = (val: any) => {
  stateRef.selectedOrderType = val
}
const setCmdyClassList = (val: any) => {
  stateRef.cmdyClassList = val
}
const setSubCmdyClassList = (val: any) => {
  stateRef.subCmdyClassList = val
}
const setCmdyTagList = (val: any) => {
  stateRef.cmdyTagList = val
}
const setCmdyList = (val: any) => {
  stateRef.cmdyList = val
}
const setShowRectangleItem = (val: any) => {
  stateRef.showRectangleItem = val
}
const fetchCmdyClassList = (
  cmdyClassId: string
): Promise<{ cmdyClass: any }> => {
  return new Promise((resolve, reject) => {
    store.setShowLoading(true)
    const data = {
      Data: {
        CmdyClassId: cmdyClassId
      }
    }
    request
      .post(
        window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.getCmdyClassV1,
        data,
        {
          headers: {
            'api-version': '2.0'
          }
        }
      )
      .then((res) => {
        const resData = res.data.Data
        store.setShowLoading(false)
        resolve({ cmdyClass: resData })
      })
      .catch((error) => {
        reject(error)
        console.error(error)
        store.setShowLoading(false)
      })
  })
}
const fetchCmdyClassListV2 = (
  fetchCmdyClassListV2RequestData: getCmdyClassListV2
): Promise<{ status: boolean }> => {
  return new Promise((resolve, reject) => {
    store.setShowLoading(true)
    const data = {
      Data: fetchCmdyClassListV2RequestData
    }
    request
      .post(
        window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.getCmdyClassV2,
        data,
        {
          headers: {
            'api-version': '2.0'
          }
        }
      )
      .then((res) => {
        const resData = res.data.Data.CmdyClassList
        setCmdyClassList(resData)
        if (stateRef.selectedCmdyClass.CmdyClassId === undefined) {
          setSelectedCmdyClass(resData[0])
          setSelectedSubCmdyClass({})
        }
        resolve({ status: true })
        store.setShowLoading(false)
      })
      .catch((error) => {
        reject(error)
        store.setShowLoading(false)
        console.error(error)
      })
  })
}

const fetchPromotionCmdyList = (
  PromotionActivityId: string
): Promise<{ resData: object }> => {
  return new Promise((resolve, reject) => {
    store.setShowLoading(true)
    const data = {
      Data: {
        PromotionActivityId: PromotionActivityId
      }
    }
    request
      .post(
        window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.getActivity,
        data
      )
      .then((res) => {
        const resData = res.data.Data
        setCmdyTagList(resData.CmdyTagList)
        if (resData.CommodityList.length !== 0) {
          const cmdyList = map(resData.CommodityList, (item: any) => {
            if (item.Checked === undefined) {
              item.Checked = false
            }
            if (item.IsEndProduct === undefined) {
              item.IsEndProduct = 1
            }
            return item
          })
          setCmdyList(cmdyList)
        } else {
          setCmdyList([])
        }
        setSelectedCmdy({})
        // if (cmdyTagId === '') {
        //   setSelectedCmdyTag({})
        // }
        resolve({ resData: resData })
        store.setShowLoading(false)
      })
      .catch((error) => {
        reject(error)
        store.setShowLoading(false)
        console.error(error)
      })
  })
}

const fetchSubCmdyClassList = (
  fetchSubCmdyClassListV2RequestData: getCmdyClassListV2
): Promise<{ status: boolean }> => {
  return new Promise((resolve, reject) => {
    setShowRectangleItem(false)
    setSelectedSubCmdyClass({})
    store.setShowLoading(true)

    const data = {
      Data: fetchSubCmdyClassListV2RequestData
    }
    request
      .post(
        window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.getCmdyClassV2,
        data,
        {
          headers: {
            'api-version': '2.0'
          }
        }
      )
      .then((res) => {
        const resData = res.data.Data.CmdyClassList
        setSubCmdyClassList(resData)
        if (stateRef.selectedSubCmdyClass.CmdyClassId === undefined) {
          setSelectedSubCmdyClass(resData[0])
          setSelectedCmdy({})
        }
        resolve({ status: true })
        store.setShowLoading(false)

        // if (stateRef.selectCmdyClassId === '') {
        //   setSelectSubCmdyClassId(resData[0].CmdyClassId)
        // }
      })
      .catch((error) => {
        reject(error)
        store.setShowLoading(false)
        console.error(error)
      })
  })
}
const fetchCmdyList = (
  fetchCmdyListRequestData: getCmdyListByClassId
): Promise<{ status: boolean }> => {
  return new Promise((resolve, reject) => {
    setShowRectangleItem(false)
    store.setShowLoading(true)
    const data = {
      Data: fetchCmdyListRequestData
    }
    request
      .post(
        window._env_.VUE_APP_STOREBOOK_SERVICE_API +
          requestUrl.getCmdyListByClassId,
        data,
        {
          headers: {
            'api-version': '2.0'
          }
        }
      )
      .then((res) => {
        const resData = res.data.Data
        setCmdyTagList(resData.CmdyTagList)
        setCmdyList(resData.CmdyList)
        stateRef.cmdyClassList.forEach((element: { CmdyClassId: string }) => {
          if (element.CmdyClassId === fetchCmdyListRequestData.CmdyClassId) {
            setSelectedCmdyClass(element)
          }
        })
        setSelectedCmdy({})
        if (fetchCmdyListRequestData.CmdyTagId === '') {
          setSelectedCmdyTag({})
        }
        store.setShowLoading(false)
        resolve({ status: true })
      })
      .catch((error) => {
        store.setShowLoading(false)
        console.error(error)
      })
  })
}
const fetchSelectedCmdyDetail = (
  data: any,
  type = 'cmdyid'
): Promise<{ status: boolean }> => {
  return new Promise((resolve, reject) => {
    if (!data) return

    const reqData = data
    const accessId = sessionStorage.accessId || ''
    let apiVersion = '5.0'
    reqData.Data.AccessId = accessId
    let getUrl =
      window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.getCmdyById

    // 依照type 不同使用不同
    if (type !== 'cmdyid') {
      getUrl =
        window._env_.VUE_APP_STOREBOOK_SERVICE_API +
        requestUrl.getCmdyByMappingCode

      apiVersion = '5.0'
    }
    request
      .post<BaseResponse<CommodityDetailResponse>>(getUrl, reqData, {
        headers: {
          'api-version': apiVersion
        }
      })
      .then((req) => {
        const resData = req.data
        setSelectedCmdyDetail(resData.Data)
        resolve({ status: true })
      })
      .catch((error) => {
        reject(error)
        console.log(error)
        toast.warning('查無商品訊息', {
          position: POSITION.TOP_CENTER
        })
        // alert('查無商品訊息')
      })
  })
}
const fetchCmdyCollectList = (
  pageNum: number,
  pageSize: number,
  cmdyTagId: string,
  cmdyName: string,
  orderType: string
) => {
  setShowRectangleItem(false)
  store.setShowLoading(true)
  const data = {
    Data: {
      PageNum: pageNum,
      PageSize: pageSize,
      CmdyTagId: cmdyTagId,
      CmdyName: cmdyName,
      OrderType: orderType
    }
  }
  request
    .post(
      window._env_.VUE_APP_STOREBOOK_SERVICE_API +
        requestUrl.getCmdyCollectList,
      data
    )
    .then((res) => {
      const resData = res.data.Data
      setCmdyTagList(resData.CmdyTagList)
      if (resData.CmdyList.length !== 0) {
        const cmdyList = map(resData.CmdyList, (item: any) => {
          if (item.Checked === undefined) {
            item.Checked = false
          }
          if (item.IsEndProduct === undefined) {
            item.IsEndProduct = 1
          }
          return item
        })
        setCmdyList(cmdyList)
      } else {
        setCmdyList([])
      }
      setSelectedCmdy({})
      if (cmdyTagId === '') {
        setSelectedCmdyTag({})
      }
      store.setShowLoading(false)
    })
    .catch((error) => {
      store.setShowLoading(false)
      console.error(error)
    })
}
const addCmdyCollect = (cmdyId: string): Promise<{ collectId: string }> => {
  return new Promise((resolve, reject) => {
    if (store.state.isLogin) {
      const data = {
        Data: {
          CmdyId: cmdyId
        }
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
            requestUrl.addCmdyCollect,
          data
        )
        .then((res) => {
          resolve({ collectId: res.data.Data.CollectId })
          cartStore.fetchShoppingCartList()
          const resData = res.data
          toast.success('已加入願望清單', {
            position: POSITION.TOP_CENTER
          })
          // alert(resData.Message)
          // fetchCmdyCollectList(0, 0, '', '')
        })
        .catch((error) => {
          reject(error)
          console.error(error)
        })
    } else {
      store.setShowLoginModal(true)
    }
  })
}
const delCmdyCollect = (
  collectIdArray: Array<unknown>
): Promise<{ status: boolean }> => {
  return new Promise((resolve, reject) => {
    if (store.state.isLogin) {
      const data = {
        Data: {
          CollectIdArray: collectIdArray
        }
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
            requestUrl.delCmdyCollect,
          data
        )
        .then((res) => {
          resolve({ status: true })
          cartStore.fetchShoppingCartList()
          const resData = res.data
          toast.success('已將商品移出願望清單', {
            position: POSITION.TOP_CENTER
          })
        })
        .catch((error) => {
          reject(error)
          console.error(error)
        })
    } else {
      store.setShowLoginModal(true)
    }
  })
}
const fetchCmdyThemeList = (
  shopHallCode: any
): Promise<{ cmdyThemeList: any }> => {
  return new Promise((resolve, reject) => {
    const data = {
      Data: {
        PageNum: 0,
        PageSize: 0,
        ShopCode: 'G-Gift',
        ShopHallCode: shopHallCode
      }
    }
    request
      .post(
        window._env_.VUE_APP_STOREBOOK_SERVICE_API +
          requestUrl.getCmdyThemeList,
        data
      )
      .then((res) => {
        // cmdyThemeList.value = res.data.Data.CmdyThemeList
        resolve({ cmdyThemeList: res.data.Data.CmdyThemeList })
      })
      .catch((error) => {
        console.error(error)
      })
  })
}
// NewCmdy:新品分類
// ShopHallRecommend:主題館推薦商品
// ShopHallMainPageRecommend:主題館推薦商品
// CmdyAlsoLike:你也喜歡
const fetchRecommended = (
  shopHallCode: string,
  cmdyClassId: string,
  type: string
): Promise<{ recommendedList: any, recommendedGroups:any }> => {
  return new Promise((resolve, reject) => {
    const data = {
      Data: {
        ShopHallCode: shopHallCode || '',
        CmdyClassId: cmdyClassId || '',
        Type: type
      }
    }
    request
      .post(
        window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.getRecommended,
        data
      )
      .then((res) => {
        const resData = res.data.Data.RecommendedGroups[0].Items
        resolve({ recommendedList: res.data.Data.RecommendedGroups[0].Items, recommendedGroups: sortBy(res.data.Data.RecommendedGroups, 'Rank') })
      })
      .catch((error) => {
        console.error(error)
      })
  })
}

const resetAllStatus = () => {
  setSelectedCmdyClass({})
  setSelectedSubCmdyClass({})
  setSelectedCmdyTag({})
  setSelectedCmdy({})
  setCmdyClassList([])
  setSubCmdyClassList([])
  setCmdyTagList([])
  setCmdyList([])
  setShowRectangleItem(false)
}

export default {
  state: readonly(stateRef),
  setSelectedCmdyClass,
  setSelectedSubCmdyClass,
  setSelectedCmdyTag,
  setSelectedCmdy,
  setSelectedCmdyDetail,
  setSelectedOrderType,
  setCmdyClassList,
  setSubCmdyClassList,
  setCmdyTagList,
  setCmdyList,
  setShowRectangleItem,
  fetchCmdyClassList,
  fetchCmdyClassListV2,
  fetchSubCmdyClassList,
  fetchCmdyList,
  fetchSelectedCmdyDetail,
  fetchCmdyCollectList,
  addCmdyCollect,
  delCmdyCollect,
  resetAllStatus,
  fetchCmdyThemeList,
  fetchRecommended,
  fetchPromotionCmdyList
}
