
import { defineComponent, onMounted, provide, ref, watch } from 'vue'
import UnderLine from '@/components/common/UnderLine.vue'
import Input from '@/components/common/Input.vue'
import CollapseMenu from '@/components/common/CollapseMenu.vue'
import Collapse from '@/components/common/Collapse.vue'
import SearchInput from '@/components/common/SearchInput.vue'
import Tabs from '@/components/common/Tabs.vue'
import SquareItemFullImage from '@/components/SquareItemFullImage.vue'
import TextArea from '@/components/common/Textarea.vue'
import DatePicker from 'vue-datepicker-next'
import Icon from '@/components/common/Icon.vue'

import { useRoute, useRouter } from 'vue-router'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import { useClipboard } from '@vueuse/core'
import { useToast, POSITION } from 'vue-toastification'
import moment from 'moment'
import store from '@/composition/store'
import { unescapeHTML } from '@/utils/utils'
import { getToken } from '@/utils/auth'
export default defineComponent({
  name: 'g-order-detail-view',
  components: {
    SquareItemFullImage,
    TextArea,
    Icon,
    UnderLine
  },
  setup () {
    provide('store', store)
    const route = useRoute()
    const router = useRouter()
    const toast = useToast()
    const copyOrderId = ref({} as HTMLElement)
    const isUpdate = ref(false)
    const { copy, isSupported } = useClipboard()
    const handleCopy = () => {
      if (!isSupported) {
        toast.warning('不支援複製功能', {
          position: POSITION.TOP_CENTER
        })
        return
      }
      copy(copyOrderId.value?.innerText)
      toast.success(`複製成功：${copyOrderId.value?.innerText}`, {
        position: POSITION.TOP_CENTER
      })
    }
    const orderDetail = ref()
    const orderItemList = ref([])
    const giveawayItemList = ref([])
    const addOnItemList = ref([])
    const openMap = () => {
      window.open('https://www.google.com/maps/place/' + orderDetail.value.DeliveryDestShopAddr, '_blank')
    }
    const fetchOrderDetail = (
      orderId: any
    ): Promise<{
      Order: any
    }> => {
      return new Promise((resolve, reject) => {
        store.setShowLoading(true)
        const data = {
          Data: {
            OrderId: orderId
          }
        }
        request
          .post(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.getOrder,
            data,
            {
              headers: {
                'api-version': '2.0'
              }
            }
          )
          .then((res) => {
            const resData = res.data.Data
            orderDetail.value = resData
            orderItemList.value = resData.OrderItemList.filter((item: any) => {
              return item.CmdyType !== 'Giveaway' && item.CmdyType !== 'AddOn'
            })
            giveawayItemList.value = resData.OrderItemList.filter(
              (item: any) => {
                return item.CmdyType === 'Giveaway'
              }
            )
            addOnItemList.value = resData.OrderItemList.filter((item: any) => {
              return item.CmdyType === 'AddOn'
            })
            store.setShowLoading(false)
            console.log(resData)
          })
          .catch((error) => {
            store.setShowLoading(false)
            reject(error)
            console.error(error)
          })
      })
    }
    const cancel = (
      orderId: any
    ): Promise<{
      Order: any
    }> => {
      return new Promise((resolve, reject) => {
        const data = {
          Data: {
            OrderList: [
              {
                OrderId: orderId,
                Status: 'BySelf'
              }
            ]
          }
        }
        request
          .post(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API +
              requestUrl.changeOrderStatus,
            data
          )
          .then((res) => {
            const resData = res.data.Data
            toast.success(res.data.Message, {
              position: POSITION.TOP_CENTER
            })
            fetchOrderDetail(orderId)
            isUpdate.value = true
            console.log(resData)
          })
          .catch((error) => {
            reject(error)
            console.error(error)
          })
      })
    }
    const payment = (orderId: any, goToUrl: any) => {
      window.location.href = `${goToUrl}?${window._env_.VUE_APP_PAYMENT_PARAMS}${window.location.protocol}//${window.location.host}/orderSuccess&OrderId=${orderId}&RedirectFailUrl=${window.location.protocol}//${window.location.host}/OrderDetail/${orderId}`
    }
    const formatDate = (dateString: string) => {
      const descTime = moment
        .utc(moment.utc(dateString, 'YYYYMMDDHHmmss').local() as any)
        .local()
      let _date = ''
      if (dateString !== '') {
        // _date = moment(dateString).format('YYYY-MM-DD HH:mm:ss')
        _date = descTime.format('YYYY-MM-DD HH:mm:ss')
        return _date
      }
      return ''
    }
    const backToPreviousPage = () => {
      if (window.history.state.back?.includes('/user/orderList')) {
        if (isUpdate.value) {
          router.push({
            name: 'orderList',
            query: {
              purchaseStatus: 'Canceled'
            },
            params: {
              dateNow: Date.now()
            }
          })
        } else {
          router.go(-1)
        }
      } else {
        router.push({
          name: 'orderList',
          params: {
            dateNow: Date.now()
          }
        })
      }
    }
    const goProductDetail = (_cmdy: any) => {
      // if (route.name !== 'ipproductList') {
      //   router.push({
      //     name: 'ipproductList',
      //     params: {
      //       shopHallCode: 'BT21',
      //       cmdyId: _cmdyId
      //     }
      //   })
      // } else {
      //   productStore.setShowRectangleItem(false)
      //   if (route.params.cmdyId === _cmdyId) {
      //     router.push({
      //       params: {
      //         shopHallCode: route.params.shopHallCode,
      //         cmdyId: _cmdyId,
      //         dateNow: Date.now()
      //       }
      //     })
      //   } else {
      //     router.push({
      //       params: {
      //         shopHallCode: route.params.shopHallCode,
      //         cmdyId: _cmdyId
      //       }
      //     })
      //   }
      // }
      if (
        _cmdy.FrontendParameter === undefined ||
        _cmdy.FrontendParameter === ''
      ) {
        router.push({
          name: 'productDetail',
          query: {
            cmdyId: _cmdy.CmdyId
          }
        })
      } else {
        window.location.href = unescapeHTML(_cmdy.FrontendParameter)
      }
    }
    const openECPay = () => {
      window.location.href =
        window._env_.VUE_APP_STOREBOOK_SERVICE_API +
        '/ecpay/redirect-to-logistics-selection?Token=' +
        getToken() +
        // '&TempShoppingCartId=' +
        // orderDetail.value.tempShoppingCartId.value +
        '&Amount=' +
        orderDetail.value.TotalAmount +
        '&ReceiverName=' +
        orderDetail.value.ReceiverName +
        '&ReceiverMobile=' +
        orderDetail.value.ReceiverMobile +
        '&IsChangingStore=true&OrderId=' +
        orderDetail.value.OrderId
    }
    onMounted(() => {
      if (route.query.OrderId !== '' && route.query.OrderId !== undefined) {
        if (route.query.Message !== 'Success') {
          const message = route.query.Message as string
          // toast.warning(message, {
          //   position: POSITION.TOP_CENTER
          // })
          // alert(message)
        }
        router.push({ path: '/orderDetail/' + route.query.OrderId })
        fetchOrderDetail(route.query.OrderId)
      } else {
        fetchOrderDetail(route.params.orderId)
      }
    })
    return {
      store,
      route,
      router,
      copyOrderId,
      orderDetail,
      orderItemList,
      giveawayItemList,
      addOnItemList,
      openMap,
      fetchOrderDetail,
      handleCopy,
      formatDate,
      cancel,
      payment,
      backToPreviousPage,
      unescapeHTML,
      goProductDetail,
      openECPay
    }
  },
  beforeCreate () {
    this.$nextTick(() => {
      document.body.setAttribute('style', 'background:#f5f5f5')
    })
  },
  beforeUnmount () {
    document.body.removeAttribute('style')
  }
})
