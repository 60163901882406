import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24aaaac0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "required-dot bg-dot-red"
}
const _hoisted_2 = ["value", "placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["g-input text-sm text-left inline-block", [{ disabled: _ctx.disabled }]])
  }, [
    (_ctx.label !== '')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["input-label flex items-center relative font-light pb-2 text-breadcrumb-color tracking-widest", [{ required: _ctx.required }]])
        }, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          _renderSlot(_ctx.$slots, "label-icon", {}, () => [
            (_ctx.required)
              ? (_openBlock(), _createElementBlock("span", _hoisted_1))
              : _createCommentVNode("", true)
          ], true)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "input-wrap",
      style: _normalizeStyle({ height: _ctx.height, width: _ctx.width, lineHeight: _ctx.height })
    }, [
      _createElementVNode("input", {
        ref: "inputRef",
        class: "h-full",
        value: _ctx.modelValue,
        onInput: _cache[0] || (_cache[0] = 
          (e) => {
            _ctx.change(e);
          }
        ),
        placeholder: _ctx.placeholder,
        disabled: _ctx.disabled
      }, null, 40, _hoisted_2),
      (_ctx.iconName !== '')
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            style: _normalizeStyle({ '--iconHeight': _ctx.iconWidth }),
            class: "absolute top-[calc(50%_-_var(--iconHeight)_/_2)] right-2",
            iconName: _ctx.iconName,
            width: _ctx.iconWidth
          }, null, 8, ["style", "iconName", "width"]))
        : _createCommentVNode("", true)
    ], 4)
  ], 2))
}