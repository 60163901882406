
import { defineComponent, ref, onMounted, watch, provide } from 'vue'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import Title from '@/components/common/Title.vue'
import UnderLine from '@/components/common/UnderLine.vue'
import ProductClassItemList from '@/components/ProductClassItemList.vue'
import ProductItemList from '@/components/ProductItemList.vue'
import Banner from '@/components/common/Banner.vue'
import IPDescription from '@/components/IPDescription.vue'
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import ProductClassIconList from '@/components/ProductClassIconList.vue'
import { useRouter, useRoute } from 'vue-router'
import productStore from '@/composition/productStore'
import findIndex from 'lodash/findIndex'
import isMobile from 'ismobilejs'
import { useToast, POSITION } from 'vue-toastification'
import store from '@/composition/store'
export default defineComponent({
  components: {
    Title,
    UnderLine,
    ProductClassItemList,
    ProductItemList,
    Banner,
    IPDescription,
    Breadcrumb,
    ProductClassIconList
  },
  setup () {
    provide('productStore', productStore)
    provide('store', store)
    const router = useRouter()
    const route = useRoute()
    const toast = useToast()
    const shopHallCode = ref(route.query.shopHallCode)
    const shopHallInfo = ref([])
    const cmdyThemeList = ref([] as any)
    const selectThemeList = ref([] as any)
    const getShopHallInfo = () => {
      store.setShowLoading(true)
      const data = {
        Data: {
          ShopHallCode: shopHallCode.value
        }
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.getShopHall,
          data,
          {
            headers: {
              'api-version': '2.0'
            }
          }
        )
        .then((res) => {
          const resData = res.data.Data
          shopHallInfo.value = resData
          productStore.setSelectedCmdyClass({})
          getCmdyClassList().then(() => {
            store.setShowLoading(false)
          })
        })
        .catch((error) => {
          console.error(error)
          store.setShowLoading(false)
        })
    }
    const getCmdyClassList = (): Promise<{ status: boolean }> => {
      return new Promise((resolve, reject) => {
        productStore
          .fetchCmdyClassListV2({
            ShopHallCode: shopHallCode.value?.toString() || '',
            ShopHallClassId: '',
            CmdyClassId: '',
            ParentCmdyClassId: '',
            DataType: 'ShopHall',
            MaxLevel: 2,
            IsOnlyLeafNode: false
          })
          .then(() => {
            productStore.state.cmdyClassList.forEach((element: any) => {
              if (element.CmdyClassId === route.query.cmdyClassId) {
                productStore.setSelectedCmdyClass(element)
              }
            })
            const cmdyClassId = ref(
              productStore.state.selectedCmdyClass.CmdyClassId
            )
            // if (
            //   route.query.cmdyClassId !== undefined &&
            //   route.query.cmdyClassId !== ''
            // ) {
            //   cmdyClassId.value = route.query.cmdyClassId
            // }
            const cmdyTagId = ref(
              productStore.state.selectedCmdyTag.CmdyTagId || ''
            )
            if (
              route.query.cmdyTagId !== undefined &&
              route.query.cmdyTagId !== ''
            ) {
              cmdyTagId.value = route.query.cmdyTagId
            }
            if (
              route.params.cmdyThemeId !== undefined &&
              route.params.cmdyThemeId !== ''
            ) {
              selectThemeList.value.push(route.params.cmdyThemeId)
            }
            getCmdyThemeList(shopHallCode.value).then(() => {
              productStore
                .fetchCmdyList({
                  PageNum: 0,
                  PageSize: 0,
                  CmdyClassId: cmdyClassId.value,
                  CmdyThemeId: selectThemeList.value || [],
                  CmdyTagId: cmdyTagId.value,
                  OrderType: productStore.state.selectedOrderType,
                  IsIncludeHiddenCmdy: false
                })
                .then(() => {
                  productStore.state.cmdyTagList.forEach((item: any) => {
                    if (item.CmdyTagId === route.query.cmdyTagId) {
                      productStore.setSelectedCmdyTag(item)
                    }
                  })
                  if (
                    route.query.cmdyId !== undefined &&
                    route.query.cmdyId !== ''
                  ) {
                    const _index = findIndex(
                      productStore.state.cmdyList,
                      (cmdy: any) => cmdy.CmdyId === route.query.cmdyId
                    )
                    if (isMobile().phone) {
                      RefProductItemListForMobile.value.getSelectProduct(
                        productStore.state.cmdyList[_index],
                        _index
                      )
                    } else {
                      RefProductItemList.value.getSelectProduct(
                        productStore.state.cmdyList[_index],
                        _index
                      )
                    }
                  }
                })
                .catch((e) => {
                  // alert(e)
                  toast.warning('查不到此商品分類，已幫你轉導到首頁', {
                    position: POSITION.TOP_CENTER
                  })
                  router.push({ name: 'home' })
                })
              productStore.setSelectedCmdy({})
              resolve({ status: true })
              // productStore.setSelectedCmdyTag({})
            })
          })
          .catch((e) => {
            reject(e)
            toast.warning('查不到此商品分類，已幫你轉導到首頁', {
              position: POSITION.TOP_CENTER
            })
            router.push({ name: 'home' })
          })
      })
    }
    const getCmdyThemeList = (
      shopHallCode: any
    ): Promise<{ status: boolean }> => {
      return new Promise((resolve, reject) => {
        store.setShowLoading(true)
        const data = {
          Data: {
            PageNum: 0,
            PageSize: 0,
            ShopCode: 'G-Gift',
            ShopHallCode: shopHallCode
          }
        }
        request
          .post(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API +
              requestUrl.getCmdyThemeList,
            data
          )
          .then((res) => {
            cmdyThemeList.value = res.data.Data.CmdyThemeList
            resolve({ status: true })
            store.setShowLoading(false)
          })
          .catch((error) => {
            console.error(error)
            store.setShowLoading(false)
          })
      })
    }
    const closeRecItem = () => {
      if (
        productStore.state.showRectangleItem === true &&
        store.state.showLoading === 0 &&
        store.state.showLoginModal === false
      ) {
        productStore.setShowRectangleItem(false)
      }
    }
    const clickProductClassIconList = () => {
      productStore.setSelectedCmdyTag({})
      selectThemeList.value = []
      route.query.cmdyId = ''
      route.params.cmdyThemeId = ''
      productStore.setShowRectangleItem(false)
      // getCmdyClassList()
      router.replace({
        query: {
          shopHallCode: route.query.shopHallCode,
          cmdyClassId: productStore.state.selectedCmdyClass.CmdyClassId,
          cmdyTagId: '',
          cmdyId: ''
          // dateNow: Date.now()
        }
      })
    }
    const clickProductItemList = () => {
      route.query.cmdyId = ''
      route.params.cmdyThemeId = ''
      // route.query.cmdyTagList = ''
      getCmdyClassList()
    }
    const clickProductItem = (cmdyId: string) => {
      // route.query.cmdyId = productStore.state.selectedCmdy.CmdyId
      router.replace({
        query: {
          shopHallCode: route.query.shopHallCode,
          cmdyClassId: productStore.state.selectedCmdyClass.CmdyClassId,
          cmdyTagId: productStore.state.selectedCmdyTag.CmdyTagId || '',
          cmdyId: cmdyId || productStore.state.selectedCmdy.CmdyId
          // dateNow: Date.now()
        }
      })
    }
    const clickProductClassItem = () => {
      route.query.cmdyId = ''
      route.params.cmdyThemeId = ''
      route.query.cmdyTagId = productStore.state.selectedCmdyTag.CmdyTagId
      getCmdyClassList()
      router.replace({
        query: {
          shopHallCode: route.query.shopHallCode,
          cmdyClassId: productStore.state.selectedCmdyClass.CmdyClassId,
          cmdyTagId: productStore.state.selectedCmdyTag.CmdyTagId,
          cmdyId: '',
          dateNow: Date.now()
        }
      })
    }
    const RefProductItemList = ref()
    const RefProductItemListForMobile = ref()
    watch(
      () => route.query.shopHallCode,
      (newVal, oldVal) => {
        if (newVal !== undefined && route.name === 'ipproductList') {
          shopHallCode.value = newVal
          getShopHallInfo()
        }
      }
    )
    watch(
      () => route.query.cmdyClassId,
      (newVal, oldVal) => {
        if (newVal !== undefined && route.name === 'ipproductList') {
          if (newVal === '') {
            getShopHallInfo()
          } else {
            if (route.query.cmdyTagId === '' && route.query.cmdyId === '') {
              productStore.setSelectedCmdyTag({})
              // selectThemeList.value = []
              route.query.cmdyId = ''
              route.params.cmdyThemeId = ''
              route.query.cmdyTagId = ''
              productStore.setShowRectangleItem(false)
              getCmdyClassList()
            }
          }
        }
      }
    )
    watch(
      () => productStore.state.selectedOrderType,
      () => {
        getCmdyClassList()
      }
    )
    // watch(
    //   () => route.query.cmdyId,
    //   (newVal) => {
    //     if (newVal !== undefined && route.name === 'ipproductList') {
    //       getShopHallInfo()
    //     }
    //   }
    // )
    // watch(
    //   () => route.query.dateNow,
    //   () => {
    //     if (route.name === 'ipproductList') {
    //       // productStore.setSelectedCmdyClass({})
    //       getShopHallInfo()
    //     }
    //   }
    // )
    // watch(
    //   () => route.query.cmdyThemeId,
    //   () => {
    //     getShopHallInfo()
    //   }
    // )
    onMounted(() => {
      productStore.resetAllStatus()
      getShopHallInfo()
    })
    return {
      RefProductItemList,
      RefProductItemListForMobile,
      productStore,
      shopHallCode,
      shopHallInfo,
      cmdyThemeList,
      selectThemeList,
      getShopHallInfo,
      getCmdyThemeList,
      getCmdyClassList,
      closeRecItem,
      clickProductClassIconList,
      clickProductItemList,
      clickProductItem,
      clickProductClassItem,
      isMobile
    }
  }
})
