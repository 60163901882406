
import { defineComponent, computed, PropType, inject } from 'vue'
import { isPropType } from '@/utils/tools'
export default defineComponent({
  name: 'g-checkbox',
  emits: ['change', 'update:modelValue'],
  inject: ['CheckboxGroup'],
  props: {
    text: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number, Object, Boolean],
      default: () => undefined
    },
    value: {
      type: [String, Number, Object, Boolean]
    },
    iconWidth: {
      type: String,
      default: '20px'
    }
  },
  setup (props, context) {
    const { emit } = context

    const checkboxGroup = inject('CheckboxGroup', {}) as any

    const isGroup = computed(
      () => checkboxGroup && checkboxGroup.name === 'CheckboxGroup'
    )

    const change = (event: Event) => {
      emit('change', props.value)
    }

    const store = computed(() =>
      checkboxGroup ? checkboxGroup.modelValue.value : props.modelValue
    )

    const model = computed({
      get () {
        return isGroup.value ? store.value : props.modelValue
      },
      set (val) {
        console.log('val >>>', val)
        if (isGroup.value && Array.isArray(val)) {
          checkboxGroup.changeEvent(val)
        } else {
          emit('update:modelValue', val)
        }
      }
    })

    const isChecked = computed(() => {
      const value = model.value as any
      // console.log('isChecked >>>', isChecked)
      if (isPropType(value, 'boolean')) {
        return value
      } else if (isPropType(value, 'array')) {
        return value.includes(props.value)
      }
      return null
    })

    return {
      change,
      isChecked,
      model
    }
  }
})
