import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "g-container hidden sm:block" }
const _hoisted_2 = { class: "g-container flex flex-col sm:mt-10 p-[24px] pb-0 sm:p-0" }
const _hoisted_3 = { class: "g-container flex flex-col pt-0 sm:p-0" }
const _hoisted_4 = { class: "mx-[16px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_Banner = _resolveComponent("Banner")!
  const _component_IPDescription = _resolveComponent("IPDescription")!
  const _component_UnderLine = _resolveComponent("UnderLine")!
  const _component_Title = _resolveComponent("Title")!
  const _component_ProductClassIconList = _resolveComponent("ProductClassIconList")!
  const _component_ProductClassItemList = _resolveComponent("ProductClassItemList")!
  const _component_ProductItemList = _resolveComponent("ProductItemList")!
  const _directive_clickOutSide = _resolveDirective("clickOutSide")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Breadcrumb, {
        breadcrumbList: [{ path: '/', title: _ctx.shopHallInfo.Name }]
      }, null, 8, ["breadcrumbList"])
    ]),
    _createVNode(_component_Banner, {
      image: _ctx.shopHallInfo.LogoImage
    }, null, 8, ["image"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_IPDescription, {
        shopHallInfo: _ctx.shopHallInfo,
        "onUpdate:shopHallInfo": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.shopHallInfo) = $event))
      }, null, 8, ["shopHallInfo"]),
      _createVNode(_component_UnderLine),
      _createVNode(_component_Title, {
        class: "g-title-type2 mt-[30px] sm:mt-[60px] mb-[30px] sm:my-[60px]",
        title: _ctx.shopHallInfo.Name
      }, null, 8, ["title"])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["w-full", { 'g-container': !_ctx.isMobile().phone }])
    }, [
      _createVNode(_component_ProductClassIconList, { onGetCmdyClassList: _ctx.clickProductClassIconList }, null, 8, ["onGetCmdyClassList"])
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_UnderLine)
      ]),
      _createVNode(_component_ProductClassItemList, { onGetCmdyClassList: _ctx.clickProductClassItem }, null, 8, ["onGetCmdyClassList"]),
      _withDirectives((_openBlock(), _createElementBlock("div", null, [
        (!_ctx.isMobile().phone)
          ? (_openBlock(), _createBlock(_component_ProductItemList, {
              key: 0,
              ref: "RefProductItemList",
              getCmdyClassList: _ctx.clickProductItemList,
              "onUpdate:getCmdyClassList": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.clickProductItemList) = $event)),
              cmdyThemeList: _ctx.cmdyThemeList,
              "onUpdate:cmdyThemeList": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.cmdyThemeList) = $event)),
              selectThemeList: _ctx.selectThemeList,
              "onUpdate:selectThemeList": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectThemeList) = $event)),
              onClickProductItem: _ctx.clickProductItem,
              class: "hidden sm:block mt-[36px] sm:mt-[30px]"
            }, null, 8, ["getCmdyClassList", "cmdyThemeList", "selectThemeList", "onClickProductItem"]))
          : _createCommentVNode("", true),
        (_ctx.isMobile().phone)
          ? (_openBlock(), _createBlock(_component_ProductItemList, {
              key: 1,
              class: "sm:hidden mt-[10px] sm:mt-[30px]",
              ref: "RefProductItemListForMobile",
              getCmdyClassList: _ctx.clickProductItemList,
              "onUpdate:getCmdyClassList": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.clickProductItemList) = $event)),
              cmdyThemeList: _ctx.cmdyThemeList,
              "onUpdate:cmdyThemeList": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.cmdyThemeList) = $event)),
              selectThemeList: _ctx.selectThemeList,
              "onUpdate:selectThemeList": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectThemeList) = $event)),
              onClickProductItem: _ctx.clickProductItem,
              row: 2,
              isMobile: true
            }, null, 8, ["getCmdyClassList", "cmdyThemeList", "selectThemeList", "onClickProductItem"]))
          : _createCommentVNode("", true)
      ])), [
        [_directive_clickOutSide, _ctx.closeRecItem]
      ])
    ])
  ], 64))
}