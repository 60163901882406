import { reactive, computed, readonly } from 'vue'
import { getToken } from '@/utils/auth'
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import {
  BaseResponse,
  DeliveryType,
  PaymentType,
  InvoiceType,
  GetDeliveryType,
  GetPaymentType,
  GetInvoiceType,
  OrderShoppingCartCmdy,
  DeliveryShop,
  DeliveryRegion,
  GetDeliveryShop,
  Shop
} from '@/types/ResponseType'
import {
  getShoppingCartOnLocal,
  setShoppingCartInLocal
} from '@/utils/localStorageHandler'
import store from './store'
import { ShoppingCartInfo } from './types/cartType'
import map from 'lodash/map'
import cloneDeep from 'lodash/cloneDeep'
import { useToast, POSITION } from 'vue-toastification'
import find from 'lodash/find'
const toast = useToast()

// 對應原本的 state
const stateRef: any = reactive({
  shoppingCartListWithNoLogin: [] as OrderShoppingCartCmdy[],
  shoppingCartList: {} as any,
  orderShoppingCartCmdyList: [] as OrderShoppingCartCmdy[],
  shoppingCartCount: 0,
  shoppingCartDetailInfo: {} as ShoppingCartInfo,
  deliveryTypeList: [] as DeliveryType[],
  deliveryShopList: [] as DeliveryShop[],
  allDeliveryShop: [] as Shop[],
  deliveryShopCityList: [] as DeliveryRegion[],
  deliveryShopFilterByRegionList: [] as Shop[],
  paymentTypeList: [] as PaymentType[],
  invoiceTypeList: [] as InvoiceType[],
  purchaseStatusList: []
})

// 對應原本的 mutations
const setShoppingCartListWithNoLogin = (val: any) => {
  stateRef.shoppingCartListWithNoLogin = val
}
const setShoppingCartList = (val: any) => {
  stateRef.shoppingCartList = val
}
const setOrderShoppingCartCmdyList = (val: OrderShoppingCartCmdy[]) => {
  stateRef.orderShoppingCartCmdyList = val
}
const setShoppingCartCount = (val: number) => {
  stateRef.shoppingCartCount = val
}
const setShoppingCartDetailInfo = (val: ShoppingCartInfo) => {
  if (!val) return

  stateRef.shoppingCartDetailInfo.CmdyList =
    cloneDeep(val.CmdyList) || stateRef.shoppingCartDetailInfo.CmdyList

  if (val.Info) {
    stateRef.shoppingCartDetailInfo.Info = cloneDeep(val.Info)
  }
  // if (val.Delivery) {
  //   stateRef.shoppingCartDetailInfo.Delivery = cloneDeep(val.Delivery)
  // }
  stateRef.shoppingCartDetailInfo.Delivery = cloneDeep(val.Delivery)
  if (val.Invoice) {
    stateRef.shoppingCartDetailInfo.Invoice = cloneDeep(val.Invoice)
  }

  if (val.Payment) {
    stateRef.shoppingCartDetailInfo.Payment = cloneDeep(val.Payment)
  }

  if (val.Receiver) {
    stateRef.shoppingCartDetailInfo.Receiver = cloneDeep(val.Receiver)
  }

  if (val.Buyer) {
    stateRef.shoppingCartDetailInfo.Buyer = cloneDeep(val.Buyer)
  }
  if (val.PromotionList) {
    stateRef.shoppingCartDetailInfo.PromotionList = cloneDeep(val.PromotionList)
  }
  if (val.GiveawayCmdyList) {
    stateRef.shoppingCartDetailInfo.GiveawayCmdyList = cloneDeep(
      val.GiveawayCmdyList
    )
  }
  if (val.AddOnCmdyList) {
    stateRef.shoppingCartDetailInfo.AddOnCmdyList = cloneDeep(
      val.AddOnCmdyList
    )
  } else {
    stateRef.shoppingCartDetailInfo.PromotionList = null
  }

  stateRef.shoppingCartDetailInfo = cloneDeep(stateRef.shoppingCartDetailInfo)
}
const setDeliveryTypeList = (val: DeliveryType[]) => {
  stateRef.deliveryTypeList = val
}
const setDeliveryShopList = (val: DeliveryShop[]) => {
  stateRef.deliveryShopList = val
}
const setDeliveryShopCityList = (val: DeliveryRegion[]) => {
  stateRef.deliveryShopCityList = val
}

const setDeliveryShopFilterByRegionList = (val: Shop[]) => {
  stateRef.deliveryShopFilterByRegionList = val
}

const setAllDeliveryShop = (val: Shop[]) => {
  stateRef.allDeliveryShop = val
}
const setPaymentTypeList = (val: PaymentType[]) => {
  stateRef.paymentTypeList = val
}
const setInvoiceTypeList = (val: InvoiceType[]) => {
  stateRef.invoiceTypeList = val
}
const setPurchaseStatusList = (val: InvoiceType[]) => {
  stateRef.purchaseStatusList = val
}
const fetchShoppingCartList = (): Promise<{
  orderShoppingCartCmdyList: OrderShoppingCartCmdy[]
}> => {
  return new Promise((resolve, reject) => {
    if (store.state.isLogin) {
      if (
        JSON.parse(getShoppingCartOnLocal()).OrderShoppingCartCmdyList !==
          undefined &&
        JSON.parse(getShoppingCartOnLocal()).OrderShoppingCartCmdyList
          .length !== 0
      ) {
        JSON.parse(getShoppingCartOnLocal()).OrderShoppingCartCmdyList.forEach(
          (element: any) => {
            addShoppingCart(element, element.FrontendParameter)
          }
        )
        setShoppingCartInLocal({} as OrderShoppingCartCmdy, 'clean', 0)
        fetchShoppingCartList()
      } else {
        const data = {
          Data: {
            ShopCodes: 'G-Gift',
            OrderShoppingCartIds: ''
          }
        }
        request
          .post(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API +
              requestUrl.getShoppingCartList,
            data,
            {
              headers: {
                'api-version': '2.0'
              }
            }
          )
          .then((res) => {
            const resData = res.data.Data
            setShoppingCartList(resData)
            if (
              resData.OrderShoppingCartList !== null &&
              resData.OrderShoppingCartList.length !== 0
            ) {
              const orderShoppingCartCmdyList = map(
                resData.OrderShoppingCartList[0].OrderShoppingCartCmdyList,
                (item: OrderShoppingCartCmdy) => {
                  if (item.Checked === undefined) {
                    item.Checked = true
                  }
                  return item
                }
              )
              resolve({ orderShoppingCartCmdyList: orderShoppingCartCmdyList })
              setOrderShoppingCartCmdyList(orderShoppingCartCmdyList)
              fetchShoppingCartCount()
            } else {
              resolve({ orderShoppingCartCmdyList: [] })
              setOrderShoppingCartCmdyList([])
              fetchShoppingCartCount()
            }
            // fetchShoppingCartCount()
            // setOrderShoppingCartCmdyList(resData.OrderShoppingCartList[0].OrderShoppingCartCmdyList)
          })
          .catch((error) => {
            reject(error)
            console.error(error)
          })
      }
    } else {
      setShoppingCartList(JSON.parse(getShoppingCartOnLocal()))
      setShoppingCartListWithNoLogin(JSON.parse(getShoppingCartOnLocal()))
      setOrderShoppingCartCmdyList(
        JSON.parse(getShoppingCartOnLocal()).OrderShoppingCartCmdyList
      )
      setShoppingCartCount(
        stateRef.shoppingCartListWithNoLogin.OrderShoppingCartCmdyList.length
      )
    }
  })
}
const addShoppingCart = (
  cmdy: any,
  path: string
): Promise<{
  OrderShoppingCartId: string
}> => {
  return new Promise((resolve, reject) => {
    if (store.state.isLogin) {
      console.log('aa', cmdy)
      if (cmdy.PackageCmdyList?.length > 0) {
        cmdy.PackageCmdyList[0].CmdyQty = cmdy.CmdyQty
      }
      const data = {
        Data: {
          CmdyId: cmdy.CmdyId,
          EditorPortfolioId: cmdy.EditorPortfolioId || '',
          CmdyQty: cmdy.CmdyQty || 1,
          FrontendParameter: path || '',
          // PackageCmdyList: [],
          // AddOnCmdyList: []
          PackageCmdyList: cmdy.PackageCmdyList || [],
          AddOnCmdyList: cmdy.AddOnCmdyList || []
        }
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
            requestUrl.addShoppingCart,
          data,
          {
            headers: {
              'api-version': '3.0'
            }
          }
        )
        .then((res) => {
          const resData = res.data.Data
          toast.success('已加入購物車', {
            position: POSITION.TOP_CENTER
          })
          resolve({ OrderShoppingCartId: resData.OrderShoppingCartId })
          // alert('已加入購物車')
          fetchShoppingCartList()
        })
        .catch((error) => {
          console.error(error)
        })
    } else {
      toast.success('已加入購物車', {
        position: POSITION.TOP_CENTER
      })
      cmdy.FrontendParameter = path
      setShoppingCartInLocal(cmdy, 'add', 0)
      setShoppingCartListWithNoLogin(JSON.parse(getShoppingCartOnLocal()))
      fetchShoppingCartList()
    }
  })
}
const delShoppingCart = (
  orderShoppingCartId: any,
  index: any,
  type = 'cmdy',
  subIndex: number
) => {
  if (store.state.isLogin) {
    const data = {
      Data: {
        OrderShoppingCartId: orderShoppingCartId
      }
    }
    request
      .post(
        window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.delShoppingCart,
        data
      )
      .then((res) => {
        const resData = res.data
        fetchShoppingCartList()
        toast.success(resData.Message, {
          position: POSITION.TOP_CENTER
        })
        // alert(resData.Message)
      })
      .catch((error) => {
        console.error(error)
      })
  } else {
    if (type === 'addOn') {
      const _shoppingCart = JSON.parse(getShoppingCartOnLocal())
        .OrderShoppingCartCmdyList[index]
      _shoppingCart.AddOnCmdyList.splice(subIndex, 1)
      setShoppingCartInLocal(
        _shoppingCart as OrderShoppingCartCmdy,
        'update',
        index
      )
      // updateShoppingCart(_shoppingCart[index])
    } else {
      setShoppingCartInLocal({} as OrderShoppingCartCmdy, 'delete', index)
    }
    fetchShoppingCartList()
    // setShoppingCart(cmdy, false)
  }
}
const updateShoppingCart = (
  cmdy: OrderShoppingCartCmdy
): Promise<{ orderShoppingCartCmdy: any }> => {
  return new Promise((resolve, reject) => {
    const data = {
      Data: {
        OrderShoppingCartId: cmdy.OrderShoppingCartId,
        CmdyQty: cmdy.CmdyQty
      }
    }
    request
      .post(
        window._env_.VUE_APP_STOREBOOK_SERVICE_API +
          requestUrl.updateShoppingCart,
        data,
        {
          headers: {
            'api-version': '2.0'
          }
        }
      )
      .then((res) => {
        const resData = res.data
        fetchShoppingCartList().then((res) => {
          const _orderShoppingCartCmdy = res.orderShoppingCartCmdyList.find(
            (item) => {
              return item.OrderShoppingCartId === cmdy.OrderShoppingCartId
            }
          )
          resolve({ orderShoppingCartCmdy: _orderShoppingCartCmdy })
        })
        toast.success(resData.Message, {
          position: POSITION.TOP_CENTER
        })
        // alert(resData.Message)
      })
      .catch((error) => {
        reject(error)
        toast.error(error.data.Message, {
          position: POSITION.TOP_CENTER
        })
        // alert(error.data.Message)
        console.log(error)
      })
  })
}
const fetchShoppingCartCount = () => {
  request
    .post(
      window._env_.VUE_APP_STOREBOOK_SERVICE_API +
        requestUrl.getShoppingCartCount,
      {
        Data: {
          ShopCodes: 'G-Gift',
          OrderShoppingCartIds: ''
        }
      }
    )
    .then((res) => {
      setShoppingCartCount(res.data.Data.CmdyQty)
    })
    .catch((err) => {
      setShoppingCartCount(0)
      console.log(err)
    })
}
const fetchPaymentTypeList = () => {
  request
    .post<BaseResponse<GetPaymentType>>(
      window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.paymentTypeList,
      { Data: {} }
    )
    .then((res) => {
      setPaymentTypeList(res.data.Data.PaymentTypeList)
    })
    .catch((err) => {
      console.log(err)
    })
}
const fetchInvoiceTypeList = () => {
  request
    .post<BaseResponse<GetInvoiceType>>(
      window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.invoiceTypeList,
      { Data: {} }
    )
    .then((res) => {
      setInvoiceTypeList(res.data.Data.InvoiceTypeList)
    })
    .catch((err) => {
      console.log(err)
    })
}
const fetchDeliveryTypeList = () => {
  request
    .post<BaseResponse<GetDeliveryType>>(
      window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.deliveryTypeList,
      { Data: {} }
    )
    .then((res) => {
      map(res.data.Data.DeliveryTypeList, (item: any) => {
        item.Desc = item.Desc + ' $' + item.Freight
      })
      setDeliveryTypeList(res.data.Data.DeliveryTypeList)
    })
    .catch((err) => {
      console.log(err)
    })
}
const fetchDeliveryShopList = () => {
  request
    .post<BaseResponse<GetDeliveryShop>>(
      window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.deliveryShopList,
      { Data: {} }
    )
    .then((res) => {
      console.log(res.data.Data)
      setDeliveryShopList(res.data.Data.RegionList)
      let city = [] as any[]
      // let cityShop = [] as Shop[]
      city = res.data.Data.RegionList.reduce((result: any, item: any) => {
        result.push({ CityName: item.RegionName })
        return result
      }, [] as any) as any[]
      console.log('city ', city)
      // if (city.length > 0) {
      //   const result = find(res.data.Data.RegionList, { RegionName: city[0] })
      //   if (result) {
      //     cityShop = result.ShopList
      //   }
      // }
      // let _deliveryShopList = [] as Shop[]
      // res.data.Data.RegionList
      // console.log(_deliveryShopList)
      setDeliveryShopCityList(city)
      // if (cityShop) {
      //   setDeliveryShopFilterByRegionList(cityShop)
      // }
    })
    .catch((err) => {
      console.log(err)
    })
}
const fetchPurchaseStatusList = () => {
  request
    .post<BaseResponse<GetDeliveryType>>(
      window._env_.VUE_APP_STOREBOOK_SERVICE_API +
        requestUrl.getPurchaseStatusList,
      { Data: {} }
    )
    .then((res: any) => {
      const _purchaseStatusList = map(
        res.data.Data.PurchaseStatusList,
        (item, index) => {
          item.id = index
          item.code = item.Code
          item.text = item.Desc
          return item
        }
      )
      setPurchaseStatusList(_purchaseStatusList)
    })
    .catch((err) => {
      console.log(err)
    })
}

export default {
  state: readonly(stateRef),
  setShoppingCartListWithNoLogin,
  setShoppingCartList,
  setOrderShoppingCartCmdyList,
  setShoppingCartCount,
  setShoppingCartDetailInfo,
  setDeliveryTypeList,
  setDeliveryShopList,
  setInvoiceTypeList,
  setDeliveryShopFilterByRegionList,
  setPaymentTypeList,
  setPurchaseStatusList,
  fetchShoppingCartList,
  addShoppingCart,
  delShoppingCart,
  fetchPaymentTypeList,
  fetchInvoiceTypeList,
  fetchDeliveryTypeList,
  fetchDeliveryShopList,
  updateShoppingCart,
  fetchShoppingCartCount,
  fetchPurchaseStatusList
}
