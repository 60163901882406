import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28383d4b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-sm text-left inline-block" }
const _hoisted_2 = {
  key: 0,
  class: "required-dot bg-dot-red"
}
const _hoisted_3 = { class: "flex gap-2" }
const _hoisted_4 = { class: "w-[37%]" }
const _hoisted_5 = { class: "w-[37%]" }
const _hoisted_6 = { class: "w-[26%]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!
  const _component_Input = _resolveComponent("Input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label !== '')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["input-label relative font-light pt-2 pb-3 text-breadcrumb-color tracking-widest", [{ required: _ctx.required }]])
        }, [
          _createTextVNode(_toDisplayString(_ctx.label), 1),
          (_ctx.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Select, {
          width: '100%',
          itemList: _ctx.cityArr,
          modelValue: _ctx.deliveryCityRef,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.deliveryCityRef) = $event)),
          onChange: _ctx.getDists
        }, null, 8, ["itemList", "modelValue", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Select, {
          width: '100%',
          itemList: _ctx.distArr,
          itemValue: 'distName',
          itemText: 'distName',
          modelValue: _ctx.deliveryDistRef,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.deliveryDistRef) = $event)),
          onChange: _ctx.getZip
        }, null, 8, ["itemList", "modelValue", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_Input, {
          disabled: true,
          modelValue: _ctx.deliveryZipRef,
          width: '100%'
        }, null, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_Input, {
        class: "under-line mt-1 mb-5 w-full",
        width: '100%',
        placeholder: '街道名稱、巷弄號樓',
        modelValue: _ctx.deliveryAddrRef,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.deliveryAddrRef) = $event))
      }, null, 8, ["modelValue"])
    ])
  ]))
}