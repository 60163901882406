import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39eadcf0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SwiperSlide = _resolveComponent("SwiperSlide")!
  const _component_Swiper = _resolveComponent("Swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Swiper, {
      class: "swiper",
      modules: _ctx.modules,
      observer: true,
      onObserverUpdate: _ctx.observerUpdate,
      freeMode: true,
      mousewheel: true,
      onSwiper: _ctx.setControlledSwiper,
      navigation: {
        hiddenClass: 'swiper-button-hidden',
        prevEl: 'g-swiper-nav-prev',
        nextEl: 'g-swiper-nav-next',
      },
      slidesPerView: 'auto'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_SwiperSlide, { class: "slide" }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["ml-[16px] text-[13px] h-[33px] leading-[30px] sm:h-[41px] sm:leading-[40px] sm:text-sm text-center tracking-[0.1em] text-gary-text-color border-[1px] border-black-300-color rounded-full mr-[12px] px-[12px] whitespace-nowrap cursor-pointer", {
            'bg-primary-color':
              _ctx.productStore.state.selectedCmdyTag.CmdyTagId === undefined,
            'text-white':
              _ctx.productStore.state.selectedCmdyTag.CmdyTagId === undefined,
            'border-primary-color':
              _ctx.productStore.state.selectedCmdyTag.CmdyTagId === undefined,
          }]),
              onClick: _cache[0] || (_cache[0] = ($event: any) => {
            _ctx.productStore.setSelectedCmdyTag({});
            _ctx.getCmdyList('');
          })
            }, " ALL ", 2)
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productStore.state.cmdyTagList, (productClassItem) => {
          return (_openBlock(), _createBlock(_component_SwiperSlide, {
            class: "slide",
            key: productClassItem.CmdyTagId
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["text-[13px] h-[33px] leading-[30px] sm:h-[41px] sm:leading-[40px] sm:text-sm text-center tracking-[0.1em] text-gary-text-color border-[1px] border-black-300-color rounded-full mr-[12px] px-[12px] whitespace-nowrap cursor-pointer", {
            'bg-primary-color':
              _ctx.productStore.state.selectedCmdyTag.CmdyTagId ===
              productClassItem.CmdyTagId,
            'text-white':
              _ctx.productStore.state.selectedCmdyTag.CmdyTagId ===
              productClassItem.CmdyTagId,
            'border-primary-color':
              _ctx.productStore.state.selectedCmdyTag.CmdyTagId ===
              productClassItem.CmdyTagId,
          }]),
                onClick: ($event: any) => {
            _ctx.productStore.setSelectedCmdyTag(productClassItem);
            _ctx.getCmdyList(_ctx.productStore.state.selectedCmdyTag.CmdyTagId);
          }
              }, _toDisplayString(productClassItem.Name) + "(" + _toDisplayString(productClassItem.CmdyCount) + ") ", 11, _hoisted_2)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["modules", "onObserverUpdate", "onSwiper"])
  ]))
}