
import { defineComponent } from 'vue'
import SquareItemFullImage from '@/components/SquareItemFullImage.vue'
import Title from '@/components/common/Title.vue'
import BgIcon from '@/components/common/BgIcon.vue'
import { Navigation, Autoplay, Mousewheel, FreeMode, Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/pagination'
import isMobile from 'ismobilejs'
export default defineComponent({
  name: 'g-image-list',
  emits: ['goProductDetail'],
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    subTitle: {
      type: String,
      required: true,
      default: ''
    },
    imageList: {
      type: Array,
      required: true,
      default: () => []
    },
    row: {
      type: Number,
      required: false,
      default: 2
    }
  },
  components: {
    Title,
    Swiper,
    SwiperSlide,
    // BgIcon,
    SquareItemFullImage
  },
  setup () {
    return {
      modules: [Autoplay, Navigation, Mousewheel, FreeMode, Scrollbar],
      isMobile
      //   productClassIconListRef,
      //   selectProductClassIconIdRef
    }
  }
})
