
import { defineComponent, ref, toRef } from 'vue'
export default defineComponent({
  props: {
    placeholder: {
      type: String,
      default: '搜尋'
    },
    getInput: {
      type: Function,
      default: () => []
    }
  },
  setup () {
    const searchInput = ref('')
    const inputRef = ref(null)
    return { searchInput, inputRef }
  }
})
