import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col sm:flex-row gap-6" }
const _hoisted_2 = { class: "basis-1/4 flex flex-row sm:flex-col gap-4 pt-5 sm:pl-20" }
const _hoisted_3 = { class: "basis-1/2 flex flex-row sm:pt-5" }
const _hoisted_4 = { class: "w-[70px] sm:basis-1/3 sm:pt-7" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "w-[140px] sm:basis-2/3 flex flex-col sm:pt-7" }
const _hoisted_7 = { class: "text-gary-text-color h-[70px] font-bold text-[18px] sm:text-[24px] pl-4 flex items-center" }
const _hoisted_8 = { class: "basis-3/4 text-xs text-gary-text-color leading-[19px] tracking-[.1em] sm:text-sm flex flex-col gap-6 p-1.5 pb-[20px] sm:p-20" }
const _hoisted_9 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            id: "IMG_2",
            class: "h-[70px] w-[70px]",
            src: _ctx.shopHallInfoRef.IconImage?.PcWeb || ''
          }, null, 8, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.shopHallInfoRef.Name), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("p", {
        innerHTML: _ctx.shopHallInfoRef.Desc
      }, null, 8, _hoisted_9)
    ])
  ]))
}