
import { defineComponent, ref, reactive, watch, toRef, provide } from 'vue'
import ModalBase from '@/components/common/ModalBase.vue'
import SwiperSquareItem from '@/components/SwiperSquareItem.vue'
import Select from '@/components/common/Select.vue'
import Input from '@/components/common/Input.vue'
import RectangImageRadioButton from '@/components/common/RectangImageRadioButton.vue'
import TextRadioButton from '@/components/common/TextRadioButton.vue'
import Btn from '@/components/common/Btn.vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import CanvasText from '@/components/common/CanvasText.vue'
import Spacer from '../common/Spacer.vue'
import InputWithButton from '@/components/common/InputWithButton.vue'
import { POSITION, useToast } from 'vue-toastification'
import store from '@/composition/store'
import request from '@/utils/request'
import * as requestUrl from '@/utils/requestUrl'
import { BaseResponse, CommodityDetailResponse } from '@/types/ResponseType'
import { mappingCodeParser } from '@/utils/mappingCodeParser'
import cloneDeep from 'lodash/cloneDeep'
import { onClickOutside } from '@vueuse/core'
export default defineComponent({
  name: 'g-add-on-modal',
  emits: ['cancel', 'ok', 'update:AddOnCmdyItem'],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    AddOnCmdyItem: {
      type: Object as any
    },
    cmdyType: {
      type: String
    },
    setPackage: {
      type: Function,
      default: null
    },
    page: {
      type: String
    }
  },
  components: {
    ModalBase,
    SwiperSquareItem,
    Btn,
    RectangImageRadioButton,
    TextRadioButton,
    Select,
    Input,
    // SwiperSlide,
    // CanvasText,
    Spacer,
    InputWithButton
  },
  setup (props, context) {
    const { emit } = context
    provide('store', store)
    const toast = useToast()
    const dataLoaded = ref(false)
    const openModal = ref(false)
    const test = ref(1)
    const text = ref('')
    const showDescript = ref(true)
    const showDescript2 = ref(true)
    const selectFont = ref('Noto Serif TC')
    const selectAddOn = ref({} as any)
    const AddOnCmdyItemRef = toRef(props, 'AddOnCmdyItem')
    // const AddOnCmdyItemRef = ref(props.AddOnCmdyItem)
    const cmdyQty = ref(AddOnCmdyItemRef.value.CmdyQty)
    const optionModel = ref({})
    const maxCmdyQty = ref(20 as number)
    const textPropList = reactive([
      { text: '', fontSize: '30pt', fontName: '' }
    ])
    const getCmdyDetail = (parameter: any, type = 'cmdyid') => {
      if (!parameter) return

      store.setShowLoading(true)
      const reqData = {
        Data: { CmdyId: parameter, MappingCode: parameter, AccessId: '' }
      }
      const accessId = sessionStorage.accessId || ''
      let apiVersion = '5.0'
      reqData.Data.AccessId = accessId
      let getUrl =
        window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.getCmdyById

      // 依照type 不同使用不同
      if (type !== 'cmdyid') {
        getUrl =
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
          requestUrl.getCmdyByMappingCode

        apiVersion = '5.0'
      }
      request
        .post<BaseResponse<CommodityDetailResponse>>(getUrl, reqData, {
          headers: {
            'api-version': apiVersion
          }
        })
        .then((req) => {
          const resData = req.data
          // specList.value = resData.Data.CmdySpecList

          selectAddOn.value = resData.Data

          dataLoaded.value = true
          // selectedCmdyAddOn.value = resData.Data.AddOnCmdyList
          // selectedCmdyPackage.value = resData.Data.PackageCmdyList
          // selectedCmdyAddOn.value.forEach((item) => {
          //   item.Checked = false
          //   item.CmdyQty = 1
          // })
          // if (resData.Data.CmdyRelList.length !== 0) {
          //   resData.Data.CmdyRelList.forEach((CmdyRel) => {
          //     if (CmdyRel.Type === 'Package') {
          //       selectedCmdyAddOn.value = CmdyRel.RelCmdyList
          //     } else if (CmdyRel.Type === 'Package') {
          //       selectedCmdyPackage.value = CmdyRel.RelCmdyList
          //     }
          //   })
          // }
          maxCmdyQty.value = selectAddOn.value.MaxCmdyQty

          mappingCodeParser(resData.Data.MappingCode)
            .then((result: any) => {
              optionModel.value = cloneDeep(result)
            })
            .catch((e: any) => {
              console.log(e)
            })
          store.setShowLoading(false)
          // 解析MappingCode 從mappingcode反推現在所選擇的option

          // console.log(resData);
          // let _infoList = resData.Data.IntroList;
          // this.imageList = [];
          // if (_infoList.length === 0) return;

          // _infoList[0].IntroImgList.forEach((element: any) => {
          //   this.imageList.push({
          //     id: '0_' + element.Rank,
          //     image: element.Image
          //   });
          // });
          // console.log('activities >>>> ', resData.Data);
          // this.$route.params.productId = resData.Data.CmdyId;
          // 確認detail id是否一致
        })
        .catch((error) => {
          console.log(error)
          store.setShowLoading(false)
        })
    }

    const addOnRef = ref(null)
    onClickOutside(addOnRef, (event) => {
      emit('cancel')
    })
    watch(selectFont, (oldValue, newValue) => {
      textPropList.map((item) => {
        if (newValue) {
          item.fontName = newValue
        }

        return item
      })
    })
    // watch(
    //   () => props.AddOnCmdyItem,
    //   (oldValue, newValue) => {
    //     if (oldValue.CmdyId !== newValue.CmdyId) {
    //       alert(1)
    //       getCmdyDetail(newValue.CmdyId)
    //     }
    //   }
    // )
    watch(
      () => props.show,
      (oldValue, newValue) => {
        getCmdyDetail(props.AddOnCmdyItem.CmdyId)
        // getCmdyDetail('9c5a10e2-6216-48c8-ba11-118f30cc1b86')
      }
    )

    const subtractClick = () => {
      if (AddOnCmdyItemRef.value.CmdyQty === 1) {
        return
      }
      AddOnCmdyItemRef.value.CmdyQty = AddOnCmdyItemRef.value.CmdyQty - 1
    }
    const addClick = () => {
      if (AddOnCmdyItemRef.value.CmdyQty >= maxCmdyQty.value) {
        AddOnCmdyItemRef.value.CmdyQty = maxCmdyQty.value
        return toast.warning('已達訂購上限', {
          position: POSITION.TOP_CENTER
        })
      }
      AddOnCmdyItemRef.value.CmdyQty = AddOnCmdyItemRef.value.CmdyQty + 1
    }
    const getSelected = () => {
      console.log('tt' + AddOnCmdyItemRef.value.Checked)
      AddOnCmdyItemRef.value.Checked = true
      console.log('tt1' + AddOnCmdyItemRef.value.Checked)

      if (props.cmdyType === 'Package') {
        props.setPackage(AddOnCmdyItemRef.value)
      } else {
        // emit('update:AddOnCmdyItem', AddOnCmdyItemRef.value)
      }
      emit('ok')
    }

    return {
      store,
      openModal,
      test,
      text,
      showDescript,
      showDescript2,
      textPropList,
      selectFont,
      AddOnCmdyItemRef,
      cmdyQty,
      selectAddOn,
      subtractClick,
      addClick,
      getSelected,
      addOnRef,
      dataLoaded
    }
  }
})
