import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_MobileHeader = _resolveComponent("MobileHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_LoginModal = _resolveComponent("LoginModal")!
  const _component_DefaultLoader = _resolveComponent("DefaultLoader")!
  const _component_ActivityDetailModal = _resolveComponent("ActivityDetailModal")!
  const _component_ActivityListModal = _resolveComponent("ActivityListModal")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, { class: "hidden sm:block" }),
    _createVNode(_component_MobileHeader, { class: "sm:hidden" }),
    _createElementVNode("div", {
      class: "relative g-content mb-[80px]",
      style: {
      paddingTop: '50px',
    },
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.store.setShowProfile(false)))
    }, [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          (_openBlock(), _createBlock(_KeepAlive, { include: "user-layout" }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ], 1024))
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_Footer),
    _createVNode(_component_LoginModal),
    _createVNode(_component_DefaultLoader),
    _createVNode(_component_ActivityDetailModal, {
      ref: "activityDetailModalRef",
      show: _ctx.activityDetailModalVisible,
      "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activityDetailModalVisible) = $event)),
      onOpenActivityListModal: _ctx.openActivityListModal
    }, null, 8, ["show", "onOpenActivityListModal"]),
    _createVNode(_component_ActivityListModal, {
      ref: "activityListModalRef",
      show: _ctx.activityListModalVisible,
      "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.activityListModalVisible) = $event)),
      "cmdy-coupon-list": _ctx.couponList,
      "activity-list": _ctx.activityList
    }, null, 8, ["show", "cmdy-coupon-list", "activity-list"]),
    (_ctx.fixedIconShow)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["fixed-icon", _ctx.fixedIconName]),
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.openUrl && _ctx.openUrl(...args)))
        }, [
          _createVNode(_component_Icon, {
            "icon-name": "close_light",
            width: '15px',
            class: "absolute top-[-20px] right-[5px]",
            onClick: _cache[3] || (_cache[3] = (e) => { e.preventDefault(); e.stopPropagation(); _ctx.fixedIconShow = false; })
          })
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}