
import { defineComponent } from 'vue'
import { useField } from 'vee-validate'
export default defineComponent({
  name: 'g-textarea',
  props: {
    width: {
      type: String,
      require: false,
      default: '100px'
    },
    height: {
      type: String,
      require: false,
      default: '40px'
    },
    modelValue: {
      type: String,
      require: false,
      default: ''
    },
    label: {
      type: String,
      require: false,
      default: ''
    },
    placeholder: {
      type: String,
      require: false,
      default: ''
    },
    required: {
      type: Boolean,
      require: false,
      default: false
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false
    },
    iconName: {
      type: String,
      require: false,
      default: ''
    },
    iconWidth: {
      type: String,
      require: false,
      default: ''
    }
  },
  setup (props, context) {
    const { emit } = context
    const change = (event: Event) => {
      emit('update:modelValue', (event.target as HTMLInputElement).value)
    }

    return {
      change
    }
  }
})
