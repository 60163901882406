
import { defineComponent, provide, ref, toRef } from 'vue'
import { CmdyClass as CmdyType } from '@/types/cmdyType'
import FavoriteButton from '@/components/common/FavoriteButton.vue'
import { unescapeHTML } from '@/utils/utils'
import productStore from '@/composition/productStore'
import moment from 'moment'

export default defineComponent({
  emits: ['addToCollect'],
  props: {
    // product: {
    //   type: [],
    //   required: false,
    //   default: {
    //     CmdyClassId: '319dbb68-5a7c-11ed-a1ff-0242ac1b0002',
    //     Name: '123',
    //     ShopHallCode: 'BT21',
    //     Rank: 1,
    //     Image: {
    //       PcWeb: 'https://fakeimg.pl/594x399/?text=2',
    //       MobileWeb: 'https://fakeimg.pl/594x399/?text=2'
    //     },
    //     Alternation: '',
    //     MarkImage: '',
    //     ClassDesc: '',
    //     IconImage: 'https://fakeimg.pl/594x399/?text=2'
    //   }
    // }
    product: {
      type: Object as () => CmdyType,
      required: false
    }
  },
  components: {
    FavoriteButton
  },
  setup (props) {
    provide('productStore', productStore)
    const productRef = toRef(props, 'product')
    const isLike = ref(false)
    const addToCollect = async (item: any) => {
      console.log(item)
      if (!item.IsInCmdyCollect) {
        productStore.addCmdyCollect(item.CmdyId).then((res) => {
          item.IsInCmdyCollect = true
          item.CmdyCollectId = res.collectId
        })
        // try {
        //   const _confirmModal = confirmModal.value as ConfirmModal
        //   _confirmModal
        //     .createConfirm('確定移至我的最愛，下次再訂購？', '', false)
        //     .then(() => {

        //     })
        // } catch (err) {
        //   console.log(err)
        // }
      } else {
        const collectIdArray: string[] = []
        collectIdArray.push(item.CmdyCollectId)
        productStore.delCmdyCollect(collectIdArray).then(() => {
          item.IsInCmdyCollect = false
        })
        // try {
        //   const _confirmModal = confirmModal.value as ConfirmModal
        //   _confirmModal
        //     .createConfirm('確定要從我的最愛移除？', '', false)
        //     .then(() => {
        //     })
        // } catch (err) {
        //   console.log(err)
        // }
      }
    }
    return {
      isLike,
      productRef,
      addToCollect,
      unescapeHTML,
      moment
    }
  }
})
