
import { defineComponent } from 'vue'
export default defineComponent({
  data () {
    return {}
  },
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    customClass: {
      type: String,
      required: false,
      default: ''
    },
    width: {
      type: String,
      default: 'inherit'
    },
    image: {
      type: String,
      required: false,
      default: 'https://fakeimg.pl/600x600/'
    },
    productItem: {
      type: [],
      required: false,
      default: {
        CmdyId: 'd4a60b78-5595-11ed-a1ff-0242ac1b0002',
        Name: '鳳梨禮盒10',
        SubName: '規格：2入',
        Image: 'https://fakeimg.pl/600x600/',
        UnitFeeOri: 500,
        UnitFeeReal: 349,
        Status: 'Active',
        StatusDesc: '上架',
        ActiveTime: '',
        AnnounceType: ''
      }
    }
  }
})
