import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0abbec7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hidden sm:flex flex-col gap-5 text-gary-text-sub-title-color" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "g-collapse" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "sm:hidden text-gary-text-color text-sm sm:text-base font-normal px-[16px]" }
const _hoisted_6 = { class: "flex gap-[30px] font-medium py-3 flex-nowrap overflow-x-auto px-[16px]" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "flex gap-[30px] sm:gap-2 py-5 flex-nowrap overflow-x-auto bg-light-gary-color px-[34px] rounded-lg" }
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Collapse = _resolveComponent("Collapse")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createElementBlock("ul", {
          key: item.id,
          class: "cursor-pointer"
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(["transition-all", [
            {
              'border-gary-text-color border-b-[1px] text-gary-text-color':
                _ctx.showListRef[item.id],
            },
          ]]),
            onClick: ($event: any) => (_ctx.menuClickRef(item))
          }, _toDisplayString(item.title), 11, _hoisted_2),
          _createVNode(_component_Collapse, null, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.items, (subitem) => {
                  return (_openBlock(), _createElementBlock("li", {
                    class: _normalizeClass(["relative py-[3px] first:pt-4", {
                'subtitle-actived': subitem === _ctx.modelValue,
              }]),
                    key: subitem.id
                  }, [
                    _createElementVNode("span", {
                      class: _normalizeClass(["relative ml-[20px]", {
                  'border-b-[1px]': subitem.title === '會員發票載具歸戶',
                  'border-gary-text-color':
                    subitem.title === '會員發票載具歸戶' &&
                    subitem !== _ctx.modelValue,
                  'border-primary-color': subitem === _ctx.modelValue,
                }]),
                      onClick: ($event: any) => {
                  _ctx.$emit('update:modelValue', subitem);
                  _ctx.itemsClickRef(subitem);
                }
                    }, _toDisplayString(subitem.title), 11, _hoisted_4)
                  ], 2))
                }), 128))
              ], 512), [
                [_vShow, _ctx.showListRef[item.id]]
              ])
            ]),
            _: 2
          }, 1024)
        ]))
      }), 128)),
      _renderSlot(_ctx.$slots, "extend", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("ul", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass(["subheader-item inline-block min-w-fit", [
          {
            'border-gary-text-color border-b-[1px] text-gary-text-color':
              _ctx.showList[item.id],
          },
        ]]),
            key: item.id,
            onClick: ($event: any) => (_ctx.menuClickRef(item))
          }, _toDisplayString(item.title), 11, _hoisted_7))
        }), 128))
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createElementVNode("ul", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectItemRef, (subitem) => {
            return (_openBlock(), _createElementBlock("li", {
              class: _normalizeClass(["subheader-item inline-block min-w-fit", { 'subtitle-actived': subitem === _ctx.modelValue }]),
              key: subitem.id
            }, [
              _createElementVNode("span", {
                class: "relative",
                onClick: ($event: any) => {
              _ctx.$emit('update:modelValue', subitem);
              _ctx.itemsClickRef(subitem);
            }
              }, _toDisplayString(subitem.title), 9, _hoisted_9)
            ], 2))
          }), 128))
        ])
      ], 512), [
        [_vShow, _ctx.selectItemRef !== [] && _ctx.selectItemRef !== null]
      ])
    ])
  ], 64))
}