import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-034fe6cf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "g-rectang-radio-button" }
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["rectang-radio-image relative", [{ 'selected': _ctx.selected}]]),
      style: _normalizeStyle({ width: _ctx.width, height: _ctx.width })
    }, [
      _createElementVNode("input", {
        type: "radio",
        value: _ctx.inputValue,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args)))
      }, null, 8, _hoisted_2),
      _createElementVNode("div", {
        style: _normalizeStyle({ backgroundSize: 'contain', width: _ctx.width, height: _ctx.width, backgroundImage: 'url(https://fakeimg.pl/40x40/?text=3)' })
      }, null, 4)
    ], 6)
  ]))
}