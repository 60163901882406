
import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
  provide,
  watch
} from 'vue'
import { useRoute } from 'vue-router'
import store from '@/composition/store'
import cartStore from '@/composition/cartStore'
import Menu from '@/components/mobile/Menu.vue'
import Icon from '../common/Icon.vue'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import router from '@/router'
import { clearCookies } from '@/utils/auth'
import { useToast, POSITION } from 'vue-toastification'
import map from 'lodash/map'
import { unescapeHTML } from '@/utils/utils'
export default defineComponent({
  name: 'g-mobile-header',
  props: {},
  components: {
    Menu,
    Icon
  },
  setup () {
    provide('store', store)
    provide('cartStore', cartStore)
    const header = ref(null)
    const route = useRoute()
    const toast = useToast()
    const lastScroll = ref(0)
    const menuList = ref([] as any)

    const scrollFunc = () => {
      store.setShowProfile(false)
      if (route.name !== 'home') {
        const st = Math.abs(
          window.scrollY ||
            window.pageYOffset ||
            (document.getElementById('app') as HTMLBodyElement).scrollTop
        )
        if (st < lastScroll.value || st === 0) {
          if (header.value) {
            (header.value as HTMLElement).style.top = '0px'
          }
        } else {
          if (st < 50) {
            if (header.value) {
              (header.value as HTMLElement).style.top = '0px'
            }
          } else {
            if (header.value) {
              (header.value as HTMLElement).style.top = '-57px'
            }
          }
        }

        lastScroll.value = st
      }

      if (route.name === 'home') {
        store.setShowLogo(false)
        if (header.value) {
          (header.value as HTMLElement).style.top = '0px'
        }
      }
    }
    const getMenuTree = () => {
      const data = {
        Data: {}
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.getMenuTree,
          data
        )
        .then((req) => {
          const resData = req.data.Data.MenuList
          menuList.value = resData
        })
        .catch((error) => {
          console.error(error)
        })
    }
    const goHome = () => {
      const _app = document.getElementById('app') as HTMLBodyElement
      _app.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      router.push({
        name: 'home'
      })
    }
    const logout = () => {
      request
        .post(window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.logout)
        .then(() => {
          store.cleanUserInfo()
          clearCookies()
          store.setIsLogin(false)
          toast.success('已登出', {
            position: POSITION.TOP_CENTER
          })
          // alert(error.response.data.Message)
          // alert('已登出')

          const _app = document.getElementById('app') as HTMLBodyElement
          _app.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
          router.push({
            name: 'home'
          })

          store.setShowMenu(false)
          store.cleanUserInfo()
        })
        .catch(() => {
          // console.error(error.response.data.Message)
        })
      if (window.FB.getAccessToken() !== null) {
        window.FB.api('/me/permissions', 'delete', null, () =>
          window.FB.logout()
        )
      }
    }
    const orderShoppingCartCmdyListRef = ref(
      cartStore.state.orderShoppingCartCmdyList
    )
    watch(
      () => cartStore.state.orderShoppingCartCmdyList,
      () => {
        orderShoppingCartCmdyListRef.value =
          cartStore.state.orderShoppingCartCmdyList
      }
    )
    const goToPage = (nextPage: string) => {
      store.setShowProfile(false)
      if (
        nextPage === 'shoppingCart' &&
        orderShoppingCartCmdyListRef.value.length === 0
      ) {
        toast.warning('購物車內目前沒有商品', {
          position: POSITION.TOP_CENTER
        })
      } else {
        const _app = document.getElementById('app') as HTMLBodyElement
        _app.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
        router.push({
          name: nextPage
        })
      }
    }
    onMounted(() => {
      (document.getElementById('app') as HTMLBodyElement).addEventListener(
        'scroll',
        scrollFunc
      )
      // window.addEventListener('scroll', scrollFunc)
      getMenuTree()
    })

    onUnmounted(() => {
      (document.getElementById('app') as HTMLBodyElement).removeEventListener(
        'scroll',
        scrollFunc
      )
      // window.removeEventListener('scroll', scrollFunc)
    })

    return {
      store,
      cartStore,
      header,
      menuList,
      goHome,
      logout,
      goToPage,
      unescapeHTML
    }
  }
})
