import { createStore } from 'vuex'

import * as actions from './actions'
import * as getters from './getters'

import { state, mutations } from './mutations'

export default createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {
  }
})
