
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'g-btn',
  props: {
    text: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: '50px'
    },
    width: {
      type: String,
      default: '177px'
    },
    type: {
      type: String,
      default: ''
    },
    fontSize: {
      type: String,
      default: '16px'
    }
  }
  // setup () {

  // }
})
