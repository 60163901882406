import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6145c3e9"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "required-dot bg-dot-red"
}
const _hoisted_2 = { class: "textarea-wrap w-full" }
const _hoisted_3 = ["placeholder", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["g-textarea text-sm text-left inline-block w-full", [{ disabled: _ctx.disabled }]])
  }, [
    (_ctx.label !== '')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["textarea-label flex items-center relative font-normal pb-2 text-breadcrumb-color tracking-widest", [{ required: _ctx.required }]])
        }, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          _renderSlot(_ctx.$slots, "label-icon", {}, () => [
            (_ctx.required)
              ? (_openBlock(), _createElementBlock("span", _hoisted_1))
              : _createCommentVNode("", true)
          ], true)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("textarea", {
        id: "message",
        class: "block p-2.5 w-full h-[120px] text-[13px] tracking-[.12em] text-black-400-color rounded-md",
        placeholder: _ctx.placeholder,
        value: _ctx.modelValue,
        onInput: _cache[0] || (_cache[0] = 
          (e) => {
            _ctx.change(e);
          }
        )
      }, null, 40, _hoisted_3)
    ])
  ], 2))
}