import { State, UserInfo } from './types/stateType'
import store from '@/store'
import { getToken } from '@/utils/auth'

export const getIsShowLogo = (state: State):boolean => {
  return state.showLogo
}
export const getIsShowMenu = (state: State):boolean => {
  return state.showMenu
}

export const getIsShowLoading = (state: State): boolean => {
  return state.isShowLoading
}

export const getUserInfo = (state: State): UserInfo | undefined => {
  if (getToken() === undefined) return state.userInfo
  if (state.userInfo === undefined) {
    store.dispatch('actionFetchUserInfo')
  }
  return state.userInfo
}
