
import { defineComponent } from 'vue'
// require('../assets/G95542_白牛皮貼裱E浪+貝瓷杯包裝_卡比獸假圖(446.5x303.5mm+單面+軋型+1款x1)印後交一勝副廠長拿掉刀模+文字-01')
export default defineComponent({
  data () {
    return {
      templateData: {
        template01: {
          templateid: '',
          textList: [{
            id: 0,
            text: '測試姓名位子',
            x: '1407',
            y: '1553',
            fontSize: '23'

          }, {
            id: 1,
            text: '測試姓名位子',
            x: '3931',
            y: '1556',
            fontSize: '23'
          }],
          productionImg: {
            img: require('../assets/img/G95542_白牛皮貼裱E浪+貝瓷杯包裝_卡比獸假圖(446.5x303.5mm+單面+軋型+1款x1)印後交一勝副廠長拿掉刀模+文字-01.jpg'),
            width: 5344,
            height: 3656
          }
        },
        template02: {
          templateid: '',
          textList: [{
            id: 0,
            text: '嚴心悅',
            x: '167.8',
            y: '62.64',
            fontSize: '50'
          }],
          productionImg: {
            img: require('../assets/img/姓名貼_拿掉文字JPG+RGB+300dpi-B1330-300x317mm-01.jpg'),
            width: 390,
            height: 189
          }
        },
        template03: {
          templateid: '',
          textList: [{
            id: 0,
            text: 'Lilly',
            x: '2191.8',
            y: '920.56',
            fontSize: '67'
          }, {
            id: 0,
            text: 'Lilly',
            x: '901.145',
            y: '920.56',
            fontSize: '67'
          }],
          productionImg: {
            img: require('../assets/img/貝瓷杯測試_拿掉文字.jpg'),
            width: 3130,
            height: 1429
          }
        }
      }
    }
  }
})
