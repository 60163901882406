
import { defineComponent, onMounted, provide, ref, computed, watch } from 'vue'
import Title from '@/components/common/Title.vue'
import ProductClassItemList from '@/components/ProductClassItemList.vue'
import ProductItemList from '@/components/ProductItemList.vue'
import ProductItemListWithSelect from '@/components/ProductItemListWithSelect.vue'
import SearchInput from '@/components/common/SearchInput.vue'
import Breadcrumb from '@/components/common/Breadcrumb.vue'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import productStore from '@/composition/productStore'
import store from '@/composition/store'
import map from 'lodash/map'
import filter from 'lodash/filter'
import cloneDeep from 'lodash/cloneDeep'

enum ContentType {
  normal = '願望清單',
  edit = '管理我的願望清單',
}
export default defineComponent({
  props: {
    subTitle: {
      type: String,
      required: true,
      default: ''
    },
    imageList: {
      type: Array,
      required: true,
      default: () => [0, 1, 2, 3, 4, 5, 6, 7, 8]
    },
    row: {
      type: Number,
      required: false,
      default: 5
    }
  },
  components: {
    Title,
    ProductClassItemList,
    ProductItemList,
    SearchInput,
    ProductItemListWithSelect,
    Breadcrumb
  },
  setup () {
    provide('productStore', productStore)
    const title = ref('願望清單')
    const getInput = (val: string) => {
      productStore.fetchCmdyCollectList(
        0,
        0,
        '',
        val,
        productStore.state.selectedOrderType
      )
    }
    const getSelectedItem = computed(() => {
      if (productStore.state.cmdyList.length === 0) {
        return []
      }

      const _selectedItems = filter(
        productStore.state.cmdyList,
        (item: any) => {
          return item.Checked === true
        }
      )

      return _selectedItems
    })
    const contentType = ref(ContentType.normal)
    const allSelect = ref(false)
    const manageCollectList = (type:any) => {
      contentType.value = type
      productStore.fetchCmdyCollectList(
        0,
        0,
        '',
        '',
        productStore.state.selectedOrderType
      )
    }
    const allItemSelect = () => {
      const _cmdyList = cloneDeep(productStore.state.cmdyList)
      map(_cmdyList, (item) => {
        item.Checked = !allSelect.value
        return item
      })
      allSelect.value = !allSelect.value
      // const _cmdyList = map(productStore.state.cmdyList, (item: any) => {
      //   item.Checked = !allSelect.value
      // })
      productStore.setCmdyList(_cmdyList)
    }
    const delCmdyCollect = () => {
      const collectIdArray: string[] = []
      getSelectedItem.value.forEach((element) => {
        collectIdArray.push(element.CollectId)
      })
      productStore.delCmdyCollect(collectIdArray).then(() => {
        productStore.fetchCmdyCollectList(
          0,
          0,
          '',
          '',
          productStore.state.selectedOrderType
        )
      })
    }
    watch(
      () => productStore.state.selectedOrderType,
      () => {
        productStore.fetchCmdyCollectList(
          0,
          0,
          '',
          '',
          productStore.state.selectedOrderType
        )
      }
    )
    onMounted(() => {
      productStore.fetchCmdyCollectList(
        0,
        0,
        '',
        '',
        productStore.state.selectedOrderType
      )
    })
    return {
      title,
      contentType,
      ContentType,
      productStore,
      allSelect,
      getSelectedItem,
      manageCollectList,
      getInput,
      allItemSelect,
      delCmdyCollect
    }
  }
})
