
import productStore from '@/composition/productStore'
import {
  defineComponent,
  ref,
  reactive,
  provide,
  onMounted,
  onUnmounted
} from 'vue'
export default defineComponent({
  name: 'g-dropdown',
  components: {},
  setup () {
    provide('productStore', productStore)
    const openFilter = () => {
      showContent.value = !showContent.value
      productStore.setShowRectangleItem(false)
    }
    const closeFilter = () => {
      showContent.value = false
    }
    const orderTypeList = ref([
      { code: 'OnShelfDate', Desc: '最新上架' },
      { code: 'PriceDesc', Desc: '＄由高至低' },
      { code: 'PriceAsc', Desc: '＄由低至高' }
    ])

    // const show1 = ref(true)
    // const show2 = ref(true)
    // const show3 = ref(true)
    const showContent = ref(false)
    const selected = ref(orderTypeList.value[0].Desc)
    const filterClick = (orderType: any) => {
      selected.value = orderType.Desc
      console.log(orderType)
      productStore.setSelectedOrderType(orderType.code)
      showContent.value = false
    }
    const sliderModel = reactive([100, 1000])
    const scrollFunc = () => {
      showContent.value = false
    }
    onMounted(() => {
      (document.getElementById('app') as HTMLBodyElement).addEventListener('scroll', scrollFunc)
      // window.addEventListener('scroll', scrollFunc)
    })

    onUnmounted(() => {
      (document.getElementById('app') as HTMLBodyElement).removeEventListener('scroll', scrollFunc)
      // window.removeEventListener('scroll', scrollFunc)
    })
    return {
      productStore,
      openFilter,
      closeFilter,
      filterClick,
      orderTypeList,
      showContent,
      sliderModel,
      selected
    }
  }
})
