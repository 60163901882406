
import { defineComponent, onMounted, provide, ref } from 'vue'
import CheckBox from '@/components/common/CheckBox.vue'
import CheckBoxGroup from '@/components/common/CheckBoxGroup.vue'
import Textarea from '@/components/common/Textarea.vue'
import Input from '@/components/common/Input.vue'
import Radio from '@/components/common/Radio.vue'
import Btn from '@/components/common/Btn.vue'
import RadioButton from '@/components/common/RadioButton.vue'
import ValidateCodeWithCaptcha from '@/components/common/ValidateCodeWithCaptcha.vue'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import { isEmail, isPhoneNumber } from '@/utils/utils'
import { POSITION, useToast } from 'vue-toastification'
import store from '@/composition/store'
import { QuestionType } from '@/types/ResponseType'
export default defineComponent({
  name: 'contact-view',
  components: {
    // CheckBoxGroup,
    ValidateCodeWithCaptcha,
    // CheckBox,
    Textarea,
    Input,
    // Radio,
    RadioButton,
    Btn
  },
  setup () {
    provide('store', store)
    const toast = useToast()
    const check = ref([])
    const questionTypeList = ref([] as QuestionType[])
    const availableContactTimeList = ref()
    const selectQuestionType = ref()
    const selectContactTime = ref()
    const questionContent = ref('')
    const contactMobile = ref(store.state.userInfo.Mobile)
    const contactEmail = ref(store.state.userInfo.Email)
    const orderId = ref('')
    const captchaId = ref('')
    const captchaBase64Image = ref('')
    const captchaCode = ref('')
    const fetchQuestionTypeList = () => {
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
            requestUrl.getQuestionTypeList,
          { Data: {} }
        )
        .then((res) => {
          questionTypeList.value = res.data.Data.QuestionTypeList
        })
        .catch((err) => {
          console.log(err)
        })
    }
    const fetchAvailableContactTimeList = () => {
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
            requestUrl.getAvailableContactTimeList,
          { Data: {} }
        )
        .then((res) => {
          availableContactTimeList.value = res.data.Data.ContactTimeList
        })
        .catch((err) => {
          console.log(err)
        })
    }
    const getCaptchaImage = () => {
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
            requestUrl.getCaptchaImage
        )
        .then((res) => {
          captchaId.value = res.data.Data.CaptchaId
          captchaBase64Image.value = res.data.Data.CaptchaBase64Image
        })
        .catch((err) => {
          console.log(err)
        })
    }
    const addCustomContact = () => {
      store.setShowLoading(true)
      if (
        selectQuestionType.value === undefined ||
        selectQuestionType.value === null
      ) {
        store.setShowLoading(false)
        return toast.warning('請選擇問題分類', {
          position: POSITION.TOP_CENTER
        })
      }
      if (questionContent.value === '') {
        store.setShowLoading(false)
        return toast.warning('請填寫問題說明', {
          position: POSITION.TOP_CENTER
        })
      }
      if (contactMobile.value === '') {
        store.setShowLoading(false)
        return toast.warning('請填寫手機號碼', {
          position: POSITION.TOP_CENTER
        })
      }
      if (!isPhoneNumber(contactMobile.value)) {
        store.setShowLoading(false)
        return toast.warning('手機格式錯誤', {
          position: POSITION.TOP_CENTER
        })
      }
      if (contactEmail.value === '') {
        store.setShowLoading(false)
        return toast.warning('請填寫Email', {
          position: POSITION.TOP_CENTER
        })
      }
      if (!isEmail(contactEmail.value)) {
        store.setShowLoading(false)
        return toast.warning('信箱格式錯誤', {
          position: POSITION.TOP_CENTER
        })
      }
      if (captchaCode.value === '') {
        store.setShowLoading(false)
        return toast.warning('請輸入驗證碼', {
          position: POSITION.TOP_CENTER
        })
      }
      const data = {
        Data: {
          ShopCode: 'G-Gift',
          QuestionType: selectQuestionType.value?.Code,
          Content: questionContent.value,
          Mobile: contactMobile.value,
          Email: contactEmail.value,
          OrderId: orderId.value,
          AvailableContactTime: selectContactTime.value?.Code || 'All',
          CaptchaId: captchaId.value,
          CaptchaCode: captchaCode.value
        }
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
            requestUrl.addCustomContact,
          data
        )
        .then((res) => {
          (document.getElementById('app') as HTMLBodyElement).scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
          toast.success(res.data.Message, {
            position: POSITION.TOP_CENTER
          })
          selectQuestionType.value = null
          questionContent.value = ''
          orderId.value = ''
          captchaCode.value = ''
          selectContactTime.value = null
          store.setShowLoading(false)
        })
        .catch((err) => {
          captchaCode.value = ''
          getCaptchaImage()
          store.setShowLoading(false)
          return toast.warning(err.data.Message, {
            position: POSITION.TOP_CENTER
          })
          // console.log(err)
        })
    }
    onMounted(() => {
      getCaptchaImage()
      fetchQuestionTypeList()
      fetchAvailableContactTimeList()
    })
    return {
      check,
      questionTypeList,
      availableContactTimeList,
      selectContactTime,
      selectQuestionType,
      questionContent,
      contactEmail,
      orderId,
      captchaId,
      captchaBase64Image,
      captchaCode,
      contactMobile,
      getCaptchaImage,
      fetchQuestionTypeList,
      fetchAvailableContactTimeList,
      addCustomContact
    }
  }
})
