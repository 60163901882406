import { reactive, computed, readonly } from 'vue'
import { getToken, clearCookies } from '@/utils/auth'
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import { BaseResponse, UserDetail } from '@/types/ResponseType'

// 對應原本的 state
const stateRef: any = reactive({
  showLogo: false,
  showMenu: false,
  showProfile: false,
  showShoppingCart: false,
  showLoading: 0,
  showLoginModal: false,
  showSearchBar: false,
  isLogin: false,
  userInfo: {},
  nextUrl: ''
})

// 對應原本的 mutations
const setShowLogo = (val: boolean) => {
  stateRef.showLogo = val
}
const setShowMenu = (val: boolean) => {
  stateRef.showMenu = val
}
const setShowProfile = (val: boolean) => {
  stateRef.showProfile = val
}
const setShowShoppingCart = (val: boolean) => {
  stateRef.showShoppingCart = val
}
const setShowLoading = (val: boolean) => {
  if (val) {
    stateRef.showLoading++
  } else {
    stateRef.showLoading--
  }
}
const setShowLoginModal = (val: boolean) => {
  stateRef.showLoginModal = val
}
const setShowSearchBar = (val: boolean) => {
  console.log(val)
  stateRef.showSearchBar = val
}
const setIsLogin = (val: boolean) => {
  stateRef.isLogin = val
}
const setUserInfo = (val: any) => {
  stateRef.userInfo = val
}
const setNextUrl = (val: string) => {
  stateRef.nextUrl = val
}

const cleanUserInfo = () => {
  setUserInfo({})
  setIsLogin(false)
}
const fetchUserInfo = (): Promise<{ userInfo: any }> => {
  return new Promise((resolve, reject) => {
    request
      .post<BaseResponse<UserDetail>>(
        window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.getMemberDetail,
        {}
      )
      .then((res) => {
        setUserInfo(res.data.Data)
        setIsLogin(true)
        resolve({ userInfo: res.data.Data })
      })
      .catch((err) => {
        reject(err)
        cleanUserInfo()
      })
  })
}

export default {
  state: readonly(stateRef),
  setShowLogo,
  setShowMenu,
  setShowProfile,
  setShowShoppingCart,
  setShowLoginModal,
  setShowSearchBar,
  setIsLogin,
  setUserInfo,
  setShowLoading,
  setNextUrl,
  cleanUserInfo,
  fetchUserInfo
}
