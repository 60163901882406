
import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
  provide,
  watch
} from 'vue'
import { useRoute } from 'vue-router'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import Menu from '@/components/Menu.vue'
import Divider from './common/Divider.vue'
import Input from './common/Input.vue'
import InputWithButton from './common/InputWithButton.vue'
import UnderLine from './common/UnderLine.vue'
import SearchInput from './common/SearchInput.vue'
import SquareItemFullImage from './SquareItemFullImage.vue'
import ProductItem from './ProductItem.vue'
import store from '@/composition/store'
import { clearCookies, getToken } from '@/utils/auth'
import router from '@/router'
import cartStore from '@/composition/cartStore'
import Btn from '@/components/common/Btn.vue'
import productStore from '@/composition/productStore'
import FavoriteButton from '@/components/common/FavoriteButton.vue'
import { useToast, POSITION } from 'vue-toastification'
import map from 'lodash/map'
import ModalConfirm from '@/components/modal/ConfirmModal.vue'
import { ConfirmModal } from '@/types/ConfirmModal'
import { unescapeHTML } from '@/utils/utils'
import { OrderShoppingCartCmdy } from '@/types/ResponseType'
import cloneDeep from 'lodash/cloneDeep'
import { setShoppingCartInLocal } from '@/utils/localStorageHandler'
import { onClickOutside } from '@vueuse/core'
import Collapse from '@/components/common/Collapse.vue'
export default defineComponent({
  name: 'g-header',
  props: {},
  components: {
    Menu,
    Divider,
    UnderLine,
    SquareItemFullImage,
    FavoriteButton,
    ModalConfirm,
    InputWithButton,
    SearchInput,
    ProductItem,
    Collapse,
    // Input,
    Btn
  },
  setup () {
    provide('store', store)
    provide('cartStore', cartStore)
    provide('productStore', productStore)
    const toast = useToast()
    const confirmModal = ref()
    const header = ref(null)
    const route = useRoute()
    const menuList = ref()
    const subMenuList = ref([])
    const isShowSearchBar = ref(false)
    let timeout = null as any
    const lastScroll = ref(0)
    const mouseLeave = () => {
      // timeout = window.setTimeout(() => {
      //   store.setShowMenu(false)
      // }, 50)
      store.setShowMenu(true)
    }

    const mouseenter = (ChildMenus: any) => {
      window.clearTimeout(timeout)
      subMenuList.value = ChildMenus
      store.setShowMenu(true)
    }
    const mouseLeaveForShoppingCart = () => {
      timeout = window.setTimeout(() => {
        store.setShowShoppingCart(false)
      }, 50)
    }

    const mouseenterForShoppingCart = (ChildMenus: any) => {
      window.clearTimeout(timeout)
      subMenuList.value = ChildMenus
      store.setShowShoppingCart(true)
    }

    const scrollFunc = () => {
      store.setShowSearchBar(false)
      if (route.name !== 'home') {
        const st =
          window.scrollY ||
          window.pageYOffset ||
          (document.getElementById('app') as HTMLBodyElement).scrollTop
        if (st < lastScroll.value) {
          if (header.value) {
            (header.value as HTMLElement).style.top = '0px'
          }
        } else {
          if (header.value) {
            (header.value as HTMLElement).style.top = '-50px'
          }
        }

        lastScroll.value = st
      }

      if (route.name === 'home') {
        if (header.value) {
          (header.value as HTMLElement).style.top = '0px'
        }
      }
    }
    const getMenuTree = () => {
      const data = {
        Data: {}
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.getMenuTree,
          data
        )
        .then((req) => {
          const resData = req.data.Data.MenuList
          menuList.value = resData
        })
        .catch((error) => {
          console.error(error)
        })
    }
    const logout = () => {
      request
        .post(window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.logout)
        .then(() => {
          store.cleanUserInfo()
          clearCookies()
          store.setIsLogin(false)
          store.setShowProfile(false)
          toast.success('已登出', {
            position: POSITION.TOP_CENTER
          })
          // alert('已登出')
          router.push({
            name: 'home'
          })
          cartStore.fetchShoppingCartList()
          store.cleanUserInfo()
        })
        .catch((error) => {
          console.error(error)
        })
      if (window.FB.getAccessToken() !== null) {
        window.FB.api('/me/permissions', 'delete', null, () =>
          window.FB.logout()
        )
      }
    }
    const goToPage = (nextPage: string) => {
      (document.getElementById('app') as HTMLBodyElement).scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      store.setShowProfile(false)
      store.setShowShoppingCart(false)
      store.setShowSearchBar(false)
      if (nextPage === 'QA') {
        router.push({
          name: 'faq'
        })
      } else if (nextPage === 'search') {
        router.push({
          name: nextPage,
          params: {
            searchName: searchName.value
          }
        })
      } else {
        router.push({
          name: nextPage,
          params: {
            dateNow: Date.now()
          }
        })
      }
    }
    const showLogin = () => {
      store.setShowLoginModal(true)
    }
    // const addToCollect = (item: any) => {
    //   if (!item.IsInCmdyCollect) {
    //     productStore.addCmdyCollect(item.CmdyId).then(() => {
    //       cartStore.fetchShoppingCartList()
    //     })
    //   }

    //   // const _confirmModal = confirmModal.value as any
    //   // _confirmModal.createConfirm('確定移至我的最愛，下次再訂購？', '').then(
    //   //   productStore.addCmdyCollect(item.CmdyId).then(() => {
    //   //     cartStore.fetchShoppingCartList()
    //   //   })
    //   // )
    // }
    const subtractClick = (
      shoppingCartItem: OrderShoppingCartCmdy,
      index: any,
      type = 'cmdy',
      subIndex: number
    ) => {
      if (type === 'cmdy' && shoppingCartItem.CmdyQty === 1) {
        return
      }
      if (
        type === 'addOn' &&
        shoppingCartItem.AddOnCmdyList[subIndex].CmdyQty === 1
      ) {
        return
      }
      // const _shoppingCartItem = cloneDeep(shoppingCartItem)

      // _shoppingCartItem.CmdyQty = _shoppingCartItem.CmdyQty - 1

      // itemUpdate(_shoppingCartItem, index)

      if (type !== 'cmdy') {
        if (type === 'addOn') {
          shoppingCartItem.AddOnCmdyList[subIndex].CmdyQty -= 1
        }
      } else {
        shoppingCartItem.CmdyQty = shoppingCartItem.CmdyQty - 1
      }
      itemUpdate(shoppingCartItem, index, type, subIndex)
    }
    const addClick = (
      shoppingCartItem: OrderShoppingCartCmdy,
      index: any,
      type = 'cmdy',
      subIndex: number
    ) => {
      if (shoppingCartItem.CmdyQty >= 100) {
        shoppingCartItem.CmdyQty = 100
        itemUpdate(shoppingCartItem, index, 'cmdy', 0)
        return toast.warning('已達訂購上限', {
          position: POSITION.TOP_CENTER
        })
      }
      // const _shoppingCartItem = cloneDeep(shoppingCartItem)
      // _shoppingCartItem.CmdyQty = _shoppingCartItem.CmdyQty + 1

      // itemUpdate(_shoppingCartItem, index)

      if (type !== 'cmdy') {
        if (type === 'addOn') {
          shoppingCartItem.AddOnCmdyList[subIndex].CmdyQty += 1
        }
      } else {
        shoppingCartItem.CmdyQty = shoppingCartItem.CmdyQty + 1
      }
      itemUpdate(shoppingCartItem, index, type, subIndex)
    }
    const itemUpdate = (
      itemInfo: any,
      index: any,
      type = 'cmdy',
      subIndex: number
    ) => {
      if (itemInfo.CmdyQty === '' || parseInt(itemInfo.CmdyQty) === 0) {
        itemInfo.CmdyQty = 1
        itemUpdate(itemInfo, index, 'cmdy', 0)
      } else {
        console.log(itemInfo.CmdyQty)
        itemInfo.CmdyQty = parseInt(itemInfo.CmdyQty)
        if (store.state.isLogin) {
          if (type === 'addOn') {
            cartStore
              .updateShoppingCart(itemInfo.AddOnCmdyList[subIndex])
              .then((res) => {
                cartStore.fetchShoppingCartList()
              })
          } else {
            cartStore.updateShoppingCart(itemInfo).then((res) => {
              cartStore.fetchShoppingCartList()
            })
          }
        } else {
          setShoppingCartInLocal(
            itemInfo as OrderShoppingCartCmdy,
            'update',
            index
          )
          cartStore.fetchShoppingCartList()
        }
      }
    }
    const addToCollect = (cmdy: any) => {
      if (store.state.isLogin) {
        if (cmdy.IsInCmdyCollect) {
          const collectIdArray: string[] = []
          collectIdArray.push(cmdy.CmdyCollectId)
          productStore.delCmdyCollect(collectIdArray).then(() => {
            // getCmdyDetail(cmdy.CmdyId)
            cartStore.fetchShoppingCartList()
          })
          // try {
          //   const _confirmModal = confirmModal.value as ConfirmModal
          //   _confirmModal
          //     .createConfirm('確定取消收藏此商品？', '', false)
          //     .then(() => {

          //     })
          // } catch (err) {
          //   console.log(err)
          // }
        } else {
          productStore.addCmdyCollect(cmdy.CmdyId).then(() => {
            // getCmdyDetail(cmdy.CmdyId)
            cartStore.fetchShoppingCartList()
          })
        }
      } else {
        store.setShowLoginModal(true)
      }
    }
    const goProductDetail = (_cmdy: any) => {
      // if (route.name !== 'ipproductList') {
      //   router.push({
      //     name: 'ipproductList',
      //     params: {
      //       shopHallCode: 'BT21',
      //       cmdyId: _cmdyId
      //     }
      //   })
      // } else {
      //   productStore.setShowRectangleItem(false)
      //   if (route.params.cmdyId === _cmdyId) {
      //     router.push({
      //       params: {
      //         shopHallCode: route.params.shopHallCode,
      //         cmdyId: _cmdyId,
      //         dateNow: Date.now()
      //       }
      //     })
      //   } else {
      //     router.push({
      //       params: {
      //         shopHallCode: route.params.shopHallCode,
      //         cmdyId: _cmdyId
      //       }
      //     })
      //   }
      // }
      (document.getElementById('app') as HTMLBodyElement).scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      store.setShowSearchBar(false)
      if (
        _cmdy.FrontendParameter === undefined ||
        _cmdy.FrontendParameter === ''
      ) {
        router.push({
          name: 'productDetail',
          query: {
            cmdyId: _cmdy.CmdyId
          }
        })
      } else {
        window.location.href = unescapeHTML(_cmdy.FrontendParameter)
      }
    }
    const searchBarRef = ref(null)
    const clickSearch = () => {
      if (route.name !== 'search') {
        window.setTimeout(() => {
          store.setShowSearchBar(true)
        }, 50)
      }
    }
    onClickOutside(searchBarRef, (event) => {
      store.setShowSearchBar(false)
    })
    const searchCmdyList = ref()
    const CmdyListLength = ref(0)
    const fetchSearchCmdyList = (fetchSearchCmdyRequestData: any) => {
      store.setShowLoading(true)
      const data = {
        Data: fetchSearchCmdyRequestData
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.searchCmdy,
          data
        )
        .then((res) => {
          const resData = res.data.Data
          CmdyListLength.value = resData.TotalSize
          if (resData.CmdyList.length !== 0) {
            searchCmdyList.value = resData.CmdyList
          } else {
            searchCmdyList.value = []
          }

          store.setShowLoading(false)
        })
        .catch((error) => {
          store.setShowLoading(false)
          console.error(error)
        })
    }
    const searchName = ref('')
    const getInput = (val: string) => {
      searchName.value = val
      fetchSearchCmdyList({
        pageNum: 1,
        pageSize: 5,
        Name: searchName.value,
        OrderType: productStore.state.selectedOrderType
      })
    }
    const orderShoppingCartCmdyListRef = ref(
      cartStore.state.orderShoppingCartCmdyList as OrderShoppingCartCmdy[]
    )
    watch(
      () => cartStore.state.orderShoppingCartCmdyList,
      () => {
        orderShoppingCartCmdyListRef.value =
          cartStore.state.orderShoppingCartCmdyList
      }
    )
    onMounted(() => {
      (document.getElementById('app') as HTMLBodyElement).addEventListener(
        'scroll',
        scrollFunc
      )
      // window.addEventListener('scroll', scrollFunc)
      getMenuTree()
    })

    onUnmounted(() => {
      (document.getElementById('app') as HTMLBodyElement).removeEventListener(
        'scroll',
        scrollFunc
      )
      // window.removeEventListener('scroll', scrollFunc)
    })
    return {
      mouseLeave,
      mouseenter,
      mouseLeaveForShoppingCart,
      mouseenterForShoppingCart,
      getMenuTree,
      store,
      route,
      cartStore,
      confirmModal,
      productStore,
      header,
      menuList,
      subMenuList,
      isShowSearchBar,
      logout,
      goToPage,
      addToCollect,
      unescapeHTML,
      goProductDetail,
      subtractClick,
      addClick,
      itemUpdate,
      searchBarRef,
      clickSearch,
      searchName,
      searchCmdyList,
      CmdyListLength,
      getInput,
      orderShoppingCartCmdyListRef,
      showLogin
    }
  }
})
