
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'g-swiper-nav-btn',
  emits: ['prevClick', 'nextClick'],
  setup () {
    const leftIconName = ref('left')
    const rightIconName = ref('right')

    return {
      leftIconName,
      rightIconName
    }
  }
})
