
import { defineComponent, onMounted, provide, ref, watch } from 'vue'
import ModalBase from '@/components/common/ModalBase.vue'
import Tabs from '@/components/common/Tabs.vue'
import Input from '@/components/common/Input.vue'
import CheckBox from '@/components/common/CheckBox.vue'
import Btn from '@/components/common/Btn.vue'
import AddressInput from '@/components/common/AddressInput.vue'
import DatePicker from 'vue-datepicker-next'
import Toggle from '@/components/common/Toggle.vue'
import Select from '@/components/common/Select.vue'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import find from 'lodash/find'
import {
  BaseResponse,
  DeliveryList,
  UserAddress,
  DeliveryShop,
  DeliveryShopList,
  UserShop
} from '@/types/ResponseType'
import cartStore from '@/composition/cartStore'
import cloneDeep from 'lodash/cloneDeep'
import { POSITION, useToast } from 'vue-toastification'
import ModalConfirm from '@/components/modal/ConfirmModal.vue'
import { ConfirmModal } from '@/types/ConfirmModal'
import store from '@/composition/store'
import { isPhoneNumber } from '@/utils/utils'
// import { ShoppingCartInfo } from '@/types/cartType'
// import { Form } from 'vee-validate'

enum DeliveryModalContentType {
  HOME_LIST = 'Home',
  SHOP_LIST = 'SHOP',
  HOME_ADD = '3',
  SHOP_ADD = '4'
}

export default defineComponent({
  emits: ['cancel', 'ok'],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    deliveryType: {
      type: String,
      default: 'Home'
    },
    buyerName: {
      type: String,
      default: ''
    },
    buyerEmail: {
      type: String,
      default: ''
    },
    buyerCity: {
      type: String,
      default: ''
    },
    buyerDist: {
      type: String,
      default: ''
    },
    buyerZip: {
      type: String,
      default: ''
    },
    buyerAddr: {
      type: String,
      default: ''
    }
  },
  components: {
    ModalBase,
    Input,
    Btn,
    AddressInput,
    ModalConfirm,
    Toggle,
    Select
  },
  setup (props, context) {
    const { emit } = context
    provide('cartStore', cartStore)
    provide('store', store)
    const toast = useToast()
    const confirmModal = ref()
    const isSameMemberInfo = ref(true)
    const contentType = ref(DeliveryModalContentType.HOME_LIST)
    const deliveryAddrId = ref('')
    const deliveryShopId = ref('')
    const receiverName = ref(props.buyerName || ('' as any))
    const receiverTel = ref('')
    const receiverMobile = ref(store.state.userInfo.Mobile || '')
    const deliveryZip = ref(props.buyerZip || '')
    const deliveryCountry = ref('')
    const deliveryProvince = ref('')
    const deliveryCity = ref(props.buyerCity || '')
    const deliveryDist = ref(props.buyerDist || '')
    const deliveryAddr = ref(props.buyerAddr || '')
    const shopCode = ref('')
    const isDefault = ref(true)

    const selectDeliveryCity = ref('')
    const selectDeliveryShop = ref('')
    // const shoppingCartInfo = ref({} as UserAddress)
    const setEditDeliveryDetail = (deliveryAddrItem: any) => {
      deliveryAddrId.value = deliveryAddrItem.DeliveryAddrId || ''
      receiverName.value = deliveryAddrItem.ReceiverName || ''
      receiverTel.value = deliveryAddrItem.ReceiverTel || ''
      receiverMobile.value = deliveryAddrItem.ReceiverMobile || ''
      deliveryZip.value = deliveryAddrItem.DeliveryZip || ''
      deliveryCountry.value = deliveryAddrItem.DeliveryCountry || ''
      deliveryProvince.value = deliveryAddrItem.DeliveryProvince || ''
      deliveryCity.value = deliveryAddrItem.DeliveryCity || ''
      deliveryDist.value = deliveryAddrItem.DeliveryDist || ''
      deliveryAddr.value = deliveryAddrItem.DeliveryAddr || ''
      isDefault.value = Boolean(deliveryAddrItem.IsDefault) || false
      contentType.value = DeliveryModalContentType.HOME_ADD
    }

    // 選擇門市
    const setEditShopDetail = (deliveryShopItem: any) => {
      deliveryShopId.value = deliveryShopItem.DeliveryShopId || ''
      receiverName.value = deliveryShopItem.ReceiverName || ''
      receiverMobile.value = deliveryShopItem.ReceiverMobile || ''
      selectDeliveryCity.value = deliveryShopItem.ShopInfo.RegionName || ''
      selectDeliveryShop.value = deliveryShopItem.ShopInfo.ShopCode || ''
      const selectCity = find(cartStore.state.deliveryShopList, {
        RegionName: selectDeliveryCity.value
      }) as DeliveryShop
      console.log('selectCity ', selectCity)
      if (selectCity) {
        cartStore.setDeliveryShopFilterByRegionList(selectCity.ShopList)
      }
      isDefault.value = Boolean(deliveryShopItem.IsDefault) || false
      contentType.value = DeliveryModalContentType.SHOP_ADD
    }
    // 選擇寄件地址
    const editAddrDetail = () => {
      if (!receiverName.value || receiverName.value === '') {
        return toast.warning('請輸入收件人姓名', {
          position: POSITION.TOP_CENTER
        })
      }
      if (!receiverMobile.value || receiverMobile.value === '') {
        return toast.warning('請輸入收件人手機', {
          position: POSITION.TOP_CENTER
        })
      }
      if (!isPhoneNumber(receiverMobile.value)) {
        return toast.warning('手機格式錯誤', {
          position: POSITION.TOP_CENTER
        })
      }
      if (!deliveryCity.value || deliveryCity.value === '') {
        return toast.warning('請輸入縣市', { position: POSITION.TOP_CENTER })
      }
      if (!deliveryAddr.value || deliveryAddr.value === '') {
        return toast.warning('請輸入地址', { position: POSITION.TOP_CENTER })
      }

      const data = {
        Data: {
          DeliveryAddrId: deliveryAddrId.value,
          Name: receiverName.value,
          Mobile: receiverMobile.value,
          Zip: deliveryZip.value,
          Country: deliveryCountry.value,
          Province: deliveryProvince.value,
          City: deliveryCity.value,
          Dist: deliveryDist.value,
          Addr: deliveryAddr.value,
          IsDefault: isDefault.value ? 1 : 0
        }
      }
      if (deliveryAddrId.value === '') {
        request
          .post(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API +
              requestUrl.updateDeliveryAddr,
            data
          )
          .then((req) => {
            const resData = req.data
            toast.success(resData.Message, {
              position: POSITION.TOP_CENTER
            })
            // alert(resData.Message)
            getAddrDetail()
            contentType.value = DeliveryModalContentType.HOME_LIST
          })
          .catch((error) => {
            toast.error(error.response.data.Message, {
              position: POSITION.TOP_CENTER
            })
            // alert(error.response.data.Message)
            console.error(error.response.data.Message)
          })
      } else {
        request
          .put(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API +
              requestUrl.updateDeliveryAddr,
            data
          )
          .then((res) => {
            getAddrDetail()
            contentType.value = DeliveryModalContentType.HOME_LIST
            toast.success(res.data.Message, {
              position: POSITION.TOP_CENTER
            })
            // alert(res.data.Message)
          })
          .catch((error) => {
            toast.error(error.response.data.Message, {
              position: POSITION.TOP_CENTER
            })
            // alert(error.response.data.Message)
            console.error(error.response.data.Message)
          })
      }
    }

    const editShopDetail = () => {
      if (!receiverName.value || receiverName.value === '') {
        return toast.warning('請輸入收件人姓名', {
          position: POSITION.TOP_CENTER
        })
      }
      if (!receiverMobile.value || receiverMobile.value === '') {
        return toast.warning('請輸入收件人手機', {
          position: POSITION.TOP_CENTER
        })
      }
      if (!isPhoneNumber(receiverMobile.value)) {
        return toast.warning('手機格式錯誤', {
          position: POSITION.TOP_CENTER
        })
      }
      if (!selectDeliveryCity.value || selectDeliveryCity.value === '') {
        return toast.warning('請選擇區域', { position: POSITION.TOP_CENTER })
      }
      if (!selectDeliveryShop.value || selectDeliveryShop.value === '') {
        return toast.warning('請選擇門店', { position: POSITION.TOP_CENTER })
      }

      const data = {
        Data: {
          DeliveryShopId: deliveryShopId.value,
          Name: receiverName.value,
          Mobile: receiverMobile.value,
          DeliveryShopCode: selectDeliveryShop.value,
          IsDefault: isDefault.value ? 1 : 0
        }
      }
      if (deliveryShopId.value === '') {
        request
          .post(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API +
              requestUrl.updateDeliveryShops,
            data
          )
          .then((req) => {
            const resData = req.data
            toast.success(resData.Message, {
              position: POSITION.TOP_CENTER
            })
            // alert(resData.Message)
            getShopList()
            contentType.value = DeliveryModalContentType.SHOP_LIST
          })
          .catch((error) => {
            toast.error(error.response.data.Message, {
              position: POSITION.TOP_CENTER
            })
            // alert(error.response.data.Message)
            console.error(error.response.data.Message)
          })
      } else {
        request
          .put(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API +
              requestUrl.updateDeliveryShops,
            data
          )
          .then((res) => {
            getShopList()
            contentType.value = DeliveryModalContentType.SHOP_LIST
            toast.success(res.data.Message, {
              position: POSITION.TOP_CENTER
            })
            // alert(res.data.Message)
          })
          .catch((error) => {
            toast.error(error.response.data.Message, {
              position: POSITION.TOP_CENTER
            })
            // alert(error.response.data.Message)
            console.error(error.response.data.Message)
          })
      }
    }
    const setSelectDelivery = (deliveryAddrItem: any) => {
      const _shoppingCartDetailInfo = cloneDeep(
        cartStore.state.shoppingCartDetailInfo
      )
      _shoppingCartDetailInfo.Delivery = deliveryAddrItem
      cartStore.setShoppingCartDetailInfo(_shoppingCartDetailInfo)
      resetAll()
      emit('ok')
    }

    const setSelectShopDelivery = (deliveryShop: any) => {
      const _shoppingCartDetailInfo = cloneDeep(
        cartStore.state.shoppingCartDetailInfo
      )
      _shoppingCartDetailInfo.Delivery = deliveryShop
      cartStore.setShoppingCartDetailInfo(_shoppingCartDetailInfo)
      resetAll()
      emit('ok')
    }

    const deliveryAddrList = ref([] as UserAddress[])
    const deliverySelect = ref({} as UserAddress)
    const getAddrDetail = () => {
      request
        .post<BaseResponse<DeliveryList>>(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
            requestUrl.getDeliveryAddrList,
          {
            Data: {}
          }
        )
        .then((req) => {
          const resData = req.data
          deliveryAddrList.value = resData.Data.DeliveryAddrList

          deliverySelect.value =
            find(deliveryAddrList.value, (item) => {
              return item.IsDefault === 1
            }) || deliveryAddrList.value[0]
          if (cartStore.state.shoppingCartDetailInfo.Delivery) {
            deliverySelect.value =
              find(deliveryAddrList.value, (item) => {
                return (
                  item.DeliveryAddrId ===
                  cartStore.state.shoppingCartDetailInfo.Delivery.DeliveryAddrId
                )
              }) || deliverySelect.value
          }
        })
        .catch((error) => {
          console.error(error)
          // alert(error)
        })
    }

    const deliveryShopList = ref([] as UserShop[])
    const deliveryShopSelect = ref({} as UserShop)
    const getShopList = () => {
      request
        .post<BaseResponse<DeliveryShopList>>(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
            requestUrl.getDeliveryShopList,
          {
            Data: {}
          },
          {
            headers: {
              'api-version': '1.0'
            }
          }
        )
        .then((req) => {
          const resData = req.data
          deliveryShopList.value = resData.Data.DeliveryShopList

          deliveryShopSelect.value =
            find(deliveryShopList.value, (item) => {
              return item.IsDefault === 1
            }) || deliveryShopList.value[0]

          if (cartStore.state.shoppingCartDetailInfo.Delivery) {
            deliveryShopSelect.value =
              find(deliveryShopList.value, (item) => {
                return (
                  item.DeliveryShopId ===
                  cartStore.state.shoppingCartDetailInfo.Delivery.DeliveryShopId
                )
              }) || deliveryShopSelect.value
          }
        })
        .catch((error) => {
          console.error(error)
          // alert(error)
        })
    }
    watch(
      () => props.deliveryType,
      (newVal, oldVal) => {
        console.log(props.deliveryType)
        if (newVal && newVal !== 'Home' && newVal !== '') {
          contentType.value = DeliveryModalContentType.SHOP_LIST
        } else {
          contentType.value = DeliveryModalContentType.HOME_LIST
        }
      }
    )
    watch(
      () => isSameMemberInfo.value,
      (newVal) => {
        if (newVal) {
          deliveryCity.value = props.buyerCity
          deliveryDist.value = props.buyerDist
          deliveryAddr.value = props.buyerAddr
          deliveryZip.value = props.buyerZip
          receiverMobile.value = store.state.userInfo.Mobile
          receiverName.value = props.buyerName
        } else {
          deliveryZip.value = ''
          deliveryDist.value = ''
          deliveryAddr.value = ''
          deliveryCity.value = ''
          receiverMobile.value = ''
          receiverName.value = ''
        }
      }
    )
    const remove = (item: any) => {
      try {
        if (item.DeliveryAddrId) {
          const _confirmModal = confirmModal.value as ConfirmModal
          _confirmModal
            .createConfirm('是否要移除此地址', '', false)
            .then(async () => {
              const res = await request.post(
                window._env_.VUE_APP_STOREBOOK_SERVICE_API +
                  requestUrl.deleteAddress,
                {
                  Data: {
                    DeliveryAddrIds: item.DeliveryAddrId
                  }
                }
              )
              getAddrDetail()
            })
        } else {
          const _confirmModal = confirmModal.value as ConfirmModal
          _confirmModal
            .createConfirm('是否要移除此門店', '', false)
            .then(async () => {
              const res = await request.post(
                window._env_.VUE_APP_STOREBOOK_SERVICE_API +
                  requestUrl.deleteShop,
                {
                  Data: {
                    DeliveryShopIds: item.DeliveryShopId
                  }
                }
              )
              getShopList()
            })
        }
      } catch (err) {
        console.log(err)
      }
    }
    const resetAll = () => {
      console.log('all')
      if (props.deliveryType !== 'Home') {
        contentType.value = DeliveryModalContentType.SHOP_LIST
      } else {
        contentType.value = DeliveryModalContentType.HOME_LIST
      }
      deliveryAddrId.value = ''
      deliveryShopId.value = ''
      selectDeliveryCity.value = ''
      selectDeliveryShop.value = ''
      receiverName.value = ''
      receiverTel.value = ''
      receiverMobile.value = ''
      deliveryZip.value = ''
      deliveryCountry.value = ''
      deliveryProvince.value = ''
      deliveryCity.value = ''
      deliveryDist.value = ''
      deliveryAddr.value = ''
      isDefault.value = true
    }
    const resetInfo = () => {
      console.log('info')
      deliveryAddrId.value = ''
      deliveryShopId.value = ''
      selectDeliveryCity.value = ''
      selectDeliveryShop.value = ''
      receiverName.value = ''
      receiverTel.value = ''
      receiverMobile.value = ''
      deliveryZip.value = ''
      deliveryCountry.value = ''
      deliveryProvince.value = ''
      deliveryCity.value = ''
      deliveryDist.value = ''
      deliveryAddr.value = ''
    }
    const goToAddAddress = () => {
      contentType.value = DeliveryModalContentType.HOME_ADD
      if (isSameMemberInfo.value) {
        deliveryCity.value = props.buyerCity
        deliveryDist.value = props.buyerDist
        deliveryAddr.value = props.buyerAddr
        deliveryZip.value = props.buyerZip
        receiverMobile.value = store.state.userInfo.Mobile
        receiverName.value = props.buyerName
      } else {
        deliveryZip.value = ''
        deliveryDist.value = ''
        deliveryAddr.value = ''
        deliveryCity.value = ''
        receiverMobile.value = ''
        receiverName.value = ''
      }
    }

    const goToAddShop = () => {
      contentType.value = DeliveryModalContentType.SHOP_ADD
      if (isSameMemberInfo.value) {
        receiverMobile.value = store.state.userInfo.Mobile
        receiverName.value = props.buyerName
      } else {
        receiverMobile.value = ''
        receiverName.value = ''
        selectDeliveryCity.value = ''
        selectDeliveryShop.value = ''
      }
    }

    const setCity = () => {
      const selectCity = find(cartStore.state.deliveryShopList, {
        RegionName: selectDeliveryCity.value
      }) as DeliveryShop
      console.log('selectCity ', selectCity)
      if (selectCity) {
        selectDeliveryShop.value = selectCity.ShopList[0].ShopCode
        cartStore.setDeliveryShopFilterByRegionList(selectCity.ShopList)
      }
    }

    const setDelivery = () => {
      const _shoppingCartDetailInfo = cloneDeep(
        cartStore.state.shoppingCartDetailInfo
      )

      const selectShop = find(cartStore.state.deliveryShopFilterByRegionList, {
        ShopCode: selectDeliveryShop.value
      })

      console.log(selectShop)

      _shoppingCartDetailInfo.Delivery = {
        DeliveryDestCode: selectShop.ShopCode,
        DeliveryDestName: selectShop.ShopName
      }
    }

    onMounted(() => {
      if (
        props.deliveryType &&
        props.deliveryType !== 'Home' &&
        props.deliveryType !== ''
      ) {
        contentType.value = DeliveryModalContentType.SHOP_LIST
      } else {
        contentType.value = DeliveryModalContentType.HOME_LIST
      }
      getAddrDetail()
      getShopList()
    })
    return {
      store,
      isSameMemberInfo,
      confirmModal,
      deliveryAddrId,
      receiverName,
      receiverTel,
      receiverMobile,
      deliveryZip,
      deliveryCountry,
      deliveryProvince,
      deliveryCity,
      deliveryDist,
      deliveryAddr,
      isDefault,
      cartStore,
      editAddrDetail,
      DeliveryModalContentType,
      deliveryAddrList,
      deliverySelect,
      contentType,
      shopCode,
      selectDeliveryCity,
      selectDeliveryShop,
      deliveryShopList,
      deliveryShopSelect,
      deliveryShopId,
      setEditShopDetail,
      editShopDetail,
      getAddrDetail,
      setSelectDelivery,
      setSelectShopDelivery,
      resetAll,
      resetInfo,
      setEditDeliveryDetail,
      remove,
      goToAddAddress,
      goToAddShop,
      setCity,
      setDelivery,
      find
    }
  }
})
