
import { defineComponent, provide } from 'vue'
import productStore from '@/composition/productStore'
import { Navigation, Autoplay, Mousewheel, FreeMode, Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/pagination'
export default defineComponent({
  emits: ['getCmdyClassList'],
  props: {
    sourceType: {
      type: String,
      default: 'product'
    }
    // productClassItemList: [],
    // selectProductClassItemId: null
  },
  components: { Swiper, SwiperSlide },
  setup (props, context) {
    const { emit } = context
    provide('productStore', productStore)
    const getCmdyList = (cmdyTagId: string) => {
      switch (props.sourceType) {
        case 'product':
          // productStore.fetchCmdyList({
          //   PageNum: 0,
          //   PageSize: 0,
          //   CmdyClassId: productStore.state.selectedCmdyClass.CmdyClassId,
          //   CmdyThemeId: '',
          //   CmdyTagId: cmdyTagId,
          //   OrderType: 'PriceDesc',
          //   IsIncludeHiddenCmdy: false
          // })
          emit('getCmdyClassList')
          break
        case 'collect':
          productStore.fetchCmdyCollectList(0, 0, cmdyTagId, '', 'PriceDesc')
          break
      }
    }
    return {
      getCmdyList,
      productStore,
      modules: [Autoplay, Navigation, Mousewheel, FreeMode, Scrollbar]
    }
  }
})
