
import {
  defineComponent,
  ref,
  onMounted,
  watch
} from 'vue'
export default defineComponent({
  name: 'g-tabs',
  emits: ['tabSelect', 'mobileTabSelect'],
  props: {
    customClass: {
      type: String,
      required: false,
      default: ''
    },
    data: {
      type: Array,
      required: false,
      default: () => []
    },
    selectedNum: {
      type: Number,
      default: 0
    }
  },
  setup (props, context) {
    const selected = ref(props.selectedNum)
    const indicator = ref(null)
    const mobilIndicator = ref(null)
    const tab = ref(null)
    const mobileTab = ref(null)
    const { emit } = context

    const tabSelect = (target: HTMLElement, tab: number) => {
      selected.value = tab;
      (indicator.value! as HTMLElement).style.left = target.offsetLeft + 'px';
      (indicator.value! as HTMLElement).style.width = target.offsetWidth + 'px'
      emit('tabSelect', tab)
    }
    const mobileTabSelect = (target: HTMLElement, mobileTab: number) => {
      console.log(target.offsetLeft)
      selected.value = mobileTab;
      (mobilIndicator.value! as HTMLElement).style.left =
        target.offsetLeft + 'px';
      (mobilIndicator.value! as HTMLElement).style.width =
        target.offsetWidth + 'px'

      emit('mobileTabSelect', mobileTab)
    }
    watch(
      () => tab.value,
      () => {
        if (tab.value) {
          tabSelect(tab.value[props.selectedNum], props.selectedNum)
        }
        if (mobileTab.value) {
          mobileTabSelect(
            mobileTab.value[props.selectedNum],
            props.selectedNum
          )
        }
      }
    )
    watch(
      () => props.selectedNum,
      () => {
        if (tab.value) {
          tabSelect(tab.value[props.selectedNum], props.selectedNum)
        }
        if (mobileTab.value) {
          mobileTabSelect(
            mobileTab.value[props.selectedNum],
            props.selectedNum
          )
        }
      }
    )

    onMounted(() => {
      // console.log(tab.value)
      // if (tab.value) {
      //   tabSelect(tab.value[props.], 0)
      // }
      // if (mobileTab.value) {
      //   mobileTabSelect(mobileTab.value[0], 0)
      // }
      // tabSelect(tab.value[props.selectedNum], props.selectedNum)
      // mobileTabSelect(mobileTab.value[props.selectedNum], props.selectedNum)
    })

    // onActivated(() => {
    //   if (tab.value) {
    //     tabSelect(tab.value[0], 0)
    //   }
    // })

    return {
      selected,
      indicator,
      mobilIndicator,
      tab,
      mobileTab,
      tabSelect,
      mobileTabSelect
    }
  }
})
