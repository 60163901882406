import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "g-container" }
const _hoisted_2 = { class: "ml-[30px] sm:ml-[0px] my-[20px] sm:my-[40px] font-bold text-2xl text-gary-line-color" }
const _hoisted_3 = { class: "g-container gap-[40px]" }
const _hoisted_4 = { class: "w-full flex flex-col sm:flex-row" }
const _hoisted_5 = { class: "relative sm:w-[230px] sm:p-[30px] sm:bg-black-200-color sm:rounded-[10px] sm:h-fit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")!
  const _component_CollapseMenu = _resolveComponent("CollapseMenu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, " 歡迎，" + _toDisplayString(_ctx.store.state.userInfo.Name || "你好"), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_CollapseMenu, {
            onMenuClick: _ctx.menuClick,
            onItemsClick: _ctx.itemsClick,
            modelValue: _ctx.collapseMenu,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.collapseMenu) = $event)),
            showList: _ctx.showList,
            "onUpdate:showList": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showList) = $event)),
            selectItem: _ctx.selectItem,
            "onUpdate:selectItem": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectItem) = $event)),
            items: _ctx.items,
            multiple: false
          }, {
            extend: _withCtx(() => [
              _createVNode(_component_Divider),
              _createElementVNode("div", {
                class: "cursor-pointer",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
              }, "登出")
            ]),
            _: 1
          }, 8, ["onMenuClick", "onItemsClick", "modelValue", "showList", "selectItem", "items"])
        ]),
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component }) => [
            (_openBlock(), _createBlock(_KeepAlive, { include: "g-order-list-view" }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ], 1024))
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}