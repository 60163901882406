
import { defineComponent, ref, onMounted, provide, watch } from 'vue'
import SquareItemList from '@/components/SquareItemList.vue'
import Title from '@/components/common/Title.vue'
import UnderLine from '@/components/common/UnderLine.vue'
import ProductClassItemList from '@/components/ProductClassItemList.vue'
import ProductClassIconList from '@/components/ProductClassIconList.vue'
import ProductItemList from '@/components/ProductItemList.vue'
import Banner from '@/components/common/Banner.vue'
import Breadcrumb from '@/components/common/Breadcrumb.vue'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import { useRouter, useRoute } from 'vue-router'
import productStore from '@/composition/productStore'
import { useToast, POSITION } from 'vue-toastification'
import findIndex from 'lodash/findIndex'
import isMobile from 'ismobilejs'
import store from '@/composition/store'
export default defineComponent({
  setup () {
    provide('productStore', productStore)
    provide('store', store)
    const router = useRouter()
    const route = useRoute()
    const toast = useToast()
    const RefProductItemList = ref()
    const RefProductItemListForMobile = ref()
    const cmdyClassItem = ref({})
    const cmdyThemeList = ref([] as any)
    const selectThemeList = ref([] as any)
    const getCmdyClassList = () => {
      productStore
        .fetchCmdyClassList(route.query.cmdyClassId?.toString() || '')
        .then((res) => {
          cmdyClassItem.value = res.cmdyClass
          productStore
            .fetchCmdyClassListV2({
              ShopHallCode: '',
              ShopHallClassId: '',
              CmdyClassId: route.query.cmdyClassId?.toString() || '',
              ParentCmdyClassId: '',
              DataType: 'Shop',
              MaxLevel: 3,
              IsOnlyLeafNode: true
            })
            .then(() => {
              const cmdyClassId = ref(
                productStore.state.selectedCmdyClass.CmdyClassId
              )
              const cmdyTagId = ref(
                productStore.state.selectedCmdyTag.CmdyTagId
              )
              if (
                route.query.cmdyTagId !== undefined &&
                route.query.cmdyTagId !== ''
              ) {
                cmdyTagId.value = route.query.cmdyTagId
              }
              if (
                route.query.subCmdyClassId !== undefined &&
                route.query.subCmdyClassId !== '' &&
                productStore.state.selectedCmdyClass.CmdyClassId !== ''
              ) {
                cmdyClassId.value = route.query.subCmdyClassId
              }
              productStore
                .fetchCmdyList({
                  PageNum: 0,
                  PageSize: 0,
                  CmdyClassId: cmdyClassId.value,
                  CmdyThemeId: [],
                  CmdyTagId: cmdyTagId.value,
                  OrderType: productStore.state.selectedOrderType,
                  IsIncludeHiddenCmdy: false
                })
                .then(() => {
                  productStore.state.cmdyTagList.forEach((item: any) => {
                    if (item.CmdyTagId === route.query.cmdyTagId) {
                      productStore.setSelectedCmdyTag(item)
                    }
                  })
                  if (
                    route.query.cmdyId !== undefined &&
                    route.query.cmdyId !== ''
                  ) {
                    const _index = findIndex(
                      productStore.state.cmdyList,
                      (cmdy: any) => cmdy.CmdyId === route.query.cmdyId
                    )
                    if (isMobile().phone) {
                      RefProductItemListForMobile.value.getSelectProduct(
                        productStore.state.cmdyList[_index],
                        _index
                      )
                    } else {
                      RefProductItemList.value.getSelectProduct(
                        productStore.state.cmdyList[_index],
                        _index
                      )
                    }
                  }
                })
                .catch(() => {
                  toast.warning('查不到此商品分類，已幫你轉導到首頁', {
                    position: POSITION.TOP_CENTER
                  })
                  router.push({ name: 'home' })
                })
              productStore.setSelectedCmdy({})
              // productStore.setSelectedCmdyTag({})
            })
            .catch(() => {
              toast.warning('查不到此商品分類，已幫你轉導到首頁', {
                position: POSITION.TOP_CENTER
              })
              router.push({ name: 'home' })
            })
        })
        .catch(() => {
          toast.warning('查不到此商品分類，已幫你轉導到首頁', {
            position: POSITION.TOP_CENTER
          })
          router.push({ name: 'home' })
        })
      // productStore.setSelectedCmdyClass({})
    }
    const closeRecItem = () => {
      if (
        productStore.state.showRectangleItem === true &&
        store.state.showLoading === 0 &&
        store.state.showLoginModal === false
      ) {
        productStore.setShowRectangleItem(false)
      }
    }
    const clickProductClassIconList = () => {
      productStore.setSelectedCmdyTag({})
      selectThemeList.value = []
      route.query.cmdyId = ''
      route.params.cmdyThemeId = ''
      // route.params.cmdyClassId = productStore.state.selectedCmdyClass.CmdyClassId
      // route.query.subCmdyClassId =
      //   productStore.state.selectedCmdyClass.CmdyClassId
      productStore.setShowRectangleItem(false)
      // getCmdyClassList()
      router.replace({
        query: {
          // shopHallCode: route.query.shopHallCode,
          cmdyClassId: route.query.cmdyClassId,
          subCmdyClassId: productStore.state.selectedCmdyClass.CmdyClassId,
          cmdyTagId: '',
          cmdyId: ''
          // dateNow: Date.now()
        }
      })
    }
    const clickProductClassItem = () => {
      route.query.cmdyId = ''
      route.params.cmdyThemeId = ''
      route.query.cmdyTagId = productStore.state.selectedCmdyTag.CmdyTagId
      getCmdyClassList()
      router.replace({
        query: {
          // shopHallCode: route.query.shopHallCode,
          cmdyClassId: route.query.cmdyClassId,
          subCmdyClassId: productStore.state.selectedCmdyClass.CmdyClassId,
          cmdyTagId: productStore.state.selectedCmdyTag.CmdyTagId,
          cmdyId: '',
          dateNow: Date.now()
        }
      })
    }
    const clickProductItemList = () => {
      route.query.cmdyId = ''
      route.params.cmdyThemeId = ''
      // router.push({ name: 'home' })
      getCmdyClassList()
    }
    const clickProductItem = (cmdyId: string) => {
      // route.query.cmdyId = productStore.state.selectedCmdy.CmdyId
      router.replace({
        query: {
          // shopHallCode: route.query.shopHallCode,
          cmdyClassId: route.query.cmdyClassId,
          subCmdyClassId: productStore.state.selectedCmdyClass.CmdyClassId,
          cmdyTagId: productStore.state.selectedCmdyTag.CmdyTagId || '',
          cmdyId: cmdyId || productStore.state.selectedCmdy.CmdyId
          // dateNow: Date.now()
        }
      })
    }
    watch(
      () => route.query.cmdyClassId,
      (newVal) => {
        if (newVal !== undefined && route.name === 'productList') {
          productStore.setSelectedCmdyClass({})
          getCmdyClassList()
        }
      }
    )
    watch(
      () => route.query.subCmdyClassId,
      (newVal) => {
        if (newVal !== undefined && route.name === 'productList') {
          getCmdyClassList()
        }
      }
    )
    watch(
      () => productStore.state.selectedOrderType,
      () => {
        const cmdyClassId = ref(
          productStore.state.selectedCmdyClass.CmdyClassId
        )
        productStore.fetchCmdyList({
          PageNum: 0,
          PageSize: 0,
          CmdyClassId: cmdyClassId.value,
          CmdyThemeId: [],
          CmdyTagId: '',
          OrderType: productStore.state.selectedOrderType,
          IsIncludeHiddenCmdy: false
        })
        productStore.setSelectedCmdy({})
        productStore.setSelectedCmdyTag({})
      }
    )
    // watch(
    //   () => route.query.dateNow,
    //   () => {
    //     if (route.name === 'productList') {
    //       // productStore.setSelectedCmdyClass({})
    //       getCmdyClassList()
    //     }
    //   }
    // )
    onMounted(() => {
      productStore.resetAllStatus()
      getCmdyClassList()
    })
    return {
      RefProductItemList,
      RefProductItemListForMobile,
      cmdyClassItem,
      productStore,
      cmdyThemeList,
      selectThemeList,
      getCmdyClassList,
      closeRecItem,
      clickProductClassIconList,
      clickProductItemList,
      clickProductClassItem,
      clickProductItem,
      isMobile
    }
  },
  components: {
    // SquareItemList,
    Title,
    UnderLine,
    ProductClassItemList,
    ProductClassIconList,
    ProductItemList,
    Banner,
    Breadcrumb
  }
})
