
import productStore from '@/composition/productStore'
import { props } from 'bluebird'
import { computed, defineComponent, provide, ref } from 'vue'
export default defineComponent({
  data () {
    return {}
  },
  props: {
    BgIconId: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    width: {
      type: String,
      default: '142px'
    },
    image: {
      type: String,
      required: false,
      default: 'https://fakeimg.pl/60x60/'
    },
    imageSelected: {
      type: String,
      required: false,
      default: 'https://fakeimg.pl/60x60/'
    },
    selectBgIconId: {
      type: String,
      required: false
    }
  },
  setup (props) {
    provide('productStore', productStore)
    const showImage = computed(() => {
      if (props.imageSelected === '') {
        return props.image
      } else {
        if (
          productStore.state.selectedCmdyClass.CmdyClassId !== undefined &&
          productStore.state.selectedCmdyClass.CmdyClassId === props.BgIconId
        ) {
          return props.imageSelected
        }

        return props.image
      }
    })
    // const showImage = computed()

    return { productStore, showImage }
  }
})
