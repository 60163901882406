
import { defineComponent, ref, provide, onBeforeUpdate, onUpdated } from 'vue'
import ProductItem from '@/components/ProductItem.vue'
import RectangleItem from '@/components/RectangleItem.vue'
import { CmdyClass as CmdyType } from '@/types/cmdyType'
import productStore from '@/composition/productStore'
import cloneDeep from 'lodash/cloneDeep'
import map from 'lodash/map'
export default defineComponent({
  data () {
    return {}
  },
  props: {
    row: {
      type: Number,
      required: false,
      default: 5
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ProductItem
  },
  setup () {
    provide('productStore', productStore)

    const getSelectProduct = (product: any) => {
      const _cmdyList = cloneDeep(productStore.state.cmdyList)
      map(_cmdyList, (item) => {
        if (product.CollectId === item.CollectId) {
          item.Checked = !item.Checked
        }
        return item
      })
      productStore.setCmdyList(_cmdyList)
    }
    // onUpdated(() => {
    // })
    return {
      productStore,
      getSelectProduct
    }
  }
})
