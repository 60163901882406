
import { defineComponent, ref, onMounted, provide } from 'vue'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import SquareItemList from '@/components/SquareItemList_IP.vue'
import SwiperIconList from '@/components/SwiperIconList.vue'
import SubHeader from '@/components/common/SubHeader.vue'
import ProductItemList from '@/components/ProductItemList_IP.vue'
import Title from '@/components/common/Title.vue'
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import { useRouter, useRoute } from 'vue-router'
import productStore from '@/composition/productStore'
export default defineComponent({
  components: {
    SquareItemList,
    SwiperIconList,
    // SubHeader,
    ProductItemList,
    Title,
    Breadcrumb
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    provide('productStore', productStore)
    const recommendedList = ref(null)
    const ipIconList = ref(null)
    const ipSubList = ref(null)
    const cmdyThemeList = ref([])
    const getRecommended = () => {
      const data = {
        Data: {
          ShopHallCode: '',
          CmdyClassId: '',
          Type: 'ShopHallMainPageRecommend'
        }
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
            requestUrl.getRecommended,
          data
        )
        .then((res) => {
          const resData = res.data.Data.RecommendedGroups[0].Items
          recommendedList.value = resData
        })
        .catch((error) => {
          console.error(error)
        })
    }
    const getIPIconList = () => {
      const data = {
        Data: {
          PurposeType: 'Brand'
        }
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.getHallList,
          data
        )
        .then((res) => {
          const resData = res.data.Data.ShopHallList
          ipIconList.value = resData
        })
        .catch((error) => {
          console.error(error)
        })
    }
    const getIPSubList = () => {
      productStore.fetchCmdyClassListV2({
        ShopHallCode: '',
        ShopHallClassId: '',
        CmdyClassId: '',
        ParentCmdyClassId: '',
        DataType: 'ShopHall',
        MaxLevel: 2,
        IsOnlyLeafNode: true
      })
    }
    const goIPProductList = (
      shopHallCode: string,
      cmdyClassId: string,
      cmdyThemeId: string
    ) => {
      (document.getElementById('app') as HTMLBodyElement).scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      router.push({
        name: 'ipproductList',
        query: {
          shopHallCode: shopHallCode,
          cmdyClassId: cmdyClassId
        },
        params: {
          cmdyThemeId: cmdyThemeId || ''
        }
      })
    }
    const getCmdyThemeList = (): Promise<{ status: boolean }> => {
      return new Promise((resolve, reject) => {
        const data = {
          Data: {
            PageNum: 0,
            PageSize: 0,
            ShopCode: 'G-Gift',
            ShopHallCode: ''
          }
        }
        request
          .post(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API +
              requestUrl.getCmdyThemeList,
            data
          )
          .then((res) => {
            cmdyThemeList.value = res.data.Data.CmdyThemeList
            resolve({ status: true })
          })
          .catch((error) => {
            console.error(error)
          })
      })
    }
    onMounted(() => {
      productStore.resetAllStatus()
      getRecommended()
      getIPIconList()
      getIPSubList()
      getCmdyThemeList()
    })
    return {
      productStore,
      recommendedList,
      ipIconList,
      ipSubList,
      cmdyThemeList,
      getRecommended,
      getIPIconList,
      getIPSubList,
      goIPProductList,
      getCmdyThemeList
    }
  }
})
