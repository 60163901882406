
import { defineComponent, provide, toRef } from 'vue'
import RectangleItemFullImage from '@/components/RectangleItemFullImage.vue'
import FilterDropDown from '@/components/common/FilterDropDown.vue'
import DropDown from '@/components/common/DropDown.vue'
import productStore from '@/composition/productStore'
export default defineComponent({
  props: {
    row: {
      type: Number,
      required: false,
      default: 3
    },
    ipSubList: [],
    goIPProductList: {
      type: Function,
      default: () => []
    }
  },
  components: {
    RectangleItemFullImage
    // FilterDropDown,
    // DropDown
  },
  setup (props) {
    const ipSubListRef = toRef(props, 'ipSubList')
    provide('productStore', productStore)
    return { ipSubListRef, productStore }
  }
})
