
import { defineComponent, ref } from 'vue'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import BgIcon from '@/components/common/BgIcon.vue'
import 'swiper/css'
import 'swiper/css/pagination'

export default defineComponent({
  name: 'swiper-example-responsive-breakpoints',
  title: 'Responsive breakpoints',
  url: import.meta.url,
  components: {
    Swiper,
    SwiperSlide,
    BgIcon
  },
  props: {
    ipIconList: [],
    goIPProductList: {
      type: Function,
      default: () => []
    }
  },
  setup () {
    const ipList = ref([])
    const getIPList = () => {
      const data = {
        Data: {}
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
            requestUrl.getMenuTree,
          data
        )
        .then((req) => {
          const resData = req.data.Data.MenuList
          ipList.value = resData
        })
        .catch((error) => {
          console.error(error)
        })
    }
    return {
      modules: [Pagination]
    }
  }
})
