
import { defineComponent } from 'vue'
import { positiveIntegerInput } from '@/utils/utils'
import { useToast, POSITION } from 'vue-toastification'
export default defineComponent({
  name: 'g-input',
  emits: ['subtract-click', 'add-click', 'update:modelValue', 'blur'],
  props: {
    modelValue: {
      type: Number,
      require: false,
      default: 1
    },
    maxNumber: {
      type: Number,
      default: 999
    }
  },
  setup (props, context) {
    const { emit } = context
    const toast = useToast()
    const change = (event: Event) => {
      (event.target as HTMLInputElement).value = positiveIntegerInput(event)

      if (
        positiveIntegerInput(event) === '0' ||
        positiveIntegerInput(event) === ''
      ) {
        (event.target as HTMLInputElement).value = '1'
      }
      let _number = Number((event.target as HTMLInputElement).value)
      if (_number > props.maxNumber) {
        _number = props.maxNumber
        toast.warning('已達訂購上限', {
          position: POSITION.TOP_CENTER
        })
      }
      (event.target as HTMLInputElement).value = _number.toString()
      console.log(_number)
      emit(
        'update:modelValue',
        parseInt((event.target as HTMLInputElement).value)
      )
      emit('blur')
    }
    const amountHandle = (flag: string, qty: number) => {
      if (flag === 'add') {
        emit('add-click', qty)
      } else {
        emit('subtract-click', qty)
      }
    }
    return {
      change,
      amountHandle
    }
  }
})
