
import {
  defineComponent,
  ref,
  onUnmounted,
  onMounted,
  provide,
  watch,
  computed
} from 'vue'
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import SwiperSquareItem from '@/components/SwiperSquareItem.vue'
import ProductOption from '@/components/common/ProductOption.vue'
import Tabs from '@/components/common/Tabs.vue'
import SquareItemFullImage from '@/components/SquareItemFullImage.vue'
import ImageProductList from '@/components/ImageProductList.vue'
import AddOnModal from '@/components/modal/AddOnModal.vue'
import ImageRadioButton from '@/components/common/ImageRadioButton.vue'
import TextRadioButton from '@/components/common/TextRadioButton.vue'
import Btn from '@/components/common/Btn.vue'
import Input from '@/components/common/Input.vue'
import FavoriteButton from '@/components/common/FavoriteButton.vue'
import InputWithButton from '@/components/common/InputWithButton.vue'
import store from '@/composition/store'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import cloneDeep from 'lodash/cloneDeep'
import {
  BaseResponse,
  CmdySpecList,
  CommodityDetailResponse
} from '@/types/ResponseType'
import {
  mappingCodeCombine,
  mappingCodeParser
} from '@/utils/mappingCodeParser'
import { useRoute, useRouter } from 'vue-router'
import productStore from '@/composition/productStore'
import cartStore from '@/composition/cartStore'
import ModalConfirm from '@/components/modal/ConfirmModal.vue'
import { ConfirmModal } from '@/types/ConfirmModal'
import { unescapeHTML } from '@/utils/utils'
import AddPortFolioModal from '@/components/modal/AddPortfolioModal.vue'
import axios from 'axios'
import { useToast, POSITION } from 'vue-toastification'
import { getToken } from '@/utils/auth'
import moment from 'moment'
import { event } from 'vue-gtag'
import { useGtm } from '@gtm-support/vue-gtm'
import { Navigation, Autoplay, Mousewheel, FreeMode, Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import filter from 'lodash/filter'
import map from 'lodash/map'
import find from 'lodash/find'
import { onClickOutside } from '@vueuse/core'

import EventBus from '@/event_bus/eventBus'
export default defineComponent({
  name: 'g-product-detail',
  components: {
    Swiper,
    SwiperSlide,
    Breadcrumb,
    SwiperSquareItem,
    // ProductOption,
    Tabs,
    // ImageProductList,
    Input,
    InputWithButton,
    FavoriteButton,
    Btn,
    ImageRadioButton,
    TextRadioButton,
    ModalConfirm,
    AddPortFolioModal,
    SquareItemFullImage,
    AddOnModal
  },
  setup () {
    provide('store', store)
    provide('productStore', productStore)
    provide('cartStore', cartStore)
    const toast = useToast()
    const route = useRoute()
    const router = useRouter()
    const confirmModal = ref()
    const test = ref(1)
    const showAddOnModal = ref(false)
    const isShow = ref(false)
    const addOnShow = ref(true)
    const packageShow = ref(true)
    const mailShow = ref(1)
    const restockMail = ref('')
    const tab = ref(1)
    const showProductInfo = ref(false)
    const lastScroll = ref(0)
    const gtm = useGtm()
    const currentUrl = ref(window.location.href)
    const selectAddOnCmdy = ref({} as any)
    const selectedType = ref('')

    const tabSelect = (_tab: number) => {
      console.log('>>>>', _tab)
      tab.value = _tab
    }
    const mobileTabSelect = (_tab: number) => {
      console.log('>>>>', _tab)
      tab.value = _tab
    }

    // 控制價格產品名稱是否顯示
    const scrollFunc = () => {
      const st =
        window.scrollY ||
        window.pageYOffset ||
        (document.getElementById('app') as HTMLBodyElement).scrollTop
      if (st < lastScroll.value || st === 0) {
        showProductInfo.value = false
        // store.dispatch('actionSetShowLogo', true)
      } else {
        showProductInfo.value = true
        // store.dispatch('actionSetShowLogo', false)
      }

      lastScroll.value = st
      console.log('scroll top >>', st)
    }
    const specList = ref(Object() as CmdySpecList[])
    const selectedCmdy = ref({} as any)
    const selectedAddOn = ref([] as any[])
    const selectedCmdyAddOn = ref([] as any[])
    const selectedCmdyPackage = ref([] as any[])
    const optionModel = ref({})
    const cmdyQty = ref(1 as number)
    const maxCmdyQty = ref(20 as number)
    const isClickGoEditor = ref(false)
    const clickGoEditor = () => {
      isClickGoEditor.value = true
      goEditor()
    }
    const getSelectedAddOn = computed(() => {
      const _selectedItems = filter(selectedCmdyAddOn.value, (item: any) => {
        return item.Checked === true
      })
      return _selectedItems
    })
    const getSelectedPackage = computed(() => {
      const _selectedItem = filter(selectedCmdyPackage.value, (item: any) => {
        return item.Checked === true
      })
      return _selectedItem
    })
    const getCmdyDetail = (parameter: any, type = 'cmdyid') => {
      if (!parameter) return

      store.setShowLoading(true)
      const reqData = {
        Data: { CmdyId: parameter, MappingCode: parameter, AccessId: '' }
      }
      const accessId = sessionStorage.accessId || ''
      let apiVersion = '5.0'
      reqData.Data.AccessId = accessId
      let getUrl =
        window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.getCmdyById

      // 依照type 不同使用不同
      if (type !== 'cmdyid') {
        getUrl =
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
          requestUrl.getCmdyByMappingCode

        apiVersion = '5.0'
      }
      request
        .post<BaseResponse<CommodityDetailResponse>>(getUrl, reqData, {
          headers: {
            'api-version': apiVersion
          }
        })
        .then((res) => {
          const resData = res.data
          specList.value = resData.Data.CmdySpecList
          selectedCmdy.value = resData.Data
          selectedCmdyAddOn.value = map(resData.Data.AddOnCmdyList, (item) => {
            item.Checked = false
            item.CmdyQty = 1
            return item
          })
          selectedCmdyPackage.value = map(
            resData.Data.PackageCmdyList,
            (item, index) => {
              item.Checked = false
              if (index === 0) {
                item.Checked = true
              }
              item.CmdyQty = 1
              return item
            }
          )
          // if (resData.Data.CmdyRelList.length !== 0) {
          //   resData.Data.CmdyRelList.forEach((CmdyRel) => {
          //     if (CmdyRel.Type === 'Package') {
          //       selectedCmdyAddOn.value = CmdyRel.RelCmdyList
          //     } else if (CmdyRel.Type === 'Package') {
          //       selectedCmdyPackage.value = CmdyRel.RelCmdyList
          //     }
          //   })
          // }
          maxCmdyQty.value = selectedCmdy.value.MaxCmdyQty

          mappingCodeParser(resData.Data.MappingCode)
            .then((result: any) => {
              optionModel.value = cloneDeep(result)
            })
            .catch((e: any) => {
              console.log(e)
            })
          router.replace({ query: { cmdyId: resData.Data.CmdyId } })
          store.setShowLoading(false)
          if (gtm?.enabled()) {
            window.dataLayer?.push({ ecommerce: null }) // Clear the previous ecommerce object.
            window.dataLayer?.push({
              event: 'view_item',
              ecommerce: {
                currency: 'TWD',
                value: selectedCmdy.value.MinUnitFeeReal,
                items: [
                  {
                    item_id: selectedCmdy.value.CmdyId,
                    item_name: selectedCmdy.value.Name,
                    price: selectedCmdy.value.MinUnitFeeReal,
                    quantity: 1
                  }
                ]
              }
            })
          }
          window.fbq('track', 'ViewContent', {
            content_type: 'product',
            content_ids: [selectedCmdy.value.CmdyId],
            content_name: selectedCmdy.value.Name,
            value: selectedCmdy.value.MinUnitFeeReal,
            currency: 'TWD'
          })
          // 解析MappingCode 從mappingcode反推現在所選擇的option

          // console.log(resData);
          // let _infoList = resData.Data.IntroList;
          // this.imageList = [];
          // if (_infoList.length === 0) return;

          // _infoList[0].IntroImgList.forEach((element: any) => {
          //   this.imageList.push({
          //     id: '0_' + element.Rank,
          //     image: element.Image
          //   });
          // });
          // console.log('activities >>>> ', resData.Data);
          // this.$route.params.productId = resData.Data.CmdyId;
          // 確認detail id是否一致
        })
        .catch((error) => {
          console.log(error)
          store.setShowLoading(false)
        })
    }
    const subtractClick = () => {
      if (cmdyQty.value === 1) {
        return
      }

      cmdyQty.value = cmdyQty.value - 1
    }
    const addClick = () => {
      if (cmdyQty.value >= maxCmdyQty.value) {
        cmdyQty.value = maxCmdyQty.value
        return toast.warning('已達訂購上限', {
          position: POSITION.TOP_CENTER
        })
      }
      cmdyQty.value = cmdyQty.value + 1
    }
    const addToCollect = (cmdy: any) => {
      window.fbq('track', 'AddToWishlist')
      if (store.state.isLogin) {
        if (cmdy.IsInCmdyCollect) {
          try {
            const _confirmModal = confirmModal.value as ConfirmModal
            _confirmModal
              .createConfirm('確定取消收藏此商品？', '', false)
              .then(() => {
                const collectIdArray: string[] = []
                collectIdArray.push(selectedCmdy.value.CmdyCollectId)
                productStore.delCmdyCollect(collectIdArray).then(() => {
                  getCmdyDetail(cmdy.CmdyId)
                  cartStore.fetchShoppingCartList()
                })
              })
          } catch (err) {
            console.log(err)
          }
        } else {
          productStore.addCmdyCollect(cmdy.CmdyId).then(() => {
            getCmdyDetail(cmdy.CmdyId)
            cartStore.fetchShoppingCartList()
          })
        }
      } else {
        store.setShowLoginModal(true)
      }
    }
    const addShoppingCart = (cmdy: any) => {
      cmdy.CmdyQty = cmdyQty.value
      cmdy.AddOnCmdyList = getSelectedAddOn.value
      cmdy.PackageCmdyList = getSelectedPackage.value
      cartStore.addShoppingCart(cmdy, route.fullPath)
      if (gtm?.enabled()) {
        console.log(cmdy)
        window.dataLayer?.push({ ecommerce: null }) // Clear the previous ecommerce object.
        window.dataLayer?.push({
          event: 'add_to_cart',
          ecommerce: {
            currency: 'TWD',
            value: cmdy.MinUnitFeeReal,
            items: [
              {
                item_id: cmdy.CmdyId,
                item_name: unescapeHTML(cmdy.Name),
                price: cmdy.MinUnitFeeReal,
                quantity: cmdy.CmdyQty
              }
            ]
          }
        })
      }
      window.fbq('track', 'AddToCart', {
        content_ids: [cmdy.CmdyId],
        content_name: cmdy.Name,
        contents: [{ id: cmdy.CmdyId }]
      })
    }
    const changeOption = () => {
      mappingCodeCombine(optionModel.value)
        .then((result: string) => {
          console.log(result)
          getCmdyDetail(result, 'mappingcode')
        })
        .catch((e: any) => {
          console.log(e)
        })
    }
    const addPortFolioModal = ref()
    const portfolioName = ref('')
    const goEditor = () => {
      store
        .fetchUserInfo()
        .then(() => {
          try {
            portfolioName.value = ''
            const _addPortFolioModal = addPortFolioModal.value as any
            _addPortFolioModal.createConfirm('作品名稱', '', false).then(() => {
              store.setShowLoading(true)
              const data = {
                DistributorId: store.state.userInfo.Account,
                TemplateSkuId:
                  selectedCmdy.value.EditorTemplateList[0]
                    .EditorPartnerTemplateId
              }
              axios
                .post(
                  window._env_.VUE_APP_AIPS_URL +
                    '/UltraAIPSTaskManagerNet/WorkSet/GenerateWorkSetID',
                  data
                )
                .then((res) => {
                  const resData = res.data.Data
                  if (res.data.Status === 'Success') {
                    const workSetID = resData.WorkSetID

                    addPortfolio(workSetID)
                      .then(() => {
                        const PartnerBookId = workSetID
                        const orderurl = encodeURIComponent(
                          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
                            '/orders/add-shopping-cart-from-editor?Token=' +
                            getToken() +
                            '&PartnerBookId=' +
                            PartnerBookId +
                            '&CmdyQty=1'
                        )
                        let exiturl = ''
                        let returnurl = ''

                        if (window._env_.ENV === 'develop') {
                          exiturl = encodeURIComponent(
                            'http://localhost:9123' +
                              router.currentRoute.value.fullPath
                          )
                          returnurl = encodeURIComponent(
                            'http://localhost:9123/addShoppingCart?partnerBookId=' +
                              PartnerBookId
                          )
                        } else {
                          exiturl = encodeURIComponent(
                            window._env_.VUE_APP_GIFT_URL +
                              router.currentRoute.value.fullPath
                          )
                          returnurl = encodeURIComponent(
                            window._env_.VUE_APP_GIFT_URL +
                              '/addShoppingCart?partnerBookId=' +
                              PartnerBookId
                          )
                        }

                        window.location.href =
                          window._env_.VUE_APP_GIFT_EDITOR_URL +
                          `/auth/${workSetID}/ip?orderurl=${orderurl}&exiturl=${exiturl}&returnurl=${returnurl}&distributorid=${store.state.userInfo.Account}`
                      })
                      .catch((e) => {
                        toast.error(e.data.Message, {
                          position: POSITION.TOP_CENTER
                        })
                        store.setShowLoading(false)
                      })
                  } else {
                    toast.warning(res.data.Message, {
                      position: POSITION.TOP_CENTER
                    })
                    store.setShowLoading(false)
                  }
                })
            })
          } catch (err) {
            console.log(err)
          }
        })
        .catch(() => {
          store.setShowLoginModal(true)
        })
    }
    const addPortfolio = (
      editorPartnerBookId: string
    ): Promise<{ status: any }> => {
      return new Promise((resolve, reject) => {
        const data = {
          Data: {
            PortfolioList: [
              {
                CmdyId: selectedCmdy.value.CmdyId,
                EditorTempId:
                  selectedCmdy.value.EditorTemplateList[0].EditorTemplateId,
                EditorPartnerId:
                  selectedCmdy.value.EditorTemplateList[0].EditorPartnerId,
                EditorPartnerBookId: editorPartnerBookId,
                Name: portfolioName.value,
                PageQty: '',
                IsInCart: '0',
                RefPortfolioId: '',
                RefReason: ''
              }
            ]
          }
        }
        request
          .post(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API +
              requestUrl.addPortfolio,
            data
          )
          .then((res) => {
            const resData = res.data.Data
            resolve({ status: true })
          })
          .catch((error) => {
            reject(error)
            console.error(error)
          })
      })
    }
    const sentRestockNotification = (cmdyId: string) => {
      let isSendMail = false

      if (mailShow.value === 2 && restockMail.value === '') {
        return toast.warning('請輸入Email', {
          position: POSITION.TOP_CENTER
        })
      }
      if (store.state.isLogin) {
        if (store.state.userInfo.Email) {
          isSendMail = true
          restockMail.value = store.state.userInfo.Email
          mailShow.value = 3
        } else {
          mailShow.value = 2
        }
      } else {
        if (mailShow.value === 2) {
          isSendMail = true
        } else {
          mailShow.value = 2
        }
      }
      if (isSendMail) {
        const data = {
          Data: {
            CmdyId: cmdyId,
            Email: restockMail.value
          }
        }
        request
          .post(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API +
              requestUrl.addCmdyRestockNotify,
            data
          )
          .then((res) => {
            restockMail.value = ''
            mailShow.value = 3
            if (res.data.Data.IsExist) {
              toast.warning('重複發送', {
                position: POSITION.TOP_CENTER
              })
            } else {
              toast.success('已發送', {
                position: POSITION.TOP_CENTER
              })
            }
          })
          .catch((error) => {
            console.error(error)
            toast.error(error.data.Message, {
              position: POSITION.TOP_CENTER
            })
          })
      }
    }
    const setSelectAddOnCmdy = (addOnCmdy: any, type: string) => {
      selectedType.value = type
      selectAddOnCmdy.value = addOnCmdy
      showAddOnModal.value = true
    }
    const getSelectPrice = computed(() => {
      const _cmdyOriAmt = selectedCmdy.value.MinUnitFeeOri * cmdyQty.value
      const _cmdyRealAmt = selectedCmdy.value.MinUnitFeeReal * cmdyQty.value
      const _packageOriAmt =
        getSelectedPackage.value[0]?.UnitFeeOri *
        getSelectedPackage.value[0]?.CmdyQty
      const _packageRealAmt =
        getSelectedPackage.value[0]?.UnitFeeReal *
        getSelectedPackage.value[0]?.CmdyQty
      let _addOnOriAmt = 0
      let _addOnRealAmt = 0
      getSelectedAddOn.value.forEach((item: any) => {
        _addOnOriAmt += item.UnitFeeOri * item.CmdyQty
        _addOnRealAmt += item.UnitFeeReal * item.CmdyQty
      })
      return {
        TotalOriAmt: _cmdyOriAmt + _packageOriAmt + _addOnOriAmt,
        TotalRealAmt: _cmdyRealAmt + _packageRealAmt + _addOnRealAmt
      }
    })
    const setPackage = (cmdy: any) => {
      console.log(cmdy)
      selectedCmdyPackage.value = selectedCmdyPackage.value.map(
        (packageCmdy: any) => {
          if (packageCmdy.CmdyId === cmdy.CmdyId) {
            packageCmdy.Checked = true
          } else {
            packageCmdy.Checked = false
          }
          return packageCmdy
        }
      )
    }
    watch(
      () => route.query.cmdyId,
      () => {
        getCmdyDetail(route.query.cmdyId)
        currentUrl.value = window.location.href
        isClickGoEditor.value = false
        mailShow.value = 1
      }
    )
    watch(
      () => store.state.isLogin,
      (newVal) => {
        if (
          newVal === true &&
          selectedCmdy.value.IsEndProduct === 0 &&
          isClickGoEditor.value
        ) {
          goEditor()
        }
      }
    )
    onMounted(() => {
      const appHTMLBodyElement = (document.getElementById('app') as HTMLBodyElement)
      appHTMLBodyElement.addEventListener(
        'scroll',
        scrollFunc
      )
      // window.addEventListener('scroll', scrollFunc)
      console.log(route.query.cmdyId)
      getCmdyDetail(route.query.cmdyId)
    })

    onUnmounted(() => {
      (document.getElementById('app') as HTMLBodyElement).removeEventListener(
        'scroll',
        scrollFunc
      )
      // window.removeEventListener('scroll', scrollFunc)
    })

    return {
      modules: [Autoplay, Navigation, Mousewheel, FreeMode, Scrollbar],
      addOnShow,
      addPortFolioModal,
      test,
      showAddOnModal,
      isShow,
      mailShow,
      restockMail,
      tab,
      showProductInfo,
      confirmModal,
      route,
      selectAddOnCmdy,
      tabSelect,
      mobileTabSelect,
      getSelectedAddOn,
      selectedCmdy,
      selectedAddOn,
      selectedCmdyAddOn,
      selectedCmdyPackage,
      specList,
      optionModel,
      getCmdyDetail,
      cmdyQty,
      getSelectPrice,
      addToCollect,
      // delCmdyCollect,
      portfolioName,
      changeOption,
      addShoppingCart,
      addClick,
      subtractClick,
      unescapeHTML,
      goEditor,
      moment,
      isClickGoEditor,
      clickGoEditor,
      currentUrl,
      sentRestockNotification,
      setSelectAddOnCmdy,
      setPackage,
      packageShow,
      selectedType,
      getSelectedPackage,
      EventBus
    }
  }
})
