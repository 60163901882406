
import { defineComponent, onMounted, onUnmounted, ref, provide } from 'vue'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import Title from '@/components/common/Title.vue'
import SwiperProducImage from '@/components/SwiperProducImage.vue'
import ImageList from '@/components/ImageList.vue'
import SwiperImageList from '@/components/SwiperImageList.vue'
import SquareItemFullImage from '@/components/SquareItemFullImage.vue'
import store from '@/composition/store'
import liff from '@line/liff'
import LiffWeb from '@/plugins/line'
import cloneDeep from 'lodash/cloneDeep'
import { useRouter } from 'vue-router'
import { unescapeHTML } from '@/utils/utils'
import productStore from '@/composition/productStore'

export default defineComponent({
  name: 'G-HomeView',
  components: {
    SwiperProducImage,
    SquareItemFullImage,
    Title,
    ImageList
    // SwiperImageList
  },
  setup () {
    provide('store', store)
    provide('productStore', productStore)
    const router = useRouter()
    const check = ref([])
    const imageList = ref([] as any)
    const isLoading = ref(true)
    const recommendedGroups = ref()

    // 控制header logo是否顯示
    const scrollFunc = () => {
      const st =
        window.scrollY ||
        window.pageYOffset ||
        (document.getElementById('app') as HTMLBodyElement).scrollTop
      if (st >= 100) {
        store.setShowLogo(true)
      } else {
        store.setShowLogo(false)
      }
      // console.log('scroll top >>', st)
    }

    const getImageList = () => {
      const data = {
        Data: {
          ShopHallCode: '',
          TopRank: 0,
          RootClassId: ''
        }
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.getHotSales,
          data,
          {
            headers: {
              'api-version': '2.0'
            }
          }
        )
        .then((res) => {
          const resData = res.data.Data
          imageList.value = resData.CmdyList
          isLoading.value = false
        })
        .catch((error) => {
          console.error(error)
          isLoading.value = false
        })
    }
    const goProductDetail = (_cmdyItem: any) => {
      (document.getElementById('app') as HTMLBodyElement).scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      if (
        _cmdyItem.FrontendParameter === undefined ||
        _cmdyItem.FrontendParameter === ''
      ) {
        if (_cmdyItem.CmdyId === undefined || _cmdyItem.CmdyId === '') {
          router.push({
            name: 'productDetail',
            query: {
              cmdyId: _cmdyItem.RefIdSet.CmdyId
            }
          })
        } else {
          router.push({
            name: 'productDetail',
            query: {
              cmdyId: _cmdyItem.CmdyId
            }
          })
        }
      } else {
        window.location.href = unescapeHTML(_cmdyItem.FrontendParameter)
      }
    }
    const goProductList = (shopHallCode: string) => {
      (document.getElementById('app') as HTMLBodyElement).scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      router.push({
        name: 'ipproductList',
        query: {
          shopHallCode: shopHallCode,
          cmdyClassId: '',
          cmdyTagId: '',
          cmdyId: ''
        }
      })
    }
    onMounted(() => {
      // const liffWeb = new LiffWeb()
      // window.liffWeb = liffWeb
      // liffWeb.initLine()
      // liff
      //   .init({ liffId: '1657734280-PbXM8EnY' })
      //   .then(() => {
      //     // liff.logout()
      //     if (!liff.isLoggedIn()) {
      //       window.liffWeb.login((liffInfo: any) => {
      //         console.log(liffInfo)
      //       })
      //     }
      //   })
      //   .catch(() => {
      //     console.log('fail')
      //   })

      // liff.logout()
      (document.getElementById('app') as HTMLBodyElement).addEventListener(
        'scroll',
        scrollFunc
      )
      // window.addEventListener('scroll', scrollFunc)
      productStore.fetchRecommended('', '', 'NewCmdy').then((res) => {
        recommendedGroups.value = res.recommendedGroups
      })
      getImageList()
    })

    onUnmounted(() => {
      (document.getElementById('app') as HTMLBodyElement).removeEventListener(
        'scroll',
        scrollFunc
      )
      // window.removeEventListener('scroll', scrollFunc)
    })

    return {
      check,
      imageList,
      isLoading,
      recommendedGroups,
      getImageList,
      goProductDetail,
      goProductList
    }
  }
})
