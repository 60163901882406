import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f22959c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "fixed w-full bg-white z-[50]",
  ref: "header"
}
const _hoisted_2 = { class: "g-container center flex justify-between items-center h-[50px] py-[8px]" }
const _hoisted_3 = { class: "basis-1/3 flex flex-row-reverse mr-3 gap-2" }
const _hoisted_4 = { class: "relative" }
const _hoisted_5 = {
  key: 0,
  class: "cartQuantity text-xs text-white bg-primary-color"
}
const _hoisted_6 = {
  key: 0,
  class: "absolute flex z-9999999 ml-[-60px] flex-col text-sm gap-2.5 bg-white cursor-pointer"
}
const _hoisted_7 = { class: "w-[100px] mx-5 flex flex-col gap-2 pt-[24px]" }
const _hoisted_8 = { class: "pb-[10px] mx-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_divider = _resolveComponent("divider")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "basis-1/3",
        onClick: _cache[0] || (_cache[0] = ($event: any) => {
          _ctx.store.setShowMenu(!_ctx.store.state.showMenu);
          _ctx.store.setShowProfile(false);
        })
      }, [
        _createVNode(_component_Icon, {
          class: "ml-3",
          iconName: "menu",
          width: '24px'
        })
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["w-[109px] items-top", ['logo']]),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goHome && _ctx.goHome(...args)))
      }),
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", _hoisted_4, [
          _createVNode(_component_Icon, {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goToPage('shoppingCart'))),
            iconName: "bag"
          }),
          (
              _ctx.cartStore.state.orderShoppingCartCmdyList.length !== undefined
            )
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.cartStore.state.orderShoppingCartCmdyList.length || 0), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("div", null, [
            _createVNode(_component_Icon, {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.store.setShowProfile(!_ctx.store.state.showProfile))),
              iconName: "profile"
            })
          ]),
          (_ctx.store.state.showProfile)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", {
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.goToPage('orderList')))
                  }, "訂單記錄"),
                  _createElementVNode("span", {
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.goToPage('setting')))
                  }, "帳號設定"),
                  _createElementVNode("span", {
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.goToPage('collection')))
                  }, "願望清單"),
                  _createElementVNode("span", {
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.goToPage('portfolio')))
                  }, "作品庫"),
                  _createElementVNode("span", {
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.goToPage('contact')))
                  }, "聯絡客服")
                ]),
                _createVNode(_component_divider),
                _createElementVNode("div", _hoisted_8, [
                  (_ctx.store.state.isLogin)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        onClick: _cache[9] || (_cache[9] = ($event: any) => {
                  _ctx.logout();
                  _ctx.store.setShowProfile(!_ctx.store.state.showProfile);
                })
                      }, "登出"))
                    : _createCommentVNode("", true),
                  (!_ctx.store.state.isLogin)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        onClick: _cache[10] || (_cache[10] = ($event: any) => {
                  _ctx.store.setShowLoginModal(true);
                  _ctx.store.setShowProfile(!_ctx.store.state.showProfile);
                })
                      }, "登入"))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createVNode(_component_Menu, {
      menuList: _ctx.menuList,
      "onUpdate:menuList": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.menuList) = $event))
    }, null, 8, ["menuList"])
  ], 512))
}