
import { defineComponent, toRef } from 'vue'
import SquareItemFullImage from '@/components/SquareItemFullImage.vue'
import RectangleItemFullImage from '@/components/RectangleItemFullImage.vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  data () {
    return {}
  },
  props: {
    recommendedList: {
      type: Array
    },
    goIPProductList: {
      type: Function,
      default: () => []
    }
  },
  components: {
    SquareItemFullImage,
    RectangleItemFullImage
  },
  setup (props, context) {
    const router = useRouter()
    const recommendedListRef = toRef(props, 'recommendedList')
    const goProductDetail = (refIdSet: any) => {
      router.push({
        name: 'ipproductList',
        query: {
          shopHallCode: refIdSet.ShopHallCode,
          cmdyId: refIdSet.CmdyId
        }
      })
    }
    return { recommendedListRef, goProductDetail }
  }
})
