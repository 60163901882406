
import { defineComponent, ref } from 'vue'
import Password from '@/components/common/Password.vue'
import Btn from '@/components/common/Btn.vue'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import { POSITION, useToast } from 'vue-toastification'
export default defineComponent({
  name: 'g-setting-view',
  components: {
    Password,
    Btn
  },
  setup () {
    const toast = useToast()
    const currentPassword = ref('')
    const newPassword = ref('')
    const confirmNewPassword = ref('')
    const changePassword = () => {
      if (
        currentPassword.value === '' ||
        newPassword.value === '' ||
        confirmNewPassword.value === ''
      ) {
        toast.warning('密碼不許為空值', {
          position: POSITION.TOP_CENTER
        })
      } else {
        if (newPassword.value !== confirmNewPassword.value) {
          toast.warning('您輸入的密碼不匹配。', {
            position: POSITION.TOP_CENTER
          })
        } else {
          const data = {
            Data: {
              NowPassword: currentPassword.value,
              NewPassword: newPassword.value
            }
          }
          request
            .post(
              window._env_.VUE_APP_STOREBOOK_SERVICE_API +
                requestUrl.changePassword,
              data
            )
            .then((res) => {
              const resData = res.data

              toast.success(resData.Message, {
                position: POSITION.TOP_CENTER
              })
              // alert(resData.Message)
              currentPassword.value = ''
              newPassword.value = ''
              confirmNewPassword.value = ''
            })
            .catch((error) => {
              toast.error(error.data.Message, {
                position: POSITION.TOP_CENTER
              })
              // alert(error.data.Message)
              console.error(error)
            })
        }
      }
    }
    return { currentPassword, newPassword, confirmNewPassword, changePassword }
  }
})
