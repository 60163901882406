import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vShow as _vShow, normalizeStyle as _normalizeStyle, withCtx as _withCtx, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1521596c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "swiper-image-list mt-9" }
const _hoisted_3 = { class: "flex flex-col flex-wrap gap-[12px] w-full" }
const _hoisted_4 = { class: "flex flex-col flex-wrap gap-[12px] w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_SquareItemFullImage = _resolveComponent("SquareItemFullImage")!
  const _component_SwiperSlide = _resolveComponent("SwiperSlide")!
  const _component_Swiper = _resolveComponent("Swiper")!

  return (_ctx.imageList)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Title, {
          title: _ctx.title,
          subTitle: _ctx.subTitle
        }, null, 8, ["title", "subTitle"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Swiper, {
            class: "swiper w-full",
            modules: _ctx.modules,
            observer: true,
            onObserverUpdate: _ctx.observerUpdate,
            freeMode: true,
            mousewheel: true,
            onSwiper: _ctx.setControlledSwiper,
            navigation: {
          hiddenClass: 'swiper-button-hidden',
          prevEl: 'g-swiper-nav-prev',
          nextEl: 'g-swiper-nav-next',
        },
            slidesPerView: 'auto'
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageList, (item, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                  (index % 2 == 0)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _withDirectives(_createVNode(_component_SwiperSlide, {
                          class: "slide hidden sm:block h-fit mr-[12px]",
                          style: _normalizeStyle({
                width: `calc(100%/5 - 12px)`,
              })
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_3, [
                              _createVNode(_component_SquareItemFullImage, {
                                width: 'inherit',
                                image: _ctx.imageList[index]?.Image.PcWeb,
                                class: "hidden sm:block",
                                onClick: ($event: any) => (_ctx.$emit('goProductDetail', _ctx.imageList[index]))
                              }, null, 8, ["image", "onClick"]),
                              _createVNode(_component_SquareItemFullImage, {
                                width: 'inherit',
                                image: _ctx.imageList[index + 1]?.Image.PcWeb,
                                class: "hidden sm:block",
                                onClick: ($event: any) => (_ctx.$emit('goProductDetail', _ctx.imageList[index + 1]))
                              }, null, 8, ["image", "onClick"])
                            ])
                          ]),
                          _: 2
                        }, 1032, ["style"]), [
                          [_vShow, !_ctx.isMobile().phone]
                        ]),
                        _withDirectives(_createVNode(_component_SwiperSlide, {
                          class: "slide h-fit mr-[3px]",
                          style: _normalizeStyle({
                width: `calc(100%/2 - 3px)`,
              })
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_4, [
                              _createVNode(_component_SquareItemFullImage, {
                                width: 'inherit',
                                image: _ctx.imageList[index]?.Image.MobileWeb,
                                class: "block sm:hidden",
                                onClick: ($event: any) => (_ctx.$emit('goProductDetail', _ctx.imageList[index]))
                              }, null, 8, ["image", "onClick"]),
                              _createVNode(_component_SquareItemFullImage, {
                                width: 'inherit',
                                image: _ctx.imageList[index + 1]?.Image.MobileWeb,
                                class: "block sm:hidden",
                                onClick: ($event: any) => (_ctx.$emit('goProductDetail', _ctx.imageList[index + 1]))
                              }, null, 8, ["image", "onClick"])
                            ])
                          ]),
                          _: 2
                        }, 1032, ["style"]), [
                          [_vShow, _ctx.isMobile().phone]
                        ])
                      ], 64))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            ]),
            _: 1
          }, 8, ["modules", "onObserverUpdate", "onSwiper"])
        ])
      ]))
    : _createCommentVNode("", true)
}