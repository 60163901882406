import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = { class: "h-[50px] pl-[20px] pt-[18px]" }
const _hoisted_4 = { class: "h-[90vh] overflow-scroll" }
const _hoisted_5 = { class: "flex flex-col divide-y-[1px] mx-6 gap-[10px]" }
const _hoisted_6 = { class: "pt-[20px]" }
const _hoisted_7 = { class: "title g-menu-fixed-position-title flex-none" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "pt-[20px]" }
const _hoisted_10 = { class: "title g-menu-fixed-position-title flex-none leading-6" }
const _hoisted_11 = { class: "pt-[20px]" }
const _hoisted_12 = { class: "title g-menu-fixed-position-title flex-none leading-6" }
const _hoisted_13 = { class: "flex flex-col" }
const _hoisted_14 = { class: "h-[50px] pl-[20px] pt-[18px] flex" }
const _hoisted_15 = { class: "pl-4 text-base" }
const _hoisted_16 = { class: "h-[90vh] overflow-scroll" }
const _hoisted_17 = { class: "flex flex-col divide-y-[1px] mx-6 gap-[10px]" }
const _hoisted_18 = { class: "pt-[20px]" }
const _hoisted_19 = { class: "title g-menu-fixed-position-title flex-none" }
const _hoisted_20 = { class: "flex" }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = ["onClick"]
const _hoisted_23 = ["onClick"]
const _hoisted_24 = { class: "title g-menu-fixed-position-title flex-none pt-1" }
const _hoisted_25 = ["onClick"]
const _hoisted_26 = { class: "flex flex-col" }
const _hoisted_27 = { class: "h-[50px] pl-[20px] pt-[18px]" }
const _hoisted_28 = { class: "h-[90vh] overflow-scroll" }
const _hoisted_29 = { class: "mx-[30px]" }
const _hoisted_30 = {
  key: 0,
  class: "mx-3"
}
const _hoisted_31 = { class: "mb-[30px]" }
const _hoisted_32 = { class: "flex flex-wrap justify-start space-b gap-[10px] sm:gap-[25px] mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_ProductItem = _resolveComponent("ProductItem")!
  const _directive_clickOutSide = _resolveDirective("clickOutSide")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.store.state.showMenu)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "g-mobile-menu-mask",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.store.setShowMenu(false)))
        }))
      : _createCommentVNode("", true),
    (_ctx.store.state.showMenu)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.level == 1)
            ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["g-mobile-menu text-gary-text-color", [{ 'show-menu': _ctx.store.state.showMenu }]])
              }, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_Icon, {
                      iconName: "close",
                      width: '16px',
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.store.setShowMenu(false)))
                    })
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", {
                      class: "mx-[30px]",
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.clickSearch && _ctx.clickSearch(...args)))
                    }, [
                      _createVNode(_component_SearchInput)
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("ul", _hoisted_7, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuListRef, (menuItem) => {
                            return (_openBlock(), _createElementBlock("li", {
                              class: "flex justify-between",
                              key: menuItem.MenuId,
                              onClick: ($event: any) => (_ctx.firstLevelGoSelectUrl(menuItem))
                            }, [
                              _createTextVNode(_toDisplayString(_ctx.unescapeHTML(menuItem.Name)), 1),
                              _createVNode(_component_Icon, {
                                iconName: "arrow_right_black",
                                width: '24px'
                              })
                            ], 8, _hoisted_8))
                          }), 128))
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("ul", _hoisted_10, [
                          _createElementVNode("li", {
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.goToPage('collection', true)))
                          }, "我的收藏"),
                          _createElementVNode("li", {
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.goToPage('orderList', true)))
                          }, "訂單記錄"),
                          _createElementVNode("li", {
                            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.goToPage('setting', true)))
                          }, "帳號設定"),
                          _createElementVNode("li", {
                            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.goToPage('portfolio', true)))
                          }, "作品庫"),
                          _createElementVNode("li", {
                            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.goToPage('contact', false)))
                          }, "聯絡客服")
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          (_ctx.store.state.isLogin)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 0,
                                onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
                              }, "登出"))
                            : _createCommentVNode("", true),
                          (!_ctx.store.state.isLogin)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 1,
                                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.store.setShowLoginModal(true)))
                              }, "登入"))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ])
                  ])
                ])
              ], 2)), [
                [_directive_clickOutSide, _ctx.closeRecItem]
              ])
            : _createCommentVNode("", true),
          (_ctx.level == 2)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["g-mobile-menu text-gary-text-color", [{ 'show-menu': _ctx.store.state.showMenu }]])
              }, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_Icon, {
                      iconName: "back",
                      width: '24px',
                      onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.level = 1))
                    }),
                    _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.selectedMenu.Name), 1)
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("ul", _hoisted_19, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subMenuList, (subMenuItem) => {
                            return (_openBlock(), _createElementBlock("li", {
                              class: "flex flex-col",
                              key: subMenuItem.MenuId
                            }, [
                              _createElementVNode("div", _hoisted_20, [
                                _withDirectives(_createElementVNode("span", {
                                  class: "w-full",
                                  onClick: ($event: any) => (
                        _ctx.goSelectUrl(
                          subMenuItem.TargetPageType,
                          subMenuItem.RefId,
                          ''
                        )
                      )
                                }, _toDisplayString(subMenuItem.Name), 9, _hoisted_21), [
                                  [_vShow, subMenuItem.ChildMenus?.length === 0]
                                ]),
                                _withDirectives(_createElementVNode("span", {
                                  class: "w-full",
                                  onClick: ($event: any) => (
                        subMenuItem.ChildMenus.Selected =
                          !subMenuItem.ChildMenus.Selected
                      )
                                }, _toDisplayString(subMenuItem.Name), 9, _hoisted_22), [
                                  [_vShow, subMenuItem.ChildMenus?.length !== 0]
                                ]),
                                _withDirectives(_createElementVNode("div", {
                                  onClick: ($event: any) => (
                        subMenuItem.ChildMenus.Selected =
                          !subMenuItem.ChildMenus.Selected
                      )
                                }, [
                                  _withDirectives(_createVNode(_component_Icon, {
                                    iconName: "arrow_right_black",
                                    width: '24px'
                                  }, null, 512), [
                                    [_vShow, subMenuItem.ChildMenus.Selected]
                                  ]),
                                  _withDirectives(_createVNode(_component_Icon, {
                                    iconName: "arrow_down_black",
                                    width: '24px'
                                  }, null, 512), [
                                    [_vShow, !subMenuItem.ChildMenus.Selected]
                                  ])
                                ], 8, _hoisted_23), [
                                  [_vShow, subMenuItem.ChildMenus?.length !== 0]
                                ])
                              ]),
                              _withDirectives(_createElementVNode("ul", _hoisted_24, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subMenuItem.ChildMenus, (thirdMenuItem) => {
                                  return (_openBlock(), _createElementBlock("li", {
                                    class: "pl-[10px] pt-1",
                                    key: thirdMenuItem.MenuId
                                  }, [
                                    _createElementVNode("span", {
                                      class: "w-full",
                                      onClick: ($event: any) => (
                          _ctx.goSelectUrl(
                            thirdMenuItem.TargetPageType,
                            subMenuItem.RefId,
                            thirdMenuItem.RefId
                          )
                        )
                                    }, _toDisplayString(thirdMenuItem.Name), 9, _hoisted_25)
                                  ]))
                                }), 128))
                              ], 512), [
                                [_vShow, !subMenuItem.ChildMenus.Selected]
                              ])
                            ]))
                          }), 128))
                        ])
                      ])
                    ])
                  ])
                ])
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.level == 3)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: _normalizeClass(["g-mobile-menu text-gary-text-color", [{ 'show-menu': _ctx.store.state.showMenu }]])
              }, [
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("div", _hoisted_27, [
                    _createVNode(_component_Icon, {
                      iconName: "back",
                      width: '24px',
                      onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.level = 1))
                    })
                  ]),
                  _createElementVNode("div", _hoisted_28, [
                    _createElementVNode("div", _hoisted_29, [
                      _createVNode(_component_SearchInput, {
                        ref: "inputRef",
                        placeholder: '請輸入關鍵字',
                        getInput: _ctx.getInput,
                        "onUpdate:getInput": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.getInput) = $event))
                      }, null, 8, ["getInput"])
                    ]),
                    (_ctx.searchCmdyList)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                          _createElementVNode("div", _hoisted_31, [
                            _createElementVNode("div", _hoisted_32, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchCmdyList, (cmdy, index) => {
                                return (_openBlock(), _createBlock(_component_ProductItem, {
                                  key: index,
                                  style: _normalizeStyle({ 'flex-basis': `calc(100%/2 - 6px)` }),
                                  class: "sm:block cursor-pointer",
                                  product: _ctx.searchCmdyList[index],
                                  "onUpdate:product": ($event: any) => ((_ctx.searchCmdyList[index]) = $event),
                                  onClick: ($event: any) => (_ctx.goProductDetail(_ctx.searchCmdyList[index]))
                                }, null, 8, ["style", "product", "onUpdate:product", "onClick"]))
                              }), 128))
                            ])
                          ]),
                          _createElementVNode("div", {
                            class: "cursor-pointer w-fit m-auto flex text-xs underline decoration-gary-text-sub-title-color underline-offset-2 items-center text-gary-text-sub-title-color font-bold",
                            onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.goToPage('search', false)))
                          }, [
                            _createTextVNode(" 查看全部" + _toDisplayString(_ctx.CmdyListLength) + "個商品 ", 1),
                            _createVNode(_component_Icon, {
                              iconName: "arrow_right_424242",
                              width: '24px'
                            })
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ], 2))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}