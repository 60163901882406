
import { defineComponent, ref, toRef, computed } from 'vue'
import ModalBase from '@/components/common/ModalBase.vue'
import Tabs from '@/components/common/Tabs.vue'
import Input from '@/components/common/Input.vue'
import CheckBox from '@/components/common/CheckBox.vue'
import Btn from '@/components/common/Btn.vue'
import AddressInput from '@/components/common/AddressInput.vue'
import DatePicker from 'vue-datepicker-next'
import Toggle from '@/components/common/Toggle.vue'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import { useToast, POSITION } from 'vue-toastification'
import { isPhoneNumber } from '@/utils/utils'
// import { Form } from 'vee-validate'

export default defineComponent({
  emits: ['update:isUpdate', 'update:show', 'cancel', 'ok'],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    info: {},
    isUpdate: {}
  },
  components: {
    ModalBase,
    Input,
    Btn,
    AddressInput,
    Toggle
  },
  setup (props, context) {
    const { emit } = context
    const toast = useToast()
    // const isUpdateRef = computed({
    //   get: () => props.isUpdate,
    //   set: (val:any) => {
    //     emit('update:isUpdate', val)
    //   }
    // })
    const infoRef = toRef(props, 'info') as any
    const editAddrDetail = () => {
      if (!infoRef.value.ReceiverName || infoRef.value.ReceiverName === '') {
        return toast.warning('請輸入收件人姓名', {
          position: POSITION.TOP_CENTER
        })
      }
      if (
        !infoRef.value.ReceiverMobile ||
        infoRef.value.ReceiverMobile === ''
      ) {
        return toast.warning('請輸入收件人手機', {
          position: POSITION.TOP_CENTER
        })
      }
      if (!isPhoneNumber(infoRef.value.ReceiverMobile)) {
        return toast.warning('手機格式錯誤', {
          position: POSITION.TOP_CENTER
        })
      }
      if (!infoRef.value.DeliveryCity || infoRef.value.DeliveryCity === '') {
        return toast.warning('請輸入縣市', { position: POSITION.TOP_CENTER })
      }
      if (!infoRef.value.DeliveryAddr || infoRef.value.DeliveryAddr === '') {
        return toast.warning('請輸入地址', { position: POSITION.TOP_CENTER })
      }
      const data = {
        Data: {
          DeliveryAddrId: infoRef.value.DeliveryAddrId,
          Name: infoRef.value.ReceiverName,
          Tel: infoRef.value.ReceiverTel,
          Mobile: infoRef.value.ReceiverMobile,
          Zip: infoRef.value.DeliveryZip,
          Country: infoRef.value.DeliveryCountry,
          Province: infoRef.value.DeliveryProvince,
          City: infoRef.value.DeliveryCity,
          Dist: infoRef.value.DeliveryDist,
          Addr: infoRef.value.DeliveryAddr,
          IsDefault: infoRef.value.IsDefault ? 1 : 0
        }
      }
      if (infoRef.value.DeliveryAddrId === '') {
        request
          .post(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API +
              requestUrl.updateDeliveryAddr,
            data
          )
          .then((res) => {
            emit('update:isUpdate', !props.isUpdate)
            // isUpdateRef.value = !isUpdateRef.value
            emit('update:show', false)
            toast.success(res.data.Message, {
              position: POSITION.TOP_CENTER
            })
          })
          .catch((error) => {
            toast.warning(error.response.data.Message, {
              position: POSITION.TOP_CENTER
            })
            // alert(error.response.data.Message)
            console.error(error.response.data.Message)
          })
      } else {
        request
          .put(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API +
              requestUrl.updateDeliveryAddr,
            data
          )
          .then((res) => {
            toast.success(res.data.Message, {
              position: POSITION.TOP_CENTER
            })
            // alert(res.data.Message)
            emit('update:isUpdate', !props.isUpdate)
            emit('update:show', false)
          })
          .catch((error) => {
            toast.warning(error.response.data.Message, {
              position: POSITION.TOP_CENTER
            })
            // alert(error.response.data.Message)
            console.error(error.response.data.Message)
          })
      }
    }
    return {
      // isUpdateRef,
      infoRef,
      editAddrDetail
    }
  }
})
