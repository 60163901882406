
import { any } from 'bluebird'
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'g-banner',
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    image: {
      type: Object,
      default () {
        return {
          PcWeb: 'https://fakeimg.pl/1920x380/FFFFFF/?text=%20',
          MobileWeb: 'https://fakeimg.pl/375x240/FFFFFF/?text=%20'
        }
      }
    }
  }
})
