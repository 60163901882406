
import { defineComponent, ref, toRef } from 'vue'
import { Scrollbar, Autoplay, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperNavBtn from '@/components/common/SwiperNavBtn.vue'
import 'swiper/css'
import 'swiper/css/scrollbar'
import 'swiper/css/autoplay'
import 'swiper/css/navigation'

export default defineComponent({
  name: 'g-swiper-square-image',
  components: {
    Swiper,
    SwiperSlide,
    SwiperNavBtn
  },
  props: {
    customClass: {
      type: String,
      required: false,
      default: ''
    },
    autoPlay: {
      type: [Object, Boolean],
      require: false,
      default: () => {
        return { delay: 3000 }
      }
    },
    height: {
      type: [String],
      require: false,
      default: '600px'
    },
    selectItem: {
      type: [],
      require: false,
      default: [
        {
          Image: {
            PcWeb:
              'https://fakeimg.pl/600x600/FFFFFF/?text=%20',
            MobileWeb:
              'https://fakeimg.pl/375x375/FFFFFF/?text=%20'
          },
          Alternation: '',
          Rank: 1,
          IsCover: 1
        },
        {
          Image: {
            PcWeb:
              'https://fakeimg.pl/600x600/FFFFFF/?text=%20',
            MobileWeb:
              'https://fakeimg.pl/375x375/FFFFFF/?text=%20'
          },
          Alternation: '',
          Rank: 2,
          IsCover: 1
        }
      ]
    }
  },
  methods: {},
  setup (props) {
    const selectItemRef = toRef(props, 'selectItem')
    const controlledSwiper = ref(null)

    const prevClick = () => {
      (controlledSwiper.value as any).slidePrev(500, true)
    }

    const nextClick = () => {
      (controlledSwiper.value as any).slideNext(500, true)
    }
    const setControlledSwiper = (swiper: any) => {
      controlledSwiper.value = swiper
    }

    return {
      modules: [Scrollbar, Autoplay, Navigation],
      controlledSwiper,
      setControlledSwiper,
      prevClick,
      nextClick,
      selectItemRef
    }
  }
})
