import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "g-container hidden sm:block" }
const _hoisted_2 = { class: "g-container flex flex-col mx-[17px]" }
const _hoisted_3 = { class: "full-width-bg mt-[30px]" }
const _hoisted_4 = { class: "g-container relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_SquareItemList = _resolveComponent("SquareItemList")!
  const _component_SwiperIconList = _resolveComponent("SwiperIconList")!
  const _component_Title = _resolveComponent("Title")!
  const _component_ProductItemList = _resolveComponent("ProductItemList")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Breadcrumb, { class: "my-2.5" })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SquareItemList, {
        recommendedList: _ctx.recommendedList,
        "onUpdate:recommendedList": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.recommendedList) = $event)),
        goIPProductList: _ctx.goIPProductList,
        "onUpdate:goIPProductList": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.goIPProductList) = $event)),
        class: "",
        direction: 'left'
      }, null, 8, ["recommendedList", "goIPProductList"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_SwiperIconList, {
        ipIconList: _ctx.ipIconList,
        "onUpdate:ipIconList": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.ipIconList) = $event)),
        goIPProductList: _ctx.goIPProductList,
        "onUpdate:goIPProductList": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.goIPProductList) = $event)),
        class: "g-container"
      }, null, 8, ["ipIconList", "goIPProductList"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Title, {
        class: "g-title-type2 mt-[60px] sm:mt-[5.34em]",
        title: '授權x聯名'
      }),
      _createVNode(_component_ProductItemList, {
        ipSubList: _ctx.cmdyThemeList,
        "onUpdate:ipSubList": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.cmdyThemeList) = $event)),
        goIPProductList: _ctx.goIPProductList,
        "onUpdate:goIPProductList": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.goIPProductList) = $event)),
        class: "hidden sm:block"
      }, null, 8, ["ipSubList", "goIPProductList"]),
      _createVNode(_component_ProductItemList, {
        ipSubList: _ctx.cmdyThemeList,
        "onUpdate:ipSubList": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.cmdyThemeList) = $event)),
        goIPProductList: _ctx.goIPProductList,
        "onUpdate:goIPProductList": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.goIPProductList) = $event)),
        class: "my-2 mx-4 sm:hidden",
        row: 2
      }, null, 8, ["ipSubList", "goIPProductList"])
    ])
  ], 64))
}