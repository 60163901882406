
import { computed, defineComponent, onMounted, provide, ref, watch } from 'vue'
import ProductClassItemList from '@/components/ProductClassItemList.vue'
import PortfolioItemList from '@/components/PortfolioItemList.vue'
import SearchInput from '@/components/common/SearchInput.vue'
import DatePicker from 'vue-datepicker-next'
import store from '@/composition/store'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import { useToast, POSITION } from 'vue-toastification'
import { getToken } from '@/utils/auth'
import { useRouter } from 'vue-router'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import ModalConfirm from '@/components/modal/ConfirmModal.vue'
import { ConfirmModal } from '@/types/ConfirmModal'
import axios from 'axios'
// import 'vue-datepicker-next/index.css'

export default defineComponent({
  components: {
    ProductClassItemList,
    PortfolioItemList,
    SearchInput,
    ModalConfirm,
    DatePicker
  },
  setup () {
    provide('store', store)
    const toast = useToast()
    const router = useRouter()
    const confirmModal = ref()
    const timeRange = ref(null)
    const langString = ref('zh-tw')
    const dateStart = ref('')
    const dateEnd = ref('')
    const searchName = ref('')
    const portfolioList = ref([] as any[])
    const fetchPortfolioList = (fetchPortfolioListRequestData: any) => {
      store.setShowLoading(true)
      const data = {
        Data: fetchPortfolioListRequestData
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
            requestUrl.getPortfolioList,
          data,
          {
            headers: {
              'api-version': '2.0'
            }
          }
        )
        .then((res) => {
          const resData = res.data.Data
          portfolioList.value = resData.PortfolioList

          store.setShowLoading(false)
        })
        .catch((error) => {
          store.setShowLoading(false)
          console.error(error)
        })
    }
    const editPortfolioName = (portfolio: any) => {
      store.setShowLoading(true)
      const data = {
        Data: {
          PortfolioList: [
            {
              EditorPortfolioId: portfolio.EditorPortfolioId,
              Name: portfolio.Name,
              PageQty: '',
              IsInCart: '',
              IsPrinted: '',
              IsShared: '',
              IsPublished: '',
              PublishDate: '',
              BrowseCount: '',
              Status: ''
            }
          ]
        }
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
            requestUrl.modifyPortfolio,
          data
        )
        .then((res) => {
          const resData = res.data.Data
          toast.success(res.data.Message, {
            position: POSITION.TOP_CENTER
          })
          portfolioList.value.forEach((item, index) => {
            if (item.EditorPortfolioId === portfolio.EditorPortfolioId) {
              item = portfolio
            }
          })

          store.setShowLoading(false)
        })
        .catch((error) => {
          store.setShowLoading(false)
          console.error(error)
        })
    }
    const delPortfolio = (portfolio: any) => {
      try {
        const _confirmModal = confirmModal.value as ConfirmModal
        _confirmModal.createConfirm('確定要刪除作品？', '', false).then(() => {
          store.setShowLoading(true)
          const data = {
            Data: {
              EditorPortfolioIds: portfolio.EditorPortfolioId
            }
          }
          request
            .post(
              window._env_.VUE_APP_STOREBOOK_SERVICE_API +
                requestUrl.delPortfolio,
              data
            )
            .then((res) => {
              const resData = res.data.Data
              portfolioList.value.forEach((item, index) => {
                if (item.EditorPortfolioId === portfolio.EditorPortfolioId) {
                  portfolioList.value.splice(index, 1)
                }
              })
              portfolioList.value = cloneDeep(portfolioList.value)

              toast.success(res.data.Message, {
                position: POSITION.TOP_CENTER
              })
              // alert(res.data.Message)
              store.setShowLoading(false)
            })
            .catch((error) => {
              store.setShowLoading(false)
              console.error(error)
            })
        })
      } catch (err) {
        console.log(err)
      }
    }
    const continueEdit = (portfolio: any) => {
      if (!portfolio.CanEdit) return
      const workSetID = portfolio.EditorPartnerBookId
      const PartnerBookId = portfolio.EditorPartnerBookId
      const orderurl = encodeURIComponent(
        window._env_.VUE_APP_STOREBOOK_SERVICE_API +
          '/orders/add-shopping-cart-from-editor?Token=' +
          getToken() +
          '&PartnerBookId=' +
          PartnerBookId +
          '&CmdyQty=1'
      )
      let exiturl = ''
      let returnurl = ''
      if (window._env_.ENV === 'develop') {
        exiturl = encodeURIComponent(
          'http://localhost:9123' + router.currentRoute.value.fullPath
        )
        returnurl = encodeURIComponent('http://localhost:9123/portfolio')
      } else {
        exiturl = encodeURIComponent(
          window._env_.VUE_APP_GIFT_URL + router.currentRoute.value.fullPath
        )
        returnurl = encodeURIComponent(
          window._env_.VUE_APP_GIFT_URL + '/portfolio'
        )
      }

      window.location.href =
        window._env_.VUE_APP_GIFT_EDITOR_URL +
        `/auth/${workSetID}/ip?orderurl=${orderurl}&exiturl=${exiturl}&returnurl=${returnurl}&edittype=1&distributorid=${store.state.userInfo.Account}`
    }
    const copyPortfolio = async (portfolio: any) => {
      if (!portfolio.CanEdit) return
      try {
        const _confirmModal = confirmModal.value as ConfirmModal
        _confirmModal.createConfirm('確定要複製作品？', '', false).then(() => {
          store.setShowLoading(true)
          const data = {
            Data: {
              EditorPortfolioId: portfolio.EditorPortfolioId
            }
          }
          request
            .post(
              window._env_.VUE_APP_STOREBOOK_SERVICE_API +
                requestUrl.copyPortfolio,
              data
            )
            .then((res) => {
              const resData = res.data.Data
              // portfolioList.value = resData.PortfolioList
              fetchPortfolioList({
                pageNum: 1,
                pageSize: 0,
                dateStart: dateStart.value,
                dateEnd: dateEnd.value,
                editorPortfolioName: searchName.value,
                isShowAll: 0
              })

              store.setShowLoading(false)
            })
            .catch((error) => {
              store.setShowLoading(false)
              console.error(error)
            })

          // const workSetInfoCloneData = {
          //   DistributorId: store.state.userInfo.Account,
          //   TemplateSkuId: portfolio.EditorPartnerTemplateId,
          //   WorkSetID: portfolio.EditorPartnerBookId
          // }
          // axios
          //   .post(
          //     window._env_.VUE_APP_AIPS_URL +
          //       '/UltraAIPSTaskManagerNet/WorkSet/WorkSetInfoClone',
          //     workSetInfoCloneData
          //   )
          //   .then((res) => {
          //     const resData = res.data.Data
          //     if (res.data.Status === 'Success') {
          //       const workSetID = resData.WorkSetID
          //       const data = {
          //         Data: {
          //           PortfolioList: [
          //             {
          //               CmdyId: portfolio.CmdyId,
          //               EditorTempId: portfolio.EditorTempId,
          //               EditorPartnerId: portfolio.EditorPartnerId,
          //               EditorPartnerBookId: workSetID,
          //               Name: portfolio.Name + '-copy',
          //               PageQty: '',
          //               IsInCart: '0',
          //               RefPortfolioId: portfolio.EditorPortfolioId,
          //               RefReason: 'Copy'
          //             }
          //           ]
          //         }
          //       }
          //       request
          //         .post(
          //           window._env_.VUE_APP_STOREBOOK_SERVICE_API +
          //             requestUrl.addPortfolio,
          //           data
          //         )
          //         .then((res) => {
          //           const resData = res.data.Data
          //           fetchPortfolioList({
          //             pageNum: 1,
          //             pageSize: 0,
          //             dateStart: dateStart.value,
          //             dateEnd: dateEnd.value,
          //             editorPortfolioName: searchName.value,
          //             isShowAll: 0
          //           })
          //         })
          //         .catch((error) => {
          //           console.error(error)
          //         })
          //       // addPortfolio(workSetID)
          //       //   .then(() => {
          //       //     const PartnerBookId = workSetID
          //       //     const orderurl = encodeURIComponent(
          //       //       window._env_.VUE_APP_STOREBOOK_SERVICE_API +
          //       //         '/orders/add-shopping-cart-from-editor?Token=' +
          //       //         getToken() +
          //       //         '&PartnerBookId=' +
          //       //         PartnerBookId +
          //       //         '&CmdyQty=1'
          //       //     )
          //       //     let exiturl = ''
          //       //     let returnurl = ''

          //       //     if (window._env_.ENV === 'develop') {
          //       //       exiturl = encodeURIComponent(
          //       //         'http://localhost:9123' + router.currentRoute.value.path
          //       //       )
          //       //       returnurl = encodeURIComponent(
          //       //         'http://localhost:9123/shoppingCart'
          //       //       )
          //       //     } else {
          //       //       exiturl = encodeURIComponent(
          //       //         window._env_.VUE_APP_GIFT_URL +
          //       //           router.currentRoute.value.path
          //       //       )
          //       //       returnurl = encodeURIComponent(
          //       //         window._env_.VUE_APP_GIFT_URL + '/shoppingCart'
          //       //       )
          //       //     }

          //       //     window.location.href =
          //       //       window._env_.VUE_APP_GIFT_EDITOR_URL +
          //       //       `/Portfolio/${workSetID}/ip?orderurl=${orderurl}&exiturl=${exiturl}&returnurl=${returnurl}`
          //       //     // store.setShowLoading(false)
          //       //   })
          //       //   .catch((e) => {
          //       //     toast.error(e.data.Message, {
          //       //       position: POSITION.TOP_CENTER
          //       //     })
          //       //     // alert(e.data.Message)
          //       //     store.setShowLoading(false)
          //       //   })
          //     } else {
          //       toast.warning(res.data.Message, {
          //         position: POSITION.TOP_CENTER
          //       })
          //       // alert(res.data.Message)
          //       store.setShowLoading(false)
          //     }
          //   })
        })
      } catch (err) {
        console.log(err)
      }
    }
    // watch(
    //   () => portfolioList.value,
    //   (newVal) => {
    //     console.log(newVal)
    //   }
    // )
    watch(timeRange, (newVal) => {
      if (timeRange.value !== null) {
        dateStart.value = moment(timeRange.value[0]).format('YYYYMMDDHHmmss')
        dateEnd.value = moment(timeRange.value[1]).format('YYYYMMDDHHmmss')
        fetchPortfolioList({
          pageNum: 1,
          pageSize: 0,
          dateStart: dateStart.value,
          dateEnd: dateEnd.value,
          editorPortfolioName: searchName.value,
          isShowAll: 0
        })
      }
    })
    const getInput = (val: string) => {
      searchName.value = val
      fetchPortfolioList({
        pageNum: 1,
        pageSize: 0,
        dateStart: dateStart.value,
        dateEnd: dateEnd.value,
        editorPortfolioName: val,
        isShowAll: 0
      })
    }
    const disabledAfterToday = (date: any) => {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      return date > today
    }
    onMounted(() => {
      fetchPortfolioList({
        pageNum: 1,
        pageSize: 0,
        dateStart: '',
        dateEnd: '',
        editorPortfolioName: '',
        isShowAll: 0
      })
    })
    return {
      confirmModal,
      timeRange,
      langString,
      dateStart,
      dateEnd,
      searchName,
      portfolioList,
      continueEdit,
      fetchPortfolioList,
      delPortfolio,
      editPortfolioName,
      getInput,
      disabledAfterToday,
      copyPortfolio
    }
  }
})
