
import { ref, defineComponent, provide } from 'vue'
import store from '@/composition/store'
import { useRoute, useRouter } from 'vue-router'
export default defineComponent({
  name: 'g-menu',
  props: {
    menuList: []
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    provide('store', store)
    const selectUrl = ref('')
    const _params = ref({})
    const _query = ref({})
    const goSelectUrl = (targetPageType: any, refId: any, subRefId: any) => {
      switch (targetPageType) {
        case 'CmdyIdea':
          // selectUrl.value = '/inspiration/' + refId
          selectUrl.value = 'inspiration'
          _query.value = {
            cmdyClassId: refId,
            subCmdyClassId: ''
            // dateNow: Date.now()
          }
          break
        case 'CmdyList':
          // selectUrl.value = '/productList/' + refId + '/' + subRefId
          selectUrl.value = 'productList'
          _query.value = {
            cmdyClassId: refId,
            subCmdyClassId: subRefId,
            cmdyTagId: '',
            cmdyId: ''
            // dateNow: Date.now()
          }
          break
        case 'IPList':
          // selectUrl.value = '/ipinspiration'
          selectUrl.value = 'ipinspiration'
          break
        case 'IP':
          // router.push({
          //   name: 'ipproductList',
          //   params: {
          //     shopHallCode: route.params.shopHallCode,
          //     dateNow: Date.now()
          //   }
          // })
          selectUrl.value = 'ipproductList'
          _query.value = {
            shopHallCode: subRefId,
            cmdyClassId: '',
            cmdyTagId: '',
            cmdyId: ''
            // dateNow: Date.now()
          }
          break
        case 'Promotion':
          // router.push({
          //   name: 'ipproductList',
          //   params: {
          //     shopHallCode: route.params.shopHallCode,
          //     dateNow: Date.now()
          //   }
          // })
          selectUrl.value = 'promotionList'
          _query.value = {
            promotionId: refId
            // dateNow: Date.now()
          }
          break
        case 'Customized':
          // selectUrl.value = '/customMade/' + refId + '/' + subRefId
          selectUrl.value = 'customMade'
          _query.value = {
            cmdyClassId: refId,
            subCmdyClassId: subRefId || ''
            // dateNow: Date.now()
          }
          break
      }
      (document.getElementById('app') as HTMLBodyElement).scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      store.setShowMenu(false)
      // router.push({
      //   path: selectUrl.value
      // })
      router.push({
        name: selectUrl.value,
        params: _params.value,
        query: _query.value
      })
      // return selectUrl.value
    }
    return {
      route,
      router,
      store,
      selectUrl,
      goSelectUrl
    }
  }
})
