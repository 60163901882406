
import { defineComponent, onMounted, provide, ref, watch } from 'vue'
import CollapseMenu from '@/components/common/CollapseMenu.vue'
import { useRoute, useRouter } from 'vue-router'
import find from 'lodash/find'
import store from '@/composition/store'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import { clearCookies } from '@/utils/auth'
import { POSITION, useToast } from 'vue-toastification'
import cartStore from '@/composition/cartStore'
import { BaseResponse, CarrierMergeUrl } from '@/types/ResponseType'
export default defineComponent({
  name: 'user-layout',
  components: {
    CollapseMenu
  },
  setup () {
    provide('store', store)
    provide('cartStore', cartStore)
    const toast = useToast()
    const route = useRoute()
    const router = useRouter()
    const collapseMenu = ref(null)
    const selectItem = ref()
    const subSelectItem = ref()
    const showList = ref([] as boolean[])
    const menuClick = (item: any) => {
      showList.value = []

      selectItem.value = item.items
      showList.value[item.id] = !showList.value[item.id]
      if (selectItem.value !== null && selectItem.value !== []) {
        if (collapseMenu.value === null) {
          collapseMenu.value = item.items[0]
          if (item.items[0].path !== undefined) {
            const _query = ref({})
            if (item.items[0].path === 'orderList') {
              _query.value = route.query
            }
            router.push({
              name: item.items[0].path,
              query: _query.value
            })
          }
        }
      } else {
        collapseMenu.value = null
      }

      if (item.path !== undefined) {
        const _query = ref({})
        if (item.path === 'orderList') {
          _query.value = route.query
        }
        router.push({
          name: item.path,
          query: _query.value
        })
      }
    }
    const itemsClick = async (item: any) => {
      if (item.path !== undefined) {
        console.log(item)
        if (item.title === '會員發票載具歸戶') {
          const res = await request.post(window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.getCarrierMergeUrl)

          window.open(res.data.Data.Url, '_blank')
        } else {
          const _query = ref({})
          if (item.path === 'orderList') {
            _query.value = route.query
          }
          router.push({
            name: item.path,
            query: _query.value
          })
        }
      }
    }
    const getSelectItem = () => {
      items.value.forEach((item: any) => {
        if (item.items !== null) {
          item.items.forEach((subItem: any) => {
            if (subItem.path === route.name) {
              selectItem.value = item
              collapseMenu.value = subItem
              // itemClick(subItem)
            }
          })
        } else {
          if (item.path === route.name) {
            selectItem.value = item
          }
        }
      })
      menuClick(selectItem.value)
    }
    const logout = () => {
      request
        .post(window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.logout)
        .then(() => {
          store.cleanUserInfo()
          clearCookies()
          store.setIsLogin(false)
          store.setShowProfile(false)
          toast.success('已登出', {
            position: POSITION.TOP_CENTER
          })
          // alert('已登出')
          router.push({
            name: 'home'
          })
          cartStore.fetchShoppingCartList()
          store.cleanUserInfo()
        })
        .catch((error) => {
          console.error(error)
        })
      if (window.FB.getAccessToken() !== null) {
        window.FB.api('/me/permissions', 'delete', null, () =>
          window.FB.logout()
        )
      }
    }
    watch(
      () => route.path,
      (newVal: any) => {
        if (newVal.includes('/user/')) {
          getSelectItem()
        }
      }
    )
    const items = ref([
      {
        id: 1,
        title: '訂單記錄',
        items: null,
        path: 'orderList'
      },
      {
        id: 2,
        title: '帳號設定',
        items: [
          {
            id: '2-1',
            title: '基本設定',
            path: 'setting'
          },
          {
            id: '2-2',
            title: '管理地址',
            path: 'address'
          },
          {
            id: '2-3',
            title: '變更密碼',
            path: 'changePassword'
          },
          {
            id: '2-4',
            title: '會員發票載具歸戶',
            path: ''
          }
        ]
      },
      {
        id: 3,
        title: '聯絡客服',
        items: null,
        path: 'contact'
      }
    ])
    onMounted(() => {
      getSelectItem()
    })
    return {
      store,
      items,
      collapseMenu,
      selectItem,
      subSelectItem,
      showList,
      getSelectItem,
      menuClick,
      itemsClick,
      logout
    }
  }
})
