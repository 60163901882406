export const initFacebook = () => {
  window.fbAsyncInit = function () {
    /* global FB */
    window.FB.init({
      appId: '1520622651717550',
      cookie: true,
      xfbml: true,
      version: 'v15.0'
    })
    window.FB.AppEvents.logPageView()
  }
}
