import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a1fe836"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "rectangle-item"
}
const _hoisted_2 = { class: "rectangle-photo-content" }
const _hoisted_3 = {
  key: 0,
  class: "description-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.image)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "hidden sm:block product-img bg-image-style",
            style: _normalizeStyle({
          backgroundImage: 'url(' + _ctx.image.PcWeb + ')',
        })
          }, null, 4),
          _createElementVNode("div", {
            class: "sm:hidden product-img bg-image-style",
            style: _normalizeStyle({
          backgroundImage: 'url(' + _ctx.image.MobileWeb + ')',
        })
          }, null, 4)
        ]),
        (_ctx.title !== '')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.title), 1))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}