import { reactive, computed, readonly } from 'vue'
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import { getOrderList } from '@/types/RequestType'

// 對應原本的 state
const stateRef: any = reactive({
  order: {},
  orderList: []
})

// 對應原本的 mutations
const setOrder = (val: any) => {
  stateRef.order = val
}
const setOrderList = (val: any) => {
  stateRef.orderList = val
}
const fetchOrder = (orderId:string): Promise<{
  order: any;
}> => {
  return new Promise((resolve, reject) => {
    const data = {
      Data: {
        OrderId: orderId
      }
    }
    request
      .post(
        window._env_.VUE_APP_STOREBOOK_SERVICE_API +
              requestUrl.getOrder,
        data,
        {
          headers: {
            'api-version': '2.0'
          }
        }
      )
      .then((res) => {
        const resData = res.data.Data
        setOrder(resData)
        resolve({ order: resData })
      })
      .catch((error) => {
        reject(error)
        console.error(error)
      })
  })
}
const fetchOrderList = (fetchOrderListRequestData:getOrderList): Promise<{
  status: any;
}> => {
  return new Promise((resolve, reject) => {
    const data = {
      Data: fetchOrderListRequestData
    }
    request
      .post(
        window._env_.VUE_APP_STOREBOOK_SERVICE_API +
              requestUrl.getOrderList,
        data,
        {
          headers: {
            'api-version': '3.0'
          }
        }
      )
      .then((res) => {
        const resData = res.data.Data
        setOrderList(resData)
      })
      .catch((error) => {
        reject(error)
        console.error(error)
      })
  })
}

export default {
  state: readonly(stateRef),
  setOrder,
  setOrderList,
  fetchOrder,
  fetchOrderList
}
