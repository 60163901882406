import Promise from 'bluebird'
import reduce from 'lodash/reduce'

const mappingCodeParser = (mappingCode: string): Promise<any> => {
  // console.log(mappingCode)
  const propsArr = mappingCode.split('###')
  return Promise.reduce(
    propsArr,
    (result, item) => {
      const [key, value] = item.split('=')
      result[key] = value
      return result
    },
    {} as any
  )
}

const mappingCodeCombine = (optionModel?: object): Promise<string> => {
  const combineString = reduce(
    optionModel as any,
    async (result: any, value: string, key: string) => {
      const _result = await result
      if (_result === '') return `${key}=${value}`
      return _result + '###' + `${key}=${value}`
    },
    Promise.resolve('')
  ) as Promise<string>
  return combineString
}

export { mappingCodeParser, mappingCodeCombine }
