
import { defineComponent } from 'vue'

// 讀出所有svg
const requireAll = (requireContext: any) =>
  requireContext.keys().map(requireContext)
const req = require.context('@/assets/img/svg/icon', false, /\.svg$/)
requireAll(req)

export default defineComponent({
  name: 'g-icon',
  props: {
    width: {
      type: String,
      default: '24px'
    },
    iconName: {
      type: String,
      required: true
    },
    customClass: {
      type: String,
      default: ''
    }
  }
})
