
import { defineComponent, onMounted, provide, ref } from 'vue'
import liff from '@line/liff'
// import { setLiffInfo } from '@/plugins/line'
import store from '@/composition/store'
import cartStore from '@/composition/cartStore'
import { useGtm } from '@gtm-support/vue-gtm'
import { useRoute, useRouter } from 'vue-router'
import { unescapeHTML } from '@/utils/utils'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import { BaseResponse, CommodityDetailResponse } from '@/types/ResponseType'
import { getToken } from '@/utils/auth'
export default defineComponent({
  components: {},
  setup () {
    provide('cartStore', cartStore)
    const route = useRoute()
    const router = useRouter()
    const gtm = useGtm()
    const cmdyId = ref('')
    const selectItemDetail = ref({} as any)
    const getPortfolio = (partnerBookId: any) => {
      store.setShowLoading(true)
      const reqData = {
        PartnerBookId: partnerBookId
      }

      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
            requestUrl.getGiftPortfolioByPartnerBookId,
          reqData
        )
        .then((res) => {
          const resData = res.data.Data
          getSelectItemDetail(resData.CmdyId)
          store.setShowLoading(false)
        })
        .catch((error) => {
          console.log(error)

          router.push({
            name: 'shoppingCart'
          })
          store.setShowLoading(false)
        })
    }
    const getSelectItemDetail = (parameter: any, type = 'cmdyid') => {
      if (!parameter) return

      store.setShowLoading(true)
      const reqData = {
        Data: { CmdyId: parameter, MappingCode: parameter, AccessId: '' }
      }
      const accessId = sessionStorage.accessId || ''
      const apiVersion = '5.0'
      reqData.Data.AccessId = accessId
      const getUrl =
        window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.getCmdyById

      request
        .post<BaseResponse<CommodityDetailResponse>>(getUrl, reqData, {
          headers: {
            'api-version': apiVersion
          }
        })
        .then((req) => {
          const resData = req.data
          selectItemDetail.value = resData.Data
          addShoppingCart(selectItemDetail.value)
          store.setShowLoading(false)
        })
        .catch((error) => {
          console.log(error)
          router.push({
            name: 'shoppingCart'
          })
          store.setShowLoading(false)
        })
    }
    const addShoppingCart = (cmdy: any) => {
      store.setShowLoading(true)
      cmdy.CmdyQty = 1
      // cartStore.addShoppingCart(cmdy, 'portfolio')
      if (gtm?.enabled()) {
        window.dataLayer?.push({ ecommerce: null }) // Clear the previous ecommerce object.
        window.dataLayer?.push({
          event: 'add_to_cart',
          ecommerce: {
            currency: 'TWD',
            value: cmdy.MinUnitFeeReal,
            items: [
              {
                item_id: cmdy.CmdyId,
                item_name: unescapeHTML(cmdy.Name),
                price: cmdy.MinUnitFeeReal,
                quantity: cmdy.CmdyQty
              }
            ]
          }
        })
      }
      window.fbq('track', 'AddToCart', {
        content_ids: [cmdy.CmdyId],
        content_name: cmdy.Name,
        contents: [{ id: cmdy.CmdyId }]
      })
      router.push({
        name: 'shoppingCart'
      })
      store.setShowLoading(false)
    }
    onMounted(() => {
      getPortfolio(route.query.partnerBookId)
    })
    return {
      route,
      getPortfolio,
      getSelectItemDetail,
      addShoppingCart
    }
  }
})
