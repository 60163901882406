const clickOutSide = {
  beforeMount (el: any, binding: any) {
    function documentHandler (e: any) {
      if (el.contains(e.target)) return false
      // console.log('判斷指令中是否綁定了函數', binding)
      if (binding.value) {
        // console.log('有綁定')
        binding.value(e)
      }
    }
    el.__vueClickOutside__ = documentHandler
    document.addEventListener('click', documentHandler)
  },
  unmounted (el: any) {
    document.removeEventListener('click', el.__vueClickOutside__)
    delete el.__vueClickOutside__
  }
}
const directives = {
  install: function (app: any) {
    app.directive('clickOutSide', clickOutSide)
  }
}
export default directives
