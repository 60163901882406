import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28ad1dca"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "hidden sm:flex flex-row space-x-3"
}
const _hoisted_2 = { class: "flex flex-col basis-1/2" }
const _hoisted_3 = { class: "flex flex-row basis-1/2 pb-1.5 flex-wrap" }
const _hoisted_4 = { class: "flex flex-col basis-1/2" }
const _hoisted_5 = { class: "flex flex-row basis-1/2 pt-1.5 flex-wrap" }
const _hoisted_6 = {
  key: 1,
  class: "sm:hidden flex flex-col"
}
const _hoisted_7 = { class: "flex flex-col" }
const _hoisted_8 = { class: "flex flex-row" }
const _hoisted_9 = { class: "flex flex-col" }
const _hoisted_10 = { class: "flex flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SquareItemFullImage = _resolveComponent("SquareItemFullImage")!
  const _component_RectangleItemFullImage = _resolveComponent("RectangleItemFullImage")!
  const _component_RectangleItem = _resolveComponent("RectangleItem")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.recommendedListRef)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_SquareItemFullImage, {
                image: _ctx.recommendedListRef[0].ChildItems[0].Image.PcWeb,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goProductDetail(_ctx.recommendedListRef[0].ChildItems[0].RefIdSet))),
                class: "basis-1/2 pr-1.5"
              }, null, 8, ["image"]),
              _createVNode(_component_SquareItemFullImage, {
                image: _ctx.recommendedListRef[0].ChildItems[1].Image.PcWeb,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goProductDetail(_ctx.recommendedListRef[0].ChildItems[1].RefIdSet))),
                class: "basis-1/2 pl-1.5"
              }, null, 8, ["image"])
            ]),
            _createVNode(_component_RectangleItemFullImage, {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goIPProductList(_ctx.recommendedListRef[0].RefIdSet.ShopHallCode))),
              image: _ctx.recommendedListRef[0].Image,
              class: "basis-1/2 pt-1.5"
            }, null, 8, ["image"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_RectangleItemFullImage, {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.goIPProductList(_ctx.recommendedListRef[1].RefIdSet.ShopHallCode))),
              image: _ctx.recommendedListRef[1].Image,
              class: "basis-1/2 pb-1.5"
            }, null, 8, ["image"]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_SquareItemFullImage, {
                image: _ctx.recommendedListRef[1].ChildItems[0].Image.PcWeb,
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.goProductDetail(_ctx.recommendedListRef[1].ChildItems[0].RefIdSet))),
                class: "basis-1/2 pr-1.5"
              }, null, 8, ["image"]),
              _createVNode(_component_SquareItemFullImage, {
                image: _ctx.recommendedListRef[1].ChildItems[1].Image.PcWeb,
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.goProductDetail(_ctx.recommendedListRef[1].ChildItems[1].RefIdSet))),
                class: "basis-1/2 pl-1.5"
              }, null, 8, ["image"])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.recommendedListRef)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_RectangleItemFullImage, {
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.goIPProductList(_ctx.recommendedListRef[0].RefIdSet.ShopHallCode))),
              image: _ctx.recommendedListRef[0].Image,
              class: "pb-1.5"
            }, null, 8, ["image"]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_SquareItemFullImage, {
                image: _ctx.recommendedListRef[0].ChildItems[0].Image.MobileWeb,
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.goProductDetail(_ctx.recommendedListRef[0].ChildItems[0].RefIdSet))),
                class: "basis-1/2 pr-[3px] pb-1.5"
              }, null, 8, ["image"]),
              _createVNode(_component_SquareItemFullImage, {
                image: _ctx.recommendedListRef[0].ChildItems[1].Image.MobileWeb,
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.goProductDetail(_ctx.recommendedListRef[0].ChildItems[1].RefIdSet))),
                class: "basis-1/2 pl-[3px] pb-1.5"
              }, null, 8, ["image"])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_RectangleItemFullImage, {
              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.goIPProductList(_ctx.recommendedListRef[1].RefIdSet.ShopHallCode))),
              image: _ctx.recommendedListRef[1].Image,
              class: "pb-1.5"
            }, null, 8, ["image"]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_SquareItemFullImage, {
                image: _ctx.recommendedListRef[1].ChildItems[0].Image.MobileWeb,
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.goProductDetail(_ctx.recommendedListRef[1].ChildItems[0].RefIdSet))),
                class: "basis-1/2 pr-[3px] pb-1.5"
              }, null, 8, ["image"]),
              _createVNode(_component_SquareItemFullImage, {
                image: _ctx.recommendedListRef[1].ChildItems[1].Image.MobileWeb,
                onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.goProductDetail(_ctx.recommendedListRef[1].ChildItems[1].RefIdSet))),
                class: "basis-1/2 pl-[3px] pb-1.5"
              }, null, 8, ["image"])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_RectangleItem)
  ], 64))
}