
import { defineComponent, onMounted, ref, provide, watch, nextTick } from 'vue'
import ModalBase from '@/components/common/ModalBase.vue'
import IConButton from '@/components/common/IConButton.vue'
import Input from '@/components/common/Input.vue'
import CheckBox from '@/components/common/CheckBox.vue'
import Btn from '@/components/common/Btn.vue'
import Radio from '@/components/common/Radio.vue'
import Password from '@/components/common/Password.vue'
import { setToken, setRefreshToken } from '@/utils/auth'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import { BaseResponse, Session } from '@/types/ResponseType'
import store from '@/composition/store'
import { useRouter, useRoute } from 'vue-router'
import axios from 'axios'
import cartStore from '@/composition/cartStore'
import { isEmail, numberInput } from '@/utils/utils'
import { useToast, POSITION } from 'vue-toastification'
import productStore from '@/composition/productStore'
// import { Form } from 'vee-validate'

enum LoginModalContentType {
  LOGIN = '1',
  FORGOT_PASSWORD = '2',
  REGISTER = '3',
}
enum AccountContentType {
  G_GIFT = 'G-Gift',
  FACEBOOK = 'Facebook',
  LINE = 'Line',
}

enum ForgotPasswordStep {
  STEP1 = '1',
  STEP2 = '2',
  STEP3 = '3',
}
enum LoginStep {
  STEP1 = '1',
  STEP2 = '2',
  STEP3 = '3',
}
enum RegisterStep {
  STEP1 = '1',
  STEP2 = '2',
  STEP3 = '3',
}
export default defineComponent({
  name: 'g-login-modal',
  emits: ['cancel', 'ok'],
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ModalBase,
    Input,
    // CheckBox,
    Btn,
    // AddressInput,
    // Radio,
    // DatePicker,
    IConButton,
    Password
  },
  setup () {
    provide('store', store)
    provide('cartStore', cartStore)
    provide('productStore', productStore)
    const router = useRouter()
    const route = useRoute()
    const toast = useToast()
    const accountInputRef = ref()
    const passwordInputRef = ref()
    const checked = ref(false)
    const btnEnable = ref(true)
    const langString = ref('zh-tw')
    const descriptionModal = ref(false)
    const disabled = ref(false)
    const timeRange = ref(0)
    const contentType = ref(LoginModalContentType.LOGIN)
    const accountType = ref(AccountContentType.G_GIFT)
    const forgetPasswordStep = ref(ForgotPasswordStep.STEP1)
    const loginStep = ref(LoginStep.STEP1)
    const registerStep = ref(RegisterStep.STEP1)

    const account = ref('')
    const verifyCode = ref('')
    const password = ref('')
    const resetPassword = ref('')
    const confirmResetPassword = ref('')
    const isRegister = ref(false)
    const memberAuthCodeId = ref('')
    const resetStatus = () => {
      contentType.value = LoginModalContentType.LOGIN
      accountType.value = AccountContentType.G_GIFT
      forgetPasswordStep.value = ForgotPasswordStep.STEP1
      loginStep.value = LoginStep.STEP1
      registerStep.value = RegisterStep.STEP1
      account.value = ''
      verifyCode.value = ''
      password.value = ''
      isRegister.value = false
      memberAuthCodeId.value = ''
      resetPassword.value = ''
      confirmResetPassword.value = ''
    }
    const timer = () => {
      if (timeRange.value > 0) {
        timeRange.value--
        setTimeout(timer, 1000)
      } else {
        disabled.value = false
      }
    }
    const timerStart = () => {
      timeRange.value = 60
      timer()
    }

    const verifyRegisterMobile = () => {
      if (account.value === '') {
        toast.warning('請填寫手機號碼', {
          position: POSITION.TOP_CENTER
        })
        // alert('請填寫手機號碼')
      } else {
        if (btnEnable.value === true) {
          btnEnable.value = false
          const data = {
            Data: {
              SourceSys: 'G-Gift',
              Mobile: account.value,
              IsSendingVerifySMS: true
            }
          }
          request
            .post(
              window._env_.VUE_APP_STOREBOOK_SERVICE_API +
                requestUrl.verifyRegisterMobile,
              data
            )
            .then((res) => {
              const resData = res.data.Data
              if (resData.IsRegister) {
                loginStep.value = LoginStep.STEP2
                nextTick(() => {
                  passwordInputRef.value?.inputRef.focus()
                })
              } else {
                contentType.value = LoginModalContentType.REGISTER
                timerStart()
              }
              isRegister.value = resData.isRegister
              btnEnable.value = true
            })
            .catch((error) => {
              toast.error(error.data.Message, {
                position: POSITION.TOP_CENTER
              })
              // alert(error.data.Message)
              console.error(error)
              btnEnable.value = true
            })
        }
      }
    }
    const sendingRegisterSMS = () => {
      const data = {
        Data: {
          SourceSys: 'G-Gift',
          Mobile: account.value
        }
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
            requestUrl.sendingRegisterSMS,
          data
        )
        .then((res) => {
          const resData = res.data
          timerStart()
        })
        .catch((error) => {
          toast.error(error.data.Message, {
            position: POSITION.TOP_CENTER
          })
          // alert(error.data.Message)
          console.error(error)
        })
    }
    const verifyRegisterSMSCode = () => {
      if (verifyCode.value === '') {
        toast.warning('請填寫驗證碼', {
          position: POSITION.TOP_CENTER
        })
        // alert('請填寫驗證碼')
      } else {
        const data = {
          Data: {
            SourceSys: 'G-Gift',
            Mobile: account.value,
            Code: verifyCode.value
          }
        }
        request
          .post(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API +
              requestUrl.verifyRegisterSMSCode,
            data
          )
          .then((res) => {
            const resData = res.data
            registerStep.value = RegisterStep.STEP2
          })
          .catch((error) => {
            toast.error(error.data.Message, {
              position: POSITION.TOP_CENTER
            })
            // alert(error.data.Message)
            console.error(error)
          })
      }
    }
    const registerGiftMember = () => {
      if (password.value === '') {
        return toast.warning('密碼不許為空', {
          position: POSITION.TOP_CENTER
        })
      }
      if (password.value.length < 8 || password.value.length > 12) {
        return toast.warning('密碼長度不符合', {
          position: POSITION.TOP_CENTER
        })
      }
      const data = {
        Data: {
          SourceSys: 'G-Gift',
          Mobile: account.value,
          Password: password.value
        }
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
            requestUrl.registerGiftMember,
          data
        )
        .then((res) => {
          const resData = res.data
          login()
        })
        .catch((error) => {
          toast.error(error.data.Message, {
            position: POSITION.TOP_CENTER
          })
          // alert(error.data.Message)
          console.error(error)
        })
    }
    const verifyTpaRegisterSMSCode = () => {
      if (verifyCode.value === '') {
        toast.warning('請填寫驗證碼', {
          position: POSITION.TOP_CENTER
        })
        // alert('請填寫驗證碼')
      } else {
        const data = {
          Data: {
            SourceSys: 'G-Gift',
            TPASource: 'Facebook',
            Mobile: account.value,
            Code: verifyCode.value
          }
        }
        request
          .post(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API +
              requestUrl.verifyTpaRegisterSMSCode,
            data
          )
          .then((res) => {
            const resData = res.data
            toast.success('已完成FB登入', {
              position: POSITION.TOP_CENTER
            })
            // alert('已完成FB登入')
            setToken(resData.Data.AccessToken)
            setRefreshToken(resData.Data.RefreshToken)
            store.fetchUserInfo().then(() => {
              store.setShowLoginModal(false)
              store.setIsLogin(true)
              router.push({ path: store.state.nextUrl })
              store.setShowMenu(false)
              resetStatus()
            })
          })
          .catch((error) => {
            toast.error(error.data.Message, {
              position: POSITION.TOP_CENTER
            })
            // alert(error.data.Message)
            console.error(error)
          })
      }
    }
    const getResetPasswordAuthCode = () => {
      const data = {
        Data: {
          Mobile: account.value
        }
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
            requestUrl.getResetPasswordAuthCode,
          data
        )
        .then((res) => {
          const resData = res.data
          verifyCode.value = ''
          contentType.value = LoginModalContentType.FORGOT_PASSWORD
          timerStart()
        })
        .catch((error) => {
          toast.error(error.data.Message, {
            position: POSITION.TOP_CENTER
          })
          // alert(error.data.Message)
          console.error(error)
        })
    }
    const verifyResetPasswordAuthCode = () => {
      if (verifyCode.value === '') {
        return toast.warning('驗證碼為空', {
          position: POSITION.TOP_CENTER
        })
      }
      const data = {
        Data: {
          Mobile: account.value,
          AuthCode: verifyCode.value
        }
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
            requestUrl.verifyResetPasswordAuthCode,
          data
        )
        .then((res) => {
          const resData = res.data.Data
          memberAuthCodeId.value = resData.MemberAuthCodeId
          forgetPasswordStep.value = ForgotPasswordStep.STEP3

          // verifyCode.value = ''
          // contentType.value = LoginModalContentType.FORGOT_PASSWORD
        })
        .catch((error) => {
          verifyCode.value = ''
          toast.error(error.data.Message, {
            position: POSITION.TOP_CENTER
          })
          // alert(error.data.Message)
          console.error(error)
        })
    }
    const resetMemberPassword = () => {
      if (resetPassword.value === '' || confirmResetPassword.value === '') {
        return toast.warning('密碼不許為空', {
          position: POSITION.TOP_CENTER
        })
      }
      if (
        confirmResetPassword.value.length < 8 ||
        confirmResetPassword.value.length > 12 ||
        resetPassword.value.length < 8 ||
        resetPassword.value.length > 12
      ) {
        return toast.warning('密碼長度不符合', {
          position: POSITION.TOP_CENTER
        })
      }
      if (resetPassword.value !== confirmResetPassword.value) {
        return toast.warning('您輸入的密碼不匹配。', {
          position: POSITION.TOP_CENTER
        })
      }
      const data = {
        Data: {
          Mobile: account.value,
          MemberAuthCodeId: memberAuthCodeId.value,
          NewPassword: resetPassword.value
        }
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
            requestUrl.resetGiftMemberPassword,
          data
        )
        .then((res) => {
          toast.success('密碼已設定完成，請重新登入', {
            position: POSITION.TOP_CENTER
          })
          resetStatus()
        })
        .catch((error) => {
          verifyCode.value = ''
          toast.error(error.data.Message, {
            position: POSITION.TOP_CENTER
          })
          console.error(error)
        })
    }
    const login = () => {
      if (password.value === '') {
        return toast.warning('密碼不許為空', {
          position: POSITION.TOP_CENTER
        })
      }
      if (password.value.length < 8 || password.value.length > 12) {
        return toast.warning('密碼長度不符合', {
          position: POSITION.TOP_CENTER
        })
      }
      store.setShowLoading(true)
      const data = {
        Data: {
          Mobile: account.value,
          Password: password.value
        }
      }
      request
        .post<BaseResponse<Session>>(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
            requestUrl.loginGiftMember,
          data
        )
        .then((req) => {
          const resData = req.data
          setToken(resData.Data.AccessToken)
          setRefreshToken(resData.Data.RefreshToken)
          // alert(resData)
          // EventBus.emit((eventTypes.SHOW_MESSAGE, resData.Message))
          // store.setIsLogin(true)
          store.fetchUserInfo().then(() => {
            store.setShowLoginModal(false)
            store.setIsLogin(true)
            router.push({ path: store.state.nextUrl })
            store.setShowMenu(false)
            resetStatus()
            cartStore.fetchShoppingCartList()
            window.setTimeout(() => {
              store.setShowLoading(false)
            }, 1000)
            // store.setShowLoading(false)
          })
        })
        .catch((error) => {
          toast.error(error.data.Message, {
            position: POSITION.TOP_CENTER
          })
          // alert(error.data.Message)
          // alert(error.response.data.Message)
          console.error(error)
          store.setShowLoading(false)
        })
    }
    async function checkFBLoginState () {
      // document.location.href = 'https://www.facebook.com/v15.0/dialog/oauth?client_id=1520622651717550&redirect_uri=https://localhost:9123/'
      // axios.get('/test/v15.0/dialog/oauth?client_id=1520622651717550&redirect_uri=https://localhost:9123/')
      //   .then((res) => {
      //     console.log(res)
      //   })
      // login with facebook then authenticate with the API to get a JWT auth token

      const { authResponse } = await new Promise(window.FB.login)
      if (!authResponse) {
        // window.FB.init({
        //   appId: '1520622651717550',
        //   cookie: true,
        //   xfbml: true,
        //   version: 'v15.0'
        // })
        return
      }
      // console.log(authResponse.accessToken)
      // console.log(authResponse.signedRequest)
      const data = {
        Data: {
          SourceSys: 'G-Gift',
          TPASource: 'Facebook',
          TPAAccessToken: authResponse.accessToken
        }
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
            requestUrl.tpaLoginGiftMember,
          data
        )
        .then((res) => {
          const resData = res.data.Data
          if (resData.IsMobileVerify) {
            setToken(resData.AccessToken)
            setRefreshToken(resData.RefreshToken)
            store.fetchUserInfo().then(() => {
              store.setShowLoginModal(false)
              store.setIsLogin(true)
              router.push({ path: store.state.nextUrl })
              store.setShowMenu(false)
              resetStatus()
            })
          } else {
            setToken(resData.AccessToken)
            setRefreshToken(resData.RefreshToken)
            contentType.value = LoginModalContentType.REGISTER
            accountType.value = AccountContentType.FACEBOOK
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
    // const FBLogout = () => {
    //   window.FB.api('/me/permissions', 'delete', null, () =>
    //     window.FB.logout()
    //   )
    // }
    watch(
      () => store.state.showLoginModal,
      (newVal) => {
        if (newVal === true) {
          nextTick(() => {
            accountInputRef.value?.inputRef.focus()
          })
        }
      }
    )
    onMounted(() => {
      // login()
    })
    return {
      store,
      cartStore,
      checked,
      langString,
      timeRange,
      accountInputRef,
      passwordInputRef,
      LoginModalContentType,
      AccountContentType,
      ForgotPasswordStep,
      RegisterStep,
      LoginStep,
      contentType,
      accountType,
      forgetPasswordStep,
      loginStep,
      registerStep,
      descriptionModal,
      account,
      verifyCode,
      password,
      resetPassword,
      confirmResetPassword,
      login,
      timer,
      timerStart,
      checkFBLoginState,
      verifyRegisterMobile,
      sendingRegisterSMS,
      verifyRegisterSMSCode,
      registerGiftMember,
      resetStatus,
      verifyTpaRegisterSMSCode,
      getResetPasswordAuthCode,
      verifyResetPasswordAuthCode,
      resetMemberPassword
    }
  }
})
