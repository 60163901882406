
import { computed, defineComponent, ref } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Swiper as SwiperType } from 'swiper/types'
import { Autoplay, Navigation } from 'swiper'
import Title from '@/components/common/Title.vue'
import SwiperNavBtn from '@/components/common/SwiperNavBtn.vue'
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/navigation'

export default defineComponent({
  name: 'g-swiper-product-image',
  emits: ['goProductDetail', 'update:recommendedGroups', 'goProductList'],
  props: {
    recommendedGroups: {
      type: Array,
      default: [] as any
    },
    autoPlay: {
      type: [Object, Boolean],
      require: false,
      default: () => {
        return { delay: 5000 }
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    Title,
    SwiperNavBtn
  },
  setup (props, context) {
    const { emit } = context
    const timestamp = ref(Date.now())
    const controlledSwiper = ref(null as SwiperType | null)

    const prevClick = () => {
      if (controlledSwiper.value) {
        controlledSwiper.value.slidePrev(500, true)
      }
    }

    const nextClick = () => {
      if (controlledSwiper.value) {
        controlledSwiper.value.slideNext(500, true)
      }
    }
    const setControlledSwiper = (swiper: SwiperType) => {
      controlledSwiper.value = swiper
    }
    const recommendedGroupsRef = computed({
      get: () => props.recommendedGroups,
      set: (val: any) => {
        emit('update:recommendedGroups', val)
      }
    })

    return {
      modules: [Autoplay, Navigation],
      timestamp,
      controlledSwiper,
      setControlledSwiper,
      prevClick,
      nextClick,
      recommendedGroupsRef
    }
  }
})
