import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "g-breadcrumb flex my-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ol", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.route.meta.breadcrumbs, (breadcrumb) => {
      return (_openBlock(), _createElementBlock("li", {
        key: breadcrumb,
        class: "mx-1 first:ml-0 flex text-xs items-center text-breadcrumb-color"
      }, _toDisplayString(breadcrumb.title), 1))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbList, (breadcrumbProps) => {
      return (_openBlock(), _createElementBlock("li", {
        key: breadcrumbProps,
        class: "mx-1 first:ml-0 flex text-xs items-center text-breadcrumb-color"
      }, _toDisplayString(breadcrumbProps.title), 1))
    }), 128))
  ]))
}