
import {
  defineComponent,
  onMounted,
  ref,
  watch,
  toRef,
  provide,
  nextTick
} from 'vue'
import SearchInput from '@/components/common/SearchInput.vue'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import store from '@/composition/store'
import { clearCookies } from '@/utils/auth'
import router from '@/router'
import { useToast, POSITION } from 'vue-toastification'
import map from 'lodash/map'
import cloneDeep from 'lodash/cloneDeep'
import { unescapeHTML } from '@/utils/utils'
import ProductItemList from '@/components/ProductItemList.vue'
import ProductItem from '@/components/ProductItem.vue'
import productStore from '@/composition/productStore'
export default defineComponent({
  name: 'g-mobile-menu',
  props: {
    menuList: []
  },
  components: {
    // ProductItemList,
    ProductItem,
    SearchInput
  },
  setup (props) {
    provide('store', store)
    provide('productStore', productStore)
    const toast = useToast()
    const level = ref(1)
    const menuListRef = toRef(props, 'menuList')
    const subMenuList = ref([] as any[])
    const thirdMenuList = ref(null)
    const selectedMenu = ref({})
    const selectedSubMenu = ref({})
    const inputRef = ref()
    const setSubMenuList = (val: any) => {
      const _subMenuList = map(val, (item: any) => {
        item.Selected = false

        return item
      })
      subMenuList.value = cloneDeep(_subMenuList)
    }
    const setThirdMenuList = (val: any) => {
      thirdMenuList.value = val
    }
    const selectUrl = ref('')
    const _params = ref({})
    const _query = ref({})
    const firstLevelGoSelectUrl = (menuItem: any) => {
      if (menuItem.TargetPageType === 'QA') {
        goSelectUrl(menuItem.TargetPageType, '', '')
      } else {
        level.value = 2
        selectedMenu.value = menuItem
        setSubMenuList(menuItem.ChildMenus)
      }
    }
    const goSelectUrl = (targetPageType: any, refId: any, subRefId: any) => {
      switch (targetPageType) {
        case 'CmdyIdea':
          // selectUrl.value = '/inspiration/' + refId
          selectUrl.value = 'inspiration'
          _query.value = {
            cmdyClassId: refId,
            subCmdyClassId: ''
            // dateNow: Date.now()
          }
          break
        case 'CmdyList':
          // selectUrl.value = '/productList/' + refId + '/' + subRefId
          selectUrl.value = 'productList'
          _query.value = {
            cmdyClassId: refId,
            subCmdyClassId: subRefId,
            cmdyTagId: '',
            cmdyId: ''
            // dateNow: Date.now()
          }
          break
        case 'IPList':
          // selectUrl.value = '/ipinspiration'
          selectUrl.value = 'ipinspiration'
          break
        case 'IP':
          // router.push({
          //   name: 'ipproductList',
          //   params: {
          //     shopHallCode: route.params.shopHallCode,
          //     dateNow: Date.now()
          //   }
          // })
          selectUrl.value = 'ipproductList'
          _query.value = {
            shopHallCode: subRefId,
            cmdyClassId: '',
            cmdyTagId: '',
            cmdyId: ''
            // dateNow: Date.now()
          }
          break
        case 'Customized':
          // selectUrl.value = '/customMade/' + refId + '/' + subRefId
          selectUrl.value = 'customMade'
          _query.value = {
            cmdyClassId: refId,
            subCmdyClassId: subRefId || ''
            // dateNow: Date.now()
          }

          break
        case 'QA':
          // selectUrl.value = '/customMade/' + refId + '/' + subRefId
          selectUrl.value = 'faq'
          break
      }
      (document.getElementById('app') as HTMLBodyElement).scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      store.setShowMenu(false)
      level.value = 1
      router.push({
        name: selectUrl.value,
        params: _params.value,
        query: _query.value
      })
      // return selectUrl.value
    }
    const logout = () => {
      request
        .post(window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.logout)
        .then(() => {
          store.cleanUserInfo()
          clearCookies()
          store.setIsLogin(false)
          toast.success('已登出', {
            position: POSITION.TOP_CENTER
          })
          // alert('已登出')
          router.push({
            name: 'home'
          })
          store.setShowMenu(false)
          store.fetchUserInfo()
        })
        .catch(() => {
          // console.error(error.response.data.Message)
        })
    }
    const goToPage = (nextPage: string, isNeedLogin: boolean) => {
      // if (isNeedLogin === true) {
      //   if (store.state.isLogin) {
      //     router.push({
      //       name: nextPage
      //     })
      //   } else {
      //     store.setShowLoginModal(true)
      //   }
      // } else {
      //   router.push({
      //     name: nextPage
      //   })
      // }
      store.setShowMenu(false)
      if (nextPage === 'QA') {
        router.push({
          name: 'faq'
        })
      } else if (nextPage === 'search') {
        router.push({
          name: nextPage,
          params: {
            searchName: searchName.value
          }
        })
      } else {
        router.push({
          name: nextPage,
          params: {
            dateNow: Date.now()
          }
        })
      }
    }
    // onMounted(() => {
    //   document.body.classList.add('modal-open')
    // })

    // onUnmounted(() => {
    //   document.body.classList.remove('modal-open')
    // })
    // watch(
    //   () => store.state.showMenu,
    //   (newVal, oldVal) => {
    //     if (!newVal) {
    //       document.body.classList.add('modal-open')
    //     }
    //   }
    // )
    // watch(
    //   () => level.value,
    //   (newVal) => {
    //     console.log(newVal)
    //     console.log(subMenuList.value)
    //     const _subMenuList = map(subMenuList.value, (item: any) => {
    //       item.Selected = false

    //       return item
    //     })
    //     subMenuList.value = _subMenuList
    //   }
    // )
    const closeRecItem = () => {
      // alert(store.state.showMenu)
      // alert('im')
      // if (store.state.showMenu === true) {
      //   store.setShowMenu(false)
      // }
    }
    const searchCmdyList = ref()
    const CmdyListLength = ref(0)
    const fetchSearchCmdyList = (fetchSearchCmdyRequestData: any) => {
      store.setShowLoading(true)
      const data = {
        Data: fetchSearchCmdyRequestData
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.searchCmdy,
          data
        )
        .then((res) => {
          const resData = res.data.Data
          CmdyListLength.value = resData.TotalSize
          if (resData.CmdyList.length !== 0) {
            searchCmdyList.value = resData.CmdyList
          } else {
            searchCmdyList.value = []
          }

          store.setShowLoading(false)
        })
        .catch((error) => {
          store.setShowLoading(false)
          console.error(error)
        })
    }
    const clickSearch = () => {
      level.value = 3
      nextTick(() => {
        inputRef.value?.inputRef.focus()
      })
    }
    const searchName = ref('')
    const getInput = (val: string) => {
      searchName.value = val
      fetchSearchCmdyList({
        pageNum: 1,
        pageSize: 4,
        Name: searchName.value,
        OrderType: productStore.state.selectedOrderType
      })
    }
    const goProductDetail = (_cmdy: any) => {
      (document.getElementById('app') as HTMLBodyElement).scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      store.setShowMenu(false)
      if (
        _cmdy.FrontendParameter === undefined ||
        _cmdy.FrontendParameter === ''
      ) {
        router.push({
          name: 'productDetail',
          query: {
            cmdyId: _cmdy.CmdyId
          }
        })
      } else {
        window.location.href = unescapeHTML(_cmdy.FrontendParameter)
      }
    }
    watch(
      () => store.state.showMenu,
      (newVal) => {
        if (newVal === false) {
          level.value = 1
          searchCmdyList.value = null
        }
      }
    )
    return {
      store,
      level,
      menuListRef,
      subMenuList,
      selectedMenu,
      selectedSubMenu,
      inputRef,
      setSubMenuList,
      thirdMenuList,
      setThirdMenuList,
      selectUrl,
      goSelectUrl,
      logout,
      firstLevelGoSelectUrl,
      goToPage,
      unescapeHTML,
      closeRecItem,
      clickSearch,
      searchName,
      searchCmdyList,
      CmdyListLength,
      getInput,
      goProductDetail
    }
  }
})
