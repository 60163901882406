import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53daccd0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex border-[1px] items-center rounded-full" }
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Icon, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.amountHandle('subtract', _ctx.modelValue))),
      class: "cursor-pointer",
      iconName: 'zoom-out',
      width: '36px'
    }),
    _createElementVNode("input", {
      class: "w-[36px] p-0 text-center",
      value: _ctx.modelValue,
      onBlur: _cache[1] || (_cache[1] = 
        (e) => {
          _ctx.change(e);
        }
      ),
      onKeyup: _cache[2] || (_cache[2] = _withKeys(
        (e) => {
          _ctx.change(e);
        }
      , ["enter"]))
    }, null, 40, _hoisted_2),
    _createVNode(_component_Icon, {
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.amountHandle('add', _ctx.modelValue))),
      class: "cursor-pointer",
      iconName: 'zoom-in',
      width: '36px'
    })
  ]))
}