import Cookies from 'js-cookie'

const TokenKey = 'DJIT-Token'
const RefreshTokenKey = 'DJIT-RefreshToken'

export function getToken (): string | undefined {
  return Cookies.get(TokenKey)
}

export const setToken = (token:string) => {
  Cookies.set(TokenKey, token, { expires: 5 })
}

// export function setToken (token: string): void {
//   if (token) {
//     return Cookies.set(TokenKey, token, { expires: 5 })
//   }
// }
export function removeToken (): void {
  return Cookies.remove(TokenKey)
}

export function getRefreshToken (): string | undefined {
  return Cookies.get(RefreshTokenKey)
}

export const setRefreshToken = (token:string) => {
  Cookies.set(RefreshTokenKey, token, { expires: 5 })
}

// export function setRefreshToken (token: string): void {
//   if (token) {
//     return Cookies.set(RefreshTokenKey, token, { expires: 5 })
//   }
// }

export function removeRefreshToken (): void {
  return Cookies.remove(RefreshTokenKey)
}

export function clearCookies (): void {
  Object.keys(Cookies.get()).forEach(cookieName => {
    if (cookieName !== 'remind') {
      Cookies.remove(cookieName)
    }
  })
}
