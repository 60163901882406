import Vue from 'vue'
import axios from 'axios'
// import axiosRetry from 'AxiosRetry'
// import { MessageBox, Message } from 'element-ui'
// import store from '@/store'
import * as mutationsType from '@/store/mutationsType'
import {
  getToken,
  getRefreshToken,
  setToken,
  setRefreshToken,
  clearCookies
} from '@/utils/auth'
import isMobile from 'ismobilejs'
// import * as moment from 'moment'
import * as requestUrl from '@/utils/requestUrl'
import { v4 as uuidv4 } from 'uuid'
import router from '@/router/index'
import * as eventTypes from '@/event_bus/eventTypes'
import EventBus from '@/event_bus/eventBus'
import store from '@/composition/store'
import toastedMessage from '@/utils/toastedMessage'

// const requiredLoginApi = [requestUrl.getMembers]

// create an axios instance
const service = axios.create({
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 1200000 // request timeout
  // retry: 3,
  // retryDelay: 1000
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    if (
      config.url !==
      window._env_.VUE_APP_STOREBOOK_SERVICE_API +
        requestUrl.getProductInfoMappingCode
    ) {
      // store.commit(mutationsType.CHANGE_SHOW_LOADING_STATUS, true)
    }
    // console.log('config >> ', config)
    if (config.method === 'post' || config.method === 'put') {
      // console.log('data >>>> ', req)
      const _url = config.url || ''

      // console.log('url >>>', _url)
      // return axios
      //   .post(
      //     window._env_.VUE_APP_BACK_END_SERVICE_API + requestUrl.getTimestamp,
      //     {
      //       Lang: 'zh-TW',
      //       RequestId: uuidv4()
      //     }
      //   )
      //   .then((res) => {
      config.data = config.data || {}
      // config.data.Timestamp = res.data.Data.Timestamp
      config.data.RequestId = uuidv4()
      config.data.Lang = 'zh-TW'
      if (config.data.Data) {
        config.data.Data.ShopCodes = window._env_.VUE_APP_SHOP_CODE
        config.data.Data.ShopCode = window._env_.VUE_APP_SHOP_CODE

        if (
          _url.indexOf(requestUrl.getShoppingCartCount) > -1 ||
          _url.indexOf(requestUrl.getPurchaseOrderList) > -1
        ) {
          config.data.Data.ShopCodes =
            window._env_.VUE_APP_SHOP_CODE_2 +
            ',' +
            window._env_.VUE_APP_SHOP_CODE
          config.data.Data.ShopCode = window._env_.VUE_APP_SHOP_CODE_2
        }
      }
      // do something before request is sent
      // console.log('getToken()', getToken())

      // 判斷是否為手機
      if (isMobile().phone) {
        config.data.Source = 'MobileWeb'
      } else {
        config.data.Source = 'PcWeb'
      }

      if (
        _url.indexOf(requestUrl.getClassList) > -1 ||
        _url.indexOf(requestUrl.getAdsList) > -1 ||
        _url.indexOf(requestUrl.cmdyClassList) > -1 ||
        _url.indexOf(requestUrl.getLatestNews) > -1 ||
        // _url.indexOf(requestUrl.getclassDetails) > -1 ||
        _url.indexOf(requestUrl.paymentTypeList) > -1 ||
        _url.indexOf(requestUrl.invoiceTypeList) > -1 ||
        _url.indexOf(requestUrl.deliveryTypeList) > -1 ||
        _url.indexOf(requestUrl.getCmdyClass) > -1 ||
        // _url.indexOf(requestUrl.getProductInfo) > -1 ||
        // _url.indexOf(requestUrl.getProductInfoMappingCode) > -1 ||
        _url.indexOf(requestUrl.logCmdyAnalyses) > -1 ||
        _url.indexOf(requestUrl.getTemplateList) > -1 ||
        _url.indexOf(requestUrl.getTemplateClass) > -1 ||
        _url.indexOf(requestUrl.getCmdyTrees) > -1 ||
        _url.indexOf(requestUrl.getHallList) > -1 ||
        _url.indexOf(requestUrl.getHallInfo) > -1 ||
        // _url.indexOf(requestUrl.getHallItemDetail) > -1 ||
        _url.indexOf(requestUrl.getMenuTree) > -1 ||
        _url.indexOf(requestUrl.getCmdyListByClassId) > -1 ||
        _url.indexOf(requestUrl.getCmdyClassV2) > -1 ||
        _url.indexOf(requestUrl.getCmdyClassV1) > -1 ||
        _url.indexOf(requestUrl.verifyRegisterMobile) > -1 ||
        _url.indexOf(requestUrl.sendingRegisterSMS) > -1 ||
        _url.indexOf(requestUrl.verifyRegisterSMSCode) > -1 ||
        _url.indexOf(requestUrl.registerGiftMember) > -1 ||
        _url.indexOf(requestUrl.tpaLoginGiftMember) > -1 ||
        _url.indexOf(requestUrl.getShopHall) > -1 ||
        _url.indexOf(requestUrl.getResetPasswordAuthCode) > -1 ||
        _url.indexOf(requestUrl.verifyResetPasswordAuthCode) > -1 ||
        _url.indexOf(requestUrl.resetGiftMemberPassword) > -1 ||
        _url.indexOf(requestUrl.getRecommended) > -1 ||
        _url.indexOf(requestUrl.getAvailableContactTimeList) > -1 ||
        _url.indexOf(requestUrl.searchCmdy) > -1 ||
        _url.indexOf(requestUrl.getQuestionTypeList) > -1 ||
        _url.indexOf(requestUrl.getCaptchaImage) > -1 ||
        _url.indexOf(requestUrl.getCmdyThemeList) > -1 ||
        _url.indexOf(requestUrl.getHotSales) > -1
      ) {
        return config
      }

      if (
        _url.indexOf(requestUrl.getclassDetails) > -1 ||
        _url.indexOf(requestUrl.getProductInfoMappingCode) > -1 ||
        _url.indexOf(requestUrl.getProductInfo) > -1 ||
        _url.indexOf(requestUrl.logAccessTraffics) > -1 ||
        _url.indexOf(requestUrl.getCmdyById) > -1 ||
        _url.indexOf(requestUrl.getCmdyByMappingCode) > -1 ||
        _url.indexOf(requestUrl.addMemberTokenByibonWeb) > -1 ||
        _url.indexOf(requestUrl.getActivityList) > -1) {
        config.data.Token = getToken()
        // console.log('config.data', config.data)
        return config
      }

      if (getToken()) {
        // let each request carry token --['X-Token'] as a custom key.
        // please modify it according to the actual situation.
        config.data.Token = getToken()
      } else {
        // config.data['Token'] = 'no-token'
        // router.push({ name: 'login' })
        console.log(router.currentRoute.value)
        if (
          router.currentRoute.value.meta.isNeedLogin !== undefined &&
          router.currentRoute.value.meta.isNeedLogin
        ) {
          if (_url.indexOf(requestUrl.getMemberDetail) === -1) {
            router.push({ name: 'home' })
            store.setShowLoginModal(true)
          }
        }
        // EventBus.emit(
        //   eventTypes.LOGIN_MODAL_HANDLER,
        //   !(_url.indexOf(requestUrl.getShoppingCartCount) > -1)
        // )
        // EventBus.$emit(eventTypes.LOGIN_MODAL_HANDLER, true)
      }
      return config
      // })
    }

    return config
  },
  (error) => {
    // do something with request error
    // console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code.
   */
  (response) => {
    // // store.commit(mutationsType.CHANGE_SHOW_LOADING_STATUS, false)
    const res = response

    // if the custom code is not 20000, it is judged as an error.
    // if (res.code !== 20000) {
    // Message({
    //   message: res.message || 'error',
    //   type: 'error',
    //   duration: 5 * 1000
    // })

    // // 50008: Illegal token 50012: Other clients logged in 50014: Token expired
    // if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
    //   // to re-login
    //   MessageBox.confirm(
    //     'You have been logged out, you can cancel to stay on this page, or log in again',
    //     'Confirm logout',
    //     {
    //       confirmButtonText: 'Re-Login',
    //       cancelButtonText: 'Cancel',
    //       type: 'warning'
    //     }
    //   ).then(() => {
    //     // store.dispatch('user/resetToken').then(() => {
    //       location.reload()
    //     })
    //   })
    // }
    // return Promise.reject(res.message || 'error')
    // } else {
    //   return res
    // }

    return res
  },
  (error) => {
    // // store.commit(mutationsType.CHANGE_SHOW_LOADING_STATUS, false)

    console.log(error)
    const _status = error.response.status
    // console.log('error res', error.response)
    // 驗證token找不到資料
    // if (_status === 404) {
    // 轉導回去form url
    // 如果url沒有資料則轉倒回去博客來健豪商店首頁
    // debugger
    // if (store.state.formUrl !== '' && store.state.formUrl) {
    //   window.location.href = store.state.formUrl
    //   return Promise.reject(new Error('test'))
    // } else {
    //   window.location.href = store.state.formUrl =
    //     'https://www.books.com.tw/'
    //   return Promise.reject(new Error('test'))
    // }
    // }

    // 驗證token過期
    if (_status === 403) {
      // router.push({ name: 'login' })
      return axios
        .post(
          window._env_.VUE_APP_BACK_END_SERVICE_API + requestUrl.getTimestamp,
          {
            Lang: 'zh-TW',
            RequestId: uuidv4()
          }
        )
        .then((res) => {
          return axios.put(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API +
              requestUrl.sessionChange,
            {
              Timestamp: res.data.Data.Timestamp,
              RequestId: uuidv4(),
              Lang: 'zh-TW',
              Source: isMobile().any ? 'MobileWeb' : 'PcWeb',
              Data: {
                ShopCode: window._env_.VUE_APP_SHOP_CODE,
                ShopCodes: window._env_.VUE_APP_SHOP_CODE,
                RefreshToken: getRefreshToken()
              }
            }
          )
        })
        .then((res) => {
          // 成功 重新跑一次request
          // store.commit(mutationsType.CHANGE_SHOW_LOADING_STATUS, false)
          setToken(res.data.Data.AccessToken)
          setRefreshToken(res.data.Data.RefreshToken)
          // console.log(error.config)
          const reqConfig = JSON.parse(error.config.data)
          reqConfig.Token = res.data.Data.AccessToken
          error.config.data = reqConfig
          // error.config.data['Token'] = res.data.Data.AccessToken
          return service.request(error.config)
        })
        .catch((e) => {
          // EventBus.$emit(eventTypes.LOGIN_MODAL_HANDLER, true)
          // if (router.currentRoute.meta.requiresAuth) {
          //   Vue.toasted.show(toastedMessage.RELOGIN)
          //   EventBus.$emit(eventTypes.LOGIN_MODAL_HANDLER, true)
          // }
          // Vue.toasted.show(toastedMessage.TOKEN_EXPRIED)
          // EventBus.$emit(eventTypes.LOGIN_MODAL_HANDLER, true)
          // console.log(e)
          clearCookies()

          // store.dispatch('actionResetState')

          // // store.dispatch('actionSetLogout')
          return Promise.reject(error.response)
        })
    }

    if (_status === 500) {
      window.location.href = `${window.location.protocol}//${window.location.host}/error.html`
    }
    // console.log(`err >>> ${errRes.status}`) // for debug
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error.response)
  }
)

export default service
