
import { defineComponent, provide, ref } from 'vue'
import Input from '@/components/common/Input.vue'
import AddressInput from '@/components/common/AddressInput.vue'
import Btn from '@/components/common/Btn.vue'
import Select from '@/components/common/Select.vue'
import DatePicker from 'vue-datepicker-next'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import store from '@/composition/store'
import moment from 'moment'
import { useToast, POSITION } from 'vue-toastification'
export default defineComponent({
  name: 'g-setting-view',
  components: {
    Input,
    AddressInput,
    Btn,
    DatePicker,
    Select
  },
  setup () {
    provide('store', store)
    const toast = useToast()
    const formatDate = (dateString: string) => {
      let _date = ''
      if (dateString !== '') {
        _date = moment(dateString).format('YYYY-MM-DD')
        return _date
      }
      return ''
    }
    const timeRange = ref(formatDate(store.state.userInfo.Birthday))
    const name = ref(store.state.userInfo.Name)
    const email = ref(store.state.userInfo.Email)
    const contactZip = ref(store.state.userInfo.ContactZip)
    const contactCity = ref(store.state.userInfo.ContactCity || '')
    const contactDist = ref(store.state.userInfo.ContactDist || '')
    const contactAddr = ref(store.state.userInfo.ContactAddr || '')
    const gender = ref(store.state.userInfo.GenderCode || '')
    const changeUserInfo = () => {
      const data = {
        Data: {
          Name: name.value,
          Email: email.value,
          Birthday: timeRange.value,
          Gender: gender.value,
          City: contactCity.value,
          Dist: contactDist.value,
          Addr: contactAddr.value
        }
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.modifyMember,
          data
        )
        .then((res) => {
          const resData = res.data
          toast.success(resData.Message, {
            position: POSITION.TOP_CENTER
          })
          store.fetchUserInfo()
          // alert(resData.Message)
        })
        .catch((error) => {
          toast.error(error.data.Message, {
            position: POSITION.TOP_CENTER
          })
          // alert(error.data.Message)
          console.error(error)
        })
    }
    const disabledAfterToday = (date:any) => {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      return date > today
    }
    return {
      store,
      name,
      email,
      timeRange,
      contactZip,
      contactCity,
      contactDist,
      contactAddr,
      gender,
      formatDate,
      changeUserInfo,
      disabledAfterToday
    }
  }
})
