
import { defineComponent, computed, watch, toRef, provide } from 'vue'
import UnderLine from './common/UnderLine.vue'
import EditInput from './common/EditInput.vue'
import { PortfolioItem } from '@/types/portfolioItemType'
import cartStore from '@/composition/cartStore'
import { useRoute } from 'vue-router'
import { useClipboard } from '@vueuse/core'
import { POSITION, useToast } from 'vue-toastification'
import { useGtm } from '@gtm-support/vue-gtm'

export default defineComponent({
  data () {
    return {
      isEdit: false
    }
  },
  props: {
    portfolio: {
      type: Object as () => PortfolioItem,
      required: true
    },
    editPortfolioName: {
      type: Function,
      default: null
    },
    continueEdit: {
      type: Function,
      default: null
    },
    delPortfolio: {
      type: Function,
      default: null
    },
    copyPortfolio: {
      type: Function,
      default: null
    }
  },
  components: {
    UnderLine,
    EditInput
  },
  setup (props, context) {
    provide('cartStore', cartStore)
    const { emit } = context
    const route = useRoute()
    const toast = useToast()
    const { copy, isSupported } = useClipboard()
    const gtm = useGtm()
    const portfolioRef = computed({
      get () {
        return props.portfolio
      },
      set (val) {
        emit('update:portfolio', val)
      }
    })
    const editPortfolioNameRef = toRef(props, 'editPortfolioName')
    const addShoppingCart = (portfolio: any) => {
      if (!portfolio.CanAddCart) return
      cartStore.addShoppingCart(
        {
          CmdyId: portfolio.CmdyId,
          EditorPortfolioId: portfolio.EditorPortfolioId
        },
        route.fullPath
      )
      if (gtm?.enabled()) {
        window.dataLayer?.push({ ecommerce: null }) // Clear the previous ecommerce object.
        window.dataLayer?.push({
          event: 'add_to_cart',
          ecommerce: {
            currency: 'TWD',
            value: 0,
            items: [
              {
                item_id: portfolio.CmdyId,
                item_name: portfolio.CmdyName
              }
            ]
          }
        })
      }
      window.fbq('track', 'AddToCart', {
        content_ids: [portfolio.CmdyId],
        content_name: portfolio.CmdyName,
        contents: [{ id: portfolio.CmdyId }]
      })
    }
    const handleCopy = (editorPartnerBookId: string) => {
      if (!isSupported) {
        toast.warning('不支援複製功能', {
          position: POSITION.TOP_CENTER
        })
        return
      }
      copy(editorPartnerBookId)
      toast.success(`複製成功：${editorPartnerBookId}`, {
        position: POSITION.TOP_CENTER
      })
    }
    // watch(
    //   () => (portfolioRef.value as any)?.Name,
    //   (newVal) => {
    //     console.log(newVal)
    //     props.editPortfolioName(portfolioRef.value)
    //   }
    // )
    return {
      emit,
      portfolioRef,
      editPortfolioNameRef,
      addShoppingCart,
      handleCopy
    }
  }
})
