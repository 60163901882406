import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.templateData[_ctx.$route.query.template || 'template01'].textList, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.id,
        style: _normalizeStyle([{
        top: item.y + 'px',
        left: item.x + 'px',
        fontSize: item.fontSize + 'px',
      }, {"width":"auto","position":"absolute","white-space":"nowrap","font-weigth":"font-weight: bold","color":"#000"}])
      }, _toDisplayString(item.text), 5))
    }), 128)),
    _createElementVNode("img", {
      style: _normalizeStyle({
      top: '0px',
      left: '0px',
      width: _ctx.templateData[_ctx.$route.query.template || 'template01'].productionImg.width + 'px',
      height: _ctx.templateData[_ctx.$route.query.template || 'template01'].productionImg.height + 'px',
      position: 'absolute',
      zIndex: '-1',
      maxWidth: 'initial'
      }),
      src: _ctx.templateData[_ctx.$route.query.template || 'template01'].productionImg.img,
      alt: ""
    }, null, 12, _hoisted_1)
  ]))
}