
import { Commit } from 'vuex'
import * as types from './mutationsType'
import { State, UserInfo } from './types/stateType'
import { BaseResponse, UserDetail } from '@/types/ResponseType'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import store from '@/store'

interface Context { state: State; commit: Commit }

export const actionSetShowLogo = (
  context: Context,
  isShowLogo: boolean
): void => {
  context.commit(types.SHOW_LOGO, isShowLogo)
}
export const actionSetShowMenu = (
  context: Context,
  isShowMenu: boolean
): void => {
  context.commit(types.SHOW_MENU, isShowMenu)
}

export const actionFetchUserInfo = (): void => {
  request.post<BaseResponse<UserDetail>>(
    window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.getMemberDetail,
    {}
  )
    .then(req => {
      store.dispatch('actionUpdateUserInfo', req.data.Data)
    })
}
export const actionUpdateUserInfo = (
  context: Context,
  userInfo: UserInfo
): void => {
  context.commit(types.UPDATE_USER_INFO, userInfo)
}

export const actionChangeShowLoadingViewStatus = (
  context: Context,
  value: boolean
): void => {
  context.commit(types.CHANGE_SHOW_LOADING_STATUS, value)
}
