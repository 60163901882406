
import { defineComponent } from 'vue'
import liff from '@line/liff'
// import { setLiffInfo } from '@/plugins/line'
import store from '@/composition/store'
export default defineComponent({
  components: {},
  setup () {
    const liffWeb = window.opener.liffWeb
    liff
      .init({
        liffId: '1657734280-PbXM8EnY'
      })
      .then(async () => {
        if (liff.isLoggedIn()) {
          // const getLiffInfo = (scopes: any): Promise<{ liffInfo: any }> => {
          //   return new Promise((resolve, reject) => {
          //     const info = {
          //       profile: {},
          //       accessToken: '' as any,
          //       errorObject: []
          //     }
          //     // Using for-of instead forEach to deal with async loop
          //     for (const scope of scopes) {
          //       switch (scope) {
          //         // case 'gerOS':
          //         //   info.os = liff.getOS()
          //         //   break
          //         // case 'getLanguage':
          //         //   info.language = liff.getLanguage()
          //         //   break
          //         // case 'getVersion':
          //         //   info.version = liff.getVersion()
          //         //   break
          //         // case 'isInClient':
          //         //   info.isInClient = liff.isInClient()
          //         //   break
          //         case 'getAccessToken':
          //           info.accessToken = liff.getAccessToken()
          //           console.log(liff.getAccessToken())
          //           break
          //         // case 'getDecodedIDToken':
          //         //   // liff.getDecodedIDToken() Gets a Promise object containing the ID token payload.
          //         //   // call next iteration after the promise has resolved
          //         //   info.decodedIDToken = await liff.getDecodedIDToken()
          //         //   break
          //         case 'getProfile':
          //           // liff.getProfile() Returns a Promise object that contains the user's profile information.
          //           // This method internally calls the Social API using axios.
          //           // For error handling, refer to the axios documentation
          //           // call next iteration after the promise has resolved
          //           info.profile = liff.getProfile()
          //           break
          //         default:
          //           break
          //       }
          //     }

          //     resolve({ liffInfo: info })
          //   })
          // }
          // getLiffInfo(['getAccessToken', 'getProfile']).then((info) => {
          //   liffWeb.setLiffInfo(info)
          // })
          const getLiffInfo = async (scopes:any) => {
            const info = {
              profile: null as any,
              accessToken: '' as any,
              errorObject: []
            }
            // Using for-of instead forEach to deal with async loop
            for (const scope of scopes) {
              switch (scope) {
                // case 'gerOS':
                //   info.os = liff.getOS()
                //   break
                // case 'getLanguage':
                //   info.language = liff.getLanguage()
                //   break
                // case 'getVersion':
                //   info.version = liff.getVersion()
                //   break
                // case 'isInClient':
                //   info.isInClient = liff.isInClient()
                //   break
                case 'getAccessToken':
                  info.accessToken = liff.getAccessToken()
                  break
                // case 'getDecodedIDToken':
                //   // liff.getDecodedIDToken() Gets a Promise object containing the ID token payload.
                //   // call next iteration after the promise has resolved
                //   info.decodedIDToken = await liff.getDecodedIDToken()
                //   break
                case 'getProfile':
                  // liff.getProfile() Returns a Promise object that contains the user's profile information.
                  // This method internally calls the Social API using axios.
                  // For error handling, refer to the axios documentation
                  // call next iteration after the promise has resolved
                  info.profile = await liff.getProfile()
                  break
                default:
                  break
              }
            }
            // info is a promise
            return info
          }
          const liffInfo = await getLiffInfo(['getAccessToken'])
          // console.log(liffInfo)
          liffWeb.setLiffInfo(liffInfo)
        } else {
          liff.login({ redirectUri: 'https://localhost:9123/lineLogin' })
        }
      })
      .catch((error) => {
        const liffInfo = {
          errorObject: error
        }
        liffWeb.setLiffInfo(liffInfo)
      })
    return {}
  }
})
