import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TestBox from '@/views/TestBox.vue'
import CanvasText from '@/components/common/CanvasText.vue'
import InspirationView from '@/views/InspirationView.vue'
import ProductDetail from '@/views/ProductDetail.vue'
import ProductListView from '@/views/ProductListView.vue'
import IPInspirationView from '@/views/IPInspirationView.vue'
import IPProductListView from '@/views/IPProductListView.vue'
import PromotionListView from '@/views/PromotionListView.vue'
import SearchView from '@/views/SearchView.vue'
import CustomMadeView from '@/views/CustomMadeView.vue'
import CollectionView from '@/views/CollectionView.vue'
import LimitedTimeView from '@/views/LimitedTimeView.vue'
import PortfolioView from '@/views/PortfolioView.vue'
import FAQView from '@/views/FAQView.vue'
import ShoppingCartView from '@/views/ShoppingCartView.vue'
import OrderListView from '@/views/OrderListView.vue'
import ContactView from '@/views/ContactView.vue'

import UserLayout from '@/components/Layout/UserLayout.vue'
import OrderDetailView from '@/views/OrderDetailView.vue'
import SettingView from '@/views/SettingView.vue'
import MyAddressView from '@/views/MyAddressView.vue'
import ChangePasswordView from '@/views/ChangePasswordView.vue'
import LineLoginView from '@/views/LineLoginView.vue'
import OrderSuccessView from '@/views/OrderSuccessView.vue'
import store from '@/composition/store'
import productStore from '@/composition/productStore'
import cartStore from '@/composition/cartStore'
import AddShoppingCartView from '@/views/AddShoppingCartView.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/test-box/test',
    name: 'text-box',
    component: TestBox
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  // {
  //   path: '/test-canvas',
  //   name: 'canvas',
  //   component: CanvasText
  // },
  {
    path: '/inspiration',
    name: 'inspiration',
    component: InspirationView,
    meta: {
      breadcrumbs: [{ path: '/', title: 'HORLaY' }]
    }
  },
  {
    path: '/productDetail/:cmdyId?',
    name: 'productDetail',
    component: ProductDetail,
    meta: {
      breadcrumbs: [{ path: '/', title: 'HORLaY' }]
    }
  },
  {
    // path: '/productList/:cmdyClassId/:subCmdyClassId?',
    path: '/productList',
    name: 'productList',
    component: ProductListView,
    meta: {
      breadcrumbs: [{ path: '/', title: 'HORLaY' }]
    }
  },
  {
    // path: '/productList/:cmdyClassId/:subCmdyClassId?',
    path: '/promotionList',
    name: 'promotionList',
    component: PromotionListView,
    meta: {
      breadcrumbs: [{ path: '/', title: 'HORLaY' }]
    }
  },
  {
    path: '/ipinspiration',
    name: 'ipinspiration',
    component: IPInspirationView,
    meta: {
      breadcrumbs: [
        { path: '/', title: 'HORLaY' },
        { path: '/', title: '授權x聯名' }
      ]
    }
  },
  {
    path: '/ipproductList',
    name: 'ipproductList',
    component: IPProductListView,
    meta: {
      breadcrumbs: [{ path: '/', title: 'HORLaY' }]
    }
  },
  {
    // path: '/customMade/:cmdyClassId?/:subCmdyClassId?/:cmdyId?',
    path: '/customMade/',
    name: 'customMade',
    component: CustomMadeView,
    meta: {
      breadcrumbs: [
        { path: '/', title: 'HORLaY' },
        { path: '/', title: '懶人做客製' }
      ]
    }
  },
  {
    path: '/search',
    name: 'search',
    component: SearchView,
    meta: {
      breadcrumbs: [
        { path: '/', title: 'HORLaY' },
        { path: '/', title: '搜尋' }
      ]
    }
  },
  {
    path: '/collection',
    name: 'collection',
    component: CollectionView,
    meta: {
      breadcrumbs: [
        { path: '/', title: 'HORLaY' },
        { path: '/collection', title: '我的收藏' }
      ],
      isNeedLogin: true
    }
  },
  {
    path: '/limitedTime',
    name: 'limitedTime',
    component: LimitedTimeView,
    meta: {
      breadcrumbs: [
        { path: '/', title: 'HORLaY' },
        { path: '/', title: '節慶' }
      ]
    }
  },
  {
    path: '/faq',
    name: 'faq',
    component: FAQView
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    component: PortfolioView,
    meta: {
      isNeedLogin: true
    }
  },
  {
    path: '/addShoppingCart/:partnerBookId?',
    name: 'addShoppingCart',
    component: AddShoppingCartView,
    meta: {
      isNeedLogin: true
    }
  },
  {
    path: '/shoppingCart',
    name: 'shoppingCart',
    component: ShoppingCartView,
    meta: {
      isNeedLogin: true
    }
  },
  {
    path: '/user',
    name: 'user',
    component: UserLayout,
    meta: {
      isNeedLogin: true
    },
    children: [
      {
        path: 'orderList',
        name: 'orderList',
        component: OrderListView
      },
      {
        path: 'setting',
        name: 'setting',
        component: SettingView
      },
      {
        path: 'address',
        name: 'address',
        component: MyAddressView
      },
      {
        path: 'changePassword',
        name: 'changePassword',
        component: ChangePasswordView
      },
      {
        path: 'contact',
        name: 'contact',
        component: ContactView
      }
    ]
  },
  {
    name: '404',
    path: '/*',
    component: () => import('@/views/notFoundView.vue')
  },
  {
    path: '/orderDetail/:orderId',
    name: 'orderDetail',
    component: OrderDetailView,
    meta: {
      isNeedLogin: true
    }
  },
  {
    path: '/orderSuccess/',
    name: 'orderSuccess',
    component: OrderSuccessView,
    meta: {
      isNeedLogin: true
    }
  },
  {
    path: '/lineLogin',
    name: 'lineLogin',
    component: LineLoginView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      if (
        to.name !== 'productDetail' &&
        to.name !== 'ipproductList' &&
        to.name !== 'customMade' &&
        to.name !== 'productList' &&
        to.name !== 'orderList'
      ) {
        // if (to.name !== 'productDetail') {
        return { top: 0 }
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.name === 'productDetail' && from.name !== 'productDetail' && from.name !== undefined) {
    window.location.href =
      window._env_.VUE_APP_GIFT_URL + '/productDetail/?cmdyId=' + to.query.cmdyId
  } else {
    store.setShowLoading(true)
    store
      .fetchUserInfo()
      .then(() => {
        if (to.name !== from.name) {
          productStore.resetAllStatus()
        }
        cartStore.fetchShoppingCartList()
        next()
        store.setShowLoading(false)
      })
      .catch(() => {
        if (to.meta.isNeedLogin !== undefined && to.meta.isNeedLogin) {
          store.setShowLoginModal(true)
          store.setNextUrl(to.fullPath)
          if (from.name === undefined) {
            next()
          }
        } else {
          if (to.name !== from.name) {
            productStore.resetAllStatus()
          }
          next()
        }
        store.setShowLoading(false)
      })
  }
})
// router.afterEach((to, from, next) => {
//   console.log(to)
//   console.log(from)
// })
export default router
