/**
 * 取得版型清單
 */
// export const getTemplateList = '/templates/queries';

export const getTemplateClass = '/editors/template-classes/queries'

/**
 * 取得子分類清單
 */

export const getclassDetails = '/shops/cmdy-class-details/queries'

/**
 * activity
 */
export const getActivities = '/shops/activities/queries'

/**
 * 取得活動詳情
 */
export const getActivityInfo = '/shops/activity-details/queries'

/**
 * 取得商品詳情
 */

export const getProductInfo = '/commodities/cmdy-id/queries'

/**
 * 取得商品詳情With mapping code
 */

export const getProductInfoMappingCode =
  '/commodities/mapping-code/queries'

/**
 * 取得商品分類列表
 */

export const getClassList = '/shops/cmdy-class-lists/queries'

/**
 * 取得最新消息列表
 */

export const getLatestNews = '/shops/latest-news/queries'

/**
 * 取得版型清單
 */

export const getTemplateList = '/editors/templates/queries'

/**
 * 取得廣告資訊
 */

export const getAdsList = '/shops/ads/queries'
export const getAdsList2 = '/shops/ads/lists/queries'

/**
 * 取得主題館列表
 */
export const getHallList = '/shops/halls/lists/queries'

/**
 * 流量日誌
 */

export const logAccessTraffics = '/logs/access-traffics'

/**
 * 取得驗證時搓
 */
export const getTimestamp = '/timestamps'

/**
 * 取得會員資訊
 */

export const getMembers = '/members/queries'

/**
 * 更新會員
 */

export const updateMember = '/members'

/**
 * 更新作品
 */
export const updatePortfolios = '/editors/portfolios'

/**
 * 取得會員詳細資訊
 */

export const getMemberDetail = '/members/details/queries'

/**
 * 更換token
 */

export const sessionChange = '/sessions'

/**
 * 會員登出
 */
export const logout = '/sessions/deletions'

/**
 * 商品清單
 */

export const cmdyClassList = '/commodities/lists/cmdy-class-id/queries'

/**
 * 取得購物車數量
 */

export const getShoppingCartCount = '/orders/shopping-carts/cmdy-qty/queries'

/**
 * 取得付款方式列表
 */

export const paymentTypeList = '/master-datas/get-payment-type-list'

/**
 * 取得寄送方式列表
 */
export const deliveryTypeList = '/master-datas/get-delivery-type-list'
/**
 * 取得配送門市列表
 */
export const deliveryShopList = '/master-datas/get-delivery-shop-list'

/**
 * 取得發票列表
 */
export const invoiceTypeList = '/master-datas/get-invoice-type-list'

/**
 * 獲取收件地址列表
 */
export const getDeliveryAddrList = '/members/get-delivery-addr-list'

/**
 * 獲取台灣行政區域列表
 */
export const getDist = '/master-datas/admin-regions/lists/tw/queries'

/**
 * 更新收件地址
 */
export const updateDeliveryAddr = '/members/delivery-addrs'

/**
 * 更新健豪收件門市
 */
export const updateDeliveryShops = '/members/delivery-shops'

/**
 * 取得使用者門市清單
 */
export const getDeliveryShopList = '/members/get-delivery-shop-list'

/**
 * 獲取採購訂單列表
 */
export const getPurchaseOrderList = '/master-datas/get-purchase-status-list'

/**
 * 新增訂單
 */
export const orders = '/orders'

/**
 * 更新訂單狀態/取消訂單
 */
export const updateOrdersStatus = '/orders/status'

/**
 * 新增商品分析日誌
 */
export const logCmdyAnalyses = '/logs/cmdy-analyses'

/**
 * 查詢捐贈碼
 */
export const donateCodeCheck = '/invoices/donate-codes/checks/queries'

/**
 * 刪除地址
 */
export const deleteAddress = '/members/delivery-addrs/deletions'

/**
 * 刪除門店
 */

export const deleteShop = '/members/delivery-shops/deletions'

/**
 * 檢查統編黑名單
 */

export const checkBIZNo = '/shops/biz-no/checks/queries'

/**
 * 刪除作品
 */
export const deletePortfolios = '/editors/portfolios/deletions'

/**
 * 取得作品相片
 */
export const getPortfolioPhoto = '/editors/portfolio-images/lists/queries'

/**
 * 獲取商品分類列表 (樹狀結構)
 */

export const getCmdyTrees = '/shops/cmdy-classes/lists/trees/queries'

/**
 * 獲取主題館資訊 (名稱/Logo)
 */

export const getHallInfo = '/shops/halls/queries'

// export const getHallItemDetail = '/shops/cmdy-classes/queries'

export const getCouponList = '/promotions/coupons/lists/owns/queries'

export const usageCoupon = '/promotions/coupons/usage-results/queries'

export const getCanUseCouponList = '/promotions/coupons/lists/checkouts/queries'

export const getCouponUseCode = '/promotions/coupons/members'

export const redeemCode = '/promotions/redeem-code'

/**
 * ibon 會員登入
 */
export const addMemberTokenByibonWeb = '/sessions/ibon-web'
/**
 * 獲取選單
 */

export const getMenuTree = '/shops/menu/get-menu-tree'

/**
 * 獲取商品列表 (根據商品分類ID)
 */

export const getCmdyListByClassId = '/commodities/get-cmdy-list-by-class-id'
/**
 * 獲取商品 (根據商品 ID)
 */

export const getCmdyById = '/commodities/get-cmdy-by-id'
/**
 * 獲取商品 (根據匹配碼)
 */

export const getCmdyByMappingCode = '/commodities/get-cmdy-by-mapping-code'

// 有用到的

/**
 * 驗證手機
 */
export const verifyRegisterMobile = '/members/verify-register-mobile'

/**
 * 發送/重送簡訊驗證碼
 */
export const sendingRegisterSMS = '/members/sending-register-sms'

/**
 * 驗證簡訊驗證碼
 */
export const verifyRegisterSMSCode = '/members/verify-register-sms-code'

/**
 * 註冊
 */
export const registerGiftMember = '/members/register-gift-member'

/**
 * 登入
 */
export const loginGiftMember = '/members/login-gift-member'

/**
 * 取得收藏商品列表
 */
export const getCmdyCollectList = '/members/get-cmdy-collect-list'

/**
 * 新增收藏商品
 */
export const addCmdyCollect = '/members/add-cmdy-collect'

/**
 * 刪除收藏商品
 */
export const delCmdyCollect = '/members/delete-cmdy-collect'

/**
 * 取得購物車列表
 */
export const getShoppingCartList = '/orders/get-shopping-cart-list'

/**
 * 新增購物車
 */
export const addShoppingCart = '/orders/add-shopping-cart'

/**
 * 刪除購物車
 */
export const delShoppingCart = '/orders/delete-shopping-cart'

/**
 * 更新購物車數量
 */

export const updateShoppingCart = '/orders/modify-shopping-cart'
/**
 * 結算購物車
 */

export const calcShoppingCart = '/orders/calc-shopping-cart'

/**
 * 獲取商品分類列表
 */

export const getCmdyClass = '/shops/cmdy-classes/lists/queries'

/**
  * 獲取商品分類列表(v2)
  */

export const getCmdyClassV2 = '/shops/get-cmdy-class-list'
/**
  * 獲取商品分類
  */

export const getCmdyClassV1 = '/shops/get-cmdy-class'

/**
 * 第三方註冊/登入
 */
export const tpaLoginGiftMember = '/members/tpa-login-gift-member'

/**
 * 驗證第三方註冊簡訊驗證碼
 */
export const verifyTpaRegisterSMSCode = '/members/verify-tpa-register-sms-code'

/**
 * 變更密碼
 */
export const changePassword = '/members/change-password'

/**
 * 更新會員資料
 */
export const modifyMember = '/members/modify-member'

/**
 * 新增訂單
 */
export const addOrder = '/orders/add-order'

/**
 * 取得訂單
 */
export const getOrder = '/orders/get-order'

/**
 * 取得訂單列表
 */
export const getOrderList = '/orders/get-order-list'

/**
 * 變更訂單狀態/取消訂單
 */
export const changeOrderStatus = '/orders/change-order-status'

/**
 * 新增作品
 */
export const addPortfolio = '/editors/add-portfolio'

/**
 * 取得訂單狀態列表
 */
export const getPurchaseStatusList = '/master-datas/get-purchase-status-list'

/**
 * 取得主題館
 */
export const getShopHall = '/shops/halls/get-shophall'

/**
 * 取得作品清單
 */

export const getPortfolioList = '/editors/get-portfolio-list'
/**
 * 修改作品
 */
export const modifyPortfolio = '/editors/modify-portfolio'
/**
 * 刪除作品
 */
export const delPortfolio = '/editors/delete-portfolio'
/**
 * 刪除作品
 */
export const copyPortfolio = '/editors/copy-portfolio'
/**
 * 驗證手機條碼載具
 */
export const verifyBarcode = '/invoices/verify-barcode'
/**
 * 忘記密碼 1.發送授權碼
 */
export const getResetPasswordAuthCode = '/members/get-reset-password-auth-code'
/**
 * 忘記密碼 2.驗證授權碼
 */
export const verifyResetPasswordAuthCode = '/members/verify-reset-password-auth-code'
/**
 * 忘記密碼 3.重設密碼
 */
export const resetGiftMemberPassword = '/members/reset-gift-member-password'
/**
 * 取得推薦商品/商品分類
 */
export const getRecommended = '/commodities/get-recommended'
/**
 * 取得 "聯絡客服-問題類型" 列表
 */
export const getQuestionTypeList = '/members/get-question-type-list'
/**
 * 取得 "聯絡客服-方便聯絡時間" 列表
 */
export const getAvailableContactTimeList = '/members/get-available-contact-time-list'
/**
 * 新增聯絡客服
 */
export const addCustomContact = '/members/add-custom-contact'
/**
 * 查詢商品
 */
export const searchCmdy = '/commodities/search-cmdy'
/**
 * 取得驗證碼圖片
 */
export const getCaptchaImage = '/captcha/get-captcha-image'
/**
 * 取得商品主題列表
 */
export const getCmdyThemeList = '/commodities/get-cmdy-theme-list'

/**
 * 獲取熱銷商品列表
 */
export const getHotSales = '/shops/get-hot-sales'

/**
 *
 */
export const getGiftPortfolioByPartnerBookId = '/editors/get-gift-portfolio-by-partner-bookid'

/**
 * 暫存結帳購物車
 */
export const saveTempShoppingCart = '/orders/save-temp-shopping-cart'

/**
 * 取得暫存結帳購物車
 */
export const getTempShoppingCart = '/orders/get-temp-shopping-cart'

/**
 * 登錄商品貨到通知
 */
export const addCmdyRestockNotify = '/commodities/add-cmdy-restock-notify'

/**
 * 取得載具歸戶url
 */
export const getCarrierMergeUrl = '/gh/get-carrier-merge-url'

/**
 * 取得促銷活動包含商品
 */
export const getActivity = '/promotions/activity/queries'

/**
 * 取得活動列表
 */
export const getActivityList = '/promotions/activity/lists/queries'

/**
 * 根據活動id取得商品
 */
export const getCmdyListByPromotionId = '/commodities/get-cmdy-list-by-promotion-id'
