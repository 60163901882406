
import { defineComponent, toRef, computed, watch } from 'vue'
import PortfolioItem from '@/components/PortfolioItem.vue'
import FilterDropDown from '@/components/common/FilterDropDown.vue'
import DropDown from '@/components/common/DropDown.vue'
export default defineComponent({
  data () {
    return {}
  },
  emits: ['change', 'update:portfolioList'],
  props: {
    row: {
      type: Number,
      required: false,
      default: 5
    },
    portfolioList: {},
    editPortfolioName: {
      type: Function,
      default: null
    },
    continueEdit: {
      type: Function,
      default: null
    },
    delPortfolio: {
      type: Function,
      default: null
    },
    copyPortfolio: {
      type: Function,
      default: null
    }
  },
  components: {
    PortfolioItem
    // FilterDropDown,
    // DropDown
  },
  setup (props, context) {
    const { emit } = context
    const portfolioListRef = computed({
      get () {
        return props.portfolioList
      },
      set (val) {
        emit('update:portfolioList', val)
      }
    })
    const editPortfolioNameRef = toRef(props, 'editPortfolioName')
    const continueEditRef = toRef(props, 'continueEdit')
    const delPortfolioRef = toRef(props, 'delPortfolio')
    const copyPortfolioRef = toRef(props, 'copyPortfolio')
    return {
      emit,
      portfolioListRef,
      editPortfolioNameRef,
      continueEditRef,
      delPortfolioRef,
      copyPortfolioRef
    }
  }
})
