import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass(['icon', _ctx.customClass]),
    style: _normalizeStyle({ width: _ctx.width, height: _ctx.width }),
    "aria-hidden": "true"
  }, [
    _createElementVNode("use", {
      "xlink:href": `#${_ctx.iconName}`
    }, null, 8, _hoisted_1)
  ], 6))
}