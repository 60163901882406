
import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
  provide,
  watch
} from 'vue'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import { useRoute, useRouter } from 'vue-router'
import SubHeader from '@/components/common/SubHeader.vue'
import SquareItemList from '@/components/SquareItemList.vue'
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import store from '@/composition/store'
import productStore from '@/composition/productStore'
import { useToast, POSITION } from 'vue-toastification'
export default defineComponent({
  components: {
    SquareItemList,
    SubHeader,
    Breadcrumb
  },
  setup () {
    provide('store', store)
    provide('productStore', productStore)
    const router = useRouter()
    const route = useRoute()
    const toast = useToast()
    const breadcrumb = ref(null)
    const lastScroll = ref(50)

    const selectProduct = ref(null)
    const scrollFunc1 = () => {
      if (route.name === 'inspiration') {
        const st =
          window.scrollY ||
          window.pageYOffset ||
          (document.getElementById('app') as HTMLBodyElement).scrollTop
        if (st < lastScroll.value || st === 0) {
          if (breadcrumb.value) {
            (breadcrumb.value as HTMLElement).style.top = '50px'
          }
        } else {
          if (breadcrumb.value) {
            (breadcrumb.value as HTMLElement).style.top = '0px'
          }
        }

        lastScroll.value = st
      }
    }
    const getCmdyClassList = () => {
      productStore
        .fetchCmdyClassList(route.query.cmdyClassId?.toString() || '')
        .then((res) => {
          productStore
            .fetchCmdyClassListV2({
              ShopHallCode: '',
              ShopHallClassId: '',
              CmdyClassId: res.cmdyClass.RootClassId,
              ParentCmdyClassId: '',
              DataType: 'Shop',
              MaxLevel: 2,
              IsOnlyLeafNode: false
            })
            .then(() => {
              productStore.state.cmdyClassList.forEach(
                (element: { CmdyClassId: string }) => {
                  if (element.CmdyClassId === route.query.cmdyClassId) {
                    productStore.setSelectedCmdyClass(element)
                  }
                }
              )
              productStore
                .fetchSubCmdyClassList({
                  ShopHallCode: '',
                  ShopHallClassId: '',
                  CmdyClassId: productStore.state.selectedCmdyClass.CmdyClassId,
                  ParentCmdyClassId: '',
                  DataType: 'Shop',
                  MaxLevel: 3,
                  IsOnlyLeafNode: true
                })
                .then(() => {
                  productStore.state.subCmdyClassList.forEach(
                    (element: { CmdyClassId: string }) => {
                      if (element.CmdyClassId === route.query.subCmdyClassId) {
                        productStore.setSelectedSubCmdyClass(element)
                      }
                    }
                  )
                  const _cmdyClassId = ref(
                    productStore.state.selectedCmdyClass.CmdyClassId
                  )
                  if (route.query.subCmdyClassId !== '') {
                    _cmdyClassId.value = route.query.subCmdyClassId
                  }
                  productStore.fetchCmdyList({
                    PageNum: 0,
                    PageSize: 0,
                    CmdyClassId: _cmdyClassId.value,
                    CmdyThemeId: [],
                    CmdyTagId: '',
                    OrderType: 'PriceDesc',
                    IsIncludeHiddenCmdy: false
                  })
                })
            })
        })
        .catch(() => {
          toast.warning('查不到此分類，已幫你轉導到首頁', {
            position: POSITION.TOP_CENTER
          })
          router.push({ name: 'home' })
        })
    }
    const getSubCmdyClassList = () => {
      router.push({
        query: {
          cmdyClassId: productStore.state.selectedCmdyClass.CmdyClassId,
          subCmdyClassId: productStore.state.selectedSubCmdyClass.CmdyClassId,
          cmdyTagId: productStore.state.selectedCmdyTag.CmdyTagId
          // dateNow: Date.now()
        }
      })
      productStore.fetchCmdyList({
        PageNum: 0,
        PageSize: 0,
        CmdyClassId: productStore.state.selectedSubCmdyClass.CmdyClassId,
        CmdyThemeId: [],
        CmdyTagId: '',
        OrderType: 'PriceDesc',
        IsIncludeHiddenCmdy: false
      })
    }

    const clickCmdyClassList = () => {
      // productStore.setSubCmdyClassList([])
      (document.getElementById('app') as HTMLBodyElement).scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      route.query.subCmdyClassId = ''
      router.push({
        query: {
          cmdyClassId: productStore.state.selectedCmdyClass.CmdyClassId,
          subCmdyClassId: ''
        }
      })
    }
    const clickSubCmdyClassList = () => {
      (document.getElementById('app') as HTMLBodyElement).scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      router.push({
        query: {
          cmdyClassId: productStore.state.selectedCmdyClass.CmdyClassId,
          subCmdyClassId: productStore.state.selectedSubCmdyClass.CmdyClassId
        }
      })
    }
    onMounted(() => {
      (document.getElementById('app') as HTMLBodyElement).addEventListener(
        'scroll',
        scrollFunc1
      )
      // window.addEventListener('scroll', scrollFunc1)
      productStore.resetAllStatus()
      getCmdyClassList()
    })
    watch(
      () => route.query.cmdyClassId,
      (newVal, oldVal) => {
        if (newVal !== undefined && route.name === 'inspiration') {
          // productStore.setSubCmdyClassList([])
          // productStore.setCmdyList({})
          productStore.setShowRectangleItem(false)
          getCmdyClassList()
        }
      }
    )
    watch(
      () => route.query.subCmdyClassId,
      (newVal, oldVal) => {
        if (newVal !== undefined && route.name === 'inspiration') {
          if (newVal !== '') {
            getSubCmdyClassList()
          } else {
            getCmdyClassList()
          }
        }
      }
    )

    onUnmounted(() => {
      (document.getElementById('app') as HTMLBodyElement).removeEventListener(
        'scroll',
        scrollFunc1
      )
      // window.removeEventListener('scroll', scrollFunc1)
    })

    return {
      store,
      productStore,
      breadcrumb,
      selectProduct,
      getCmdyClassList,
      getSubCmdyClassList,
      clickCmdyClassList,
      clickSubCmdyClassList
    }
  }
})
