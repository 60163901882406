
import { computed, defineComponent, ref } from 'vue'
import { useField } from 'vee-validate'
export default defineComponent({
  name: 'g-input',
  props: {
    width: {
      type: String,
      require: false,
      default: '100px'
    },
    height: {
      type: String,
      require: false,
      default: '40px'
    },
    modelValue: {
      type: String,
      require: false,
      default: ''
    },
    label: {
      type: String,
      require: false,
      default: ''
    },
    placeholder: {
      type: String,
      require: false,
      default: ''
    },
    required: {
      type: Boolean,
      require: false,
      default: true
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  setup (props, context) {
    const { emit } = context
    const inputRef = ref()
    const isPassword = ref(true)
    const isPasswordText = computed(() => {
      if (isPassword.value) {
        return 'password'
      }
      return 'input'
    })
    const change = (event: Event) => {
      emit('update:modelValue', (event.target as HTMLInputElement).value)
      console.log((event.target as HTMLInputElement).value)
    }
    return {
      inputRef,
      change,
      isPassword,
      isPasswordText
    }
  }
})
