
import { isPropType } from '@/utils/tools'
import { defineComponent, computed } from 'vue'
export default defineComponent({
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  name: 'g-toggle-button',
  emits: ['change', 'update:modelValue'],
  props: {
    width: {
      type: String,
      required: false,
      default: '100%'
    },
    height: {
      type: String,
      require: false,
      default: '40px'
    },
    label: {
      type: String,
      require: false,
      default: ''
    },
    text: {
      type: String,
      require: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false
    },
    modelValue: {
      type: [Boolean, Number],
      default: true || 1
    },
    value: {
      type: [Boolean, Number],
      default: true || 1
    }
  },
  setup (props, context) {
    const { emit } = context
    const change = (event: Event) => {
      emit('change', props.value)
    }
    const model = computed({
      get () {
        // if (isPropType(props.modelValue, 'boolean')) {
        //   return props.modelValue
        // } else if (isPropType(props.modelValue, 'number')) {
        //   return !!props.modelValue
        // } else {
        //   return props.modelValue
        // }
        return props.modelValue
      },
      set (val) {
        console.log(props.modelValue)
        console.log(val)
        if (isPropType(val, 'boolean')) {
          emit('update:modelValue', val)
        } else if (isPropType(val, 'number')) {
          emit('update:modelValue', val ? 1 : 0)
        }
        // console.log('val >>>', val)
        // emit('update:modelValue', val)
      }
    })
    return { change, model }
  }
})
