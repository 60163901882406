
import { defineComponent, onMounted, provide, ref, watch } from 'vue'
import Title from '@/components/common/Title.vue'
import UnderLine from '@/components/common/UnderLine.vue'
import ProductClassItemList from '@/components/ProductClassItemList.vue'
import ProductClassIconList from '@/components/ProductClassIconList.vue'
import ProductItemList from '@/components/ProductItemList.vue'
import Banner from '@/components/common/Banner.vue'
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import productStore from '@/composition/productStore'
import { useRoute, useRouter } from 'vue-router'
import store from '@/composition/store'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import isMobile from 'ismobilejs'
export default defineComponent({
  components: {
    Title,
    UnderLine,
    ProductClassItemList,
    ProductClassIconList,
    ProductItemList,
    Banner,
    Breadcrumb
  },
  setup () {
    provide('productStore', productStore)
    provide('store', store)
    const router = useRouter()
    const route = useRoute()
    const RefProductItemList = ref()
    const RefProductItemListForMobile = ref()
    const cmdyThemeList = ref()
    const cmdyClassItem = ref({})
    const getCmdyClassItem = () => {
      productStore
        .fetchCmdyClassList('0dea26a7-97d2-11ed-9a51-0242ac1b0002')
        .then((res) => {
          cmdyClassItem.value = res.cmdyClass
        })
    }
    const getCmdyClassList = () => {
      // productStore.setSelectedCmdyClass({})
      productStore
        .fetchCmdyClassListV2({
          ShopHallCode: '',
          ShopHallClassId: '',
          // CmdyClassId: route.query.cmdyClassId.toString(),
          CmdyClassId: '0dea26a7-97d2-11ed-9a51-0242ac1b0002',
          ParentCmdyClassId: '',
          DataType: 'Shop',
          MaxLevel: 2,
          IsOnlyLeafNode: false
        })
        .then(() => {
          productStore.state.cmdyClassList.forEach((element: any) => {
            if (element.CmdyClassId === route.query.cmdyClassId) {
              productStore.setSelectedCmdyClass(element)
            }
          })
          const cmdyClassId = productStore.state.selectedCmdyClass.CmdyClassId

          const cmdyTagId = ref(productStore.state.selectedCmdyTag.CmdyTagId)
          if (
            route.query.cmdyTagId !== undefined &&
            route.query.cmdyTagId !== ''
          ) {
            cmdyTagId.value = route.query.cmdyTagId
          }
          console.log(cmdyTagId.value)
          console.log(route.query.subCmdyClassId)
          console.log(productStore.state.selectedCmdyClass.CmdyClassId)

          if (
            route.query.subCmdyClassId !== undefined &&
            route.query.subCmdyClassId !== ''
          ) {
            productStore.setShowRectangleItem(false)
            store.setShowLoading(true)
            const data = {
              Data: {
                PageNum: 0,
                PageSize: 0,
                CmdyClassId:
                  route.query.subCmdyClassId?.toString() ||
                  '0dea26a7-97d2-11ed-9a51-0242ac1b0002',
                CmdyThemeId: [],
                CmdyTagId: cmdyTagId.value || '',
                OrderType: productStore.state.selectedOrderType,
                IsIncludeHiddenCmdy: false
              }
            }
            request
              .post(
                window._env_.VUE_APP_STOREBOOK_SERVICE_API +
                  requestUrl.getCmdyListByClassId,
                data,
                {
                  headers: {
                    'api-version': '2.0'
                  }
                }
              )
              .then((res) => {
                const resData = res.data.Data
                const _cmdyTagId = resData.CmdyTagList[0]?.CmdyTagId || ''
                productStore
                  .fetchCmdyList({
                    PageNum: 0,
                    PageSize: 0,
                    CmdyClassId: cmdyClassId,
                    CmdyThemeId: [],
                    CmdyTagId: _cmdyTagId,
                    OrderType: productStore.state.selectedOrderType,
                    IsIncludeHiddenCmdy: false
                  })
                  .then(() => {
                    productStore.state.cmdyTagList.forEach((item: any) => {
                      if (item.CmdyTagId === _cmdyTagId) {
                        productStore.setSelectedCmdyTag(item)
                      }
                    })
                  })
                productStore.setSelectedCmdy({})
                store.setShowLoading(false)
              })
              .catch((error) => {
                store.setShowLoading(false)
                console.error(error)
              })
          } else {
            productStore
              .fetchCmdyList({
                PageNum: 0,
                PageSize: 0,
                CmdyClassId: cmdyClassId,
                CmdyThemeId: [],
                CmdyTagId: cmdyTagId.value,
                OrderType: productStore.state.selectedOrderType,
                IsIncludeHiddenCmdy: false
              })
              .then(() => {
                productStore.state.cmdyTagList.forEach((item: any) => {
                  if (item.CmdyTagId === cmdyTagId.value) {
                    productStore.setSelectedCmdyTag(item)
                  }
                })
              })
            productStore.setSelectedCmdy({})
            // productStore.setSelectedCmdyTag({})
          }
        })
        .catch()
    }
    const closeRecItem = () => {
      if (
        productStore.state.showRectangleItem === true &&
        store.state.showLoading === 0 &&
        store.state.showLoginModal === false
      ) {
        productStore.setShowRectangleItem(false)
      }
    }
    const clickProductClassIconList = () => {
      productStore.setSelectedCmdyTag({})
      // selectThemeList.value = []
      route.query.cmdyId = ''
      route.query.cmdyThemeId = []
      route.query.cmdyClassId =
        productStore.state.selectedCmdyClass.CmdyClassId
      // route.query.subCmdyClassId = productStore.state.selectedCmdyClass.CmdyClassId
      route.query.subCmdyClassId = ''
      productStore.setShowRectangleItem(false)

      // getCmdyClassList()
      router.push({
        query: {
          cmdyClassId: productStore.state.selectedCmdyClass.CmdyClassId,
          subCmdyClassId: '',
          cmdyTagId: ''
          // dateNow: Date.now()
        }
      })
    }
    const clickProductItemList = () => {
      route.query.cmdyId = ''
      route.query.cmdyThemeId = ''
      route.query.subCmdyClassId = ''
      route.query.cmdyTagList = ''
      productStore.setSelectedCmdyTag({})
      // router.push({ name: 'home' })
      getCmdyClassList()
    }
    const clickProductClassItem = () => {
      route.query.cmdyId = ''
      route.query.cmdyThemeId = ''
      route.query.cmdyTagId = productStore.state.selectedCmdyTag.CmdyTagId
      getCmdyClassList()
      router.push({
        query: {
          cmdyClassId: productStore.state.selectedCmdyClass.CmdyClassId,
          subCmdyClassId: '',
          cmdyTagId: productStore.state.selectedCmdyTag.CmdyTagId
          // dateNow: Date.now()
        }
      })
    }
    const getCmdyThemeList = () => {
      productStore.fetchCmdyThemeList('').then((res) => {
        cmdyThemeList.value = res.cmdyThemeList
      })
    }
    watch(
      () => route.query.cmdyClassId,
      (newVal) => {
        if (newVal !== undefined && route.name === 'customMade') {
          productStore.setSelectedCmdyTag({})
          // selectThemeList.value = []
          route.query.cmdyId = ''
          route.query.cmdyThemeId = ''
          route.query.cmdyTagId = ''
          // route.query.subCmdyClassId = productStore.state.selectedCmdyClass.CmdyClassId
          // route.query.subCmdyClassId = ''
          productStore.setShowRectangleItem(false)
          getCmdyClassList()
        }
      }
    )
    watch(
      () => route.query.subCmdyClassId,
      (newVal) => {
        if (newVal !== undefined && route.name === 'customMade') {
          if (newVal === '') {
            productStore.setSelectedCmdyTag({})
          }
          getCmdyClassList()
        }
      }
    )
    watch(
      () => productStore.state.selectedOrderType,
      () => {
        const cmdyClassId = ref(
          productStore.state.selectedCmdyClass.CmdyClassId
        )
        productStore.fetchCmdyList({
          PageNum: 0,
          PageSize: 0,
          CmdyClassId: cmdyClassId.value,
          CmdyThemeId: [],
          CmdyTagId: '',
          OrderType: productStore.state.selectedOrderType,
          IsIncludeHiddenCmdy: false
        })
        productStore.setSelectedCmdy({})
        productStore.setSelectedCmdyTag({})
      }
    )
    // watch(
    //   () => route.query.dateNow,
    //   () => {
    //     if (route.name === 'customMade') {
    //       // productStore.setSelectedCmdyClass({})
    //       getCmdyClassList()
    //     }
    //   }
    // )
    onMounted(() => {
      productStore.resetAllStatus()
      getCmdyClassItem()
      getCmdyClassList()
      // getCmdyThemeList()
    })
    return {
      cmdyThemeList,
      cmdyClassItem,
      RefProductItemList,
      RefProductItemListForMobile,
      productStore,
      getCmdyClassList,
      closeRecItem,
      clickProductClassIconList,
      clickProductItemList,
      clickProductClassItem,
      isMobile
    }
  }
})
