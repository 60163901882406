import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0279f072"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "modal-mask"
}
const _hoisted_2 = { class: "modal-wrapper" }
const _hoisted_3 = {
  class: "modal-container",
  ref: "modalElement"
}
const _hoisted_4 = { class: "modal-title cont_16" }
const _hoisted_5 = { class: "modal-body" }
const _hoisted_6 = { class: "modal-footer clear_fix" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "modal" }, {
    default: _withCtx(() => [
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "title", {}, undefined, true)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _renderSlot(_ctx.$slots, "body", {}, undefined, true)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
                ]),
                _renderSlot(_ctx.$slots, "close-button", {}, undefined, true)
              ], 512)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}