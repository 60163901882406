import { OrderShoppingCartCmdy } from '@/types/ResponseType'
import cloneDeep from 'lodash/cloneDeep'

const shoppingCartList = 'SHOPPING_CART_LIST'

export const setShoppingCartInLocal = (
  cmdy: OrderShoppingCartCmdy,
  status: string,
  index: any
) => {
  const _shoppingCartList = JSON.parse(cloneDeep(getShoppingCartOnLocal()))
  if (status === 'clean') {
    return localStorage.setItem(
      shoppingCartList,
      JSON.stringify({ CartTotalAmount: 0, OrderShoppingCartCmdyList: [] })
    )
  } else if (status === 'delete') {
    _shoppingCartList.OrderShoppingCartCmdyList.splice(index, 1)
    let _totalAmt = 0
    _shoppingCartList.OrderShoppingCartCmdyList.forEach((item: any) => {
      _totalAmt += item.MaxUnitFeeReal * item.CmdyQty
    })
    _shoppingCartList.CartTotalAmount = _totalAmt
    return localStorage.setItem(
      shoppingCartList,
      JSON.stringify(_shoppingCartList)
    )
  } else if (status === 'update') {
    // _shoppingCartList.OrderShoppingCartCmdyList.splice(index, 1)
    _shoppingCartList.OrderShoppingCartCmdyList[index] = cmdy
    let _totalAmt = 0
    _shoppingCartList.OrderShoppingCartCmdyList.forEach((item: any) => {
      let _addOnAmt = 0
      item.AddOnCmdyList?.forEach((addOnItem: any) => {
        _addOnAmt += addOnItem.UnitFeeReal * addOnItem.CmdyQty
      })
      _totalAmt += item.MaxUnitFeeReal * item.CmdyQty + _addOnAmt
    })
    _shoppingCartList.CartTotalAmount = _totalAmt
    return localStorage.setItem(
      shoppingCartList,
      JSON.stringify(_shoppingCartList)
    )
  } else {
    let isUpdate = false
    _shoppingCartList.OrderShoppingCartCmdyList.forEach((item: any) => {
      if (item.CmdyId === cmdy.CmdyId && cmdy.AddOnCmdyList.length === 0) {
        item.CmdyQty += cmdy.CmdyQty
        item = cmdy
        isUpdate = true
      }
    })
    if (!isUpdate) {
      _shoppingCartList.OrderShoppingCartCmdyList.push(cmdy)
    }
    let _totalAmt = 0
    _shoppingCartList.OrderShoppingCartCmdyList.forEach((item: any) => {
      let _addOnAmt = 0
      item.AddOnCmdyList?.forEach((addOnItem: any) => {
        _addOnAmt += addOnItem.UnitFeeReal * addOnItem.CmdyQty
      })
      _totalAmt += item.MaxUnitFeeReal * item.CmdyQty + _addOnAmt
    })
    _shoppingCartList.CartTotalAmount = _totalAmt
    return localStorage.setItem(
      shoppingCartList,
      JSON.stringify(_shoppingCartList)
    )
  }
}

export const getShoppingCartOnLocal = (): any => {
  return (
    localStorage.getItem(shoppingCartList) ||
    JSON.stringify({ CartTotalAmount: 0, OrderShoppingCartCmdyList: [] })
  )
}
