
import {
  defineComponent,
  ref,
  reactive,
  watch,
  toRef,
  computed,
  onMounted,
  onUnmounted,
  provide
} from 'vue'
import Collapse from '@/components/common/Collapse.vue'
import CheckBoxGroup from '@/components/common/CheckBoxGroup.vue'
import CheckBox from '@/components/common/CheckBox.vue'
import VueSlider from 'vue-slider-component'
import Btn from '@/components/common/Btn.vue'
import { get } from 'js-cookie'
import productStore from '@/composition/productStore'
export default defineComponent({
  name: 'g-filter-dropdown',
  emits: ['update:selectThemeList', 'getCmdyClassList'],
  components: {
    Collapse,
    CheckBoxGroup,
    CheckBox,
    // VueSlider,
    Btn
  },
  props: {
    cmdyThemeList: {
      type: Array
    },
    selectThemeList: {
      type: Array,
      default: [] as any
    }
  },
  setup (props, context) {
    provide('productStore', productStore)
    const { emit } = context
    const openFilter = () => {
      showContent.value = !showContent.value
      productStore.setShowRectangleItem(false)
    }
    const closeFilter = () => {
      showContent.value = false
    }

    const selectThemeListRef = computed({
      get: () => props.selectThemeList,
      set: (val: any) => {
        emit('update:selectThemeList', val)
      }
    })
    const cleanSelectTheme = () => {
      emit('update:selectThemeList', [])
    }
    // toRef(props, 'selectThemeList')
    const show1 = ref(true)
    const show2 = ref(true)
    const show3 = ref(true)
    const showContent = ref(false)
    const check = ref([])

    const sliderModel = reactive([100, 1000])
    watch(
      () => showContent.value,
      (newVal, oldVal) => {
        // if (newVal) {
        //   document.body.classList.add('modal-open')
        // } else {
        //   document.body.classList.remove('modal-open')
        // }
        // console.log(newVal, oldVal)
      }
    )
    const scrollFunc = () => {
      showContent.value = false
    }
    onMounted(() => {
      (document.getElementById('app') as HTMLBodyElement).addEventListener('scroll', scrollFunc)
      // window.addEventListener('scroll', scrollFunc)
    })

    onUnmounted(() => {
      (document.getElementById('app') as HTMLBodyElement).removeEventListener('scroll', scrollFunc)
      // window.removeEventListener('scroll', scrollFunc)
    })
    return {
      openFilter,
      closeFilter,
      selectThemeListRef,
      cleanSelectTheme,
      check,
      show1,
      show2,
      show3,
      showContent,
      sliderModel
    }
  }
})
