import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "space-y-3 mb-3 hidden sm:block"
}
const _hoisted_2 = { class: "flex flex-col sm:flex-row sm:space-x-3" }
const _hoisted_3 = { class: "flex flex-col sm:basis-1/2 sm:gap-0 space-y-3" }
const _hoisted_4 = { class: "flex flex-row justify-between" }
const _hoisted_5 = { class: "flex flex-row justify-between" }
const _hoisted_6 = {
  key: 0,
  class: "flex flex-col sm:flex-row sm:space-x-3"
}
const _hoisted_7 = { class: "flex flex-col sm:basis-1/2 sm:gap-0 space-y-3" }
const _hoisted_8 = { class: "flex flex-row space-x-3" }
const _hoisted_9 = { class: "flex flex-row space-x-3" }
const _hoisted_10 = {
  key: 1,
  class: "space-y-3 mb-3 sm:hidden"
}
const _hoisted_11 = { class: "flex flex-col sm:flex-row sm:space-x-3" }
const _hoisted_12 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_13 = { class: "flex flex-row justify-between pb-3" }
const _hoisted_14 = { class: "flex flex-row space-x-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SquareItemFullImage = _resolveComponent("SquareItemFullImage")!
  const _component_RectangleItem = _resolveComponent("RectangleItem")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productStore.state.cmdyList, (product, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        (index % 10 == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_SquareItemFullImage, {
                  width: 'inherit',
                  productItem: _ctx.productStore.state.cmdyList[index],
                  "onUpdate:productItem": ($event: any) => ((_ctx.productStore.state.cmdyList[index]) = $event),
                  image: _ctx.productStore.state.cmdyList[index].Image.PcWeb,
                  class: "mb-2 sm:mb-0 sm:basis-1/2",
                  onClick: ($event: any) => (
              _ctx.getSelectProduct(_ctx.productStore.state.cmdyList[index], index, false)
            )
                }, null, 8, ["productItem", "onUpdate:productItem", "image", "onClick"]),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    (_ctx.productStore.state.cmdyList[index + 1] != null)
                      ? (_openBlock(), _createBlock(_component_SquareItemFullImage, {
                          key: 0,
                          width: 'inherit',
                          productItem: _ctx.productStore.state.cmdyList[index + 1],
                          "onUpdate:productItem": ($event: any) => ((_ctx.productStore.state.cmdyList[index + 1]) = $event),
                          image: _ctx.productStore.state.cmdyList[index + 1].Image.PcWeb,
                          class: "basis-[49%]",
                          onClick: ($event: any) => (
                  _ctx.getSelectProduct(
                    _ctx.productStore.state.cmdyList[index + 1],
                    index + 1,
                    false
                  )
                )
                        }, null, 8, ["productItem", "onUpdate:productItem", "image", "onClick"]))
                      : _createCommentVNode("", true),
                    (_ctx.productStore.state.cmdyList[index + 2] != null)
                      ? (_openBlock(), _createBlock(_component_SquareItemFullImage, {
                          key: 1,
                          width: 'inherit',
                          productItem: _ctx.productStore.state.cmdyList[index + 2],
                          "onUpdate:productItem": ($event: any) => ((_ctx.productStore.state.cmdyList[index + 2]) = $event),
                          image: _ctx.productStore.state.cmdyList[index + 2].Image.PcWeb,
                          class: "basis-[49%]",
                          onClick: ($event: any) => (
                  _ctx.getSelectProduct(
                    _ctx.productStore.state.cmdyList[index + 2],
                    index + 2,
                    false
                  )
                )
                        }, null, 8, ["productItem", "onUpdate:productItem", "image", "onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    (_ctx.productStore.state.cmdyList[index + 3] != null)
                      ? (_openBlock(), _createBlock(_component_SquareItemFullImage, {
                          key: 0,
                          width: 'inherit',
                          productItem: _ctx.productStore.state.cmdyList[index + 3],
                          "onUpdate:productItem": ($event: any) => ((_ctx.productStore.state.cmdyList[index + 3]) = $event),
                          image: _ctx.productStore.state.cmdyList[index + 3].Image.PcWeb,
                          class: "basis-[49%]",
                          onClick: ($event: any) => (
                  _ctx.getSelectProduct(
                    _ctx.productStore.state.cmdyList[index + 3],
                    index + 3,
                    false
                  )
                )
                        }, null, 8, ["productItem", "onUpdate:productItem", "image", "onClick"]))
                      : _createCommentVNode("", true),
                    (_ctx.productStore.state.cmdyList[index + 4] != null)
                      ? (_openBlock(), _createBlock(_component_SquareItemFullImage, {
                          key: 1,
                          width: 'inherit',
                          productItem: _ctx.productStore.state.cmdyList[index + 4],
                          "onUpdate:productItem": ($event: any) => ((_ctx.productStore.state.cmdyList[index + 4]) = $event),
                          image: _ctx.productStore.state.cmdyList[index + 4].Image.PcWeb,
                          class: "basis-[49%]",
                          onClick: ($event: any) => (
                  _ctx.getSelectProduct(
                    _ctx.productStore.state.cmdyList[index + 4],
                    index + 4,
                    false
                  )
                )
                        }, null, 8, ["productItem", "onUpdate:productItem", "image", "onClick"]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _createElementVNode("div", {
                ref_for: true,
                ref: 
            (el) => {
              _ctx.recList.push({
                isMobile: false,
                number: 1,
                index: index,
                content: el,
              });
            }
          
              }, [
                (
              _ctx.productStore.state.showRectangleItem &&
              _ctx.selectProductIndex - index < 5 &&
              _ctx.selectProductIndex - index > -1
            )
                  ? (_openBlock(), _createBlock(_component_RectangleItem, {
                      key: 0,
                      selectItem: _ctx.productStore.state.selectedCmdy,
                      "onUpdate:selectItem": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.productStore.state.selectedCmdy) = $event)),
                      isGoProductDetail: true
                    }, null, 8, ["selectItem"]))
                  : _createCommentVNode("", true)
              ], 512),
              (_ctx.productStore.state.cmdyList[index + 5] != null)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        (_ctx.productStore.state.cmdyList[index + 5] != null)
                          ? (_openBlock(), _createBlock(_component_SquareItemFullImage, {
                              key: 0,
                              width: 'inherit',
                              productItem: _ctx.productStore.state.cmdyList[index + 5],
                              "onUpdate:productItem": ($event: any) => ((_ctx.productStore.state.cmdyList[index + 5]) = $event),
                              image: _ctx.productStore.state.cmdyList[index + 5].Image.PcWeb,
                              class: "basis-1/2",
                              onClick: ($event: any) => (
                  _ctx.getSelectProduct(
                    _ctx.productStore.state.cmdyList[index + 5],
                    index + 5,
                    false
                  )
                )
                            }, null, 8, ["productItem", "onUpdate:productItem", "image", "onClick"]))
                          : _createCommentVNode("", true),
                        (_ctx.productStore.state.cmdyList[index + 6] != null)
                          ? (_openBlock(), _createBlock(_component_SquareItemFullImage, {
                              key: 1,
                              width: 'inherit',
                              productItem: _ctx.productStore.state.cmdyList[index + 6],
                              "onUpdate:productItem": ($event: any) => ((_ctx.productStore.state.cmdyList[index + 6]) = $event),
                              image: _ctx.productStore.state.cmdyList[index + 6].Image.PcWeb,
                              class: "basis-1/2",
                              onClick: ($event: any) => (
                  _ctx.getSelectProduct(
                    _ctx.productStore.state.cmdyList[index + 6],
                    index + 6,
                    false
                  )
                )
                            }, null, 8, ["productItem", "onUpdate:productItem", "image", "onClick"]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        (_ctx.productStore.state.cmdyList[index + 7] != null)
                          ? (_openBlock(), _createBlock(_component_SquareItemFullImage, {
                              key: 0,
                              width: 'inherit',
                              productItem: _ctx.productStore.state.cmdyList[index + 7],
                              "onUpdate:productItem": ($event: any) => ((_ctx.productStore.state.cmdyList[index + 7]) = $event),
                              image: _ctx.productStore.state.cmdyList[index + 7].Image.PcWeb,
                              class: "basis-1/2",
                              onClick: ($event: any) => (
                  _ctx.getSelectProduct(
                    _ctx.productStore.state.cmdyList[index + 7],
                    index + 7,
                    false
                  )
                )
                            }, null, 8, ["productItem", "onUpdate:productItem", "image", "onClick"]))
                          : _createCommentVNode("", true),
                        (_ctx.productStore.state.cmdyList[index + 8] != null)
                          ? (_openBlock(), _createBlock(_component_SquareItemFullImage, {
                              key: 1,
                              width: 'inherit',
                              productItem: _ctx.productStore.state.cmdyList[index + 8],
                              "onUpdate:productItem": ($event: any) => ((_ctx.productStore.state.cmdyList[index + 8]) = $event),
                              image: _ctx.productStore.state.cmdyList[index + 8].Image.PcWeb,
                              class: "basis-1/2",
                              onClick: ($event: any) => (
                  _ctx.getSelectProduct(
                    _ctx.productStore.state.cmdyList[index + 8],
                    index + 8,
                    false
                  )
                )
                            }, null, 8, ["productItem", "onUpdate:productItem", "image", "onClick"]))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    (_ctx.productStore.state.cmdyList[index + 9] != null)
                      ? (_openBlock(), _createBlock(_component_SquareItemFullImage, {
                          key: 0,
                          width: 'inherit',
                          productItem: _ctx.productStore.state.cmdyList[index + 9],
                          "onUpdate:productItem": ($event: any) => ((_ctx.productStore.state.cmdyList[index + 9]) = $event),
                          image: _ctx.productStore.state.cmdyList[index + 9].Image.PcWeb,
                          class: "mt-2 sm:mt-0 basis-1/2",
                          onClick: ($event: any) => (
              _ctx.getSelectProduct(
                _ctx.productStore.state.cmdyList[index + 9],
                index + 9,
                false
              )
            )
                        }, null, 8, ["productItem", "onUpdate:productItem", "image", "onClick"]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                ref_for: true,
                ref: 
            (el) => {
              _ctx.recList.push({
                isMobile: false,
                number: 2,
                index: index,
                content: el,
              });
            }
          
              }, [
                (
              _ctx.productStore.state.showRectangleItem &&
              _ctx.selectProductIndex - index >= 5 &&
              _ctx.selectProductIndex - index < 10
            )
                  ? (_openBlock(), _createBlock(_component_RectangleItem, {
                      key: 0,
                      selectItem: _ctx.selectProductRef,
                      "onUpdate:selectItem": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectProductRef) = $event)),
                      isGoProductDetail: true
                    }, null, 8, ["selectItem"]))
                  : _createCommentVNode("", true)
              ], 512)
            ]))
          : _createCommentVNode("", true),
        (index % 5 == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_SquareItemFullImage, {
                  width: 'inherit',
                  productItem: _ctx.productStore.state.cmdyList[index],
                  "onUpdate:productItem": ($event: any) => ((_ctx.productStore.state.cmdyList[index]) = $event),
                  image: _ctx.productStore.state.cmdyList[index].Image.PcWeb,
                  class: "mb-3 sm:mb-0 sm:basis-1/2",
                  onClick: ($event: any) => (
              _ctx.getSelectProduct(_ctx.productStore.state.cmdyList[index], index, true)
            )
                }, null, 8, ["productItem", "onUpdate:productItem", "image", "onClick"]),
                _createElementVNode("div", {
                  ref_for: true,
                  ref: 
              (el) => {
                _ctx.recList.push({
                  isMobile: true,
                  number: 1,
                  index: index,
                  content: el,
                });
              }
            
                }, [
                  (
                _ctx.productStore.state.showRectangleItem &&
                _ctx.selectProductIndex - index < 1 &&
                _ctx.selectProductIndex - index > -1
              )
                    ? (_openBlock(), _createBlock(_component_RectangleItem, {
                        key: 0,
                        selectItem: _ctx.selectProductRef,
                        "onUpdate:selectItem": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectProductRef) = $event)),
                        isGoProductDetail: true,
                        class: "mb-3"
                      }, null, 8, ["selectItem"]))
                    : _createCommentVNode("", true)
                ], 512),
                (_ctx.productStore.state.cmdyList[index + 1] != null)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        (_ctx.productStore.state.cmdyList[index + 1] != null)
                          ? (_openBlock(), _createBlock(_component_SquareItemFullImage, {
                              key: 0,
                              width: 'inherit',
                              productItem: _ctx.productStore.state.cmdyList[index + 1],
                              "onUpdate:productItem": ($event: any) => ((_ctx.productStore.state.cmdyList[index + 1]) = $event),
                              image: _ctx.productStore.state.cmdyList[index + 1].Image.PcWeb,
                              class: "basis-[48.5%]",
                              onClick: ($event: any) => (
                  _ctx.getSelectProduct(
                    _ctx.productStore.state.cmdyList[index + 1],
                    index + 1,
                    true
                  )
                )
                            }, null, 8, ["productItem", "onUpdate:productItem", "image", "onClick"]))
                          : _createCommentVNode("", true),
                        (_ctx.productStore.state.cmdyList[index + 2] != null)
                          ? (_openBlock(), _createBlock(_component_SquareItemFullImage, {
                              key: 1,
                              width: 'inherit',
                              productItem: _ctx.productStore.state.cmdyList[index + 2],
                              "onUpdate:productItem": ($event: any) => ((_ctx.productStore.state.cmdyList[index + 2]) = $event),
                              image: _ctx.productStore.state.cmdyList[index + 2].Image.PcWeb,
                              class: "basis-[48.5%]",
                              onClick: ($event: any) => (
                  _ctx.getSelectProduct(
                    _ctx.productStore.state.cmdyList[index + 2],
                    index + 2,
                    true
                  )
                )
                            }, null, 8, ["productItem", "onUpdate:productItem", "image", "onClick"]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", {
                        ref_for: true,
                        ref: 
                (el) => {
                  _ctx.recList.push({
                    isMobile: true,
                    number: 2,
                    index: index,
                    content: el,
                  });
                }
              
                      }, [
                        (
                  _ctx.productStore.state.showRectangleItem &&
                  _ctx.selectProductIndex - index < 3 &&
                  _ctx.selectProductIndex - index > 0
                )
                          ? (_openBlock(), _createBlock(_component_RectangleItem, {
                              key: 0,
                              selectItem: _ctx.selectProductRef,
                              "onUpdate:selectItem": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectProductRef) = $event)),
                              isGoProductDetail: true
                            }, null, 8, ["selectItem"]))
                          : _createCommentVNode("", true)
                      ], 512),
                      _createElementVNode("div", _hoisted_14, [
                        (_ctx.productStore.state.cmdyList[index + 3] != null)
                          ? (_openBlock(), _createBlock(_component_SquareItemFullImage, {
                              key: 0,
                              width: 'inherit',
                              productItem: _ctx.productStore.state.cmdyList[index + 3],
                              "onUpdate:productItem": ($event: any) => ((_ctx.productStore.state.cmdyList[index + 3]) = $event),
                              image: _ctx.productStore.state.cmdyList[index + 3].Image.PcWeb,
                              class: "basis-[48.5%]",
                              onClick: ($event: any) => (
                  _ctx.getSelectProduct(
                    _ctx.productStore.state.cmdyList[index + 3],
                    index + 3,
                    true
                  )
                )
                            }, null, 8, ["productItem", "onUpdate:productItem", "image", "onClick"]))
                          : _createCommentVNode("", true),
                        (_ctx.productStore.state.cmdyList[index + 4] != null)
                          ? (_openBlock(), _createBlock(_component_SquareItemFullImage, {
                              key: 1,
                              width: 'inherit',
                              productItem: _ctx.productStore.state.cmdyList[index + 4],
                              "onUpdate:productItem": ($event: any) => ((_ctx.productStore.state.cmdyList[index + 4]) = $event),
                              image: _ctx.productStore.state.cmdyList[index + 4].Image.PcWeb,
                              class: "basis-[48.5%]",
                              onClick: ($event: any) => (
                  _ctx.getSelectProduct(
                    _ctx.productStore.state.cmdyList[index + 4],
                    index + 4,
                    true
                  )
                )
                            }, null, 8, ["productItem", "onUpdate:productItem", "image", "onClick"]))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", {
                ref_for: true,
                ref: 
            (el) => {
              _ctx.recList.push({
                isMobile: true,
                number: 3,
                index: index,
                content: el,
              });
            }
          
              }, [
                (
              _ctx.productStore.state.showRectangleItem &&
              _ctx.selectProductIndex - index < 5 &&
              _ctx.selectProductIndex - index > 2
            )
                  ? (_openBlock(), _createBlock(_component_RectangleItem, {
                      key: 0,
                      selectItem: _ctx.selectProductRef,
                      "onUpdate:selectItem": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectProductRef) = $event)),
                      isGoProductDetail: true
                    }, null, 8, ["selectItem"]))
                  : _createCommentVNode("", true)
              ], 512)
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}