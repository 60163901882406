
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'g-transition-plus-button',
  props: {
    width: {
      type: Number,
      default: 12
    },
    height: {
      type: Number,
      default: 12
    },
    actived: {
      type: Boolean,
      default: false
    }
  }
  // setup () {

  // }
})
