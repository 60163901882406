
import { defineComponent, computed } from 'vue'
export default defineComponent({
  props: {
    shopHallInfo: null
  },
  components: {},
  setup (props, context) {
    const { emit } = context
    const shopHallInfoRef = computed({
      get: () => {
        const value = props.shopHallInfo
        value.Desc = value.Desc?.replace(/\n/g, '<br/>')
        return value
      },
      set: (val) => {
        // console.log(val)

        emit('update:shopHallInfo', val)
      }
    })
    // const shopHallInfoRef = toRef(props, 'shopHallInfo')
    // shopHallInfoRef.value.Desc = shopHallInfoRef.value?.replace(/\n/g, '<br/>')
    return { shopHallInfoRef }
  }
})
