
import { defineComponent, nextTick, ref, watch } from 'vue'
export default defineComponent({
  emits: ['editPortfolioName', 'update:modelValue'],
  props: {
    placeholder: {
      type: String,
      default: '搜尋'
    },
    modelValue: {
      type: String,
      require: false,
      default: ''
    },
    editPortfolioName: {
      type: Function,
      default: null
    },
    portfolio: {}
  },
  setup (props, context) {
    const { emit } = context
    const nameInputRef = ref()
    const name = ref('')
    const isEdit = ref(false)
    // const change = (event: Event) => {
    //   emit('update:modelValue', (event.target as HTMLInputElement).value)
    // }
    const editName = () => {
      isEdit.value = true
      name.value = props.modelValue.toString()
    }
    const editDone = () => {
      isEdit.value = false
      emit('update:modelValue', name.value)
      emit('editPortfolioName')
    }
    watch(
      () => isEdit.value,
      (newVal) => {
        if (newVal === true) {
          nextTick(() => {
            nameInputRef.value.focus()
          })
        }
      }
    )
    return {
      nameInputRef,
      isEdit,
      name,
      // change,
      editName,
      editDone
    }
  }
})
