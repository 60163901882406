import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "product-item-list" }
const _hoisted_2 = {
  key: 0,
  class: "flex justify-end gap-2 pr-[16px] sm:mb-5"
}
const _hoisted_3 = {
  key: 0,
  class: "flex flex-wrap justify-start space-b gap-[10px] sm:gap-[25px] mt-5"
}
const _hoisted_4 = { class: "flex justify-between w-full px-[16px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterDropDown = _resolveComponent("FilterDropDown")!
  const _component_DropDown = _resolveComponent("DropDown")!
  const _component_ProductItem = _resolveComponent("ProductItem")!
  const _component_RectangleItem = _resolveComponent("RectangleItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.withSelect)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_FilterDropDown, {
            selectThemeList: _ctx.selectThemeListRef,
            "onUpdate:selectThemeList": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectThemeListRef) = $event)),
            cmdyThemeList: _ctx.cmdyThemeListRef,
            "onUpdate:cmdyThemeList": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.cmdyThemeListRef) = $event)),
            onGetCmdyClassList: _ctx.getCmdyClassList
          }, null, 8, ["selectThemeList", "cmdyThemeList", "onGetCmdyClassList"]),
          _createVNode(_component_DropDown)
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productStore.state.cmdyList, (productItem, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        (index % _ctx.row == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.row, (n) => {
                return (_openBlock(), _createBlock(_component_ProductItem, {
                  key: n,
                  style: _normalizeStyle({ 'flex-basis': `calc(100%/${_ctx.row} - 20px)` }),
                  class: "hidden sm:block",
                  product: _ctx.productStore.state.cmdyList[index + (n - 1)],
                  "onUpdate:product": ($event: any) => ((_ctx.productStore.state.cmdyList[index + (n - 1)]) = $event),
                  onClick: ($event: any) => (
            _ctx.getSelectProduct(
              _ctx.productStore.state.cmdyList[index + (n - 1)],
              index + (n - 1)
            )
          )
                }, null, 8, ["style", "product", "onUpdate:product", "onClick"]))
              }), 128)),
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.row, (n) => {
                  return (_openBlock(), _createBlock(_component_ProductItem, {
                    key: n,
                    style: _normalizeStyle({ 'flex-basis': `calc(100%/${_ctx.row} - 5px)` }),
                    class: "sm:hidden",
                    product: _ctx.productStore.state.cmdyList[index + (n - 1)],
                    "onUpdate:product": ($event: any) => ((_ctx.productStore.state.cmdyList[index + (n - 1)]) = $event),
                    onClick: ($event: any) => (
              _ctx.getSelectProduct(
                _ctx.productStore.state.cmdyList[index + (n - 1)],
                index + (n - 1)
              )
            )
                  }, null, 8, ["style", "product", "onUpdate:product", "onClick"]))
                }), 128))
              ]),
              _createElementVNode("div", {
                class: "w-full",
                ref_for: true,
                ref: 
            (el) => {
              _ctx.recList.push({
                index: index,
                content: el,
              });
            }
          
              }, [
                (
              _ctx.productStore.state.showRectangleItem &&
              _ctx.selectProductIndex - index < _ctx.row &&
              _ctx.selectProductIndex - index > -1
            )
                  ? (_openBlock(), _createBlock(_component_RectangleItem, {
                      key: 0,
                      onChangeOption: _ctx.changeOption,
                      onSmoothToTop: _ctx.smoothToTop
                    }, null, 8, ["onChangeOption", "onSmoothToTop"]))
                  : _createCommentVNode("", true)
              ], 512)
            ]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}