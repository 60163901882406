<template>
  <div class="fullScreen" v-if="store.state.showLoading !== 0">
    <div id="feedLoading">
      <!-- <div class="loading-title">asd</div> -->
      <div class="loading-body"></div>
    </div>
  </div>
</template>
<script>
import { defineComponent, provide, watch } from 'vue'
import store from '@/composition/store'
export default defineComponent({
  props: {},
  setup () {
    provide('store', store)
    watch(
      () => store.state.showLoading,
      (newVal, oldVal) => {
        // if (newVal) {
        //   document.body.classList.add('modal-open')
        // } else {
        //   document.body.classList.remove('modal-open')
        // }
        // console.log(newVal, oldVal)
      }
    )
    return { store }
  }
  // mounted () {
  //   this.$bus.$on(EventTypes.LOADING_SHOW, () => {
  //     this.showLoading = true;
  //   });

  //   this.$bus.$on(EventTypes.LOADING_HIDE, () => {
  //     this.showLoading = false;
  //   });
  // }
})
</script>
<style lang="scss" scoped>
.fullScreen {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999999;
  align-items: center;
  background: rgba(156, 149, 149, 0.1);

  .loading-body {
    width: calc(10vw + 3rem);
    height: calc(10vw + 3rem);
    //width: 120px;
    //height: 120px;
    position: fixed;
    left: calc((100vw - (8vw + 3rem)) / 2);
    top: 40%;
    font-size: calc(2vmin + 1rem);
    background: url("@/assets/img/G.Gift_loading.gif");
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .loading-title {
    font-size: calc(1vmin + 1rem);
  }
}

@media (max-width: 770px) {
  #feedLoading {
    top: calc(50% - 100px);
  }
}
</style>
