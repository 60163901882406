import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  class: "indicator",
  ref: "indicator"
}
const _hoisted_3 = { class: "sm:hidden overflow-y-auto h-[50px] relative" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  class: "indicator",
  ref: "mobilIndicator"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("ul", {
      class: _normalizeClass(["hidden tabs sm:flex gap-4 text-gary-text-color text-sm font-light", [_ctx.customClass]])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          class: _normalizeClass(["tab", [{ actived: _ctx.selected === index }]]),
          ref_for: true,
          ref: "tab",
          key: item.id || item,
          onClick: ($event: any) => (_ctx.tabSelect($event.target, index))
        }, [
          _renderSlot(_ctx.$slots, "default", { item: item }, () => [
            _createTextVNode(_toDisplayString(item.text), 1)
          ])
        ], 10, _hoisted_1))
      }), 128)),
      _createElementVNode("div", _hoisted_2, null, 512)
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("ul", {
        class: _normalizeClass(["tabs flex gap-4 text-gary-text-color text-sm font-light border-black-300-color border-b-[1px]", [
        _ctx.customClass,
        {
          'w-max': _ctx.customClass !== 'custom-coupon-tabs',
        },
      ]])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass(["min-w-fit tab", [{ actived: _ctx.selected === index }]]),
            ref_for: true,
            ref: "mobileTab",
            key: item.id || item,
            onClick: ($event: any) => (_ctx.mobileTabSelect($event.target, index))
          }, [
            _renderSlot(_ctx.$slots, "default", { item: item }, () => [
              _createTextVNode(_toDisplayString(item.text), 1)
            ])
          ], 10, _hoisted_4))
        }), 128)),
        _createElementVNode("div", _hoisted_5, null, 512)
      ], 2)
    ])
  ], 64))
}