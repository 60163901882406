
import { defineComponent, ref, toRef, computed, provide, nextTick } from 'vue'
import ModalBase from '@/components/common/ModalBase.vue'
import Tabs from '@/components/common/Tabs.vue'
import Input from '@/components/common/Input.vue'
import CheckBox from '@/components/common/CheckBox.vue'
import Btn from '@/components/common/Btn.vue'
import AddressInput from '@/components/common/AddressInput.vue'
import DatePicker from 'vue-datepicker-next'
import Toggle from '@/components/common/Toggle.vue'
import ActivityComponent from '@/components/ActivityComponent.vue'
import store from '@/composition/store'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import { useToast, POSITION } from 'vue-toastification'
import { useRouter } from 'vue-router'
// import { Form } from 'vee-validate'
import { AppliedPromotion, CmdyCoupon } from '@/types/ResponseType'
import moment from 'moment'

export default defineComponent({
  emits: [
    'update:isUpdate',
    'update:show',
    'cancel',
    'ok',
    'update:selectCouponList'
  ],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    selectCouponList: {
      type: Array,
      default: [] as CmdyCoupon[]
    },
    cmdyCouponList: {
      type: Array,
      default: [] as CmdyCoupon[]
    },
    activityList: {
      type: Array,
      default: [] as any[]
    },
    isUpdate: {
      type: Boolean
    }
  },
  components: {
    ModalBase,
    Input,
    Tabs,
    ActivityComponent
    // Btn,
    // AddressInput,
    // Toggle
  },
  setup (props, context) {
    const { emit } = context
    const toast = useToast()
    const promotionCode = ref('')
    const router = useRouter()
    // const isUpdateRef = computed({
    //   get: () => props.isUpdate,
    //   set: (val:any) => {
    //     emit('update:isUpdate', val)
    //   }
    // })
    const modalElement2 = ref(null)
    const tabSelected = ref(-1)
    const cmdyCouponListRef = toRef(props, 'cmdyCouponList') as any
    const tabSelect = (value: number) => {
      tabSelected.value = value
    }

    // const selectCouponListRef = toRef(props, 'selectCouponList') as any
    const selectCouponListRef = computed({
      get: () => props.selectCouponList as CmdyCoupon[],
      set: (val) => {
        console.log(val)

        emit('update:selectCouponList', val)
      }
    })
    const redeemCode = () => {
      if (promotionCode.value === '') {
        return toast.warning('請輸入優惠代碼', {
          position: POSITION.TOP_CENTER
        })
      }

      store
        .fetchUserInfo()
        .then(() => {
          const data = {
            Data: {
              PromotionCode: promotionCode.value
            }
          }
          request
            .post(
              window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.redeemCode,
              data
            )
            .then((res) => {
              emit('update:isUpdate', !props.isUpdate)
              promotionCode.value = ''
              // isUpdateRef.value = !isUpdateRef.value
              toast.success(res.data.Message, {
                position: POSITION.TOP_CENTER
              })
              const _cmdyCoupon: CmdyCoupon = {
                PromotionActivityId: res.data.Data.PromotionActivityId,
                PromotionCouponId: res.data.Data.PromotionCouponId
              }
              selectCouponListRef.value.push(_cmdyCoupon)
            })
            .catch((error) => {
              toast.warning(error.data.Message, {
                position: POSITION.TOP_CENTER
              })
            })
        }).catch(() => {
          return toast.warning('請先登入', {
            position: POSITION.TOP_CENTER
          })
        })
    }
    const choseCoupon = (cmdyCoupon: CmdyCoupon) => {
      if (cmdyCoupon.IsUsing) {
        selectCouponListRef.value.forEach((item, index) => {
          if (item.PromotionCouponId === cmdyCoupon.PromotionCouponId) {
            selectCouponListRef.value.splice(index, 1)
          }
        })
      } else {
        selectCouponListRef.value.push(cmdyCoupon)
      }
      emit('update:isUpdate', !props.isUpdate)
    }
    const calcDateIsLimit = (dateTime: any) => {
      const dateDiff = moment
        .utc(moment.utc(dateTime, 'YYYYMMDDHHmmss').local())
        .diff(Date.now(), 'minute')
      if (dateDiff < 1440) {
        return true
      } else {
        return false
      }
      // const dd = moment('2023-04-11 15:50:00')
      // const diffTime = date1.diff(Date.now(), 'minute')
      // const diff = dd.diff(Date.now(), 'minute')
      // const date3 = date2.diff(date1, 'minute')// 计算相差的分钟数
      // const h = Math.floor(date3 / 60)// 相差的小时数
      // 有效期限：{{
      //           moment
      //             .utc(
      //               moment
      //                 .utc(cmdyCoupon.EffectiveDateEnd, "YYYYMMDDHHmmss")
      //                 .local()
      //             )
      //             .format("YYYY/MM/DD")
      //         }}
    }
    const calcLimitTime = (dateTime: any) => {
      // const descTime = moment
      //   .utc(moment.utc(dateString, 'YYYYMMDDHHmmss').local() as any)
      //   .local()
      // let _date = ''
      // if (dateString !== '') {
      //   // _date = moment(dateString).format('YYYY-MM-DD HH:mm:ss')
      //   _date = descTime.format('YYYY-MM-DD HH:mm:ss')
      //   return _date
      // }

      const dateNow = moment
        .utc(moment.utc(Date.now()).local())
        .local()
        .format()

      console.log(dateNow)
      console.log(dateTime)
      console.log(
        moment
          .utc(moment.utc(dateTime, 'YYYYMMDDHHmmss').local())
          .local()
          .format()
      )
      const dateDiff = moment
        .utc(moment.utc(dateTime, 'YYYYMMDDHHmmss').local())
        .local()
        .diff(dateNow, 'minute')
      // const diffHour = Math.floor(dateDiff / 60)
      const diffHour = Math.floor(dateDiff / 60)
      if (diffHour > 0) {
        return diffHour + '小時'
      } else {
        return dateDiff + '分鐘'
      }
    }

    const promiseResolver = ref(
      (value?: any | PromiseLike<Record<string, never>> | undefined) => {
        return null
      }
    )
    const promiseReject = ref(
      (value?: any | PromiseLike<Record<string, never>> | undefined) => {
        return null
      }
    )
    const hideCancel = ref(true)
    const cancel = () => {
      /**
       * 取消按鈕通知
       * @event cancel
       * @type {none}
       */
      // this.$emit('cancel');
      promiseReject.value()
      emit('update:show', false)
    }
    const confirm = () => {
      /**
       * 確認按鈕通知
       * @event confirm
       * @type {none}
       */
      // this.$emit('confirm');
      promiseResolver.value()
      emit('update:show', false)
    }
    let parentElement = ref(null)
    const createConfirm = async (
      callback: (modalElement: any) => void
    ) => {
      emit('update:show', true)
      nextTick(() => {
        if (parentElement.value === null) {
          parentElement = (modalElement2.value as any).modalElement
        }
        console.log(parentElement)
        callback(parentElement)
      })
      // console.log(modalElement2)

      return new Promise((resolve: any, reject: any) => {
        promiseResolver.value = resolve
        promiseReject.value = reject
      })
    }

    const clickActivity = (activity: any) => {
      location.href = `/promotionList?promotionId=${activity.ActivityId}`
    }

    return {
      // isUpdateRef,
      selectCouponListRef,
      cmdyCouponListRef,
      promotionCode,
      redeemCode,
      moment,
      choseCoupon,
      calcDateIsLimit,
      calcLimitTime,
      tabSelect,
      tabSelected,
      modalElement2,
      createConfirm,
      cancel,
      confirm,
      clickActivity
    }
  }
})
