
import { isPropType } from '@/utils/tools'
import { computed, defineComponent } from 'vue'
export default defineComponent({
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  name: 'g-radio-button',
  props: {
    text: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number, Object, Boolean],
      default: ''
    },
    inputValue: {
      type: [String, Number, Object, Boolean]
    },
    width: {
      type: String,
      default: '16px'
    }
  },
  // computed: {
  //   selected () {
  //     return this.modelValue === this.inputValue
  //   }
  // },
  setup (props, context) {
    const { emit } = context
    const change = (event: Event) => {
      emit('update:modelValue', props.inputValue)
    }
    const selected = computed(() => {
      const value = props.inputValue as any
      // console.log('isChecked >>>', isChecked)
      if (isPropType(value, 'boolean')) {
        return value
      } else if (isPropType(value, 'object')) {
        return JSON.stringify(props.modelValue) === JSON.stringify(props.inputValue)
      } else {
        return props.modelValue === props.inputValue
      }
    })
    return {
      selected,
      change
    }
  }
})
