
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'g-icon',
  props: {
    width: {
      type: String,
      default: '16px'
    },
    iconName: {
      type: String,
      required: true,
      default: 'facebook'
    },
    text: {
      type: String,
      required: false,
      default: ''
    }
  },
  components: {}
})
