
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
export default defineComponent({
  name: 'g-breadcrumb',
  props: {
    breadcrumbList: {
      type: Array
    }
  },
  setup () {
    const route = useRoute()
    return {
      route
    }
  }
})
