
import { defineComponent, onMounted, provide, ref, watch } from 'vue'
import Title from '@/components/common/Title.vue'
import SearchInput from '@/components/common/SearchInput.vue'
import ProductItemList from '@/components/ProductItemList.vue'
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import store from '@/composition/store'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import productStore from '@/composition/productStore'
import map from 'lodash/map'
import { useRoute } from 'vue-router'
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
      default: '123'
    }
  },
  components: {
    Title,
    SearchInput,
    ProductItemList,
    Breadcrumb
  },
  setup () {
    provide('store', store)
    provide('productStore', productStore)
    const route = useRoute()
    const searchName = ref('')
    const fetchSearchCmdyList = (fetchSearchCmdyRequestData: any) => {
      store.setShowLoading(true)
      const data = {
        Data: fetchSearchCmdyRequestData
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.searchCmdy,
          data
        )
        .then((res) => {
          const resData = res.data.Data
          if (resData.CmdyList.length !== 0) {
            const cmdyList = map(resData.CmdyList, (item: any) => {
              if (item.Checked === undefined) {
                item.Checked = false
              }
              return item
            })
            productStore.setCmdyList(cmdyList)
          } else {
            productStore.setCmdyList([])
          }
          productStore.setSelectedCmdy({})

          store.setShowLoading(false)
        })
        .catch((error) => {
          store.setShowLoading(false)
          console.error(error)
        })
    }
    const getInput = (val: string) => {
      searchName.value = val
      fetchSearchCmdyList({
        pageNum: 0,
        pageSize: 0,
        Name: searchName.value,
        OrderType: productStore.state.selectedOrderType
      })
    }
    watch(
      () => productStore.state.selectedOrderType,
      () => {
        fetchSearchCmdyList({
          pageNum: 0,
          pageSize: 0,
          Name: searchName.value,
          OrderType: productStore.state.selectedOrderType
        })
      }
    )
    onMounted(() => {
      productStore.resetAllStatus()
      if (
        route.params.searchName !== undefined &&
        route.params.searchName !== ''
      ) {
        searchName.value = route.params.searchName.toString() || ''
        fetchSearchCmdyList({
          pageNum: 0,
          pageSize: 0,
          Name: route.params.searchName,
          OrderType: productStore.state.selectedOrderType
        })
      }
    })
    return { searchName, getInput }
  }
})
