import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "portfolio-item-list relative" }
const _hoisted_2 = { class: "my-[20px]" }
const _hoisted_3 = { class: "flex justify-end gap-2" }
const _hoisted_4 = { class: "text-xs leading-[40px]" }
const _hoisted_5 = { class: "flex flex-wrap justify-start space-b gap-[10px] sm:gap-[25px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spacer = _resolveComponent("Spacer")!
  const _component_PortfolioItem = _resolveComponent("PortfolioItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, " 共 " + _toDisplayString((_ctx.portfolioListRef||[]).length) + " 個作品 ", 1),
        _createVNode(_component_Spacer)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.portfolioListRef, (portfolioItem, index) => {
        return (_openBlock(), _createBlock(_component_PortfolioItem, {
          key: index,
          style: _normalizeStyle({ 'flex-basis': `calc(100%/${_ctx.row} - 20px)` }),
          class: "hidden sm:block",
          editPortfolioName: _ctx.editPortfolioNameRef,
          "onUpdate:editPortfolioName": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editPortfolioNameRef) = $event)),
          continueEdit: _ctx.continueEditRef,
          "onUpdate:continueEdit": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.continueEditRef) = $event)),
          delPortfolio: _ctx.delPortfolioRef,
          "onUpdate:delPortfolio": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.delPortfolioRef) = $event)),
          copyPortfolio: _ctx.copyPortfolioRef,
          "onUpdate:copyPortfolio": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.copyPortfolioRef) = $event)),
          portfolio: _ctx.portfolioListRef[index],
          "onUpdate:portfolio": ($event: any) => ((_ctx.portfolioListRef[index]) = $event)
        }, null, 8, ["style", "editPortfolioName", "continueEdit", "delPortfolio", "copyPortfolio", "portfolio", "onUpdate:portfolio"]))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.portfolioListRef, (portfolioItem, index) => {
        return (_openBlock(), _createBlock(_component_PortfolioItem, {
          key: index,
          style: _normalizeStyle({ 'flex-basis': `calc(100%/${_ctx.row} - 5px)` }),
          class: "sm:hidden",
          editPortfolioName: _ctx.editPortfolioNameRef,
          "onUpdate:editPortfolioName": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editPortfolioNameRef) = $event)),
          continueEdit: _ctx.continueEditRef,
          "onUpdate:continueEdit": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.continueEditRef) = $event)),
          delPortfolio: _ctx.delPortfolioRef,
          "onUpdate:delPortfolio": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.delPortfolioRef) = $event)),
          copyPortfolio: _ctx.copyPortfolioRef,
          "onUpdate:copyPortfolio": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.copyPortfolioRef) = $event)),
          portfolio: _ctx.portfolioListRef[index],
          "onUpdate:portfolio": ($event: any) => ((_ctx.portfolioListRef[index]) = $event)
        }, null, 8, ["style", "editPortfolioName", "continueEdit", "delPortfolio", "copyPortfolio", "portfolio", "onUpdate:portfolio"]))
      }), 128))
    ])
  ]))
}