import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "product-item-list" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-wrap justify-start space-b gap-[25px] mt-5"
}
const _hoisted_3 = { class: "flex justify-between w-full px-[18px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductItem = _resolveComponent("ProductItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cmdyList, (productItem, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        (index % _ctx.row == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.row, (n) => {
                  return (_openBlock(), _createBlock(_component_ProductItem, {
                    key: n,
                    product: _ctx.cmdyList[index + (n - 1)],
                    onClick: ($event: any) => (
              _ctx.getSelectProduct(
                _ctx.cmdyList[index + (n - 1)],
                index + (n - 1)
              )
            )
                  }, null, 8, ["product", "onClick"]))
                }), 128))
              ]),
              _createElementVNode("div", {
                class: "w-full",
                ref_for: true,
                ref: 
            (el) => {
              _ctx.recList.push({
                index: index,
                content: el,
              });
            }
          
              }, null, 512)
            ]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}