export const returnNumber = (value: string): string => {
  return value.replace(/[^\d]/g, '')
}
export const returnPositiveInteger = (value: string): string => {
  return value.replace(/[^\d]/g, '')
}

export const isEmail = (email: string): boolean => {
  // eslint-disable-next-line no-control-regex
  return /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/.test(
    email
  )
}
export const isPhoneNumber = (phoneNumber: string) => {
  return /^09[0-9]{8}$/.test(phoneNumber)
}

export const numberInput = (e: Event): string => {
  // debugger;
  const target = e.target as HTMLInputElement
  if (target.value) {
    const test = returnNumber(target.value)
    return test
  }

  return ''
}
export const positiveIntegerInput = (e: Event): string => {
  const obj = e.target as HTMLInputElement
  let value = obj.value
  value = value.replace(/[^\d]/g, '')
  value = value.replace(/^0\d+/g, '0')

  obj.value = value

  if (obj.value !== value) {
    obj.value = value
  }
  return value
}

export const unescapeHTML = (val: any) => {
  if (val === undefined) {
    val = ''
  }
  val = '' + val
  return val
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&')
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, "'")
}

// export const positiveIntegerInput = (e: Event): string => {
//   // debugger;
//   const target = e.target as HTMLInputElement
//   if (target.value) {
//     console.log(target.value)
//     let test = returnPositiveInteger(target.value)
//     console.log('test --- ', test)
//     if (!parseInt(test)) {
//       test = '0'
//     }
//     return test
//   }

//   return '0'
// }
