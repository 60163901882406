import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Icon, {
      iconName: _ctx.leftIconName,
      onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.leftIconName = 'swiper-left-active')),
      onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.leftIconName = 'left')),
      class: "hidden sm:block mr-1 transition-shadow cursor-pointer rounded-[30px] g-swiper-nav-prev",
      width: "60px",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('prevClick')))
    }, null, 8, ["iconName"]),
    _createVNode(_component_Icon, {
      iconName: _ctx.rightIconName,
      onMouseover: _cache[3] || (_cache[3] = ($event: any) => (_ctx.rightIconName = 'swiper-right-active')),
      onMouseleave: _cache[4] || (_cache[4] = ($event: any) => (_ctx.rightIconName = 'right')),
      class: "hidden sm:block ml-1 transition-shadow cursor-pointer rounded-[30px] g-swiper-nav-next",
      width: "60px",
      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('nextClick')))
    }, null, 8, ["iconName"]),
    _createVNode(_component_Icon, {
      iconName: _ctx.leftIconName,
      onTouchstart: _cache[6] || (_cache[6] = ($event: any) => (_ctx.leftIconName = 'swiper-left-active')),
      onTouchend: _cache[7] || (_cache[7] = ($event: any) => (_ctx.leftIconName = 'left')),
      class: "sm:hidden mr-1 transition-shadow cursor-pointer rounded-[30px]",
      width: "40px",
      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('prevClick')))
    }, null, 8, ["iconName"]),
    _createVNode(_component_Icon, {
      iconName: _ctx.rightIconName,
      onTouchstart: _cache[9] || (_cache[9] = ($event: any) => (_ctx.rightIconName = 'swiper-right-active')),
      onTouchend: _cache[10] || (_cache[10] = ($event: any) => (_ctx.rightIconName = 'right')),
      class: "sm:hidden ml-1 transition-shadow cursor-pointer rounded-[30px]",
      width: "40px",
      onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.$emit('nextClick')))
    }, null, 8, ["iconName"])
  ], 64))
}