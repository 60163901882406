import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "g-container center flex" }
const _hoisted_2 = { class: "flex flex-col max-h-[310px] pb-2.5 flex-wrap overflow-x-auto" }
const _hoisted_3 = {
  key: 0,
  class: "grow g-menu-main-title"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "grow sub-title flex flex-col max-h-[300px] flex-wrap" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.store.setShowMenu(true))),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.store.setShowMenu(false))),
    class: _normalizeClass(["g-menu text-gary-text-color", [{ 'show-menu': true }]])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuList, (menuItem) => {
          return (_openBlock(), _createElementBlock("ul", {
            key: menuItem.MenuId,
            class: "grow title mx-12 max-w-[200px]"
          }, [
            (_ctx.store.state.showMenu)
              ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
                  _createElementVNode("span", {
                    onClick: ($event: any) => (_ctx.goSelectUrl(menuItem.TargetPageType, menuItem.RefId))
                  }, _toDisplayString(menuItem.Name), 9, _hoisted_4),
                  (menuItem.ChildMenus.length != 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createElementVNode("ul", _hoisted_6, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuItem.ChildMenus, (subMenuItem) => {
                            return (_openBlock(), _createElementBlock("li", {
                              key: subMenuItem.MenuId,
                              class: "pr-[30px]"
                            }, [
                              _createElementVNode("span", {
                                onClick: ($event: any) => (
                      _ctx.goSelectUrl(
                        subMenuItem.TargetPageType,
                        menuItem.RefId,
                        subMenuItem.RefId
                      )
                    )
                              }, [
                                _createTextVNode(_toDisplayString(subMenuItem.Name), 1),
                                (subMenuItem.TargetPageType === 'Customized')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, " (" + _toDisplayString(subMenuItem.CmdyCount) + ")", 1))
                                  : _createCommentVNode("", true)
                              ], 8, _hoisted_7)
                            ]))
                          }), 128))
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ], 512), [
        [_vShow, _ctx.store.state.showMenu]
      ])
    ])
  ], 32))
}